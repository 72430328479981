import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'
import { CommonStyles } from 'styles/CommonStyles'

interface IGridProps {
  columns?: number
}

interface IButton {
  typeButton?: 'secondary' | 'order'
  width?: string
  height?: string
  marginRight?: string
  marginTop?: string
}

export const StaticHiringTable = styled.table`
  text-align: left;
`
export const StaticHiringThead = styled.thead``

export const StaticHiringTR = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 15px 0px rgba(51, 51, 51, 0.06);
  margin: 20px 0;
  border-radius: 15px;
`

export const StaticHiringTRTitles = styled.tr`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StaticHiringTH = styled.th`
  padding: 20px 0;

  &:first-of-type {
    padding: 20px;
  }
`

export const StaticHiringTBody = styled.tbody``

export const StaticHiringTD = styled.td`
  padding: 20px 40px;

  &:first-child {
    width: 60%;
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:nth-child(3) {
    width: 20%;
  }

  &:last-child {
    width: 5%;
  }

  svg {
    cursor: pointer;
  }
`

export const Checkbox = styled.input`
  margin: 0;
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  :checked {
    border: none;
    background: ${COLORS.gray70} url(${checkboxIcon}) no-repeat center;
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const Link = styled.a<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
  text-decoration: underline;
`
