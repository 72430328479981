/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react'
import { Tags } from 'styles/Tags'
import { HiringTypeBlockStyles as ST } from './styled'
import { HiringTypeBlockTypes as Types } from './types'
import {
  HiringTypeExpense,
  HiringTypeExpenseGroup,
  HiringTypeExpenseGroupOptions,
  HiringTypeExpenseTypeOptions,
  HiringTypeStatus,
} from 'types/model/hiringType'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as ArchiveIcon } from 'assets/icons/Archive.svg'
import { archiveHiringType } from 'api/hiringType'
import { PopupWrapper } from 'components/ui/overlay/Popup'
import { COLORS } from 'constants/colors'
import { useModalWindowState } from 'hooks/useModalWindowState'
import ArchiveModal from 'components/settings/Tabs/shared/ArchiveModal'

type TableRowTextProps = {
  value: string | null | undefined
}

type ExpenseProps = {
  group: HiringTypeExpenseGroup
  expenses: HiringTypeExpense[]
}

const TableRowText: FC<TableRowTextProps> = ({ value }) => (
  <Tags.p1>{value}</Tags.p1>
)

const ExpenseGroup: FC<ExpenseProps> = ({ group, expenses }) => {
  const groupData = HiringTypeExpenseGroupOptions.find(
    (v) => v.value === group
  )!

  return expenses.length ? (
    <ST.TableRow key={group}>
      <Tags.p2Bold>
        {groupData.value}. {groupData.item}
      </Tags.p2Bold>

      <ST.TableRowDataContainer>
        {expenses.map((item) => (
          <ST.TableRowData key={item.id}>
            <TableRowText value={item.name} />
            <TableRowText
              value={`${item.value}${
                HiringTypeExpenseTypeOptions.find(
                  (opt) => opt.value === item.type
                )?.item
              }`}
            />
            <TableRowText value={String(item.number)} />
          </ST.TableRowData>
        ))}
      </ST.TableRowDataContainer>
    </ST.TableRow>
  ) : null
}

const expenseGroups: HiringTypeExpenseGroup[] = [
  HiringTypeExpenseGroup.GENERAL,
  HiringTypeExpenseGroup.OVER_SALARY,
  HiringTypeExpenseGroup.FROM_SALARY,
]

const HiringTypeBlock: FC<Types.Props> = ({ data, toggleEditModal }) => {
  const { modalOpen, toggleModal } = useModalWindowState()
  const [isArchived, setIsArchived] = useState<boolean>(
    data.status === HiringTypeStatus.ARCHIVED
  )

  const handleArchive = async () =>
    archiveHiringType(data.id).then(() => setIsArchived((p) => !p))

  const getExpensesByGroup = (group: HiringTypeExpenseGroup) =>
    data.expenses?.filter((v) => v.expenseGroup === group) ?? []

  return (
    <ST.Container>
      <ST.Table>
        <ST.Header>
          <Tags.p1Bold>{data.name}</Tags.p1Bold>
          <Tags.p2Bold>Название</Tags.p2Bold>
          <Tags.p2Bold>Значение</Tags.p2Bold>
          <Tags.p2Bold>№ списания</Tags.p2Bold>
        </ST.Header>

        {expenseGroups.map((group) => (
          <ExpenseGroup
            key={`expense_group-${group}`}
            group={group}
            expenses={getExpensesByGroup(group)}
          />
        ))}
      </ST.Table>

      <ST.Buttons>
        <IconButton icon={EditIcon} onClick={toggleEditModal} />
        <PopupWrapper
          padding={10}
          fontColor={COLORS.black70}
          fontSize={14}
          text={isArchived ? 'Вернуть из архива' : 'В архив'}
        >
          <IconButton
            icon={ArchiveIcon}
            onClick={toggleModal}
            typeButton={isArchived ? 'secondary' : 'primary'}
          />
        </PopupWrapper>
      </ST.Buttons>

      <ArchiveModal
        open={modalOpen}
        onClose={toggleModal}
        onSubmit={handleArchive}
        isArchived={isArchived}
        title="типа найма"
        description={`тип найма "${data.name}"`}
      />
    </ST.Container>
  )
}

export default HiringTypeBlock
