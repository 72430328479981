import styled from 'styled-components'
import { IIcon } from 'components/Header/Menu/styled'
import ImageEmployee from 'assets/images/employee.jpg'
import { BreakPoints } from 'constants/breakPoints'
import { CommonStyles } from 'styles/CommonStyles'
import { Link } from 'react-router-dom'

export const AuthorLine = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    justify-content: flex-end;
    width: 100%;
  }
`

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-right: 4px;
`

export const Photo = styled.div<IIcon>`
  background: url(${({ imageSrc }) => imageSrc ?? ImageEmployee}) 50% 50%
    no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: 40px 25px;
  position: relative;
  margin: 0 10px;
`

export const Name = styled(Link)<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
`
