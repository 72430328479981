import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { AuthState } from 'types/authType'
import { setUserId as setUserIdAction } from 'store/actions/userId'
import useLocalStorage from 'hooks/useLocalstorage'
import { ROLES } from 'constants/roles'
import { AuthActionCreators } from 'store/actions/auth'
import checkRole from 'utils/profile/checkRole'

const AVAILABLE_ROLES = Object.keys(ROLES)

type UserRoles = (typeof AVAILABLE_ROLES)[number]

type Permissions = Record<UserRoles, boolean>

interface UseAuthReturnType {
  isLoading: boolean
  isAuth: boolean
  permissions: Permissions
  roles: ROLES[]
  token: string
  userId: number | null
  setState: SetState<AuthState>
}

const DEFAULT_AUTH_STATE: AuthState = {
  isAuthorised: false,
  accessToken: '',
  role: [],
}

export const useAuth = (): UseAuthReturnType => {
  const dispatch = useDispatch()
  const { accessToken, isAuthorised, role } = useSelector(
    (state: RootState) => state.auth
  )
  const [authState, setAuthState] = useLocalStorage<AuthState>(
    'auth',
    DEFAULT_AUTH_STATE
  )

  const [userId] = useLocalStorage<number | null>('userId', null)
  const [isLoading, setIsLoading] = useLocalStorage<boolean>(
    'isLoading',
    true,
    true
  )

  const permissions = useMemo(
    () =>
      AVAILABLE_ROLES.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: checkRole(role, [ROLES[curr as keyof typeof ROLES]]),
        }),
        {} as Permissions
      ),
    [role]
  )

  // Обновляем Redux store при изменении authState в localStorage
  useLayoutEffect(() => {
    if (
      authState.accessToken &&
      (authState.accessToken !== accessToken ||
        JSON.stringify(authState.role) !== JSON.stringify(role))
    ) {
      dispatch(
        AuthActionCreators.update({
          accessToken: authState.accessToken,
          role: authState.role,
        })
      )
    }
    setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, authState])

  // Обновляем Redux store при изменении userId в localStorage
  useLayoutEffect(() => {
    if (userId !== null) {
      dispatch(setUserIdAction(userId))
    }
  }, [dispatch, userId])

  // Обновляем authState в localStorage при изменениях в Redux Store
  // TODO эффект не позволяет очистить localStorage при выходе из системы, остаются данные авторизации
  //  нужен ли он вообще? не достаточно ли обновления localStorage внутри authReducer?
  // TODO UPD: нужно доработать эту логику: этот эффект исправляет один баг, но влечет за собой два других
  /*useLayoutEffect(() => {
    // Проверяем, изменилось ли состояние, прежде чем обновлять localStorage
    setAuthState((prevState) =>
      accessToken &&
      role &&
      (prevState.accessToken !== accessToken ||
        JSON.stringify(prevState.role) !== JSON.stringify(role))
        ? { isAuthorised, accessToken, role }
        : prevState
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorised, accessToken, role])*/

  return useMemo<UseAuthReturnType>(
    () => ({
      isLoading,
      isAuth: isAuthorised,
      permissions,
      token: accessToken,
      roles: authState.role?.length ? authState.role : role,
      userId: userId ? +userId : null,
      setState: setAuthState,
    }),
    [
      accessToken,
      authState.role,
      isAuthorised,
      isLoading,
      permissions,
      role,
      setAuthState,
      userId,
    ]
  )
}
