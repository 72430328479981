import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { CommonStyles } from 'styles/CommonStyles'
import { Link } from 'react-router-dom'

export const DepartmentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 27.5px 40px;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;

  @media (max-width: ${BreakPoints.TABLETS}) {
    flex-direction: column;
    align-items: initial;
    gap: 16px;
    padding: 10px 15px;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`

export const Label = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const Number = styled.div``

export const Title = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
`

export const Boss = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`

export const BossName = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
`

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const EmptyDirector = styled.p`
  margin-left: 44px;
`
