import { IItem, ILabelItem } from 'components/ui/BaseSelect'
import { GradeTypes } from 'types/model/grade'
import { DepartmentTypes } from 'types/model/department'
import { TechnologyStackTypes } from 'types/model/technologyStack'
import { BenefitTypes } from 'types/model/benefit'
import { HardwareTypes } from 'types/model/hardware'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { VacancyTypes } from 'types/model/vacancy'
import { HiringStep } from 'types/model/hiring'
import { UserTypes } from 'types/model/user'

export interface IDynamicItem extends IItem {
  new?: boolean
}

interface IInitialItem {
  id: number
  name: string
}

export const parseItems = <T extends IInitialItem>(
  data: T[],
  dynamic: boolean
): IItem<number>[] => {
  let items: IItem<number>[] = []
  data.forEach((item) => {
    items.push({
      item: item.name,
      value: item.id,
      ...(dynamic ? { new: false } : {}),
    })
  })
  return items
}

export const parseDataToEmployees = (
  data: UserTypes.Model[]
): IItem<number>[] => {
  let employees: IItem<number>[] = []
  data.forEach((item) => {
    employees.push({ item: item.surname + ' ' + item.name, value: item.id })
  })
  return employees
}

export const parseDataToHiringSteps = (data: HiringStep[]): IItem[] => {
  let steps: IItem[] = []
  data.forEach((item) => {
    steps.push({ item: item.name, value: item.id! })
  })
  return steps
}

export const parseUserToEmployees = (data: any): IItem<number>[] => {
  let employees: IItem<number>[] = []
  data.forEach((item: any) => {
    employees.push({ item: item.surname + ' ' + item.name, value: item.id })
  })
  return employees
}

export const parseLabelDataToEmployees = (
  data: UserTypes.Model[]
): ILabelItem[] => {
  let employees: ILabelItem[] = []
  data.forEach((item) => {
    employees.push({ label: item.surname + ' ' + item.name, value: item.id })
  })
  return employees
}

export const parseHardwareTypesData = (
  data: HardwareTypes.HardwareType[]
): IItem<number>[] => {
  let hardwareTypes: IItem<number>[] = []
  data.forEach((item) => {
    hardwareTypes.push({ item: item.name, value: item.id })
  })
  return hardwareTypes
}

export const parseDataToBenefits = (
  data: BenefitTypes.Model[]
): IItem<number>[] => {
  let benefits: IItem<number>[] = []
  data.forEach((item) => {
    benefits.push({ item: item.type, value: item.id })
  })
  return benefits
}

export const parseDataToDynamicPositions = (
  data: DepartmentTypes.Position[]
) => {
  return parseItems<DepartmentTypes.Position>(data, true)
}

export const parseDataToPosts = (
  data: DepartmentTypes.Position[]
): IItem<number>[] => {
  return parseItems<DepartmentTypes.Position>(data, false)
}

export const parseDataToDepartments = (
  data: DepartmentTypes.Model[]
): IItem<number>[] => {
  return parseItems<DepartmentTypes.Model>(data, false)
}

export const parseDataToGrades = (data: GradeTypes.Model[]): IItem[] => {
  return parseItems<GradeTypes.Model>(data, false)
}

export const parseDataToDynamicGrades = (data: GradeTypes.Model[]): IItem[] => {
  return parseItems<GradeTypes.Model>(data, true)
}

export const parseDataToDynamicTechnologies = (
  data: TechnologyStackTypes.Technology[]
): IItem[] => {
  return parseItems<TechnologyStackTypes.Technology>(data, true)
}

export const parseStatuses = (data: UserTypes.Status[]): IItem[] => {
  return parseItems<UserTypes.Status>(data, false)
}

export const parseDate = (date: string): string => {
  return date.split('.').join('-')
}

export const parsePhone = (phone: string): string => {
  return phone.replace(/[^+\d]/g, '').slice(2)
}

export const getSettingByKey = (
  settings: ISetting[] | undefined,
  key: SettingsKeys
) => {
  return settings?.length
    ? settings.find((setting) => setting.name === key) || null
    : null
}

export const parseDataToVacancies = (data: VacancyTypes.Model[]): IItem[] => {
  let vacancies: IItem[] = []
  data.forEach((item) => {
    let date = new Date(item.dateAt).toLocaleDateString('ru')
    vacancies.push({
      item: `${item.position ?? ''},${'  '}${item.grade ?? ''}${'  '}${date}`,
      value: item.id,
    })
  })
  return vacancies
}
