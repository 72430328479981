import React, { FC } from 'react'
import * as ST from './styled'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { Link } from 'react-router-dom'
import { BenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'
import { setToastSuccess } from 'utils/handlerError'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'

interface Props {
  benefitApplication: BenefitApplicationTypes.Model
}

const TargetLine: FC<Props> = ({ benefitApplication }) => (
  <ST.TargetLine>
    <ST.InfoCard>
      <ST.CardTitle>Сотрудник</ST.CardTitle>
      <ST.CardLines>
        <Link to={`/user/${benefitApplication.targetUser.id}`}>
          <ST.CardLine>
            {benefitApplication.targetUser?.surname}{' '}
            {benefitApplication.targetUser?.name}{' '}
            {benefitApplication.targetUser?.patronymic}
          </ST.CardLine>
        </Link>
        <ST.CardLine>
          На счету:{' '}
          {benefitApplication.targetUser?.benefitWallet?.balance.toLocaleString(
            'ru'
          ) || 0}
        </ST.CardLine>
      </ST.CardLines>
    </ST.InfoCard>
    <ST.InfoCard>
      <ST.CardTitle>
        {benefitApplication.benefit.operationType === 1
          ? 'Начисление'
          : 'Списание'}{' '}
      </ST.CardTitle>
      <ST.CardLines>
        <ST.CardLine>Вид: {benefitApplication.benefit?.type}</ST.CardLine>
        <ST.CardLine>
          Номинал: {benefitApplication.value.toLocaleString('ru') ?? 0}
        </ST.CardLine>
      </ST.CardLines>
    </ST.InfoCard>
    <ST.InfoCard
      fullWidth={
        benefitApplication.status !==
        BenefitApplicationStatuses.underConsideration
      }
    >
      <ST.CardTitle>Комментарий автора</ST.CardTitle>
      <ST.CardLines>
        <ST.CardLine>{benefitApplication.commentary}</ST.CardLine>
      </ST.CardLines>
      {benefitApplication.links && benefitApplication.links.length > 0 && (
        <ST.CardLines>
          <ST.CardLine>
            Ссылки:{' '}
            {benefitApplication.links?.map((link, index) => (
              <ST.ContainerLink key={index}>
                <ST.CardLineLink href={link} target={'_blank'}>
                  {link}
                </ST.CardLineLink>
                <IconButton
                  style={{ width: '30px', height: '30px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(link).then(() => {
                      setToastSuccess('Ссылка скопирована')
                    })
                  }}
                  icon={CopyIcon}
                  noFill
                />
              </ST.ContainerLink>
            ))}
          </ST.CardLine>
        </ST.CardLines>
      )}
    </ST.InfoCard>
    {benefitApplication.status === BenefitApplicationStatuses.rejected && (
      <ST.InfoCard>
        <ST.CardTitle>Причина отклонения</ST.CardTitle>
        <ST.CardLines>
          <ST.CardLine>{benefitApplication.reviewerCommentary}</ST.CardLine>
        </ST.CardLines>
      </ST.InfoCard>
    )}
  </ST.TargetLine>
)

export default TargetLine
