import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { CommonStyles } from 'styles/CommonStyles'
import { BreakPoints } from 'constants/breakPoints'

export const TargetLine = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 20px;
`

export const AccordionDocuments = styled.div`
  padding: 3px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.black70};
  text-decoration: underline;
`

export const InfoCard = styled.div`
  flex-grow: 1;
`

export const InfoBlock = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
`

export const Task = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.WrappedText};
  max-width: 800px;
  font-size: 14px;
  color: ${COLORS.black70};
  padding: 4px 0 4px 8px;
  margin-left: 8px;

  &:not(:empty)::before {
    content: '—';
    position: absolute;
    left: -4px;
  }
`

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`

export const AccordionItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 3px 0;
`

export const AccordionValue = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.WrappedText};
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
`

export const InfoTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: ${COLORS.black};
`

export const InfoTitleTask = styled.p`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: ${COLORS.black};
`

export const AccordionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .MuiPaper-root {
    background-color: ${COLORS.white};
  }

  > div {
    width: 100%;
    margin-right: 10px;
  }

  > div > div {
    padding: 0;
    min-height: 1px !important;

    > div {
      margin: 0 !important;
    }
  }

  > div > div:nth-child(2) > div > div > div > div {
    padding: 0;
  }
`

export const CircleAccordion = styled.div`
  width: 6px;
  height: 6px;
  background: ${COLORS.black};
  border-radius: 10px;
`

export const ReviewResults = styled.div`
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
`

export const Points = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const PointCircle = styled.div`
  width: 6px;
  height: 6px;
  background: ${COLORS.black};
  border-radius: 10px;
`
export const PointText = styled.p`
  width: 100%;
  font-size: 14px;
  line-height: 16.41px;
  color: ${COLORS.black};
`

export const Comment = styled.div`
  margin-left: 14px;
  width: 80%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.black70};
`

export const TaskListTitle = styled.div<{ color?: string }>`
  display: flex;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  line-height: 19.6px;
  color: ${({ color }) => color};
`

export const TitleTaskNotCompleted = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.errorRed};
`

export const TitleTaskNext = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.black};
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
  }
`
