import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import { getEmployees } from 'api/employees'
import { handlerError } from 'utils/handlerError'
import { ReactComponent as Back } from 'assets/icons/arrow-left.svg'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { parseDataToEmployees, parseHardwareTypesData } from 'utils/parseData'
import { PATHS } from 'constants/paths'
import { ButtonLabels } from 'constants/buttonLabels'
import { Placeholders } from 'constants/placeholders'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import { editHardware, getHardware } from 'api/hardwares'
import { HardwareTypes } from 'types/model/hardware'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import debounce from 'lodash/debounce'
import { BreakPoints } from 'constants/breakPoints'
import { getHardwareTypes } from 'api/hardwareTypes'
import Body from 'components/ui/layout/Body'
import Loader from 'components/ui/Loader'
import IconButton from 'components/ui/buttons/IconButton'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import BaseInput from 'components/ui/inputs/BaseInput'

const inputName = 'name'
const hardwareType = 'hardwareType'
const serialNumber = 'serialNumber'
const cost = 'cost'

const EditHardware = () => {
  useTitle(PAGES.editHardware)

  const navigate = useNavigate()
  const { hardwareId } = useParams()
  const [hardware, setHardware] = useState<Nullable<HardwareTypes.Model>>(null)
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [hardwareTypesList, setHardwareTypesList] = useState<
    HardwareTypes.HardwareType[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const updateHardware = (id: number) => {
    getHardware(id)
      .then((data) => {
        setHardware(data)
      })
      .catch((err) => handlerError(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getHardwareTypes().then((resp) => {
      setHardwareTypesList(resp.data)
    })
  }, [])

  const fetchLists = () => {
    getEmployees()
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((err) => handlerError(err))
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, {}, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const { handleChange, values, handleSubmit, setFieldValue, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: hardware?.name ?? '',
        [hardwareType]: hardware?.hardwareType?.id ?? NaN,
        hardwareTypeData: hardwareTypesList?.length
          ? parseHardwareTypesData(hardwareTypesList).find(
              (type) => type?.value === hardware?.hardwareType?.id
            )
          : { item: '', value: 0 },
        serialNumber: hardware?.serialNumber ?? '',
        cost: hardware?.cost ?? '',
        responsibleUser: {
          value: hardware?.responsibleUser?.id ?? '',
          item: hardware?.responsibleUser
            ? `${hardware.responsibleUser.name} ${hardware.responsibleUser.surname}`
            : '',
        },
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required(RequiredFields.base),
        [hardwareType]: Yup.number().required(RequiredFields.base),
      }),
      onSubmit: async (data) => {
        await editHardware(+hardwareId!, {
          ...data,
          responsibleUserId: values.responsibleUser.value
            ? +values.responsibleUser.value
            : null,
          hardwareTypeId: +values[hardwareType],
          cost: +data.cost,
        })
        hardwareId && updateHardware(+hardwareId)
        navigate(PATHS.hardwares)
        fetchLists()
      },
    })

  useEffect(() => {
    hardwareId && updateHardware(+hardwareId)
    fetchLists()
    window.scrollTo({
      top: 0,
    })
  }, [hardwareId])

  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  return (
    <Body>
      <ST.EditDepartmentHead>
        <ST.HeadDepartment>
          <IconButton icon={Back} onClick={() => navigate(PATHS.hardwares)} />
          <ST.TitleDepartment>редактирование</ST.TitleDepartment>
        </ST.HeadDepartment>
        <BaseButton
          width={200}
          height={48}
          type={'submit'}
          text={ButtonLabels.save}
          typeButton={ButtonTypes.primary}
          onClick={handleSubmit}
        />
      </ST.EditDepartmentHead>

      <ST.EditDepartmentBody>
        {isLoading ? (
          <Loader marginTop="40px" />
        ) : (
          <>
            <ST.InputsRow>
              <BaseInput
                placeholder={Placeholders.hardwareName}
                id={inputName}
                name={inputName}
                value={values.name}
                onChange={handleChange}
                label="Название"
                required
              />
            </ST.InputsRow>

            <ST.InputsRow>
              <ST.SelectWrapper>
                <BaseSelect
                  label="Тип оборудования"
                  isSmallSelect={false}
                  placeHolder={Placeholders.hardWareType}
                  listItems={hardwareTypesList.map((t) => ({
                    item: t.name,
                    value: t.id,
                  }))}
                  name={hardwareType}
                  error={errors[hardwareType]}
                  value={values?.hardwareTypeData?.item || ''}
                  typeSelect={hardwareType}
                  onChange={(newValue) => {
                    setFieldValue(
                      'hardwareTypeData',
                      newValue ?? { item: '', value: 0 }
                    )
                    setFieldValue(hardwareType, newValue?.value ?? null)
                  }}
                  required
                />
              </ST.SelectWrapper>
              <ST.SelectWrapper>
                <ST.Label>Ответственный</ST.Label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  clearOnBlur={false}
                  blurOnSelect={true}
                  noOptionsText={'Нет доступных вариантов'}
                  options={filterUserList}
                  style={
                    isMobileDevice
                      ? {
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000',
                          backgroundColor: '#f8f8f8',
                          boxSizing: 'border-box',
                          borderRadius: '10px',
                          height: '48px',
                          fontWeight: '400',
                          fontSize: '16px',
                          width: '100%',
                          maxWidth: '500px',
                        }
                      : {
                          color: '#000000',
                          backgroundColor: '#f8f8f8',
                          padding: '0',
                          marginBottom: '30px',
                          borderRadius: '10px',
                          height: '20px',
                          fontWeight: '400',
                          fontSize: '16px',
                          width: '100%',
                          maxWidth: '500px',
                        }
                  }
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '0 !important',
                    },
                    '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
                      opacity: '0',
                    },
                    '& .MuiFormLabel-filled': {
                      display: 'none !important',
                    },
                    '& .MuiFormLabel-filled + div > fieldset > legend': {
                      display: 'none !important',
                    },
                    '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: 'transparent',
                      },
                    '& .MuiFormLabel-root': {
                      top: '-8px',
                      color: '#606060',
                      opacity: '0.5',
                      fontFamily: 'Golos, sans-serif !important',
                    },
                    '& .MuiAutocomplete-input': {
                      ...(isMobileDevice
                        ? {
                            paddingLeft: '16px !important',
                            paddingRight: '16px !important',
                            fontFamily: 'Golos, sans-serif !important',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            color: 'black',
                          }
                        : {
                            paddingLeft: '20px !important',
                            fontFamily: 'Golos, sans-serif !important',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            color: 'black',
                          }),
                    },
                    '& fieldset': {
                      border: 'none !important',
                    },
                  }}
                  filterSelectedOptions
                  value={values.responsibleUser}
                  onInputChange={debounce(
                    (e: any) => handleInputChange(e?.target?.value),
                    1000
                  )}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.value === value.value
                  }
                  filterOptions={(options: any) => options}
                  getOptionLabel={(option: any) => option.item}
                  onChange={(_, value) => {
                    if (value) {
                      setFieldValue('responsibleUser', value)
                    } else {
                      setFieldValue('responsibleUser', { item: '', value: 0 })
                    }
                  }}
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box
                        component="li"
                        style={{
                          backgroundColor: '#fefefe',
                          border: 'none',
                          color: '#898989',
                        }}
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.value}
                      >
                        {option.item}
                      </Box>
                    )
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      style={{
                        backgroundColor: '#f8f8f8',
                        borderWidth: 0,
                        '::placeholder': {
                          opacity: '0.5',
                        },
                        borderRadius: '10px',
                      }}
                      {...params}
                      label="Сотрудник"
                    />
                  )}
                />
              </ST.SelectWrapper>
              <BaseInput
                placeholder={Placeholders.serialNumber}
                id={serialNumber}
                name={serialNumber}
                value={values.serialNumber}
                onChange={handleChange}
                label="Серийный номер"
              />
              <BaseInput
                placeholder={Placeholders.cost}
                id={cost}
                name={cost}
                value={values.cost}
                onChange={handleChange}
                label="Стоимость, рублей"
              />
            </ST.InputsRow>
          </>
        )}
      </ST.EditDepartmentBody>
    </Body>
  )
}

export default EditHardware
