import { useEffect, useRef } from 'react'

export const useTitle = (title: string) => {
  const documentDefined = typeof document !== 'undefined'
  const originalTitle = useRef<string>(documentDefined ? document.title : null)
  useEffect(() => {
    const { current } = originalTitle
    document.title = title ?? current
    return () => {
      document.title = current ?? title
    }
  }, [title])
}
