import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { Link } from 'react-router-dom'

interface IButton {
  typeButton?: 'secondary' | 'primary'
  fakeDisabled?: boolean
  type?: string
}

interface IButtonLink extends IButton {
  $disabled?: boolean
}

const disabledStyles = css`
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
`

const ButtonCss = css<IButton>`
  width: 100%;
  background-color: ${({ typeButton }) =>
    typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
  border: 1px solid ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  user-select: none;

  ${({ fakeDisabled }) =>
    fakeDisabled
      ? disabledStyles
      : css`
          &:enabled:hover {
            background-color: ${COLORS.yellowHover};
            border: 1px solid ${COLORS.yellowHover};
          }
        `};

  &:disabled {
    ${disabledStyles};
  }
`

export const Button = styled.button.attrs((props: IButton) => ({
  type: props.type || 'submit',
}))<IButton>`
  ${ButtonCss};
`

export const ButtonLink = styled(Link)<IButtonLink>`
  ${ButtonCss};

  ${({ $disabled }) =>
    $disabled
      ? disabledStyles
      : css`
          &:hover {
            background-color: ${COLORS.yellowHover};
            border: 1px solid ${COLORS.yellowHover};
          }
        `};

  display: grid;
  align-items: center;
  text-align: center;
`
