import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

interface IButton {
  typeButton?: 'secondary' | 'primary'
}

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 580px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

export const Button = styled.button<IButton>`
  width: 100%;
  background-color: ${({ typeButton }) =>
    typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
  border: 1px solid ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.yellowHover};
    border: 1px solid ${COLORS.yellowHover};
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: auto;
    flex-direction: column;
  }
`

export const Description = styled.p`
  color: ${COLORS.black70};
  font-size: 16px;
  margin-top: 10px;
`
