import { PATHS } from 'constants/paths'
import { ReactNode } from 'react'
import { UserImg } from 'types/model/user'

export namespace MenuTypes {
  export type Props = {
    show: boolean
    onClose: (isShow: boolean) => void
  }

  export type MenuItemProps = {
    path?: MenuPaths
    activePaths?: PATHS[]
    icon: ReactNode | string
    name: string
    goToPage?: (path: string) => void
    onClick?: () => void
    badgeValue?: number
  }

  export type MenuItemType = Omit<MenuItemProps, 'goToPage'>

  export type MenuPaths =
    | PATHS.home
    | PATHS.employees
    | PATHS.reviews
    | PATHS.applications
    | PATHS.benefitApplications
    | PATHS.vacancies
    | PATHS.hardwares
    | PATHS.activityLog
    | PATHS.departments
    | PATHS.positions
    | PATHS.technologyStack
    | PATHS.grades
    | PATHS.settings

  export type IImgUser = UserImg

  export type Counter = {
    itemType: number
    count: number
  }

  export enum MenuItem {
    REVIEW = 1,
    APPLICATION = 2,
    BENEFIT_APPLICATION = 3,
  }
}
