import React, { FC } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import { handlerError } from 'utils/handlerError'
import { EditingGeneralTabContentStrings } from 'components/settings/Tabs/Contacts/EditingContactsTabContent'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { putSetting } from 'api/settings'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import { RequiredFields } from 'constants/requiredFields'
import * as Yup from 'yup'
import { IntegrationsSettingsTypes as T } from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/types'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseInput from 'components/ui/inputs/BaseInput'
import BackButton from 'components/ui/buttons/BackButton'

const inputsData: T.InputData[] = [
  {
    label: 'Название интеграции',
    placeholder: 'Название',
    field: 'api_hh_name',
  },
  {
    label: 'Ключ',
    placeholder: 'Ключ',
    field: 'hh_apikey',
  },
  {
    label: 'Client Secret',
    placeholder: 'Client Secret',
    field: 'hh_secret',
  },
  {
    label: 'Client ID',
    placeholder: 'Client ID',
    field: 'hh_client_id',
  },
]

type IForm = Record<
  'hh_apikey' | 'api_hh_name' | 'hh_secret' | 'hh_client_id',
  string | undefined
>

interface IEditingMailTabContent {
  handleIsEditing: () => void
  setIsEditing: SetState<boolean>
  isEditingTabs: boolean
  data: ISetting[]
}

const HHTab: FC<IEditingMailTabContent> = ({ handleIsEditing, data }) => {
  const onSubmit = async () => {
    const filteredSettings = data.filter((setting) =>
      [
        SettingsKeys.HH_APIKEY,
        SettingsKeys.API_HH_NAME,
        SettingsKeys.HH_CLIENT_ID,
        SettingsKeys.HH_SECRET,
      ].includes(setting.name as SettingsKeys)
    )

    const settingsPromises = filteredSettings.map((setting) => {
      switch (setting.name) {
        case SettingsKeys.HH_APIKEY:
          return putSetting(setting.id, {
            ...setting,
            value: values.hh_apikey,
          })
        case SettingsKeys.API_HH_NAME:
          return putSetting(setting.id, {
            ...setting,
            value: values.api_hh_name,
          })
        case SettingsKeys.HH_SECRET:
          return putSetting(setting.id, {
            ...setting,
            value: values.hh_secret,
          })
        case SettingsKeys.HH_CLIENT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.hh_client_id,
          })
      }
    })

    Promise.all(settingsPromises)
      .then(() => {
        handleIsEditing()
      })
      .catch((error) => console.error(error))
  }

  const { handleSubmit, handleChange, values, errors, isValid } =
    useFormik<IForm>({
      enableReinitialize: true,
      initialValues: {
        hh_apikey: getSettingByKey(data, SettingsKeys.HH_APIKEY)?.value,
        api_hh_name: getSettingByKey(data, SettingsKeys.API_HH_NAME)?.value,
        hh_secret: getSettingByKey(data, SettingsKeys.HH_SECRET)?.value,
        hh_client_id: getSettingByKey(data, SettingsKeys.HH_CLIENT_ID)?.value,
      },
      onSubmit,
      validationSchema: Yup.object().shape({
        hh_apikey: Yup.string().trim().required(RequiredFields.base),
        api_hh_name: Yup.string().trim().required(RequiredFields.base),
        hh_secret: Yup.string().trim().required(RequiredFields.base),
        hh_client_id: Yup.string().trim().required(RequiredFields.base),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />
          <ST.EditingHeader>Редактирование</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>

        <BaseButton
          typeButton="primary"
          text="Сохранить"
          onClick={checkForm}
          disabled={!isValid}
          width={220}
          height={40}
        />
      </ST.ManageBlock>
      <ST.BlockInfo>
        {inputsData.map(({ label, placeholder, field }) => (
          <ST.BottomWrapper key={field}>
            <BaseInput
              label={label}
              placeholder={placeholder}
              id={EditingGeneralTabContentStrings[field]}
              name={EditingGeneralTabContentStrings[field]}
              value={values[field as keyof IForm]}
              onChange={handleChange}
            />
          </ST.BottomWrapper>
        ))}
      </ST.BlockInfo>
    </>
  )
}

export default HHTab
