import { Editor } from '@tinymce/tinymce-react'
import { TinyMceApiKey } from 'constants/tinyMce'

type Props = {
  initialValue?: string
  setValue: (value: string) => void
}

const TinyMce = ({ initialValue, setValue }: Props) => {
  return (
    <>
      <Editor
        apiKey={TinyMceApiKey}
        value={initialValue ?? ''}
        init={{
          min_height: 178,
          height: 178,
          max_height: 425,
          menubar: false,
          statusbar: false,
          plugins: [
            'autolink',
            'link',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'insertdatetime',
            'code',
            'help',
            'autoresize',
          ],
          toolbar: `undo redo |
             bold italic forecolor |
             alignleft aligncenter alignright alignjustify |
             bullist numlist outdent indent |
             removeformat`,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: #f8f8f8f5 99% 50% no-repeat; }' +
            '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: rgb(205 205 205);}' +
            '.mce-content-body{font-family: Golos, sans-serif; font-size: 16px; background: #f8f8f8f5 99% 50% no-repeat;}',
          language: 'ru',
          placeholder: 'Ваше письмо',
          forced_root_block: '',
          force_br_newlines: true,
          force_p_newlines: false,
          setup: (editor) => {
            editor.on('keydown', (e) => {
              if (e.key === 'Enter') {
                if (!e.shiftKey) {
                  e.preventDefault()
                  editor.execCommand('InsertLineBreak')
                }
              }
            })
          },
        }}
        onEditorChange={(e, editor: any) => setValue(e)}
      />
    </>
  )
}

export default TinyMce
