export enum COLORS {
  absoluteWhite = '#FFFFFF',
  lightGrey20 = '#F8F8F8',
  errorRed = '#FF3B30',
  backgroundBlue = '#898989',
  white = '#fefefe',
  white_mobile = '#FCFCFC',
  black = '#1c1c1c',
  black70 = '#606060',
  black50 = '#8D8D8D',
  black20 = '#D2D2D2',
  gray70 = '#898989',
  gray50 = '#C4C4C4',
  gray20 = '#E7E7E7',
  yellow = '#FFDC24',
  yellow70 = '#FFE766',
  yellow50 = '#FFED91',
  yellow20 = '#FFF8D3',
  yellowHover = '#FECE3C',
  blue = '#0165F5',
  blueHover = '#0A51E1',
  blue70 = '#4D93F8',
  blue50 = '#80B2FA',
  blue20 = '#CCE0FD',
  inputBG = '#f8f8f8',
  gray70Input = '#ACACAC',
  green = '#1ab700',
  red = '#FF0000',
  yellowActiveButton = '#FECE3C',
  overlay = '#00000080',
  lightGrey = '#FAFAFA',
  lightGreyMobile = '#F4F4F4',
}
