import React, { FC } from 'react'
import * as ST from './styled'
import { Link } from 'react-router-dom'

interface Props {
  position: string
  grade: string
  salaryMax: number
  id: number
}

const UserLine: FC<Props> = ({ position, grade, salaryMax, id }) => (
  <Link to={`/vacancies/${id}`} target={'_blank'}>
    <ST.UserLine>
      <ST.Label>{position}, </ST.Label>
      <ST.Label>{grade}</ST.Label>
      <ST.Position>
        ({'до '}
        {salaryMax} {' руб'})
      </ST.Position>
    </ST.UserLine>
  </Link>
)

export default UserLine
