import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { ReactComponent as BackIconPic } from 'assets/icons/arrow-left.svg'

interface IGridProps {
  columns?: number
}

interface IDisabled {
  isDisabled?: boolean
}

interface IButton {
  typeButton?: 'secondary' | 'order'
  width?: string
  height?: string
  marginRight?: string
  marginTop?: string
}

export const DocTemplateList = styled.div`
  display: flex;
  width: 100%;
`

export const DND = styled.div``
export const Checkbox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`
export const CheckboxLabel = styled.label``
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`
export const Checkboxes = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
export const UploadTemplate = styled.div``
export const Delete = styled.div``

export const DocTemplateBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SingleTemplate = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
  padding: 20px 0;
`
export const EditingHiringThead = styled.thead``
export const EditingHiringTR = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
`
export const EditingHiringTH = styled.th`
  padding: 20px 40px;
`
export const EditingHiringTBody = styled.tbody``
export const EditingHiringTD = styled.td`
  padding: 20px 40px;
  &:not(:first-child) {
    padding-left: 0;
  }
  &:first-child {
    width: 60%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:last-child {
    width: 5%;
  }
`

export const BlockInfo = styled.div`
  padding: 30px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
`

export const ManageBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: ${BreakPoints.DESKTOPS_SMALL}) {
    flex-direction: column;
    gap: 20px;
  }
`

export const BackBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`

export const BackButton = styled.div`
  width: 44px;
  height: 40px;
  background: ${COLORS.yellow};
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  outline: none;
  padding: 0 0 0 20px;
  border: 0;
  border-radius: 10px;
  background: ${COLORS.inputBG};
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
`

export const EditingHeader = styled.h2`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const SaveButton = styled.button<IDisabled>`
  width: 220px;
  height: 40px;
  background: ${COLORS.yellow};
  font-family: 'Golos-Text_VF';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  border-radius: 50px;
  cursor: pointer;
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
`

export const BackIcon = styled(BackIconPic)``

export const NameInput = styled.input`
  padding: 10px 20px 8px 20px;
  border-radius: 10px;
  background: #f8f8f8;
`
export const CheckboxInput = styled.input``

export const FullName = styled(BlockInfo)``

export const HeaderBlock = styled.p`
  width: 300px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin: 30px 0 10px;
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const SocInput = styled(Input)`
  width: 300px;
`

export const SocWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SocInputs = styled.div`
  display: flex;
  gap: 40px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  gap: 20px;
`
export const Label = styled.div`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
`
