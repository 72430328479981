import React, { createContext, FC, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as TurnOnIcon } from 'assets/icons/turnOn.svg'
import { ReactComponent as TurnOffIcon } from 'assets/icons/turnOff.svg'
import ServiceModeSwitch from 'components/dialogs/settings/ServiceModeSwitch'
import { useGetServiceModeStatusQuery } from 'store/api/setting'
import Loader from 'components/ui/Loader'
import IconButton from 'components/ui/buttons/IconButton'
import { ROLES } from 'constants/roles'
import { StaticGeneralTabContentTypes as T } from './types'
import { useAuth } from 'hooks/useAuth'
import checkRole from 'utils/profile/checkRole'
import { SystemMode } from 'types/SystemType'

const InfoBlockContext = createContext<T.InfoBlockContextProps>({})

const ServiceModeBlock: FC<T.ServiceModeBlockProps> = ({
  isLoading,
  serviceMode,
  onClick,
}) => (
  <ST.Service>
    <ST.ServiceWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ST.HeaderBlock>Сервисный режим *</ST.HeaderBlock>
          <ST.RowInfo>{serviceMode ? 'Включен' : 'Выключен'}</ST.RowInfo>
        </>
      )}
    </ST.ServiceWrapper>
    <ST.ServiceModeWrapper onClick={onClick}>
      <IconButton
        icon={serviceMode ? TurnOffIcon : TurnOnIcon}
        disabled={isLoading}
        title={`${serviceMode ? 'Выключить' : 'Включить'} сервисный режим`}
      />
    </ST.ServiceModeWrapper>
  </ST.Service>
)

const StaticGeneralTabContent: FC<T.Props> = ({
  tunes,
  updateSettings,
  isLoading,
}) => {
  const [isServiceModeSwitchShow, setIsServiceModeSwitchShow] = useState(false)

  const { roles } = useAuth()

  const {
    data: serviceMode,
    isLoading: isServiceModeLoading,
    isFetching: isServiceModeFetching,
  } = useGetServiceModeStatusQuery(undefined, {
    skip: !checkRole(roles, [ROLES.admin, ROLES.owner, ROLES.sysadmin]),
  })

  const openServiceModeSwitch = () => setIsServiceModeSwitchShow(true)
  const hideServiceModeSwitch = () => setIsServiceModeSwitchShow(false)

  return (
    <InfoBlockContext.Provider value={{ tunes, updateSettings, isLoading }}>
      <ST.MainContainer>
        <ST.GridBlock>
          <ServiceModeBlock
            isLoading={isServiceModeLoading || isServiceModeFetching}
            serviceMode={serviceMode?.systemMode === SystemMode.SERVICE}
            onClick={openServiceModeSwitch}
          />
        </ST.GridBlock>
      </ST.MainContainer>
      <ServiceModeSwitch
        show={isServiceModeSwitchShow}
        onClose={hideServiceModeSwitch}
      />
    </InfoBlockContext.Provider>
  )
}

export default StaticGeneralTabContent
