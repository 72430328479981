export enum ROLES {
  admin = 'ROLE_ADMIN',
  sysadmin = 'ROLE_SYSADMIN',
  recruiter = 'ROLE_RECRUITER',
  customer = 'ROLE_CUSTOMER',
  hr = 'ROLE_HR',
  pm = 'ROLE_PM',
  user = 'ROLE_USER',
  owner = 'ROLE_OWNER',
}

export enum RolesNames {
  admin = 'Администратор',
  sysadmin = 'Системный администратор',
  recruiter = 'Рекрутер',
  customer = 'Заказчик',
  hr = 'HR',
  pm = 'PM',
  user = 'Пользователь',
  owner = 'Владелец системы',
}

export const ALL_ROLES: ROLES[] = Object.values(ROLES)
