import React, { useState } from 'react'
import BaseSelect from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { handlerError } from 'utils/handlerError'
import { ButtonTypes } from 'constants/buttonTypes'
import * as ST from './styled'
import { Placeholders } from 'constants/placeholders'
import { BenefitPatchData, createBenefit } from 'api/benefits'
import { listBenefitOperationTypes } from 'constants/benefitOperationTypesDescription'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { ModalTitles } from 'constants/modalTitles'
import { BenefitStatuses } from 'constants/benefitStatuses'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<any>>
}

const benefitTypeData = 'typeData'
const operationTypeData = 'operationTypeData'

export const selectsEnum = {
  type: 'type',
  operationType: 'operationType',
}

const CreateBenefit = ({ show, onClose, showEndModal }: Props) => {
  const [benefit, setBenefit] = useState<BenefitPatchData | null>(null)

  const { values, handleSubmit, resetForm, errors, isValid, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: true,
      validationSchema: Yup.object().shape({
        type: Yup.string().required(RequiredFields.base),
        operationTypeData: Yup.object().shape({
          value: Yup.number(),
          item: Yup.string().required(RequiredFields.base),
        }),
      }),
      initialValues: {
        type: '',
        operationType: BenefitOperationTypes.refill,
        operationTypeData: {
          value: benefit?.operationType || 1,
          item: null,
        },
      },
      onSubmit: async (newObj) => {
        await createBenefit({
          type: newObj.type,
          operationType: newObj.operationTypeData.value,
          status: BenefitStatuses.active,
        }).then(() => {
          handleClose()
          resetForm()
          if (showEndModal) {
            showEndModal('')
          }
          setBenefit(null)
          setFieldValue(selectsEnum.type, '')
          setFieldValue(operationTypeData, {
            value: benefit?.operationType || 1,
            item: `${BenefitOperationTypes[benefit?.operationType!]}`,
          })
        })
      },
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')

  const handleClose = (): void => {
    onClose(!show)
  }

  const SignupForm = () => {
    return (
      <>
        <ST.InputsBlock>
          <ST.InputWrapper>
            <ST.Label>
              Вид бенефита<ST.Star>*</ST.Star>
            </ST.Label>
            <ST.Input
              sizeInput="big"
              id={selectsEnum.type}
              name={selectsEnum.type}
              value={values?.type}
              onChange={(e) => {
                setFieldValue(selectsEnum.type, e.target.value)
              }}
            />
          </ST.InputWrapper>
          <ST.SelectWrapper>
            <ST.Label>
              Тип<ST.Star>*</ST.Star>
            </ST.Label>
            <BaseSelect
              isSmallSelect={false}
              placeHolder={Placeholders.benefitOperationalType}
              listItems={listBenefitOperationTypes}
              name={operationTypeData}
              value={values?.operationTypeData?.item || ''}
              typeSelect={operationTypeData}
              onChange={(newValue) =>
                setFieldValue(operationTypeData, newValue)
              }
              required
            />
          </ST.SelectWrapper>
        </ST.InputsBlock>
        <ST.Button
          type={ButtonTypes.submit}
          onClick={checkForm}
          disabled={!isValid}
        >
          Добавить
        </ST.Button>
      </>
    )
  }

  return (
    <>
      {show ? (
        <ST.ModalOverlay>
          <ST.Modal onClick={(e) => e.stopPropagation()}>
            <ST.ModalContent>
              <ST.Close onClick={handleClose}>
                <Close />
              </ST.Close>
              <ST.ModalTitle>{ModalTitles.createBenefitType}</ST.ModalTitle>
              {SignupForm()}
            </ST.ModalContent>
          </ST.Modal>
        </ST.ModalOverlay>
      ) : null}
    </>
  )
}

export default CreateBenefit
