import React, { FC, memo, useMemo } from 'react'
import * as ST from './styled'
import TitleLine from './TitleLine'
import TargetLine from './TargetLine'
import { UserTypes } from 'types/model/user'
import Employee from './Employee'
import { ReviewStatuses, ReviewType, ReviewTypes } from 'types/model/review'
import { ReviewTypesLabels } from 'constants/reviewStatusesDescription'
import moment from 'moment'
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar-Review.svg'
import StatusBlock from 'components/reviews/ReviewCard/TitleLine/StatusBlock'
import { DATE_FORMAT_RU } from 'constants/Date'
import getFileData from 'utils/file/getFileData'

type Props = {
  review: ReviewTypes.Model
  updateReviewList?: (userId: number) => void
  usersList: UserTypes.Model[]
  userInformation?: UserTypes.UserInfo | ReviewTypes.User | null
  hideEmployeeBlock?: boolean
  isCurrentUser?: boolean
  isProfile?: boolean
  userId?: number
}

type HeaderProps = {
  type: ReviewType
  date: string
  status: ReviewStatuses
}

const ReviewCardHeader = memo<HeaderProps>(({ type, date, status }) => (
  <ST.ReviewCardHeader>
    <ST.Title>{ReviewTypesLabels[type]}</ST.Title>
    <ST.Info>
      <ST.Date>
        <CalendarIcon />
        <ST.DateText>{date}</ST.DateText>
      </ST.Date>

      <StatusBlock status={status} />
    </ST.Info>
  </ST.ReviewCardHeader>
))

const ReviewCard: FC<Props> = ({
  review,
  userInformation,
  usersList,
  updateReviewList,
  hideEmployeeBlock,
  userId,
  isCurrentUser,
  isProfile,
}) => {
  const reviewDate = useMemo(
    () => moment(review.date).format(DATE_FORMAT_RU),
    [review.date]
  )

  return (
    <ST.ApplicationCard employeeBlockHidden={hideEmployeeBlock}>
      {!hideEmployeeBlock && (
        <Employee user={review.user} photo={getFileData(review.user.userImg)} />
      )}

      <ST.ReviewCardContent>
        <ReviewCardHeader
          type={review.type}
          date={reviewDate}
          status={review.status}
        />

        <TargetLine
          review={review}
          isCurrentUser={isCurrentUser}
          userId={userId}
          isProfile={isProfile}
        />

        {!!usersList?.length && (
          <TitleLine
            review={review}
            status={review.status}
            userId={review.user.id}
            userInformation={userInformation}
            userList={usersList}
            updateReviewList={updateReviewList}
            isCurrentUser={isCurrentUser}
          />
        )}
      </ST.ReviewCardContent>
    </ST.ApplicationCard>
  )
}

export default ReviewCard
