import { apiInstance } from 'api'
import { BenefitOperationTypes, BenefitTypes } from 'types/model/benefit'

import { ExtendedResponseType } from 'types/utils'
import { BenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'
import { BenefitStatuses } from 'constants/benefitStatuses'

export type BenefitPostData = Omit<BenefitTypes.Model, 'id'>
export type BenefitPatchData = Partial<BenefitPostData>

type GetBenefitsParams = {
  pageNumber?: number
  pageSize?: number
  operationType?: BenefitOperationTypes
  status?: BenefitStatuses
}

type ChangeBenefitStatusResponse = {
  id: number
  type: string
  operationType: BenefitOperationTypes
  status: BenefitStatuses
}

export const getBenefits = async (
  args: GetBenefitsParams | void
): Promise<ExtendedResponseType<BenefitTypes.Model>> => {
  const params = new URLSearchParams()

  if (args) {
    const { pageNumber = 1, pageSize = 50, operationType, status } = args

    params.append('pageSize', String(pageSize ?? 50))
    params.append('pageNumber', String(pageNumber ?? 1))
    operationType && params.append('operationType', String(operationType))
    status && params.append('status', String(status))
  }

  return apiInstance
    .get(`/api/benefit?${params.toString()}`)
    .then((resp) => resp.data)
}

export const getBenefitById = async (
  id: number
): Promise<BenefitTypes.Model> => {
  return apiInstance.get(`/api/benefit/${id}`).then((resp) => resp.data)
}

export const createBenefit = async (
  benefit: BenefitPostData
): Promise<BenefitTypes.Model> => {
  return apiInstance
    .post<BenefitTypes.Model>('/api/benefit', { ...benefit })
    .then((resp) => resp.data)
}

export const editBenefit = async (
  benefitId: number,
  benefit: BenefitPatchData
): Promise<BenefitTypes.Model> => {
  return apiInstance
    .put<BenefitTypes.Model>(`/api/benefit/${benefitId}`, benefit)
    .then((resp) => resp.data)
}

export const removeBenefit = async (benefitId: number) => {
  return apiInstance.delete<BenefitTypes.Model>(`/api/benefit/${benefitId}`)
}

export const changeBenefitStatus = async (
  id: number,
  status: BenefitStatuses
): Promise<ChangeBenefitStatusResponse> => {
  return apiInstance.post(`/api/benefit/changeStatus/${id}`, { status })
}
