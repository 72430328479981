import * as ST from './styled'
import React, { FC, useCallback, useRef, useState } from 'react'
import { FILE_FORMATS, FileTypes } from 'constants/fileTypes'
import { deleteFileId, postFile } from 'api/employment'
import { setToastError } from 'utils/handlerError'
import useId from '@mui/material/utils/useId'
import getFileData from 'utils/file/getFileData'

export interface Props {
  id?: string
  accept?: string
  maxFileSize?: number // bytes
  files?: IFileItem[]
  label?: string
  replacement?: boolean
  replacementLabel?: string
  disabled?: boolean
  autoUpload?: boolean
  autoDelete?: boolean
  onChange?: (
    value: IFileItem | null,
    file?: File,
    prevFileId?: number | null
  ) => void
  onRemove?: (fileId?: number | null) => void
  imagePreview?: File | string | undefined
  hideOnFileLoaded?: boolean
  strictValidation?: boolean
  getPathAsName?: boolean
  hideDeleteButton?: boolean
  hideName?: boolean
  isRequiredLabel?: boolean
  noPreview?: boolean
}

export interface IFileItem {
  id: number | null
  name: string | null
  path?: string
  url?: string
}

const bytesToMegabytes = (value: number, precise?: boolean): number =>
  precise ? value / 1024 / 1024 : value / 1000 / 1000

const DEFAULT_FORMATTED_FILE_LENGTH = 24
const DEFAULT_MAX_FILE_SIZE = 10_000_000 // 10 Mb

export const formatFileName = (
  value: string,
  maxNameLength = DEFAULT_FORMATTED_FILE_LENGTH
): string => {
  if (value?.length <= maxNameLength) return value ?? ''

  const [ext, ...fileName] = value.split('.').reverse()

  return fileName.join('').slice(0, maxNameLength).concat(`...${ext}`)
}

const AttachFile: FC<Props> = ({
  id,
  accept = FileTypes.default,
  maxFileSize,
  files,
  label,
  replacement,
  replacementLabel,
  disabled,
  onChange,
  onRemove,
  imagePreview,
  hideOnFileLoaded,
  strictValidation,
  hideDeleteButton,
  isRequiredLabel,
  autoUpload = false,
  autoDelete = false,
  getPathAsName = false,
  noPreview = false,
  hideName = false,
}) => {
  const inputId = useId(id)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const performClick = useCallback(() => {
    hiddenFileInput.current?.click()
  }, [])

  const [tick, setTick] = useState(1)
  const updateRender = () => {
    setTick(tick + 1)
  }

  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return
    const file: File = e.target.files[0]
    const fileName: string = file.name
    const ext = file.name.split('.').at(-1)

    if (ext && strictValidation && !accept?.includes(ext))
      return setToastError('Недопустимый формат файла')

    postFile(file).then(
      (resp) =>
        resp.isSuccess &&
        onChange?.(
          {
            id: resp?.payload?.id || null,
            name: getPathAsName ? getFileData(resp.payload) ?? null : fileName,
          },
          file
        )
    )
  }

  const deleteFile = async (fileId: number): Promise<void> => {
    await deleteFileId(fileId)
      .then((resp) => resp.isSuccess && onChange && onChange(null))
      .then(() => {
        let fileIndex = files?.findIndex((f) => f.id == fileId)
        if (fileIndex != undefined && fileIndex > -1) {
          Array.prototype.splice.call(files, fileIndex, 1)
        }
        updateRender()
      })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return

    const currentFile: File = e.target.files[0]
    const maxSize = maxFileSize || DEFAULT_MAX_FILE_SIZE
    const fileExtension = currentFile.name.split('.').at(-1)

    if (fileExtension && !FILE_FORMATS.includes(fileExtension)) {
      setToastError(
        `Загрузите файл другого формата. Допустимые форматы: ${FILE_FORMATS.map(
          (f) => `.${f}`
        ).join(', ')}`
      )
      e.target.value = ''
      return
    }

    if (currentFile.size > maxSize) {
      setToastError(
        `Файл ${
          currentFile.name
        } превышает максимальный размер ${bytesToMegabytes(maxSize).toFixed(
          2
        )}MB`
      )
      e.target.value = ''
      return
    }

    if (currentFile && !disabled) {
      if (autoUpload) {
        void uploadFile(e)
      } else {
        onChange?.(
          { id: null, name: currentFile.name },
          currentFile,
          files?.[0]?.id
        )
      }
    }

    e.target.value = ''
  }

  return (
    <ST.AttachFileCtr>
      {!hideOnFileLoaded && (
        <ST.HeaderContainer onClick={performClick}>
          <ST.AttachButton>
            <ST.PlusContainer isCursor={!disabled}>
              {replacement ? <ST.ReplaceFile /> : <ST.AttachFile />}
              <input
                id={inputId}
                type="file"
                accept={accept}
                ref={hiddenFileInput}
                onChange={handleChange}
                disabled={!!disabled}
                style={{ display: 'none' }}
              />
            </ST.PlusContainer>
          </ST.AttachButton>
          <ST.DownloadLink
            htmlFor={inputId}
            onClickCapture={(e) => {
              e.stopPropagation()
            }}
          >
            {replacement ? replacementLabel ?? label : label}
            {isRequiredLabel && <ST.Star>*</ST.Star>}
          </ST.DownloadLink>
        </ST.HeaderContainer>
      )}
      {files?.length ? (
        <ST.ContainerFiles
          style={hideOnFileLoaded ? { marginTop: 0 } : undefined}
        >
          {files.map((file) =>
            file.id || file.name ? (
              <ST.FileNameDeleteContainer
                key={file.id || file.name}
                style={hideOnFileLoaded ? { marginBottom: '8px' } : undefined}
              >
                {file.name &&
                  imagePreview &&
                  (!noPreview || accept === FileTypes.documents) && (
                    <ST.ImagePreview
                      src={
                        imagePreview
                          ? typeof imagePreview !== 'string'
                            ? URL.createObjectURL(imagePreview)
                            : imagePreview
                          : file.name
                      }
                    />
                  )}
                {!hideName && file.name && (
                  <ST.FileName
                    href={getFileData(file)}
                    target="_blank"
                    title={file.name}
                  >
                    {formatFileName(file.name)}
                  </ST.FileName>
                )}
                {!hideDeleteButton && (
                  <ST.Delete
                    onClick={() => {
                      if (file.id && autoDelete)
                        deleteFile(file.id).then(() => onRemove?.(file.id))
                      else onRemove?.(file.id)
                    }}
                  />
                )}
              </ST.FileNameDeleteContainer>
            ) : null
          )}
        </ST.ContainerFiles>
      ) : null}
    </ST.AttachFileCtr>
  )
}

export default AttachFile
