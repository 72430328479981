import { css, CSSProperties } from 'styled-components'
import { BreakPoints } from 'constants/breakPoints'

export namespace CommonStyles {
  type MaxWidth = CSSProperties['maxWidth']

  export const DEFAULT_MEDIA: MaxWidthProps['mediaMaxWidth'] = {
    [BreakPoints.DESKTOPS_SMALL]: 700,
    [BreakPoints.NOTEBOOK]: 475,
    [BreakPoints.TABLETS]: 400,
    [BreakPoints.TABLETS_PORTRAIT]: 175,
    [BreakPoints.PHONES]: 100,
  }

  export type MaxWidthProps = {
    maxWidth?: MaxWidth
    mediaMaxWidth?: Partial<Record<BreakPoints, MaxWidth>>
    inlineBlock?: boolean
  }

  const getMediaQueries = (
    value: NonNullable<MaxWidthProps['mediaMaxWidth']>
  ) =>
    Object.entries(value).map(
      ([breakpoint, maxWidth]) => css`
        @media (max-width: ${breakpoint}) {
          max-width: ${typeof maxWidth === 'number'
            ? `${maxWidth}px`
            : maxWidth};
        }
      `
    )

  export const ClippedText = css<MaxWidthProps>`
    ${({ inlineBlock = true }) =>
      inlineBlock &&
      css`
        display: inline-block;
      `};
    max-width: ${({ maxWidth }) =>
      typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth ?? '200px'};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${({ mediaMaxWidth }) => mediaMaxWidth && getMediaQueries(mediaMaxWidth)}
  `

  export const WrappedText = css<MaxWidthProps>`
    ${({ inlineBlock = true }) =>
      inlineBlock &&
      css`
        display: inline-block;
      `};
    word-wrap: break-word;
    max-width: ${({ maxWidth }) =>
      typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth ?? '400px'};

    ${({ mediaMaxWidth }) => mediaMaxWidth && getMediaQueries(mediaMaxWidth)}
  `
}
