import { useEffect, useState } from 'react'

type ReturnValue = [open: boolean, setOpen: SetState<boolean>]

type Params = {
  closeOnBlur?: boolean
  targetIds?: string[]
  listenerTargets?: (Element | Document | null | undefined)[]
}

type UseInputControlledStateHook = (
  initialState?: boolean,
  params?: Params
) => ReturnValue

const defaultParams: Params = {
  listenerTargets: [document],
  closeOnBlur: true,
  targetIds: [],
}

export const useInputControlledState: UseInputControlledStateHook = (
  initialState,
  params = defaultParams
) => {
  const [open, setOpen] = useState<boolean>(initialState ?? false)

  useEffect(() => {
    if (!params?.closeOnBlur) return

    if (open) {
      const handleClickEvent: EventListener = (e) => {
        let id = (e.target as Element)?.id

        if (!id || !params.targetIds?.includes(id)) {
          setOpen(false)
        }
      }

      params.listenerTargets
        ?.filter((target) => target)
        .forEach((target) => {
          target?.addEventListener('click', handleClickEvent)
        })

      return () =>
        params.listenerTargets
          ?.filter((target) => target)
          ?.forEach((target) => {
            target?.removeEventListener('click', handleClickEvent)
          })
    }
  }, [open, params?.closeOnBlur, params?.listenerTargets, params?.targetIds])

  return [open, setOpen]
}
