import React, { FC, useCallback, useState } from 'react'
import * as ST from './styled'
import { HiringStep, NotDeletableReasons } from 'types/model/hiring'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import { HiringStepsLabels } from 'constants/settingsStepsDescription'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import DeleteHiringStep from 'components/dialogs/Hiring/DeleteHiringStep'
import IconButton from 'components/ui/buttons/IconButton'
import { RolesNames } from 'constants/roles'

interface IUserProps {
  hiringSteps: HiringStep[]
  updateSteps: () => void
}

const StaticHiringStepsTabContent: FC<IUserProps> = ({
  updateSteps,
  hiringSteps,
}) => {
  const isNotebookDevice = useMediaQuery(`(max-width: ${BreakPoints.NOTEBOOK})`)
  const [selectedStep, setSelectedStep] = useState<HiringStep | null>(null)
  const [showModalDeleteStage, setShowModalDeleteStage] =
    useState<boolean>(false)

  const handleShowModalDeleteStage = useCallback((): void => {
    setShowModalDeleteStage((prev) => !prev)
  }, [])

  return isNotebookDevice ? (
    <ST.StepsNotebookContainer>
      {hiringSteps.map((step) => (
        <ST.StepCard key={step.id}>
          <ST.StepInfoRow>
            <ST.StepCardTitle>Название этапа:</ST.StepCardTitle>
            <ST.StepCardValue>{step.name}</ST.StepCardValue>
          </ST.StepInfoRow>
          <ST.StepInfoRow>
            <ST.StepCardTitle>Уведомление</ST.StepCardTitle>
            <ST.StepCheckbox
              type={'checkbox'}
              checked={step.isNotifyCustomer ?? false}
            />
          </ST.StepInfoRow>
          <ST.StepInfoRow>
            <ST.StepCardTitle>Активность:</ST.StepCardTitle>
            <ST.StepCheckbox type={'checkbox'} checked={step.isActive} />
          </ST.StepInfoRow>
          <ST.StepInfoRow>
            <ST.StepCardTitle>Действие:</ST.StepCardTitle>
            <ST.StepCardValue>
              {' '}
              {step.action ? HiringStepsLabels[step.action] : 'Нет данных'}
            </ST.StepCardValue>
          </ST.StepInfoRow>
          <DeleteButton />
        </ST.StepCard>
      ))}
    </ST.StepsNotebookContainer>
  ) : (
    <ST.StaticHiringTable>
      <ST.StaticHiringThead>
        <ST.StaticHiringTRTitle>
          <ST.StaticHiringTH style={{ width: '19%' }}>
            Название этапа
          </ST.StaticHiringTH>
          <ST.StaticHiringTH
            style={{ textAlign: 'center', width: '12%', padding: '0 0 0 60px' }}
          >
            Уведомление
          </ST.StaticHiringTH>
          <ST.StaticHiringTH style={{ textAlign: 'center' }}>
            Активность
          </ST.StaticHiringTH>
          <ST.StaticHiringTH style={{ width: '25%' }}>
            Действие
          </ST.StaticHiringTH>
          <ST.StaticHiringTH></ST.StaticHiringTH>
        </ST.StaticHiringTRTitle>
      </ST.StaticHiringThead>
      <ST.StaticHiringTBody>
        {hiringSteps.map((step) => {
          const notificationsData: RolesNames[] = []

          if (step.isNotifyCustomer) notificationsData.push(RolesNames.customer)
          if (step.isNotifyRecruiter)
            notificationsData.push(RolesNames.recruiter)

          return (
            <ST.StaticHiringTR key={step.id}>
              <ST.StaticHiringTD>
                <ST.StaticHiringTDText>{step.name}</ST.StaticHiringTDText>
              </ST.StaticHiringTD>
              <ST.StaticHiringTD style={{ textAlign: 'center' }}>
                {notificationsData?.length
                  ? notificationsData.join(', ')
                  : 'Нет'}
              </ST.StaticHiringTD>
              <ST.StaticHiringTD style={{ textAlign: 'center', padding: 0 }}>
                <ST.Checkbox type={'checkbox'} checked={step.isActive} />
              </ST.StaticHiringTD>
              <ST.StaticHiringTD>
                <ST.StaticHiringTDText>
                  {step.action ? HiringStepsLabels[step.action] : 'Нет данных'}
                </ST.StaticHiringTDText>
              </ST.StaticHiringTD>
              <ST.StaticHiringTD>
                <IconButton
                  icon={DeleteButton}
                  onClick={() => {
                    if (
                      step.notDeletableReason !==
                      NotDeletableReasons.BASIC_ACTION
                    ) {
                      setSelectedStep(step)
                      setShowModalDeleteStage((prevState) => !prevState)
                    }
                  }}
                  disabled={
                    step.notDeletableReason === NotDeletableReasons.BASIC_ACTION
                  }
                />
              </ST.StaticHiringTD>
            </ST.StaticHiringTR>
          )
        })}
      </ST.StaticHiringTBody>
      {!!selectedStep && !!hiringSteps.length && showModalDeleteStage && (
        <DeleteHiringStep
          show={showModalDeleteStage}
          onClose={handleShowModalDeleteStage}
          step={selectedStep}
          showEndModal={updateSteps}
          setHiringSteps={() => updateSteps()}
          hiringSteps={hiringSteps}
        />
      )}
    </ST.StaticHiringTable>
  )
}

export default StaticHiringStepsTabContent
