import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as EditIconPic } from 'assets/icons/edit.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/plus-file.svg'
import { ReactComponent as BackIconPic } from 'assets/icons/arrow-left.svg'
import { ReactComponent as PlusFileSign } from 'assets/icons/plus.svg'
import { ReactComponent as Cross } from 'assets/icons/cancel.svg'
import Border from 'assets/icons/dashedBorder.svg'
import BorderHover from 'assets/icons/hover.svg'
import PlusHover from 'assets/icons/employmentPage/PlusHover.svg'
import PlusSign from 'assets/icons/employmentPage/plus.svg'
import { BreakPoints } from 'constants/breakPoints'
import { ReactComponent as AttachFileIcon } from 'assets/icons/profile/documents/attach-scan.svg'
import { ReactComponent as EditFileIcon } from 'assets/icons/profile/documents/edit-scan.svg'

interface IGridProps {
  columns?: number
}

interface DisabledProps {
  $disabled?: boolean
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`

export const EditBlockContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px 15px 20px;
  background: ${COLORS.yellow};
  border-radius: 15px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background: ${COLORS.yellowHover};
  }
`

export const EditText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

export const EditIcon = styled(EditIconPic)``

export const BlockInfo = styled.div`
  padding: 30px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
`

export const HeaderBlock = styled.p`
  width: 300px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin-bottom: 12px;
  margin-right: 30px;
`

export const SocBlock = styled(HeaderBlock)`
  width: 100%;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const HeaderText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
`

export const Download = styled(DownloadIcon)`
  cursor: pointer;
`

export const InfoContainer = styled.div``

export const RowInfo = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ManageBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
  }
`

export const BackBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const BackButton = styled.div`
  width: 44px;
  height: 40px;
  background: ${COLORS.yellow};
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DeleteButton = styled.div<DisabledProps>`
  width: 44px;
  height: 40px;
  background: ${COLORS.yellow};
  border-radius: 50px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `};
`

export const EditingHeader = styled.h2`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
`

export const SaveButton = styled.button`
  width: 220px;
  height: 40px;
  background: ${COLORS.yellow};
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  border-radius: 50px;
  cursor: pointer;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }

  &:disabled {
    background-color: ${COLORS.yellow50};
    color: ${COLORS.black50};
    cursor: default;
  }
`

export const BackIcon = styled(BackIconPic)``

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  gap: 5px;
`

export const Social = styled.div``

export const FileNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Delete = styled(Cross)`
  cursor: pointer;
  margin-right: 2px;
`

export const FileName = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${COLORS.black};

  &:last-child {
    margin-bottom: 0;
  }
`

export const FileNameDeleteContainer = styled.div`
  display: grid;
  grid-template-columns: 7.5fr 0.5fr;
  grid-auto-flow: dense;
  grid-column-gap: 10px;
  margin-bottom: 8px;
`

export const Label = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
`

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  outline: none;
  padding: 0 0 0 20px;
  border: 0;
  border-radius: 10px;
  background: ${COLORS.inputBG};
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
`

export const Star = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const PlusFile = styled(PlusFileSign)`
  cursor: pointer;
`

export const Personal = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 300px);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  grid-auto-flow: dense;
  margin-bottom: 38px;

  :last-child {
    margin-bottom: 0;
  }
`

// export const FullName = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, minmax(150px, 300px));
//   grid-column-gap: 30px;
//   margin-bottom: 38px;
//
//   @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
//     display: block;
//   }
// `

export const GridBlock = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-auto-flow: dense;
`

export const FullName = styled(BlockInfo)``

export const Headers = styled.div`
  display: flex;
  flex-direction: row;
`
export const DepartmentBlock = styled.div`
  display: flex;
  margin-bottom: 30px;
`

export const SocialNetworkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

export const StackWrapper = SocialNetworkWrapper

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  margin-top: 10px;
`

export const RadioItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 18px 0 0;
  align-items: center;
`

export const Radiobutton = styled.input`
  margin: 0 8px 0 0;
  outline: none;
  border: 2px solid ${COLORS.blue50};
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: auto;
    border: 2px solid ${COLORS.blue};
  }
`

export const RadioLabel = styled.div`
  font-size: 16px;
  color: ${COLORS.black};
  cursor: pointer;
`

export const Plus = styled.div`
  background-image: url(${PlusSign});
  width: 24px;
  height: 24px;
  position: absolute;
  left: 13%;
`

export const Button = styled.button`
  background-image: url(${Border});
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.blue};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  background-color: white;
  gap: 4px;
  width: 240px;
  margin-top: 10px;
  position: relative;
  justify-content: center;

  span {
    margin-bottom: -13px;
  }

  &:hover {
    color: ${COLORS.blueHover};
    background-image: url(${BorderHover});

    div${Plus} {
      background-image: url(${PlusHover});
    }
  }
`
export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const SocWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SocInputs = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
`

export const AttachFile = styled(AttachFileIcon)`
  cursor: pointer;
`

export const EditFile = styled(EditFileIcon)`
  cursor: pointer;
  min-width: 26px;
`

export const FileLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 26px;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
`

export const FileInput = styled(Input)`
  position: absolute;
  opacity: 0;
  width: 24px;
  cursor: pointer;
`

export const FileType = styled.p`
  color: ${COLORS.black};
  font-family: Golos, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-left: 10px;
`

export const LinkButton = styled.p`
  text-align: center;
`
