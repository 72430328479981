import { Reducer } from 'redux'
import { UserInfoAction, UserInfoActionTypes, UserInfoState } from 'types/store'

const initialStateUser: UserInfoState = null

export const userInfoReducer: Reducer<UserInfoState, UserInfoAction> = (
  state = initialStateUser,
  action
): UserInfoState => {
  switch (action.type) {
    case UserInfoActionTypes.SET_USER_INFO:
      if (action.payload) {
        localStorage.setItem('name', JSON.stringify(action.payload.fullName))
        localStorage.setItem('photo', JSON.stringify(action.payload.photo))
      }
      return action.payload
    case UserInfoActionTypes.RESET_USER_INFO: {
      localStorage.removeItem('name')
      localStorage.removeItem('photo')
      return null
    }
    default:
      return state
  }
}
