import { SxProps } from '@mui/material'
import { CSSProperties } from 'styled-components'

export namespace SelectsBlockUtils {
  export const getSx = (isMobileDevice: boolean): SxProps => ({
    '& .MuiInputBase-root': {
      padding: '0 !important',
    },
    '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
      opacity: '0',
    },
    '& .MuiFormLabel-filled': {
      opacity: '0 !important',
    },
    '& .MuiFormLabel-filled + div > fieldset > legend': {
      display: 'none !important',
    },
    '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: 'transparent',
      },
    '& .MuiFormLabel-root': {
      top: '-8px',
      color: '#606060',
      opacity: '0.5',
      fontFamily: 'Golos, sans-serif !important',
    },
    '& .MuiAutocomplete-input': {
      ...(isMobileDevice
        ? {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
            fontFamily: 'Golos, sans-serif !important',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            color: 'black',
          }
        : {
            paddingLeft: '20px !important',
            fontFamily: 'Golos, sans-serif !important',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            color: 'black',
          }),
    },
    '& fieldset': {
      border: 'none !important',
    },
  })

  export const getStyle = (
    isMobileDevice: boolean,
    isTabletMiddleDevice: boolean
  ): CSSProperties =>
    isMobileDevice
      ? {
          display: 'flex',
          alignItems: 'center',
          color: '#000000',
          backgroundColor: '#f8f8f8',
          boxSizing: 'border-box',
          borderRadius: '10px',
          height: '48px',
          fontWeight: '400',
          fontSize: '16px',
          width: '100%',
        }
      : {
          color: '#000000',
          backgroundColor: '#f8f8f8',
          padding: '0',
          ...(!isTabletMiddleDevice && { marginBottom: '20px' }),
          borderRadius: '10px',
          height: '20px',
          fontWeight: '400',
          fontSize: '16px',
        }
}
