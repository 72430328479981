import { Action } from 'redux'
import { UserTypes } from 'types/model/user'

export enum UserInfoActionTypes {
  SET_USER_INFO = 'SETUSERINFO',
  RESET_USER_INFO = 'RESETUSERINFO',
}

interface SetUserInfo extends Action {
  type: UserInfoActionTypes.SET_USER_INFO
  payload: UserInfoState
}

interface ResetUser extends Action {
  type: UserInfoActionTypes.RESET_USER_INFO
}

export type UserInfoState =
  | (UserTypes.UserInfo & { fullName: string; photo: string })
  | null
export type UserInfoAction = ResetUser | SetUserInfo
