import { PositionTypes } from 'types/model/position'
import { DepartmentTypes } from 'types/model/department'
import { apiInstance } from 'api'

export type PositionCreateResponse = Pick<PositionTypes.Model, 'id'>

export type PositionPatchData = Pick<PositionTypes.Model, 'name'> &
  Partial<Pick<PositionTypes.Model, 'patternSheet'>>

export const getPositions = async (): Promise<PositionTypes.Model[]> => {
  return apiInstance.get('/api/position').then((resp) => resp.data)
}

export const getCurrentPosition = async (
  positionId: number
): Promise<PositionTypes.Model> => {
  return apiInstance
    .get(`/api/position/${positionId}`)
    .then((resp) => resp.data)
}

export const createPosition = async (
  name: string
): Promise<PositionCreateResponse> => {
  return apiInstance.post('/api/position', { name }).then((resp) => resp.data)
}

export const removePosition = async (
  positionId: number
): Promise<DepartmentTypes.Model> => {
  return apiInstance
    .delete(`/api/position/${positionId}`)
    .then((resp) => resp.data)
}

export const editPosition = async (
  positionId: number,
  position: PositionPatchData
) => {
  return apiInstance.put(`/api/position/${positionId}`, position)
}

export const addPositionGrades = async (
  positionId: number,
  grades: number[]
) => {
  return apiInstance
    .patch(`/api/position/grade/${positionId}`, { grades })
    .then((resp) => resp.data)
}

export const removePositionGrade = async (
  positionId: number,
  grade: number
): Promise<DepartmentTypes.Model> => {
  return apiInstance
    .delete(`/api/position/grade/${positionId}?grade=${grade}`)
    .then((resp) => resp.data)
}
