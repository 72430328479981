import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'

interface Props {
  show: boolean
  title: string
  hidePreTitleText?: boolean
  description?: string
  onClose: (isShow: boolean) => void
  handleConfirm?: () => void
}

const DeleteSection: FC<Props> = ({
  show,
  title,
  onClose,
  handleConfirm,
  hidePreTitleText,
  description,
}) => {
  const handleClose = (): void => {
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          {!hidePreTitleText && <ST.ModalTitle>Подтверди</ST.ModalTitle>}

          <ST.ModalTitle>{title}</ST.ModalTitle>
          {!!description?.length && (
            <ST.ModalDescription>{description}</ST.ModalDescription>
          )}
          <ST.ButtonsBlock>
            <ST.Button
              onClick={() => {
                handleConfirm && handleConfirm()
                handleClose()
              }}
            >
              Удалить
            </ST.Button>
            <ST.Button onClick={handleClose} typeButton={'secondary'}>
              Отмена
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
