import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as CloseIcon } from 'assets/icons/cancel.svg'

export namespace HiringTypesModalStyles {
  export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  `

  export const GroupContainer = styled.div``

  export const Group = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid ${COLORS.black20};
    border-radius: 10px;
    padding: 12px;
  `

  export const GroupItem = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;

    &:not(:last-of-type) {
      padding-bottom: 16px;
      border-bottom: 1px solid ${COLORS.black20};
    }
  `

  export const GroupItemRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `

  export const Close = styled(CloseIcon)`
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  `
}
