import { StatisticFiltersTypes as T } from './types'

export namespace StatisticFiltersConstants {
  export enum FilterPeriods {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
  }

  export const timeButtons: T.TimeButtonValue[] = [
    {
      title: 'Сегодня',
      period: FilterPeriods.TODAY,
    },
    {
      title: 'Вчера',
      period: FilterPeriods.YESTERDAY,
    },
    {
      title: 'Неделя',
      period: FilterPeriods.WEEK,
    },
    {
      title: 'Месяц',
      period: FilterPeriods.MONTH,
    },
  ]
}
