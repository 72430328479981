export enum PAGES {
  authorization = 'Авторизация',
  profile = 'Профиль сотрудника',
  editDepartment = 'Редактирование отдела',
  editHardware = 'Редактирование оборудования',
  editBenefitApplication = 'Редактирование заявки',
  benefitApplication = 'Бенефиты',
  survey360 = 'Опрос 360',
  editPosition = 'Редактирование должности',
  editGrade = 'Редактирование грейда',
  editTechnologyStack = 'Редактирование стека',
  employment = 'Данные для трудоустройства',
  error = 'Ошибка',
  employees = 'Сотрудники',
  applications = 'Доступы',
  activityLog = 'Журнал действий',
  departments = 'Отделы',
  reviews = 'Ревью',
  survey = 'Общий опрос',
  positions = 'Должности',
  grades = 'Грейды',
  /** @deprecated */
  technologyStack = 'Стек технологий',
  hardwares = 'Оборудование',
  /** @deprecated */
  benefits = 'Бенефиты',
  vacancies = 'Вакансии',
  vacancy = 'Вакансия',
  vacanciesBase = 'База кандидатов',
  settings = 'Настройки',
  statistic = 'Статистика',
  createResume = 'Новый кандидат',
  editResume = 'Изменение кандидата',
}
