import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const WrapperSwiper = styled.div`
  margin-left: -40px;
  margin-right: -40px;

  .swiper {
    padding: 0px 40px;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-left: -20px;
    margin-right: -20px;

    .swiper {
      padding: 0px 20px;
    }
  }
`

export const SwiperTabCard = styled.div<StepProps>`
  height: 100px;
  padding: 12px 20px 16px 20px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ isSelected }) =>
    isSelected ? COLORS.yellow : COLORS.yellow50};
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: ${BreakPoints.PHONES}) {
    padding: 10px;
  }
`

export const SwiperTabCardTitle = styled.h6`
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: ${BreakPoints.PHONES}) {
    font-size: calc((100vw - 280px) / (360 - 280) * (16 - 12) + 12px);
  }
`

export const SwiperTabCardDescription = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: ${BreakPoints.PHONES}) {
    font-size: calc((100vw - 280px) / (360 - 280) * (12 - 10) + 10px);
  }
`

interface StepProps {
  isSelected: boolean
}

export const Step = styled.div<StepProps>`
  position: relative;
  height: 32px;
  border-radius: 50px;
  text-align: center;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;
  padding: 5px 19px 0 19px;
  background-color: ${({ isSelected }) =>
    isSelected ? `${COLORS.yellow}` : ``};
  margin-right: 23px;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background: ${({ isSelected }) => (isSelected ? `` : `${COLORS.gray20}`)};
  }
`
