import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import StatusBlock from './StatusBlock'
import moment from 'moment/moment'
import { ReactComponent as FireStatus } from 'assets/icons/vacancyFireStatus.svg'
import { ReactComponent as GoodIcon } from 'assets/icons/good.svg'
import { ReactComponent as BadIcon } from 'assets/icons/bad.svg'
import { VacancyPriorityTypes } from 'constants/vacancyPriorityTypes'
import { VacancyStatuses } from 'constants/vacancyStatusTypes'

interface Props {
  date: string
  status: VacancyStatuses
  deadlineDate: string
  priority: VacancyPriorityTypes
  expired: boolean
  daysAfterDeadline: number
}

const TitleLine: FC<Props> = ({
  date,
  status,
  deadlineDate,
  priority,
  expired,
  daysAfterDeadline,
}) => {
  const renderExpired = () => {
    if (status === VacancyStatuses.CLOSED && !expired) {
      return (
        <ST.Expired>
          <GoodIcon />
        </ST.Expired>
      )
    }

    if (expired) {
      return (
        <ST.Expired>
          <BadIcon style={{ transform: 'translateY(1px)' }} />
          <ST.ExpiredText>({daysAfterDeadline} дн.)</ST.ExpiredText>
        </ST.Expired>
      )
    }

    return null
  }

  const renderPriority = () => {
    return priority === VacancyPriorityTypes.high &&
      status !== VacancyStatuses.CLOSED &&
      status !== VacancyStatuses.CANCELED ? (
      <FireStatus style={{ marginLeft: '8px' }} />
    ) : null
  }

  const renderDate = () => {
    if (status === VacancyStatuses.NEW) {
      return (
        <ST.DateText>
          {moment(date).format('DD.MM.YYYY')} {renderExpired()}
          {renderPriority()}
        </ST.DateText>
      )
    }

    return (
      <>
        <ST.DateText>{moment(date).format('DD.MM.YYYY')}</ST.DateText>
        <ST.DeadlineDateText>
          - {moment(deadlineDate).format('DD.MM.YYYY')}
        </ST.DeadlineDateText>
        {renderExpired()}
        {renderPriority()}
      </>
    )
  }

  return (
    <ST.TitleLine>
      <ST.Info>
        <ST.Date>
          <CalendarIcon />
          {renderDate()}
        </ST.Date>
        <StatusBlock status={status} />
      </ST.Info>
    </ST.TitleLine>
  )
}

export default TitleLine
