import React, { ElementRef, useEffect, useRef, useState } from 'react'
import * as ST from './styled'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import StatisticEmployeeCard from './StatisticEmployeeCard'
import StatisticVacancyCard from './StatisticVacancyCard'
import { useNavigate } from 'react-router-dom'
import { getHiringStages } from 'api/settings'
import { HiringStep } from 'types/model/hiring'
import { getStatisticByEmployees, getStatisticByVacancy } from 'api/statistic'
import {
  StatisticByEmployeesType,
  StatisticByVacanciesType,
} from 'types/statisticType'
import moment from 'moment/moment'
import Body from 'components/ui/layout/Body'
import { StatisticFilters } from 'components/vacanciesStatistic/Filters'
import { VacanciesNotFound } from 'components/vacancies/NotFound'
import Loader from 'components/ui/Loader'
import { setToastError } from 'utils/handlerError'
import BaseButton from 'components/ui/buttons/BaseButton'
import { DATE_FORMAT_DTO } from 'constants/Date'

const Statistic = () => {
  useTitle(PAGES.statistic)
  const navigate = useNavigate()
  const [stages, setStages] = useState<HiringStep[]>([])
  const [displayStatisticByEmployees, setDisplayStatisticByEmployees] =
    useState<boolean>(true)
  const [statisticByEmployees, setStatisticByEmployees] =
    useState<StatisticByEmployeesType | null>(null)
  const [statisticByVacancies, setStatisticByVacancies] =
    useState<StatisticByVacanciesType | null>(null)
  const [tableWidth, setTableWidth] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [disableCompareApplied, setDisableCompareApplied] =
    useState<boolean>(false)

  const ref = useRef<ElementRef<'table'>>(null)

  useEffect(() => {
    Promise.all([
      getStatisticByEmployees({
        dateFrom: moment(new Date(Date.now())).format(DATE_FORMAT_DTO),
        dateTo: moment(new Date(Date.now())).format(DATE_FORMAT_DTO),
      }),
      getStatisticByVacancy({
        dateFrom: moment(new Date(Date.now())).format(DATE_FORMAT_DTO),
        dateTo: moment(new Date(Date.now())).format(DATE_FORMAT_DTO),
      }),
      getHiringStages(),
    ])
      .then((res) => {
        if (res) {
          const [employees, vacancy, hiringStages] = res

          setStatisticByEmployees(employees)

          setStatisticByVacancies(vacancy)

          setStages(
            hiringStages
              .filter((stage) => stage.isActive)
              .sort((a, b) => a.sequence - b.sequence)
          )
        } else {
          setToastError('Ошибка запроса')
        }
      })
      .catch(setToastError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (stages?.length && ref.current) {
      setTableWidth(ref.current.scrollWidth)

      const listener = () => {
        setTableWidth(ref.current!.scrollWidth)
      }

      window.addEventListener('resize', listener)

      return () => {
        window.removeEventListener('resize', listener)
      }
    }
  }, [stages])

  const renderStatisticByEmployeesList = () =>
    isLoading ? (
      <Loader marginTop="40px" />
    ) : !!statisticByEmployees?.employees?.length ? (
      statisticByEmployees.employees.map((employee) => (
        <StatisticEmployeeCard
          stages={stages}
          employee={employee}
          tableWidth={tableWidth}
          key={employee.recruiter.id}
          disableCompare={disableCompareApplied}
        />
      ))
    ) : (
      <VacanciesNotFound />
    )

  const renderStatisticByVacanciesList = () =>
    statisticByVacancies?.statisticsByVacancies?.length ? (
      statisticByVacancies?.statisticsByVacancies.map((employee) => (
        <StatisticVacancyCard
          vacancy={employee}
          stages={stages}
          tableWidth={tableWidth}
          key={employee.id}
        />
      ))
    ) : (
      <VacanciesNotFound />
    )

  return (
    <Body>
      <ST.DepartmentsHead>
        <ST.DepartmentsTitle>Статистика</ST.DepartmentsTitle>
        <ST.ButtonsWrapper>
          <BaseButton
            width={'157px'}
            height={'48px'}
            onClick={() => navigate('/vacancies')}
            typeButton={'secondary'}
            text={'Вакансии'}
          ></BaseButton>
          <BaseButton
            width={'268px'}
            height={'48px'}
            text={
              displayStatisticByEmployees
                ? 'Смотреть по вакансиям'
                : 'Смотреть по сотрудникам'
            }
            onClick={() =>
              setDisplayStatisticByEmployees(!displayStatisticByEmployees)
            }
          ></BaseButton>
        </ST.ButtonsWrapper>
      </ST.DepartmentsHead>
      <StatisticFilters
        displayStatisticByEmployees={displayStatisticByEmployees}
        setStatisticByEmployees={setStatisticByEmployees}
        setStatisticByVacancies={setStatisticByVacancies}
        handleLoad={setIsLoading}
        setDisableCompareApplied={setDisableCompareApplied}
      />

      {displayStatisticByEmployees ? (
        <ST.ApplicationsBody>
          {isLoading && <Loader marginTop={60} />}
          <ST.ApplicationsBlock ref={ref} isVisible={!isLoading}>
            <ST.ApplicationsHead width={tableWidth}>
              {!!stages.length &&
                stages.map((stage) => (
                  <ST.ApplicationStatus
                    key={stage.id}
                    width={100 / stages.length}
                  >
                    {!!stage.name ? stage.name : 'Без названия'}
                  </ST.ApplicationStatus>
                ))}
            </ST.ApplicationsHead>
            <ST.Employees width={tableWidth}>
              <ST.StatisticHeader>
                <ST.EmployeesTitle>Все сотрудники</ST.EmployeesTitle>
                <ST.Result>
                  <ST.ResultTitle>
                    Хантинг -{' '}
                    <ST.ResultScore>
                      {statisticByEmployees?.totalStatistics.huntingCount ?? 0}
                    </ST.ResultScore>
                  </ST.ResultTitle>
                  <ST.ResultTitle>
                    Рекрутинг -{' '}
                    <ST.ResultScore>
                      {statisticByEmployees?.totalStatistics.recruitingCount ??
                        0}
                    </ST.ResultScore>
                  </ST.ResultTitle>
                  <ST.ResultTitle>
                    Рекомендации -{' '}
                    <ST.ResultScore>
                      {statisticByEmployees?.totalStatistics
                        .recommendationCount ?? 0}
                    </ST.ResultScore>
                  </ST.ResultTitle>
                </ST.Result>
              </ST.StatisticHeader>
              <ST.StatisticVisibleBody>
                {tableWidth &&
                  statisticByEmployees?.totalStatistics.byStages
                    .filter((s, i) => stages[i]?.isActive)
                    .map((stage) => (
                      <ST.SingleScore
                        key={stage.stage.id}
                        max={
                          100 /
                          statisticByEmployees?.totalStatistics.byStages.length
                        }
                      >
                        <ST.SingleScoreResult>
                          <ST.SingleMainScore>{stage.count}</ST.SingleMainScore>
                          {!disableCompareApplied && (
                            <ST.SingleScoreCompare isPositive={stage.diff > 0}>
                              {stage.diff > 0 ? `+${stage.diff}` : stage.diff}
                            </ST.SingleScoreCompare>
                          )}
                        </ST.SingleScoreResult>
                        <ST.SingleScoreNotice>
                          <ST.SingleScoreNoticeSpan>
                            {stage.toRejected}
                          </ST.SingleScoreNoticeSpan>{' '}
                          {' в отказ'}
                        </ST.SingleScoreNotice>
                        <ST.SingleScoreNotice>
                          <ST.SingleScoreNoticeSpan>
                            {stage.toReserve}
                          </ST.SingleScoreNoticeSpan>{' '}
                          {' в резерв'}
                        </ST.SingleScoreNotice>
                      </ST.SingleScore>
                    ))}
              </ST.StatisticVisibleBody>
            </ST.Employees>
            <ST.ApplicationsBody>
              {renderStatisticByEmployeesList()}
            </ST.ApplicationsBody>
          </ST.ApplicationsBlock>
        </ST.ApplicationsBody>
      ) : (
        <ST.ApplicationsBody>
          <ST.ApplicationsBlock ref={ref} isVisible={!isLoading}>
            <ST.ApplicationsHead width={tableWidth}>
              <ST.ApplicationStatus width={100 / stages.length + 1}>
                {''}
              </ST.ApplicationStatus>
              {!!stages.length &&
                stages.map((stage) => (
                  <ST.ApplicationStatus
                    key={stage.id}
                    width={100 / stages.length}
                  >
                    {!!stage.name ? stage.name : 'Без названия'}
                  </ST.ApplicationStatus>
                ))}
            </ST.ApplicationsHead>
            {isLoading ? (
              <Loader marginTop="40px" />
            ) : (
              <>
                {!!statisticByVacancies?.totalStatistics?.byStages?.length && (
                  <ST.Employees width={tableWidth}>
                    <ST.StatisticHeader>
                      <ST.EmployeesTitle>Все вакансии</ST.EmployeesTitle>
                    </ST.StatisticHeader>
                    <ST.StatisticVisibleBody>
                      <ST.SingleScore
                        width={
                          100 /
                          statisticByVacancies?.totalStatistics?.byStages
                            ?.length!
                        }
                      >
                        <ST.SingleScoreResult>
                          <ST.SingleMainScore>
                            {statisticByVacancies?.totalStatistics.totalHired}/
                            {''}
                            {
                              statisticByVacancies?.totalStatistics
                                .totalRequiredEmployees
                            }
                          </ST.SingleMainScore>
                        </ST.SingleScoreResult>
                        <ST.SingleScoreNotice>
                          <ST.SingleScoreNoticeSpan>
                            {
                              statisticByVacancies?.totalStatistics
                                .totalToRejected
                            }
                          </ST.SingleScoreNoticeSpan>{' '}
                          {' в отказ'}
                        </ST.SingleScoreNotice>
                        <ST.SingleScoreNotice>
                          <ST.SingleScoreNoticeSpan>
                            {
                              statisticByVacancies?.totalStatistics
                                .totalToReserve
                            }
                          </ST.SingleScoreNoticeSpan>{' '}
                          {' в резерв'}
                        </ST.SingleScoreNotice>
                      </ST.SingleScore>
                      <ST.SingleMainScore></ST.SingleMainScore>
                      {statisticByVacancies?.totalStatistics.byStages
                        .filter((_, i) => stages[i]?.isActive)
                        .map((stage) => (
                          <ST.SingleScore
                            key={stage.stageId}
                            width={
                              100 /
                              statisticByVacancies?.totalStatistics.byStages
                                .length
                            }
                          >
                            <ST.SingleScoreResult>
                              <ST.SingleMainScore>
                                {stage.count}
                              </ST.SingleMainScore>
                            </ST.SingleScoreResult>
                            <ST.SingleScoreNotice>
                              <ST.SingleScoreNoticeSpan>
                                {stage.toRejected}
                              </ST.SingleScoreNoticeSpan>{' '}
                              {' в отказ'}
                            </ST.SingleScoreNotice>
                            <ST.SingleScoreNotice>
                              <ST.SingleScoreNoticeSpan>
                                {stage.toReserve}
                              </ST.SingleScoreNoticeSpan>{' '}
                              {' в резерв'}
                            </ST.SingleScoreNotice>
                          </ST.SingleScore>
                        ))}
                    </ST.StatisticVisibleBody>
                  </ST.Employees>
                )}

                <ST.ApplicationsBody>
                  {renderStatisticByVacanciesList()}
                </ST.ApplicationsBody>
              </>
            )}
          </ST.ApplicationsBlock>
        </ST.ApplicationsBody>
      )}
    </Body>
  )
}

export default Statistic
