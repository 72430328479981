import styled, { CSSProperties } from 'styled-components'
import { COLORS } from 'constants/colors'
import ZIndex from 'constants/styles/zIndex'
import { Tags } from 'styles/Tags'
import { BreakPoints } from 'constants/breakPoints'

export namespace Modal {
  type BodyProps = {
    gap?: CSSProperties['gap']
    fullHeight?: boolean
  }

  export const Overlay = styled.div<{ disableAnimation?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${ZIndex.modal};

    opacity: 0;
    visibility: hidden;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    ${({ disableAnimation }) =>
      !disableAnimation &&
      `transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;`}
  `

  export const Container = styled.div<{ disableAnimation?: boolean }>`
    background: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: none;
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: ${ZIndex.modal + 1};

    transform: translate(-50%, -50%) scale(0.9);

    &.visible {
      transform: translate(-50%, -50%) scale(1);
    }

    ${({ disableAnimation }) =>
      !disableAnimation && `transition: transform 0.2s ease-in-out;`}
  `

  export const Title = styled(Tags.H2)<{ align?: 'left' | 'center' }>`
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${COLORS.black};
    margin-bottom: 10px;
    max-width: calc(100% - 20px);
    text-align: ${({ align }) => align};

    @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
      font-size: 26px;
      line-height: 120%;
      letter-spacing: 0.26px;
    }
  `

  export const Controls = styled.div<{
    direction?: 'row' | 'column'
    gap?: CSSProperties['gap']
  }>`
    display: flex;
    flex-direction: ${({ direction }) => direction};
    gap: ${({ gap }) => (typeof gap === 'number' ? `${gap}px` : gap ?? '20px')};
    align-items: center;
    margin-top: 30px;
  `

  export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  `

  export const Header = styled.h2`
    margin-top: 0;
  `

  export const Body = styled.div<BodyProps>`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: ${({ fullHeight }) => (fullHeight ? '100%' : 'initial')};
    gap: ${({ gap }) => (typeof gap === 'number' ? `${gap}px` : gap)};
  `

  export const CloseIconContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  `
}
