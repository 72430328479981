import { Dispatch } from 'react'
import { UserIdAction, UserIdActionTypes } from 'types/authType'

export const setUserId = (
  userId?: number
): ((dispatch: Dispatch<UserIdAction>) => void) => {
  return (dispatch: Dispatch<UserIdAction>) => {
    dispatch({
      type: UserIdActionTypes.SETUSER,
      payload: { userId: userId + '' },
    })
  }
}

export const deleteUserId = (): ((
  dispatch: Dispatch<UserIdAction>
) => void) => {
  return (dispatch: Dispatch<UserIdAction>) => {
    dispatch({ type: UserIdActionTypes.RESETUSER })
  }
}
