import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

const InfoBreakpoint = BreakPoints.TABLETS

type ApplicationCardProps = {
  employeeBlockHidden?: boolean
}

const flexCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const gridCss = css`
  display: grid;
  grid-template-columns: 1fr 4fr;
`

export const ApplicationCard = styled.div<ApplicationCardProps>`
  width: 100%;
  box-sizing: border-box;
  ${({ employeeBlockHidden }) => (employeeBlockHidden ? flexCss : gridCss)};
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 24px 24px 24px 30px;
  font-size: 18px;
  line-height: 25px;

  @media (max-width: ${BreakPoints.TABLETS}) {
    flex-direction: column;
    gap: 20px;
  }
`

export const ReviewCardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`

export const ReviewCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${InfoBreakpoint}) {
    flex-direction: column;
    gap: 4px;
  }
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 25.2px;
`

export const Info = styled.div`
  display: flex;
  gap: 14px;
  justify-content: end;

  @media (max-width: ${InfoBreakpoint}) {
    flex-direction: column;
    gap: 4px;
  }
`

export const Date = styled.div`
  display: flex;
  align-items: center;
`

export const DateText = styled.span`
  margin-left: 8px;
  font-size: 14px;
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  button {
    height: 40px;
    width: 200px;
  }
`
