import { IItem } from 'components/ui/BaseSelect'
import { ReviewOperationTypes } from './reviewsOperationTypes'

export enum ReviewOperationTypesDescription {
  adaptive = 'Адаптационное',
  plan = 'Плановое',
  disciplinary = 'Дисциплинарное',
  technical = 'Техническое ',
}

export const ReviewOperationTypesLabels = {
  [ReviewOperationTypes.adaptive]: ReviewOperationTypesDescription.adaptive,
  [ReviewOperationTypes.plan]: ReviewOperationTypesDescription.plan,
  [ReviewOperationTypes.disciplinary]:
    ReviewOperationTypesDescription.disciplinary,
  [ReviewOperationTypes.technical]: ReviewOperationTypesDescription.technical,
}

export const listReviewOperationTypes: IItem<ReviewOperationTypes>[] = [
  {
    item: ReviewOperationTypesDescription.adaptive,
    value: ReviewOperationTypes.adaptive,
  },
  {
    item: ReviewOperationTypesDescription.plan,
    value: ReviewOperationTypes.plan,
  },
  {
    item: ReviewOperationTypesDescription.disciplinary,
    value: ReviewOperationTypes.disciplinary,
  },
  {
    item: ReviewOperationTypesDescription.technical,
    value: ReviewOperationTypes.technical,
  },
]
