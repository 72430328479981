import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import {
  useGetServiceModeStatusQuery,
  useSetSystemModeMutation,
} from 'store/api/setting'
import BaseButton from 'components/ui/buttons/BaseButton'
import { ButtonTypes } from 'constants/buttonTypes'
import Loader from 'components/ui/Loader'
import { setToastError, setToastSuccess } from 'utils/handlerError'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { ROLES } from 'constants/roles'
import { SystemMode } from 'types/SystemType'
import checkRole from 'utils/profile/checkRole'
import { useAuth } from 'hooks/useAuth'

interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const ServiceModeSwitch: FC<Props> = ({ show, onClose }) => {
  const { roles } = useAuth()
  const {
    data: serviceMode,
    isLoading: isServiceModeLoading,
    isFetching: isServiceModeFetching,
  } = useGetServiceModeStatusQuery(undefined, {
    skip: !checkRole(roles, [ROLES.admin, ROLES.owner, ROLES.sysadmin]),
  })
  const [setServiceModeStatus] = useSetSystemModeMutation()

  const isServiceMode = serviceMode?.systemMode === SystemMode.SERVICE

  const handleClose = (): void => onClose(!show)
  const switchServiceMode = () => {
    const newValue = isServiceMode ? SystemMode.ACTIVE : SystemMode.SERVICE
    setServiceModeStatus(newValue)
      .then(() =>
        setToastSuccess(
          `Сервисный режим успешно ${
            newValue === SystemMode.SERVICE ? 'включен' : 'выключен'
          }`
        )
      )
      .catch(() =>
        setToastError('Не удалось изменить статус сервисного режима')
      )
    handleClose()
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          {isServiceModeLoading || isServiceModeFetching ? (
            <Loader />
          ) : (
            <>
              {' '}
              <ST.ModalTitle>
                {isServiceMode ? 'Выключение' : 'Включение'} сервисного режима
              </ST.ModalTitle>
              <ST.Description>
                Когда Сервисный режим включен, доступ в систему есть только у
                пользователей с правами администратора
              </ST.Description>
              <ST.ButtonsWrapper>
                <BaseButton
                  typeButton={ButtonTypes.primary}
                  text={isServiceMode ? 'Выключить' : 'Включить'}
                  onClick={switchServiceMode}
                />
                <BaseButton
                  typeButton={ButtonTypes.secondary}
                  text={'Отмена'}
                  onClick={() => handleClose()}
                />
              </ST.ButtonsWrapper>
            </>
          )}
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default ServiceModeSwitch
