import React from 'react'
import * as ST from './styled'
import GradeBlock from './GradeBlock'
import CreateGrade from 'components/dialogs/CreateGrade'
import { useGetGradesQuery } from 'store/api/grade'
import Loader from 'components/ui/Loader'
import { useModalWindowState } from 'hooks/useModalWindowState'
import BaseButton from 'components/ui/buttons/BaseButton'

const Grades = () => {
  const { data: grades, isLoading } = useGetGradesQuery()
  const { modalOpen, toggleModal } = useModalWindowState()

  return (
    <>
      <ST.GradesHead>
        <ST.GradesTitle>Грейды</ST.GradesTitle>
        <BaseButton
          text="Создать грейд"
          onClick={toggleModal}
          disabled={isLoading}
          width={200}
          height={40}
        />
      </ST.GradesHead>
      <ST.GradesBody>
        <ST.GradesBlock>
          {isLoading ? (
            <Loader marginTop={100} />
          ) : (
            grades?.map((item) => <GradeBlock key={item.id} gradeInfo={item} />)
          )}
        </ST.GradesBlock>
      </ST.GradesBody>
      <CreateGrade show={modalOpen} onClose={toggleModal} />
    </>
  )
}

export default Grades
