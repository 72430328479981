import { IItem } from 'components/ui/BaseSelect'

export enum BenefitApplicationStatuses {
  underConsideration = 1,
  rejected = 2,
  approved = 3,
  cancel = 4,
}

export enum BenefitApplicationStatusesDescription {
  underConsideration = 'На рассмотрении',
  approved = 'Одобрено',
  rejected = 'Отклонено',
  cancel = 'Отменено',
}

// TODO использовать отдельный енам для каждой сущности
export const listBenefitApplicationStatuses: Array<
  IItem<BenefitApplicationStatuses, BenefitApplicationStatusesDescription>
> = [
  {
    item: BenefitApplicationStatusesDescription.underConsideration,
    value: BenefitApplicationStatuses.underConsideration,
  },
  {
    item: BenefitApplicationStatusesDescription.approved,
    value: BenefitApplicationStatuses.approved,
  },
  {
    item: BenefitApplicationStatusesDescription.rejected,
    value: BenefitApplicationStatuses.rejected,
  },
  {
    item: BenefitApplicationStatusesDescription.cancel,
    value: BenefitApplicationStatuses.cancel,
  },
]
