import styled, { CSSProperties } from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { ReactComponent as BackIconPic } from 'assets/icons/arrow-left.svg'
import { Tags } from 'styles/Tags'

export interface TDProps {
  disabled?: boolean
  textAlign?: CSSProperties['textAlign']
  width?: CSSProperties['width']
}

interface THProps extends TDProps {}

export const EditingHiringTable = styled.table`
  text-align: left;
`

export const EditingHiringThead = styled.thead``

export const EditingHiringTR = styled.tr`
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  &:not(:only-of-type) {
    &:not(:first-of-type) {
      margin-top: 20px;
    }

    box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  }
`

export const EditingTRContent = styled.div`
  padding: 20px 40px;
`

export const EditingHiringTDWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EditingHiringTH = styled.th<THProps>`
  width: ${({ width }) => width ?? 'initial'};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  padding: 20px 40px;
`
export const EditingHiringTBody = styled.tbody``

export const EditingHiringTD = styled.td<TDProps>`
  &:not(:first-child) {
    padding-left: 0;
  }

  &:nth-child(2) {
    width: 200px;
  }

  &:nth-child(3) {
    width: 50px;
  }

  &:last-child {
    width: 5%;
  }

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

export const ManageBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: ${BreakPoints.DESKTOPS_SMALL}) {
    flex-direction: column;
    gap: 20px;
  }
`

export const DragIconBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  svg {
    margin-top: 4px;
  }
`

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const NameRequired = styled(Tags.p3)`
  color: ${COLORS.black70};
  padding: 0 34px;
  margin-top: 8px;

  &::after {
    content: '*';
    color: ${COLORS.errorRed};
  }
`

export const BackBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const EditingHeader = styled.h2`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const AddButton = styled.button`
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px 24px 10px 20px;
  margin-top: 30px;
  border: 1px dashed ${COLORS.blue};
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.blue};
  background: ${COLORS.white};

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    width: 100%;
  }
`

export const SaveButton = styled.button`
  width: 220px;
  height: 40px;
  background: ${COLORS.yellow};
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  border-radius: 50px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    width: 100%;
  }
`

export const BackIcon = styled(BackIconPic)``

export const NameInput = styled.input`
  padding: 10px 20px 8px 20px;
  border-radius: 10px;
  background: #f8f8f8;
`
export const CheckboxInput = styled.input`
  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    width: fit-content;
  }
`

export const StepsNotebookContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`

export const StepCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  gap: 20px;
  margin: 20px 0;

  :nth-child(1) {
    box-shadow: 0 0 0;
    border-radius: 0;
    margin: 0;
  }
`

export const StepInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 20px;
  width: 100%;

  #select_1 {
    max-width: initial;
  }

  #select2 {
    max-width: initial;
  }
`

export const StepCardTitle = styled.h6`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const ErrorMessage = styled.span`
  color: ${COLORS.errorRed};
  align-self: flex-end;
  margin: 0 0 5px 5px;
  font-size: 14px;
`
