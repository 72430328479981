import { apiRequest } from 'api/request'
import { handlerError } from 'utils/handlerError'
import { apiInstance } from 'api'
import { ROLES } from 'constants/roles'

interface TEmailResponse {
  userId?: number
  code?: string
}

export const emailCheck = async (email: string): Promise<TEmailResponse> => {
  const resp = await apiRequest().post('/api/security/check', { email })
  return resp.data
}

export const logout = async () => {
  try {
    const resp = await apiInstance.post('/api/security/logout')
    return resp.data
  } catch (err) {
    handlerError(err)
    return {}
  } finally {
    localStorage.clear()
  }
}

export interface TCodeCheckResponse {
  accessToken: string
  role: ROLES[]
  userId: number
}

export const codeCheck = async (
  code: string,
  rememberMe: boolean,
  email: string,
  signal?: AbortSignal
): Promise<TCodeCheckResponse> => {
  const resp = await apiRequest().post(
    '/api/security/login',
    {
      code,
      rememberMe,
      email,
    },
    { signal }
  )
  return resp.data
}
