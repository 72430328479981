import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import * as ST from './styled'
import { SettingsSteps } from 'constants/settingsSteps'
import StaticHardwareTypesTabContent from './StaticHardwareTypesTabContent'
import { getHardwareTypes } from 'api/hardwareTypes'
import { HardwareTypes } from 'types/model/hardware'
import CreateHardwareType from 'components/dialogs/settings/CreateHardwareType'
import BaseButton from 'components/ui/buttons/BaseButton'

interface IUserProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const HardwareTypesTab: FC<IUserProps> = ({ isEditing }) => {
  const [hardwareTypes, setHardwareTypes] = useState<
    HardwareTypes.HardwareType[]
  >([])
  const [showModalCreateHardwareType, setShowModalCreateHardwareType] =
    useState<boolean>(false)
  const handleShowCreateHardwareType = useCallback((): void => {
    setShowModalCreateHardwareType((prev) => !prev)
  }, [])
  const updateHardwareTypes = () => {
    getHardwareTypes().then((resp) => setHardwareTypes(resp.data))
  }

  useEffect(() => {
    getHardwareTypes().then((resp) => setHardwareTypes(resp.data))
  }, [])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>Типы оборудования</ST.EditingHeader>
          </ST.BackBlock>
          <BaseButton
            typeButton="primary"
            text="Добавить оборудование"
            onClick={handleShowCreateHardwareType}
            width="40%"
          />
        </ST.ManageBlock>
      )}
      {!!hardwareTypes.length && (
        <StaticHardwareTypesTabContent
          types={hardwareTypes}
          updateHardwareTypes={updateHardwareTypes}
        />
      )}
      {showModalCreateHardwareType && (
        <CreateHardwareType
          show={showModalCreateHardwareType}
          onClose={handleShowCreateHardwareType}
          showEndModal={updateHardwareTypes}
        />
      )}
    </ST.MainContainer>
  )
}

export default HardwareTypesTab
