import styled from 'styled-components'
import { COLORS } from 'constants/colors'

export const Button = styled.button`
  border: 1px dashed ${COLORS.blue};
  background: transparent;
  color: ${COLORS.blue};
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
`
