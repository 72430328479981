import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import { InputSizes } from 'constants/inputSizes'

export const EditDepartmentHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EditDepartmentBody = styled.div`
  margin-top: 30px;
  background: ${COLORS.white};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 30px;
`

export const HeadDepartment = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    width: 200px;
  }
`

export const TitleDepartment = styled.p`
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
`

export const Label = styled.p`
  font-weight: normal;
  padding-bottom: 6px;
  display: flex;
  align-items: start;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};

  span {
    margin-bottom: -15px;
  }
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  ${(props) =>
    props.sizeInput === InputSizes.big ? 'width: 460px' : 'width: 200px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DepartmentHead = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
`

export const PositionsBlock = styled.div`
  display: flex;
  row-gap: 10px;
  column-gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const PositionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
