import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import { BreakPoints } from 'constants/breakPoints'
import { FIX_CHROME_BLUR } from 'constants/fixChromeBlur'

interface ModalContentProps {
  btnDisabled: boolean
}

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: ${FIX_CHROME_BLUR};
  max-width: 580px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div<ModalContentProps>`
  padding: 40px;
  position: relative;
  max-height: 800px;
  overflow-y: auto;
  opacity: ${({ btnDisabled }) => (btnDisabled ? '0.5' : '1')};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: initial;
    box-sizing: border-box;
  }
`

export const WrapperLoader = styled.div`
  position: absolute;
  padding: 40px;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: gray;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
  margin-bottom: 10px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Button = styled.button`
  width: 100%;
  background-color: ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
`

export const ButtonBordered = styled.button`
  width: 100%;
  background-color: ${COLORS.white};
  height: 48px;
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.p`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;
  & + & {
    margin-top: 10px;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const HeaderLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LabelName = styled(HeaderLabel)``
export const LabelValue = styled(HeaderLabel)`
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const InputsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  margin-top: 18px;
  margin-bottom: 22px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin: 40px 0;
  }
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  ${(props) => (props.sizeInput === 'big' ? 'width: 460px' : 'width: 200px')};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }
`
export const InputTextArea = styled.textarea<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  ${(props) =>
    props.sizeInput === 'big' ? 'max-width: 500px' : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  box-sizing: border-box;
  padding: 10px 20px;
  height: 140px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const RowInfo = styled.div`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const ReviewInfo = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 18px;
`

export const Buttons = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }
`
