type Elements = 'menu' | 'modal' | 'menuOverlay' | 'tooltip' | 'default'

const ZIndex: Record<Elements, number> = {
  menu: 10000,
  menuOverlay: 9999,
  modal: 1000,
  tooltip: 999,
  default: 900,
}
export default ZIndex
