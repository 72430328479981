import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'

export namespace InputWithButtonWrapperStyles {
  const ButtonStyles = css`
    min-width: 100px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    color: ${COLORS.blue};
  `

  export const Wrapper = styled.div`
    position: relative;
  `

  export const Button = styled.button`
    ${ButtonStyles};
  `

  export const LinkButton = styled.a`
    ${ButtonStyles};
  `
}
