import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { handlerError, setToastSuccess } from 'utils/handlerError'
import * as Yup from 'yup'
import { FormikFields } from 'constants/formikFields'
import { ButtonLabels } from 'constants/buttonLabels'
import { InputSizes } from 'constants/inputSizes'
import { RequiredFields } from 'constants/requiredFields'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { rejectBenefitApplication } from 'api/benefitsApplications'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import IconButton from 'components/ui/buttons/IconButton'
import BaseButton from 'components/ui/buttons/BaseButton'

interface IDeniedBenefitApplication {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
  benefitApplication: BenefitApplicationTypes.Model
}

enum DeniedBenefitApplicationEnum {
  commentaryPlaceholder = 'Причина отклонения заявки',
}

const DeniedBenefitApplication = ({
  show,
  onClose,
  benefitApplication,
}: IDeniedBenefitApplication) => {
  const handleClose = (): void => {
    onClose(!show)
  }

  const { handleChange, values, resetForm, handleSubmit, errors, isValid } =
    useFormik({
      initialValues: {
        commentary: '',
      },
      onSubmit: () => {
        rejectBenefitApplication(benefitApplication.id, values)
          .then(() => {
            resetForm()
            handleClose()
          })
          .catch(() => handlerError(errors))
      },
      validationSchema: Yup.object().shape({
        commentary: Yup.string().required(RequiredFields.base2),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>
            Отклонение заявки <br /> на{' '}
            {benefitApplication.benefit.operationType === 1
              ? 'начисление'
              : 'списание'}{' '}
            бенефита
          </ST.ModalTitle>
          <ST.Container>
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>
                  Комментарий <ST.Star>*</ST.Star>
                </ST.Label>
                <ST.ErrorText>{errors.commentary}</ST.ErrorText>
              </ST.LabelWrapper>
              <ST.InputTextArea
                placeholder={DeniedBenefitApplicationEnum.commentaryPlaceholder}
                sizeInput={InputSizes.big}
                id={FormikFields.commentary}
                name={FormikFields.commentary}
                value={values.commentary}
                onChange={handleChange}
              />
            </ST.InputWrapper>

            {benefitApplication.links && !!benefitApplication.links.length && (
              <ST.LinksWrapper>
                <ST.LabelLink>Прикрепленные ссылки</ST.LabelLink>
                {benefitApplication.links.map((link, index) => (
                  <ST.ContainerLink key={index}>
                    <ST.Links maxWidth={500} href={link} target={'_blank'}>
                      {link}
                    </ST.Links>
                    <ST.ContainerIcon>
                      <IconButton
                        style={{ width: '30px', height: '30px' }}
                        onClick={() => {
                          navigator.clipboard.writeText(link).then(() => {
                            setToastSuccess('Ссылка скопирована')
                          })
                        }}
                        icon={CopyIcon}
                        noFill
                      />
                    </ST.ContainerIcon>
                  </ST.ContainerLink>
                ))}
              </ST.LinksWrapper>
            )}
          </ST.Container>

          <BaseButton
            text={ButtonLabels.reject}
            onClick={checkForm}
            disabled={!isValid || !values.commentary.length}
          />
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeniedBenefitApplication
