import styled, { css } from 'styled-components'
import { AvatarImageTypes as Types } from './types'
import { COLORS } from 'constants/colors'

export namespace AvatarImageStyles {
  type ContainerProps = {
    size: Types.Size
    pointer: boolean
  }

  type BadgeProps = {
    $color: Exclude<Types.Props['badgeColor'], undefined>
    size: Types.Size | number
  }

  const sizes: Record<Types.Size, number> = {
    small: 22,
    medium: 48,
    large: 90,
  }

  const badgeSizes: Record<Types.Size, number> = {
    small: 4,
    medium: 8,
    large: 12,
  }

  const badgeBottom: Record<Types.Size, number> = {
    small: 0,
    medium: 0,
    large: 2,
  }

  const badgeRight: Record<Types.Size, number> = {
    small: 0,
    medium: 2,
    large: 6,
  }

  const getSize = (size: Types.Size | number, type: 'badge' | 'image') =>
    type === 'image'
      ? css`
          &,
          img {
            width: ${typeof size === 'number' ? size : sizes[size]}px;
            height: ${typeof size === 'number' ? size : sizes[size]}px;
          }
        `
      : css`
          width: ${typeof size === 'number' ? size : badgeSizes[size]}px;
          height: ${typeof size === 'number' ? size : badgeSizes[size]}px;
          bottom: ${typeof size === 'number' ? size : badgeBottom[size]}px;
          right: ${typeof size === 'number' ? size : badgeRight[size]}px;
        `

  export const Container = styled.div<ContainerProps>`
    position: relative;
    display: grid;
    place-items: center;
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
    ${({ size }) => getSize(size, 'image')};
  `

  export const Image = styled.img`
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
  `

  export const Badge = styled.div<BadgeProps>`
    position: absolute;
    border: 2px solid ${COLORS.white};
    background-color: ${({ $color }) => Types.BadgeColors[$color]};
    border-radius: 50%;
    ${({ size }) => getSize(size, 'badge')};
  `
}
