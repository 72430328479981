import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ControlsContainer = styled.div``

export const Label = styled.div`
  font-family: Golos-Text_VF;
  font-weight: 700;
`

export const Name = styled.div`
  margin-left: 10px;
`
