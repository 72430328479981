import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { ErrorMessages } from 'constants/errorMessages'
import {
  httpsDomainNullable,
  phoneRuNullable,
  stringLengthNullable,
  yearNullable,
} from 'utils/validation/tests'
import { createContext } from 'react'
import { NewCandidateTypes as Types } from './types'

const MAX_STORY_LENGTH = 10000
const MAX_COMPANY_NAME_LENGTH = 511
const MAX_RESPONSIBILITIES_LENGTH = 4095

export namespace NewCandidateData {
  export const EditContext = createContext<boolean>(false)

  export const validationSchema = Yup.object().shape({
    name: Yup.string().required(RequiredFields.base),
    hiring: Yup.number().nullable(),
    surname: Yup.string().nullable(),
    candidate: Yup.object().shape({
      phone: Yup.string()
        .nullable()
        .test(
          'is-phone-valid',
          ErrorMessages.incorrectPhoneFormat,
          phoneRuNullable
        ),
      additionalPhone: Yup.string()
        .nullable()
        .test(
          'is-additional-phone-valid',
          ErrorMessages.incorrectPhoneFormat,
          phoneRuNullable
        ),
      email: Yup.string().email(ErrorMessages.incorrectEmail).nullable(),
      additionalEmail: Yup.string()
        .email(ErrorMessages.incorrectEmail)
        .nullable(),
    }),
    gender: Yup.boolean().required(RequiredFields.base).nullable(),
    portfolioLink: Yup.string().test(
      'is-portfolio-link-valid',
      ErrorMessages.httpsDomain,
      httpsDomainNullable
    ),
    resumeLink: Yup.string().test(
      'is-resume-link-valid',
      ErrorMessages.httpsDomain,
      httpsDomainNullable
    ),
    candidateWorkPlace: Yup.array().of(
      Yup.object().shape({
        companyName: Yup.string()
          .test('company-name-length', ErrorMessages.maxSymbolsLimit, (value) =>
            stringLengthNullable(value, MAX_COMPANY_NAME_LENGTH)
          )
          .nullable(),
        responsibilities: Yup.string()
          .test(
            'responsibilities-length',
            ErrorMessages.maxSymbolsLimit,
            (value) => stringLengthNullable(value, MAX_RESPONSIBILITIES_LENGTH)
          )
          .nullable(),
        startWork: Yup.string()
          .test(
            'is-start-work-year-valid',
            ErrorMessages.incorrectYear,
            yearNullable
          )
          .nullable(),
        endWork: Yup.string()
          .test(
            'is-end-work-year-valid',
            ErrorMessages.incorrectYear,
            yearNullable
          )
          .nullable(),
        companyWebsite: Yup.string()
          .nullable()
          .test(
            'companyWebsite-test',
            ErrorMessages.httpsDomain,
            httpsDomainNullable
          ),
      })
    ),
    education: Yup.array().of(
      Yup.object().shape({
        yearEntered: Yup.string()
          .test(
            'is-education-year-entered-valid',
            ErrorMessages.incorrectYear,
            yearNullable
          )
          .nullable(),
        yearEnded: Yup.string()
          .test(
            'is-education-year-ended-valid',
            ErrorMessages.incorrectYear,
            yearNullable
          )
          .nullable(),
      })
    ),
    additionalEducation: Yup.array().of(
      Yup.object().shape({
        yearEntered: Yup.string()
          .test(
            'is-additional-education-year-entered-valid',
            ErrorMessages.incorrectYear,
            yearNullable
          )
          .nullable(),
        yearEnded: Yup.string()
          .test(
            'is-additional-education-year-ended-valid',
            ErrorMessages.incorrectYear,
            yearNullable
          )
          .nullable(),
        certificateLink: Yup.string().matches(
          /^https:\/\/.+?\..+/i,
          ErrorMessages.httpsDomain
        ),
      })
    ),
    story: Yup.string()
      .test('story-length', ErrorMessages.maxSymbolsLimit, (value) =>
        stringLengthNullable(value, MAX_STORY_LENGTH)
      )
      .nullable(),
  })

  export const InitialValues: Types.Form = {
    hiring: 0,
    hiringType: { item: '', value: 0 },
    hireType: null,
    employmentType: null,
    source: { item: '', value: '' },
    sourceSelectedValue: null,
    resumeLink: '',
    name: '',
    patronymic: '',
    surname: '',
    birthday: '',
    city: '',
    gender: null,
    isMarried: false,
    isHaveChildren: false,
    story: '',
    portfolioLink: '',
    portfolioFile: null,
    portfolioFileToDeleteId: null,
    photoFile: null,
    citizenship: [''],
    workPermit: [''],
    readyToWork: NaN,
    position: '',
    grade: '',
    salary: NaN,
    specialization: [''],
    hardSkills: [''],
    removal: NaN,
    businessTrip: NaN,
    timeToWork: NaN,
    busyness: {
      fullEmployment: false,
      partTimeEmployment: false,
      projectWork: false,
      volunteering: false,
      internship: false,
    },
    workSchedule: {
      fullDay: false,
      shiftSchedule: false,
      flexibleSchedule: false,
      remoteWork: false,
      shiftMethod: false,
    },
    candidateWorkPlace: [
      {
        companyName: '',
        companyWebsite: '',
        companyLocation: '',
        companyActivity: '',
        position: '',
        responsibilities: '',
        hardSkills: [''],
        startWorkMonthType: { item: '', value: 0 },
        startWorkMonth: NaN,
        startWork: '',
        endWorkMonthType: { item: '', value: 0 },
        endWorkMonth: NaN,
        endWork: '',
      },
    ],
    educationLvlType: null,
    educationLvl: null,
    education: [
      {
        name: '',
        location: '',
        educationForm: 0,
        educationFormType: { item: '', value: 0 },
        yearEntered: NaN,
        yearEnded: NaN,
        faculty: '',
        directionTraining: '',
        speciality: '',
        successes: '',
        diploma: null,
        _diplomaFile: null,
        _diplomaFileToDelete: null,
      },
    ],
    additionalEducation: [
      {
        name: '',
        courseName: '',
        yearEntered: NaN,
        yearEnded: NaN,
        hardSkills: [''],
        successes: '',
        certificateLink: '',
        certificate: NaN,
        certificateName: '',
        _certificateFile: null,
        _certificateFileToDeleteId: null,
        certificateFile: null,
      },
    ],
    isHaveDrivingLicense: false,
    isHaveAuto: false,
    driverLicenseCategory: [''],
    nativeLanguage: '',
    foreignLanguage: [
      {
        name: '',
        nameType: { item: '', value: 0 },
        level: 0,
        levelType: { item: '', value: 0 },
      },
    ],
    candidate: {
      phone: null,
      additionalPhone: null,
      comment: '',
      email: '',
      additionalEmail: '',
      personalSiteLink: '',
      messengers: [
        {
          name: '',
          nick: '',
        },
      ],
    },
    photo: null,
    resumeFile: null,
    resume: null,
    portfolio: null,
    photoPath: '',
    resumeFilePath: '',
    portfolioPath: '',
    isReserved: false,
  }

  export const AdditionalEducationPattern = {
    name: '',
    location: '',
    educationForm: 1,
    educationFormType: { item: '', value: 0 },
    hardSkills: [''],
    yearEntered: '',
    yearEnded: '',
    faculty: '',
    directionTraining: '',
    speciality: '',
    successes: '',
  }

  export const CandidateWorkPlacePattern = {
    companyName: '',
    companyWebsite: '',
    companyLocation: '',
    companyActivity: '',
    position: '',
    responsibilities: '',
    hardSkills: [''],
    startWorkMonthType: { item: '', value: 0 },
    startWorkMonth: '',
    startWork: '',
    endWorkMonthType: { item: '', value: 0 },
    endWorkMonth: '',
    endWork: '',
  }

  export const EducationPattern = {
    name: '',
    location: '',
    educationForm: 1,
    educationFormType: { item: '', value: 0 },
    yearEntered: 0,
    yearEnded: 0,
    faculty: '',
    directionTraining: '',
    speciality: '',
    successes: '',
  }

  export const ForeignLanguagePattern = {
    name: '',
    level: 0,
    levelType: { item: '', value: 0 },
  }

  export enum Messages {
    SUCCESS_NEW = 'Новый кандидат успешно добавлен',
    SUCCESS_EDIT = 'Кандидат успешно изменен',
    ERROR_NEW = 'Произошла ошибка при добавлении нового кандидата',
    ERROR_EDIT = 'Произошла ошибка при изменении кандидата',
    ERROR_FETCH = 'Произошла ошибка при получении данных кандидата',
  }

  export const PHONE_LENGTH = 10
}
