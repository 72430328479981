import styled from 'styled-components/macro'
import { COLORS } from 'constants/colors'
import Arrow from 'assets/icons/arrow-up.svg'
import { BreakPoints } from 'constants/breakPoints'

interface IButton {
  typeButton?: 'secondary' | 'order'
  width?: string
  height?: string
}

interface IFilterTitle {
  isOpen: boolean
}

interface CompareProps {
  isPositive: boolean
}

interface IFilterList {
  isOpen: boolean
}

interface IScore extends WidthProps {
  max?: number
}

interface WidthProps {
  width?: number
  isVisible?: boolean
}

export const DepartmentsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${BreakPoints.DESKTOPS2}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

export const DepartmentsBody = styled.div``

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`

export const DepartmentsTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;
`

export const DepartmentsBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FilterBlock = styled.div`
  background: ${COLORS.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 24px 30px;
  margin-top: 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-between;
`

export const FilterTitle = styled.div<IFilterTitle>`
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};

  &:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    background: url(${Arrow});
    right: 30px;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`

export const FilterList = styled.div<IFilterList>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  row-gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1439px) {
    gap: 20px;
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 20px;
`

export const Button = styled.button<IButton>`
  background: ${(props) =>
    props.typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  color: ${COLORS.black};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background: ${COLORS.yellowActiveButton};
    border: 1px solid ${COLORS.yellowActiveButton};
  }
`

export const TimeButton = styled.p`
  padding: 5px 18px;
  color: ${COLORS.blue};

  &:hover {
    cursor: pointer;
  }
`

export const Space = styled.p``

export const FilterLineText = styled.p`
  min-width: 100px;
`

export const FilterWrapper = styled.div``

export const ApplicationsBody = styled.div``

export const ApplicationsBlock = styled.table<WidthProps>`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 8px;
  overflow-x: auto;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

export const ApplicationsHead = styled.div<WidthProps>`
  display: flex;
  gap: 4px;
  max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  justify-content: space-between;
  padding: 0 10px;
`

export const FilterItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;

  & > div {
    width: clamp(120px, 160px, 240px);
  }

  @media (max-width: ${BreakPoints.DESKTOPS2}) {
    &:has(${TimeButton}) {
      flex-wrap: wrap;
    }
  }
`

export const FilterLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: ${BreakPoints.DESKTOPS2}) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    & > div {
      max-width: none;
    }
  }
`

export const ApplicationStatus = styled.div<IScore>`
  padding: 5px 0;
  text-align: left;
  width: ${(props) => `${props.width}%`};
  max-width: 128px;
`

export const SingleScore = styled.div<IScore>`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  background: ${COLORS.lightGrey};
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  max-width: 128px;
`

export const SingleScoreResult = styled.div`
  display: flex;
`

export const SingleMainScore = styled.p`
  color: ${COLORS.black};
  font-family: Golos, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 28.8px */
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

export const Employees = styled.div<WidthProps>`
  padding: 24px 24px 30px 24px;
  box-shadow: 0 4px 15px 0 #3333330f;
  border-radius: 15px;
  max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
`

export const EmployeesTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
`

export const StatisticVisibleBody = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
`

export const SingleScoreCompare = styled.p<CompareProps>`
  color: ${(props) =>
    props.isPositive ? `${COLORS.green}` : `${COLORS.errorRed}`};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-left: 4px;
`

export const SingleScoreNotice = styled.p`
  color: ${COLORS.black70};
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 6px;

  :first-child {
    margin-top: 13px;
  }
`

export const SingleScoreNoticeSpan = styled(SingleScoreNotice)`
  font-weight: 500;
  display: inline;
`

export const WithoutCompareCheckbox = styled.div`
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CompareCheckbox = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
export const WithoutCompareCheckboxLabel = styled.label`
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-left: 8px;
`

export const StatisticHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Result = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  gap: 40px;
  margin-right: 84px;
`

export const ResultTitle = styled.div`
  color: ${COLORS.black70};
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`

export const ResultScore = styled.span`
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`
