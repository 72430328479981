import Modal from 'components/ui/overlay/Modal'
import { FC, useState } from 'react'
import { Tags } from 'styles/Tags'
import { COLORS } from 'constants/colors'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
  description: string
  title: string
  isArchived: boolean
}

const ArchiveModal: FC<Props> = ({
  open,
  onClose,
  isArchived,
  onSubmit,
  description,
  title,
}) => {
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title={`Архивирование ${title}`}
      submitButtonText={isArchived ? 'Вернуть' : 'Убрать'}
      onConfirm={() => {
        setSubmitDisabled(true)

        onSubmit()
          .then(onClose)
          .finally(() => {
            setSubmitDisabled(false)
          })
      }}
      disableSubmit={submitDisabled}
    >
      <Tags.P2 style={{ color: COLORS.black70 }}>
        {isArchived
          ? `Вернуть ${description} из архива?`
          : `Убрать ${description} в архив?`}
      </Tags.P2>
    </Modal>
  )
}

export default ArchiveModal
