import { HiringStepsEnum } from 'constants/settingsSteps'

// TODO namespace

export enum NotDeletableReasons {
  BASIC_ACTION = 1,
  HAS_RESUMES = 2,
}

export type HiringStep = {
  id?: number
  isActive: boolean
  isDeletable: boolean
  name: string
  sequence: number
  action: HiringStepsEnum | null
  actionName: string
  notDeletableReason?: NotDeletableReasons
  notDeletableReasonText?: string
  isNotifyCustomer?: boolean
  isNotifyRecruiter?: boolean
}
