import styled from 'styled-components'
import { IIcon } from 'components/Header/Menu/styled'
import ImageEmployee from 'assets/images/employee.jpg'
import { COLORS } from 'constants/colors'
import { CommonStyles } from 'styles/CommonStyles'

export const UserLine = styled.div`
  display: flex;
  align-items: center;
`

export const Label = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
  margin-right: 5px;
`

export const Count = styled.div`
  width: 50px;
  padding: 1px 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-right: 8px;
  background: ${COLORS.lightGrey20};
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`

export const Photo = styled.div<IIcon>`
  background: url(${({ imageSrc }) => (imageSrc ? imageSrc : ImageEmployee)})
    50% 50% no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: 40px 25px;
  position: relative;
  margin: 0 10px;
`

export const Name = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
`

export const Position = styled.p`
  color: ${COLORS.black70};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
`
