import styled from 'styled-components'
import { InputWithIconWrapperTypes as Types } from './types'

export namespace InputWithIconWrapperStyles {
  type IconProps = {
    pointer: boolean
    offset?: Types.Props['offset']
  }

  export const Wrapper = styled.div`
    position: relative;
  `

  export const Icon = styled.div<IconProps>`
    position: absolute;
    top: ${({ offset }) => (offset ? offset.top : '10px')};
    right: ${({ offset }) => (offset ? offset.right : '10px')};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  `
}
