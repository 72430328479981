import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import ZIndex from 'constants/styles/zIndex'

export namespace Popup {
  type CenteredProps = {
    centered?: boolean
  }

  export const Wrapper = styled.div<CenteredProps>`
    position: ${({ centered }) => (centered ? 'relative' : 'static')};
  `

  export const Container = styled.pre<CenteredProps>`
    position: absolute;
    ${({ centered }) =>
      centered &&
      css`
        left: 50%;
        top: 90%;
      `};
    border-radius: 6px;
    background: ${COLORS.white};
    box-shadow: 0 12px 24px 0 rgba(51, 51, 51, 0.1);
    padding: 10px;
    color: ${COLORS.black70};
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
    z-index: ${ZIndex.modal};
  `
}
