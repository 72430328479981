import { IItem } from 'components/ui/BaseSelect'

export enum BenefitStatuses {
  active = 10,
  archived = 20,
}

export enum BenefitStatusesDescription {
  active = 'Активный',
  archived = 'В архиве',
}

export const listBenefitStatuses: IItem<
  BenefitStatuses,
  BenefitStatusesDescription
>[] = [
  {
    item: BenefitStatusesDescription.active,
    value: BenefitStatuses.active,
  },
  {
    item: BenefitStatusesDescription.archived,
    value: BenefitStatuses.archived,
  },
]
