import styled from 'styled-components'
import { IIcon } from 'components/Header/Menu/styled'
import ImageEmployee from 'assets/images/employee.jpg'
import { IStatusCircle } from 'components/employees/EmployeeBlock/styled'
import { COLORS } from 'constants/colors'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ControlsContainer = styled.div``

export const Label = styled.div`
  font-family: 'Golos-Text_VF';
  font-weight: 700;
`

export const Photo = styled.div<IIcon>`
  background: url(${({ imageSrc }) => (imageSrc ? imageSrc : ImageEmployee)})
    50% 50% no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: 40px 25px;
  position: relative;
  margin: 0 8px 0 10px;
`

export const StatusCircle = styled.div<IStatusCircle>`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${COLORS.white};
  left: 16px;
  top: 18px;
  &:before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.statusInOffice ? COLORS.green : COLORS.yellow};
    position: absolute;
    top: 1px;
    left: 1px;
  }
`

export const Name = styled.div``
