import { Dispatch } from 'react'
import { UserTypes } from 'types/model/user'
import { UserInfoAction, UserInfoActionTypes } from 'types/store'
import getFileData from 'utils/file/getFileData'

export const setUserInfo =
  (
    payload: UserTypes.UserInfo
  ): ((dispatch: Dispatch<UserInfoAction>) => void) =>
  (dispatch) =>
    dispatch({
      type: UserInfoActionTypes.SET_USER_INFO,
      payload: {
        ...payload,
        fullName: `${payload.surname} ${
          payload.maidenName ? '(' + payload.maidenName + ')' : ''
        } ${payload.name} ${payload.patronymic}`,
        photo: getFileData(payload.userImg),
      },
    })

export const deleteUserInfo = (): ((
  dispatch: Dispatch<UserInfoAction>
) => void) => {
  return (dispatch: Dispatch<UserInfoAction>) => {
    dispatch({ type: UserInfoActionTypes.RESET_USER_INFO })
  }
}
