import React, { FC } from 'react'
import * as ST from './styled'
import DashedButton from 'components/ui/buttons/DashedButton'
import { ReactComponent as PlusIcon } from 'assets/icons/blue-plus.svg'

interface Props {
  onClick?: () => void
}

const AddGradeButton: FC<Props> = ({ onClick }) => (
  <DashedButton onClick={() => onClick && onClick()}>
    <ST.Container>
      <ST.Text>Добавить грейд</ST.Text>
      <PlusIcon />
    </ST.Container>
  </DashedButton>
)

export default AddGradeButton
