import styled from 'styled-components'
import { BreakPoints } from 'constants/breakPoints'
import { COLORS } from 'constants/colors'

import { ModalLoaderTypes as T } from './types'

export const WrapperLoader = styled.div<T.Props>`
  position: absolute;
  padding: 40px;
  opacity: 0.5;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: 100%;
  z-index: 100;
  background: ${COLORS.black};
  border-radius: 15px 0 0 15px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
  }
`
