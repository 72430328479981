import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { Tags } from 'styles/Tags'
import { CommonStyles } from 'styles/CommonStyles'
import { BreakPoints } from 'constants/breakPoints'

export namespace HiringTypeBlockStyles {
  export const Container = styled.div`
    border-radius: 15px;
    background: ${COLORS.white};
    box-shadow: 0 4px 15px 0 #3333330f;
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 20px 40px;
  `

  export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    ${Tags.p1Bold} {
      width: 20%;
      ${CommonStyles.WrappedText};
    }

    ${Tags.p2Bold} {
      width: 25%;
      text-align: center;
    }
  `

  export const TableRowData = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;

    border-bottom: 1px dashed ${COLORS.black20};
    padding-bottom: 20px;

    ${Tags.p1} {
      width: calc(33% - 4px);
      text-align: center;

      &:first-of-type {
        ${CommonStyles.WrappedText};
      }

      @media (max-width: ${BreakPoints.DESKTOPS2}) {
        ${CommonStyles.WrappedText};
      }
    }
  `

  export const Table = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${Tags.p2Bold} {
      color: ${COLORS.black50};
    }
  `

  export const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &:last-of-type ${TableRowData}:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    ${Tags.p2Bold} {
      width: 20%;
    }
  `

  export const TableRowDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 20px;
  `

  export const Buttons = styled.div`
    display: flex;
    gap: 16px;
  `
}
