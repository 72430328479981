import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { CommonStyles } from 'styles/CommonStyles'

export const PositionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 27.5px 40px;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;

  @media (max-width: ${BreakPoints.PHONES}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    div:last-of-type {
      align-self: flex-end;
    }
  }
`

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
`

export const Title = styled.div<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
  &:first-of-type {
    display: block;
    width: 60%;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
  color: ${COLORS.black70};
`

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 104px;
  gap: 16px;
`
