export enum FormActionTypes {
  REMOVEFORMSELECT = 'REMOVEFORMSELECT',
  ADDFORMSELECT = 'ADDFORMSELECT',
}

interface AddFormSelect {
  type: FormActionTypes.ADDFORMSELECT
  payload: { type: string; item: string; value: string }
}

interface RemoveFormSelect {
  type: FormActionTypes.REMOVEFORMSELECT
  payload: { type: string }
}

export interface ISelect {
  type: string
  item: string
  value: string
}

export interface FormState {
  arr: Array<ISelect>
}

export type FormAction = RemoveFormSelect | AddFormSelect
