import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import ImageEmployee from 'assets/images/employee.jpg'
import ZIndex from 'constants/styles/zIndex'

export interface IIcon {
  imageSrc?: string
  isOwn?: boolean
}

export interface IMenu {
  isActive?: boolean
}

interface IItem {
  name?: string
  amount?: string
  counter?: number
}

export const MenuContainer = styled.div<IMenu>`
  background: ${COLORS.backgroundBlue};
  position: fixed;
  z-index: ${ZIndex.menu};
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  transform: ${({ isActive }) =>
    isActive ? 'translateX(0)' : 'translateX(100%)'};
  transition: 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const MenuOverlay = styled.div<IMenu>`
  position: absolute;
  z-index: ${ZIndex.menuOverlay};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.overlay};
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  justify-content: center;
`

export const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  top: 30px;
  right: 30px;
  background: ${COLORS.yellow};
  border-radius: 50px;
  width: 48px;
  height: 48px;

  &:hover {
    background: ${COLORS.yellowHover};
  }

  &:active {
    background: ${COLORS.yellowHover};
  }
`

export const ListItems = styled.div`
  margin-top: 120px;
`

export const EmployeeIcon = styled.div<IIcon>`
  background: url(${({ imageSrc }) => (imageSrc ? imageSrc : ImageEmployee)})
    50% 50% no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
`

export const LinkIcon = styled.div<IIcon>`
  width: 24px;
  height: 24px;
  display: flex;
  -webkit-mask-image: url(${({ imageSrc }) => (imageSrc ? imageSrc : null)});
  mask-image: url(${({ imageSrc }) => (imageSrc ? imageSrc : null)});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  background-color: #fefefe;
`

export const LinkName = styled.p`
  padding-left: 32px;
`

export const Item = styled.div<IItem>`
  display: flex;
  align-items: center;
  padding-left: 60px;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.white};
  height: 65px;
  width: 100%;

  &.activeItem {
    cursor: pointer;
    color: ${COLORS.black};
    background: ${COLORS.yellow};

    ${LinkIcon} {
      background-color: ${COLORS.black};
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: ${({ counter }) => (!!counter ? `"${counter}"` : '')};
    margin-left: 15px;
    display: ${({ name }) => (name === 'Заявки' ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.yellow};
    border-radius: 4px;
    width: 24px;
    height: 24px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${COLORS.black};
  }

  &:last-child {
    position: relative;
    width: 100%;
    bottom: 0;
  }
`
