import styled from 'styled-components'
import { BreakPoints } from 'constants/breakPoints'

const BREAKPOINT = BreakPoints.TABLETS

export const StatusBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 12px 0 24px;

  @media (max-width: ${BREAKPOINT}) {
    justify-content: flex-start;
    margin-left: 7px;
  }
`

export const Label = styled.span`
  margin-left: 8px;
  font-size: 14px;

  @media (max-width: ${BREAKPOINT}) {
    margin-left: 15px;
  }
`
