import React, { ElementRef, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ModalTitles } from 'constants/modalTitles'
import { ButtonLabels } from 'constants/buttonLabels'
import { handlerError } from 'utils/handlerError'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from 'constants/formikFields'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { createHardwareType } from 'api/hardwareTypes'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'

interface IDeniedAccess {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
}

const CreateHardwareType = ({ show, onClose, showEndModal }: IDeniedAccess) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)
  const handleClose = (): void => {
    onClose(!show)
  }

  const { handleSubmit, handleChange, values, errors, isValid } = useFormik({
    validateOnMount: true,
    initialValues: {
      name: '',
    },
    onSubmit: () => {
      setIsLoading(true)
      createHardwareType(values)
        .then(() => {
          showEndModal?.(true)
          handleClose()
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required(RequiredFields.base),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{ModalTitles.createHardwareType}</ST.ModalTitle>
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>
                Тип оборудования<ST.Star>*</ST.Star>
              </ST.Label>
            </ST.LabelWrapper>
            <ST.Input
              placeholder={Placeholders.hardWareType}
              id={FormikFields.name}
              name={FormikFields.name}
              value={values.name}
              onChange={handleChange}
              sizeInput={'big'}
            />
          </ST.InputWrapper>
          <ST.Button disabled={!isValid} onClick={checkForm}>
            {ButtonLabels.add}
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateHardwareType
