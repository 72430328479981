import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import { handlerError, setToastInfo } from 'utils/handlerError'
import { ButtonTypes } from 'constants/buttonTypes'
import { EditingGeneralTabContentStrings } from 'components/settings/Tabs/Contacts/EditingContactsTabContent'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { putSetting, uploadSurveyPattern } from 'api/settings'
import { editPosition } from 'api/position'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { Placeholders } from 'constants/placeholders'
import AddPositionButton from 'components/settings/Tabs/Department/EditDepartment/AddPositionButton'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import { RequiredFields } from 'constants/requiredFields'
import * as Yup from 'yup'
import BaseInput from 'components/ui/inputs/BaseInput'
import { InputWithButtonWrapper } from 'components/ui/inputs/BaseInput/InputWithButtonWrapper'
import BackButton from 'components/ui/buttons/BackButton'
import AttachFile from 'components/ui/inputs/AttachFile'
import { FileTypes } from 'constants/fileTypes'
import IconButton from 'components/ui/buttons/IconButton'

interface IEditingMailTabContent {
  handleIsEditing: () => void
  setIsEditing: Dispatch<SetStateAction<boolean>>
  isEditingTabs: boolean
  data: ISetting[]
  posToSheet: IItem[][]
  positions: IItem[]
  sheetLists: IItem[]
}

const ReviewTab: FC<IEditingMailTabContent> = ({
  handleIsEditing,
  data,
  posToSheet,
  positions,
  sheetLists,
}) => {
  const [positionsToSheet, setPositionsToSheets] =
    useState<IItem[][]>(posToSheet)
  const [surveyExcelFile, setSurveyExcelFile] = useState<File | null>(null)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  const excelFileFromData = data.find(
    (setting) => setting.name === SettingsKeys.SURVEY_PATTERN_EXCEL_FILE
  )?.img

  const filterItems = useCallback(
    (items: IItem[], index: 0 | 1) =>
      items.filter(
        (item) => !positionsToSheet.find((p) => p[index].value === item.value)
      ),
    [positionsToSheet]
  )

  const availablePositions = useMemo(
    () => filterItems(positions, 0),
    [filterItems, positions]
  )

  const availableSheets = useMemo(
    () => filterItems(sheetLists, 1),
    [filterItems, sheetLists]
  )

  // TODO не отправлять запросы если не было изменений в поле
  const onSubmit = async () => {
    const filteredSettings = data.filter((setting) =>
      [
        SettingsKeys.PATTERN_TECH_SURVEY,
        SettingsKeys.PATTERN_COMMON_SURVEY,
        SettingsKeys.PATTERN_SURVEY_360,
        SettingsKeys.GOOGLE_CLIENT_ID,
        SettingsKeys.GOOGLE_REDIRECT_URI,
        SettingsKeys.REVIEW_NAME,
      ].includes(setting.name as SettingsKeys)
    )

    const settingsPromises = filteredSettings.map((setting) => {
      switch (setting.name) {
        case SettingsKeys.PATTERN_TECH_SURVEY:
          return putSetting(setting.id, {
            ...setting,
            value: values.pattern_tech_survey,
          })
        case SettingsKeys.PATTERN_COMMON_SURVEY:
          return putSetting(setting.id, {
            ...setting,
            value: values.pattern_common_survey,
          })
        case SettingsKeys.PATTERN_SURVEY_360:
          return putSetting(setting.id, {
            ...setting,
            value: values.pattern_survey_360,
          })
        case SettingsKeys.GOOGLE_CLIENT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.client_id,
          })
        case SettingsKeys.GOOGLE_REDIRECT_URI:
          return putSetting(setting.id, {
            ...setting,
            value: values.redirect_uri,
          })
        case SettingsKeys.REVIEW_NAME:
          return putSetting(setting.id, {
            ...setting,
            value: values.review_name,
          })
      }
    })

    const positionPromises = positionsToSheet.map(([position, sheet]) =>
      editPosition(+position.value, {
        name: position.item,
        patternSheet: sheet.item,
      })
    )

    setButtonDisabled(true)

    const promises = [...settingsPromises, ...positionPromises]

    if (surveyExcelFile)
      promises.push(uploadSurveyPattern(surveyExcelFile, surveyExcelFile.name))

    Promise.all(promises)
      .then(() => {
        if (surveyExcelFile)
          setToastInfo(
            'Пожалуйста, проверьте соответствие листов и должностей для опросов'
          )
        handleIsEditing()
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        pattern_tech_survey: getSettingByKey(
          data,
          SettingsKeys.PATTERN_TECH_SURVEY
        )?.value,
        pattern_common_survey: getSettingByKey(
          data,
          SettingsKeys.PATTERN_COMMON_SURVEY
        )?.value,
        pattern_survey_360: getSettingByKey(
          data,
          SettingsKeys.PATTERN_SURVEY_360
        )?.value,
        client_id: getSettingByKey(data, SettingsKeys.GOOGLE_CLIENT_ID)?.value,
        review_name: getSettingByKey(data, SettingsKeys.REVIEW_NAME)?.value,
        redirect_uri: getSettingByKey(data, SettingsKeys.GOOGLE_REDIRECT_URI)
          ?.value,
        survey_pattern_excel_file: getSettingByKey(
          data,
          SettingsKeys.SURVEY_PATTERN_EXCEL_FILE
        )?.value,
      },
      onSubmit,
      validationSchema: Yup.object().shape({
        pattern_tech_survey: Yup.string().trim().required(RequiredFields.base),
        pattern_common_survey: Yup.string()
          .trim()
          .required(RequiredFields.base),
        pattern_survey_360: Yup.string().trim().required(RequiredFields.base),
        client_id: Yup.string().trim().required(RequiredFields.base),
        review_name: Yup.string().trim().required(RequiredFields.base),
        redirect_uri: Yup.string().trim().required(RequiredFields.base),
      }),
    })

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const addPositionToSheet = (): void => {
    setPositionsToSheets((prevState) => [
      ...prevState,
      [
        { item: '', value: 0 },
        { item: '', value: 0 },
      ],
    ])
  }

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />
          <ST.EditingHeader>Редактирование</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>
        <ST.SaveButton
          type={ButtonTypes.submit}
          onClick={checkForm}
          disabled={!isValid || buttonDisabled}
          isDisabled={!isValid || buttonDisabled}
        >
          Сохранить
        </ST.SaveButton>
      </ST.ManageBlock>

      <ST.BlockInfo>
        <BaseInput
          id={EditingGeneralTabContentStrings.review_name}
          name={EditingGeneralTabContentStrings.review_name}
          label="Название интеграции"
          placeholder="Название"
          value={values.review_name}
          onChange={handleChange}
          required
        />
        <InputWithButtonWrapper
          buttonText="Перейти к созданию API клиента"
          buttonUrl="https://console.cloud.google.com/apis/credentials"
        >
          <BaseInput
            id={EditingGeneralTabContentStrings.client_id}
            name={EditingGeneralTabContentStrings.client_id}
            label="Client ID"
            placeholder="Client ID"
            value={values.client_id}
            onChange={handleChange}
            required
          />
        </InputWithButtonWrapper>

        <InputWithButtonWrapper
          buttonText="Перейти к созданию API Google проекта"
          buttonUrl="https://console.cloud.google.com/projectcreate"
        >
          <BaseInput
            id={EditingGeneralTabContentStrings.redirect_uri}
            name={EditingGeneralTabContentStrings.redirect_uri}
            label="Redirect URI"
            placeholder="Redirect URI"
            value={values.redirect_uri}
            onChange={handleChange}
            required
          />
        </InputWithButtonWrapper>

        <ST.HeaderBlock>Идентификаторы документов</ST.HeaderBlock>
        <BaseInput
          id={EditingGeneralTabContentStrings.pattern_survey_360}
          name={EditingGeneralTabContentStrings.pattern_survey_360}
          label="Опрос 360"
          placeholder="Опрос 360"
          value={values.pattern_survey_360}
          onChange={handleChange}
          required
        />
        <BaseInput
          id={EditingGeneralTabContentStrings.pattern_common_survey}
          name={EditingGeneralTabContentStrings.pattern_common_survey}
          label="Общий опрос сотрудника"
          placeholder="Общий опрос сотрудника"
          value={values.pattern_common_survey}
          onChange={handleChange}
          required
        />
        <BaseInput
          id={EditingGeneralTabContentStrings.pattern_tech_survey}
          name={EditingGeneralTabContentStrings.pattern_tech_survey}
          label="Техническая анкета"
          placeholder="Техническая анкета"
          value={values.pattern_tech_survey}
          onChange={handleChange}
          required
        />

        <ST.HeaderBlock>Шаблон опросов</ST.HeaderBlock>
        <AttachFile
          label="Загрузить документ (xlsx)"
          accept={FileTypes.excel}
          hideDeleteButton
          onChange={(value, file) => {
            setFieldValue('survey_pattern_excel_file', value?.name)
            setSurveyExcelFile(file ?? null)
          }}
          files={
            values.survey_pattern_excel_file
              ? [{ name: values.survey_pattern_excel_file, id: null }]
              : excelFileFromData
              ? [{ ...excelFileFromData, name: excelFileFromData.name ?? null }]
              : []
          }
        />
      </ST.BlockInfo>

      <ST.BlockInfo>
        <ST.HeaderBlock>Соответствие листов и должностей</ST.HeaderBlock>

        {positionsToSheet?.map((el, index) => (
          <ST.BottomWrapper key={+el[0].value!}>
            <ST.Wrapper>
              <ST.InputSelectWrapper>
                <ST.Label>Должность</ST.Label>
                <BaseSelect
                  isSmallSelect
                  required
                  placeHolder={Placeholders.choosePosition}
                  listItems={availablePositions}
                  name={'position'}
                  value={positionsToSheet[index][0].item || ''}
                  typeSelect={'position'}
                  onChange={(newValue) => {
                    if (newValue) {
                      const list = [...positionsToSheet]
                      list[index][0] = newValue
                      setPositionsToSheets(list)
                    }
                  }}
                />
              </ST.InputSelectWrapper>
              <ST.InputSelectWrapper>
                <ST.Label>Наименование листа в таблицах</ST.Label>
                <BaseSelect
                  isSmallSelect
                  required
                  placeHolder={Placeholders.sheetName}
                  listItems={availableSheets}
                  name={'sheetList'}
                  value={positionsToSheet[index][1]?.item || ''}
                  typeSelect={'sheetList'}
                  onChange={(newValue) => {
                    if (newValue) {
                      const list = [...positionsToSheet]
                      list[index][1] = newValue
                      setPositionsToSheets(list)
                    }
                  }}
                />
              </ST.InputSelectWrapper>

              <IconButton
                icon={DeleteButton}
                onClick={() => {
                  const list = [
                    ...positionsToSheet
                      .map((value, listIndex) => {
                        const [position] = value

                        if (listIndex === index) {
                          editPosition(+position.value, {
                            name: position.item,
                            patternSheet: '',
                          })
                        }

                        return value
                      })
                      .filter((_, listIndex) => listIndex !== index),
                  ]
                  setPositionsToSheets(list)
                }}
              />
            </ST.Wrapper>
          </ST.BottomWrapper>
        ))}
        <ST.ButtonWrapper>
          <AddPositionButton onClick={addPositionToSheet} />
        </ST.ButtonWrapper>
      </ST.BlockInfo>
    </>
  )
}

export default ReviewTab
