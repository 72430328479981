import Loader from 'components/ui/Loader'
import React, { FC } from 'react'
import * as ST from './styled'
import { ModalLoaderTypes as T } from './types'

export const ModalLoader: FC<T.Props> = ({ height }) => (
  <ST.WrapperLoader height={height}>
    <Loader width={100} height={100} border={8} />
  </ST.WrapperLoader>
)
