import React, { FC, useState } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import {
  handlerError,
  setToastError,
  setToastSuccess,
} from 'utils/handlerError'
import { EditingGeneralTabContentStrings } from 'components/settings/Tabs/Contacts/EditingContactsTabContent'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { putSetting } from 'api/settings'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import { RequiredFields } from 'constants/requiredFields'
import * as Yup from 'yup'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseInput from 'components/ui/inputs/BaseInput'
import { IntegrationsSettingsTypes as T } from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/types'
import BackButton from 'components/ui/buttons/BackButton'

interface IEditingMailTabContent {
  handleIsEditing: () => void
  setIsEditing: SetState<boolean>
  isEditingTabs: boolean
  data: ISetting[]
}

type IForm = Record<
  | 'review_chat_id'
  | 'admin_chat_id'
  | 'bot_vk_name'
  | 'access_chat_id'
  | 'bot_token'
  | 'nickname',
  string | undefined
>

const inputsData: T.InputData[] = [
  {
    label: 'Название интеграции',
    placeholder: 'Название',
    field: 'bot_vk_name',
  },
  {
    label: 'Ник',
    placeholder: 'Ник',
    field: 'nickname',
  },
  {
    label: 'Токен',
    placeholder: 'Токен',
    field: 'bot_token',
  },
  {
    label: 'Идентификатор чата администраторов',
    placeholder: 'Идентификатор чата администраторов',
    field: 'admin_chat_id',
  },
  {
    label: 'Идентификатор чата по доступам',
    placeholder: 'Идентификатор чата по доступам',
    field: 'access_chat_id',
  },
  {
    label: 'Идентификатор чата по ревью',
    placeholder: 'Идентификатор чата по ревью',
    field: 'review_chat_id',
  },
]

const VKTab: FC<IEditingMailTabContent> = ({ handleIsEditing, data }) => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const onSubmit = async () => {
    const filteredSettings = data.filter((setting) =>
      [
        SettingsKeys.REVIEW_CHAT_ID,
        SettingsKeys.ADMIN_CHAT_ID,
        SettingsKeys.ACCESS_CHAT_ID,
        SettingsKeys.BOT_TOKEN,
        SettingsKeys.NICKNAME,
        SettingsKeys.BOT_VK_NAME,
      ].includes(setting.name as SettingsKeys)
    )

    // eslint-disable-next-line array-callback-return
    const settingsPromises = filteredSettings.map((setting) => {
      switch (setting.name) {
        case SettingsKeys.REVIEW_CHAT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.review_chat_id,
          })
        case SettingsKeys.ADMIN_CHAT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.admin_chat_id,
          })
        case SettingsKeys.ACCESS_CHAT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.access_chat_id,
          })
        case SettingsKeys.BOT_TOKEN:
          return putSetting(setting.id, {
            ...setting,
            value: values.bot_token,
          })
        case SettingsKeys.NICKNAME:
          return putSetting(setting.id, {
            ...setting,
            value: values.nickname,
          })
        case SettingsKeys.BOT_VK_NAME:
          return putSetting(setting.id, {
            ...setting,
            value: values.bot_vk_name,
          })
      }
    })

    setButtonDisabled(true)
    Promise.all(settingsPromises)
      .then(() => {
        handleIsEditing()
        setToastSuccess('Сохранено')
      })
      .catch((error) => {
        setToastError(error)
      })
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  const { handleSubmit, handleChange, values, errors, isValid } =
    useFormik<IForm>({
      enableReinitialize: true,
      initialValues: {
        review_chat_id: getSettingByKey(data, SettingsKeys.REVIEW_CHAT_ID)
          ?.value,
        admin_chat_id: getSettingByKey(data, SettingsKeys.ADMIN_CHAT_ID)?.value,
        bot_vk_name: getSettingByKey(data, SettingsKeys.BOT_VK_NAME)?.value,
        access_chat_id: getSettingByKey(data, SettingsKeys.ACCESS_CHAT_ID)
          ?.value,
        bot_token: getSettingByKey(data, SettingsKeys.BOT_TOKEN)?.value,
        nickname: getSettingByKey(data, SettingsKeys.NICKNAME)?.value,
      },
      onSubmit,
      validationSchema: Yup.object().shape({
        review_chat_id: Yup.string().trim().required(RequiredFields.base),
        admin_chat_id: Yup.string().trim().required(RequiredFields.base),
        bot_vk_name: Yup.string().trim().required(RequiredFields.base),
        access_chat_id: Yup.string().trim().required(RequiredFields.base),
        bot_token: Yup.string().trim().required(RequiredFields.base),
        nickname: Yup.string().trim().required(RequiredFields.base),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />
          <ST.EditingHeader>Редактирование</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>
        <BaseButton
          typeButton="primary"
          text="Сохранить"
          onClick={checkForm}
          disabled={!isValid || buttonDisabled}
          width={220}
          height={40}
        />
      </ST.ManageBlock>
      <ST.BlockInfo>
        {inputsData.map(({ label, placeholder, field }) => (
          <ST.BottomWrapper key={field}>
            <BaseInput
              label={label}
              placeholder={placeholder}
              id={EditingGeneralTabContentStrings[field]}
              name={EditingGeneralTabContentStrings[field]}
              value={values[field as keyof IForm]}
              onChange={handleChange}
            />
          </ST.BottomWrapper>
        ))}
      </ST.BlockInfo>
    </>
  )
}

export default VKTab
