import { COLORS } from 'constants/colors'

export namespace AvatarImageTypes {
  export type Size = 'small' | 'medium' | 'large' | number

  export const BadgeColors = {
    green: COLORS.green,
    yellow: COLORS.yellow,
    red: COLORS.errorRed,
    gray: COLORS.gray50,
  }

  export type Props = {
    size?: Size
    badgeSize?: number
    onClick?: () => void
    badgeColor?: keyof typeof BadgeColors
    path: string | undefined | null
  }
}
