import React, { FC, useEffect, useState } from 'react'

import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { BenefitTypes } from 'types/model/benefit'
import { BenefitOperationTypesLabels } from 'constants/benefitOperationTypesDescription'
import { changeBenefitStatus } from 'api/benefits'
import * as ST from './styled'
import EditBenefitType from 'components/dialogs/settings/EditBenefitType'
import EditButton from 'components/ui/buttons/EditButton'
import { BenefitStatuses, listBenefitStatuses } from 'constants/benefitStatuses'
import { ReactComponent as ArchiveIcon } from 'assets/icons/Archive.svg'
import { useModalWindowState } from 'hooks/useModalWindowState'
import IconButton from 'components/ui/buttons/IconButton'
import { PopupWrapper } from 'components/ui/overlay/Popup'
import { COLORS } from 'constants/colors'
import ArchiveModal from 'components/settings/Tabs/shared/ArchiveModal'

interface IUserProps {
  benefits: BenefitTypes.Model[]
  updateBenefits: () => void
}

type ArchiveModalData = {
  benefit: BenefitTypes.Model
}

const NO_DATA_PLACEHOLDER = 'Нет данных'

const StaticBenefitTypesTabContent: FC<IUserProps> = ({
  updateBenefits,
  benefits,
}) => {
  const isNotebookDevice = useMediaQuery(`(max-width: ${BreakPoints.NOTEBOOK})`)
  const [selectedBenefit, setSelectedBenefit] =
    useState<BenefitTypes.Model | null>(null)
  const {
    modalOpen: showModalEditBenefit,
    toggleModal: handleShowModalEditBenefit,
  } = useModalWindowState()
  const { modalOpen: archiveModalOpen, toggleModal: toggleArchiveModal } =
    useModalWindowState()
  const [benefitsList, setBenefitsList] = useState<BenefitTypes.Model[]>([])
  const [disabledButtons, setDisabledButtons] = useState<boolean[]>(
    new Array(benefits.length).fill(false)
  )
  const [archiveModalData, setArchiveModalData] =
    useState<ArchiveModalData | null>(null)

  useEffect(() => {
    if (benefits.length) {
      setBenefitsList(benefits)
    }
  }, [benefits])

  const handleChangeBenefitStatus = async () => {
    const toggleBenefitLoadingState = (index: number | undefined) => {
      if (index !== undefined) {
        setDisabledButtons((prevState) =>
          prevState.map((v, i) => (i === index ? !v : v))
        )
      }
    }

    const currentBenefitIndex = benefits.findIndex(
      (b) => b.id === archiveModalData?.benefit.id
    )

    toggleBenefitLoadingState(currentBenefitIndex)

    return changeBenefitStatus(
      archiveModalData?.benefit.id!,
      archiveModalData?.benefit.status === BenefitStatuses.active
        ? BenefitStatuses.archived
        : BenefitStatuses.active
    )
      .then(() => {
        updateBenefits()
      })
      .finally(() => {
        toggleBenefitLoadingState(currentBenefitIndex)
      })
  }

  return (
    <>
      {isNotebookDevice ? (
        <ST.StepsNotebookContainer>
          {benefitsList?.map((benefit, index) => {
            const isArchived = benefit.status === BenefitStatuses.active

            return (
              <ST.StepCard key={benefit.id}>
                <ST.StepInfoRow>
                  <ST.StepCardTitle>Вид</ST.StepCardTitle>
                  <ST.StepCardValue>{benefit.type}</ST.StepCardValue>
                </ST.StepInfoRow>
                <ST.StepInfoRow>
                  <ST.StepCardTitle>Тип</ST.StepCardTitle>
                  <ST.StepCardValue>
                    {' '}
                    {BenefitOperationTypesLabels[benefit.operationType] ??
                      NO_DATA_PLACEHOLDER}
                  </ST.StepCardValue>
                </ST.StepInfoRow>
                <ST.StepInfoRow>
                  <ST.StepCardTitle>Статус</ST.StepCardTitle>
                  <ST.StepCardValue>
                    {listBenefitStatuses.find((s) => s.value === benefit.status)
                      ?.item ?? NO_DATA_PLACEHOLDER}
                  </ST.StepCardValue>
                </ST.StepInfoRow>
                <ST.ButtonsWrapper>
                  <EditButton
                    onClick={() => {
                      setSelectedBenefit(benefit)
                      handleShowModalEditBenefit()
                    }}
                  />
                  <PopupWrapper
                    padding={10}
                    fontColor={COLORS.black70}
                    fontSize={14}
                    text={isArchived ? 'В архив' : 'Вернуть из архива'}
                  >
                    <IconButton
                      icon={ArchiveIcon}
                      disabled={disabledButtons[index]}
                      typeButton={
                        benefit.status === BenefitStatuses.active
                          ? 'primary'
                          : 'secondary'
                      }
                      onClick={() => {
                        setArchiveModalData({
                          benefit,
                        })
                        toggleArchiveModal()
                      }}
                    />
                  </PopupWrapper>
                </ST.ButtonsWrapper>
              </ST.StepCard>
            )
          })}
        </ST.StepsNotebookContainer>
      ) : (
        <ST.StaticHiringTable>
          <ST.StaticHiringThead>
            <ST.StaticHiringTRTitle>
              <ST.StaticHiringTH style={{ width: '70%' }}>
                Вид
              </ST.StaticHiringTH>
              <ST.StaticHiringTH style={{ width: '19%' }}>
                Тип
              </ST.StaticHiringTH>
              <ST.StaticHiringTH style={{ width: '15%' }}>
                Статус
              </ST.StaticHiringTH>
              <ST.StaticHiringTH style={{ width: '5%' }} />
              <ST.StaticHiringTH style={{ width: '5%' }} />
            </ST.StaticHiringTRTitle>
          </ST.StaticHiringThead>
          <ST.StaticHiringTBody>
            {benefitsList?.map((benefit, index) => {
              const isArchived = benefit.status === BenefitStatuses.active

              return (
                <ST.StaticHiringTR key={benefit.id}>
                  <ST.StaticHiringTD>
                    <ST.StaticHiringTDText>
                      {benefit.type}
                    </ST.StaticHiringTDText>
                  </ST.StaticHiringTD>
                  <ST.StaticHiringTD>
                    {BenefitOperationTypesLabels[benefit.operationType] ??
                      NO_DATA_PLACEHOLDER}
                  </ST.StaticHiringTD>
                  <ST.StaticHiringTD>
                    {listBenefitStatuses.find((s) => s.value === benefit.status)
                      ?.item ?? NO_DATA_PLACEHOLDER}
                  </ST.StaticHiringTD>
                  <ST.StaticHiringTD>
                    <EditButton
                      onClick={() => {
                        setSelectedBenefit(benefit)
                        handleShowModalEditBenefit()
                      }}
                    />
                  </ST.StaticHiringTD>
                  <ST.StaticHiringTD>
                    <PopupWrapper
                      padding={10}
                      fontColor={COLORS.black70}
                      fontSize={14}
                      text={isArchived ? 'В архив' : 'Вернуть из архива'}
                    >
                      <IconButton
                        icon={ArchiveIcon}
                        disabled={disabledButtons[index]}
                        typeButton={
                          benefit.status === BenefitStatuses.active
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() => {
                          setArchiveModalData({
                            benefit,
                          })
                          toggleArchiveModal()
                        }}
                      />
                    </PopupWrapper>
                  </ST.StaticHiringTD>
                </ST.StaticHiringTR>
              )
            })}
          </ST.StaticHiringTBody>
        </ST.StaticHiringTable>
      )}

      <ArchiveModal
        open={archiveModalOpen && !!archiveModalData?.benefit}
        onClose={() => {
          toggleArchiveModal()
          setTimeout(() => {
            setArchiveModalData(null)
          }, 500)
        }}
        onSubmit={async () => {
          if (archiveModalData?.benefit) {
            await handleChangeBenefitStatus()
          }
        }}
        description={`вид бенефита "${archiveModalData?.benefit.type!}"`}
        title="вида бенефита"
        isArchived={
          archiveModalData?.benefit.status === BenefitStatuses.archived
        }
      />
      {showModalEditBenefit && !!selectedBenefit && (
        <EditBenefitType
          show={showModalEditBenefit}
          onClose={handleShowModalEditBenefit}
          showEndModal={updateBenefits}
          benefitType={selectedBenefit}
        />
      )}
    </>
  )
}

export default StaticBenefitTypesTabContent
