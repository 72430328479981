import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'
import { BreakPoints } from 'constants/breakPoints'
import { InputSizes } from 'constants/inputSizes'
import { FIX_CHROME_BLUR } from 'constants/fixChromeBlur'

interface IsOpenProps {
  isOpen?: boolean
}

interface ModalContentProps {
  btnDisabled: boolean
}

export const ModalOverlay = styled.div<Required<IsOpenProps>>`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: ${FIX_CHROME_BLUR};
  max-width: 580px;
  width: 100%;
  max-height: 95%;
  overflow-y: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div<ModalContentProps>`
  padding: 40px;
  position: relative;
  opacity: ${({ btnDisabled }) => (btnDisabled ? '0.5' : '1')};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Button = styled.button`
  width: 100%;
  background-color: ${COLORS.yellow};
  min-height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  margin-top: 30px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: 40px;
  }
`

export const CloseButton = styled.button`
  width: 100%;
  background-color: ${COLORS.white};
  height: 48px;
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
`

export const AddButton = styled.button`
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px 24px 10px 20px;
  border: 1px dashed ${COLORS.blue};
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.blue};
  background: ${COLORS.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.p`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
  width: 100%;
  margin-bottom: 3px;
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const HeaderLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LabelName = styled(HeaderLabel)``
export const LabelValue = styled(HeaderLabel)`
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const InputsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  margin-top: 18px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin: 0;
  }
`

export const InputsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  ${(props) =>
    props.sizeInput === InputSizes.big
      ? 'max-width: 500px'
      : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }

  :disabled {
    color: ${COLORS.black70};
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    height: 48px;
    padding: 14px 16px 12px;
  }
`

export const InputTextArea = styled.textarea<IInput>`
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.sizeInput === 'big' ? 'max-width: 500px' : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 140px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 14px 16px 12px;
  }
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const RowInfo = styled.div`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const Styled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const PollsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
`

export const InputWrapperWithDelete = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;

  > div > input {
    width: 406px;
  }

  > div > div {
    width: 406px;
  }
`

export const ExpertInputWrapper = styled.div`
  max-width: 446px !important;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  > div > input {
    width: 406px;
  }

  > div > div {
    border-radius: 10px;
    max-width: 446px;

    > div > fieldset {
      border: none;
    }
  }

  .MuiAutocomplete-listbox {
    scrollbar-width: thin !important;
    scrollbar-color: transparent white !important;

    ::-webkit-scrollbar {
      width: 3px !important;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      border-radius: 20px !important;
    }

    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: gray !important;
      }

      scrollbar-color: gray white !important;
    }
  }
`

export const ExpertWrapper = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  gap: 10px;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .MuiAutocomplete-listbox {
    scrollbar-width: thin !important;
    scrollbar-color: transparent ${COLORS.inputBG} !important;

    ::-webkit-scrollbar {
      width: 3px !important;
    }

    ::-webkit-scrollbar-track {
      background: ${COLORS.inputBG} !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${COLORS.inputBG} !important;
      border-radius: 20px !important;
    }

    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: gray !important;
      }

      scrollbar-color: gray ${COLORS.inputBG} !important;
    }
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`

export const GeneralEmployeesWrapper = styled.div<IsOpenProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

export const Checkbox = styled.input`
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue} url(${checkboxIcon}) no-repeat center;
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex: 1;
  align-items: flex-end;
`

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 18px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: 10px;
    margin-bottom: 40px;
  }
`
