import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import { handlerError } from 'utils/handlerError'
import BackButton from 'components/ui/buttons/BackButton'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseSelect from 'components/ui/BaseSelect'
import { PATHS } from 'constants/paths'
import { ButtonLabels } from 'constants/buttonLabels'
import { Placeholders } from 'constants/placeholders'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import { BenefitTypes } from 'types/model/benefit'
import { listBenefitTypes } from 'constants/benefitTypesDescription'
import { listBenefitOperationTypes } from 'constants/benefitOperationTypesDescription'
import {
  editBenefitApplication,
  getBenefitApplication,
} from 'api/benefitsApplications'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import Body from 'components/ui/layout/Body'

const benefitType = 'type'
const operationTypeData = 'operationTypeData'

const EditBenefitApplication = () => {
  const navigate = useNavigate()
  const { benefitApplicationId } = useParams()
  const [benefitApplication, setBenefitApplication] =
    useState<BenefitApplicationTypes.Model | null>(null)
  const [selectedBenefit, setSelectedBenefit] =
    useState<BenefitTypes.Model | null>(null)
  const updateBenefitApplication = (id: number) => {
    getBenefitApplication(id)
      .then((data) => {
        setBenefitApplication(data)
      })
      .catch((err) => handlerError(err))
  }

  useTitle(PAGES.editBenefitApplication)

  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      targetUserId: NaN,
      commentary: benefitApplication?.commentary || '',
      value: benefitApplication?.value || 1,
      benefitId: NaN,
      benefit: {
        value: selectedBenefit?.operationType || 1,
        item: `${BenefitOperationTypes[selectedBenefit?.operationType!]}`,
      },
      cancellationDate:
        benefitApplication?.cancellationDate || Date.now().toLocaleString(),
      type: benefitApplication?.benefit.type,
      operationTypeData:
        listBenefitTypes[benefitApplication?.benefit.operationType!],
      operationType: BenefitOperationTypes.refill,
    },
    onSubmit: () => {
      const newObj = {
        targetUserId: values.targetUserId,
        commentary: values.commentary,
        value: values.value,
        benefitId: values.benefitId,
        cancellationDate: values.cancellationDate,
      }
      editBenefitApplication(+benefitApplicationId!, newObj)
      navigate(PATHS.benefitApplications)
    },
  })

  useEffect(() => {
    benefitApplicationId && updateBenefitApplication(+benefitApplicationId)
  }, [benefitApplicationId])

  return (
    <Body>
      <ST.EditDepartmentHead>
        <ST.HeadDepartment>
          <BackButton onClick={() => navigate(PATHS.benefits)} />
          <ST.TitleDepartment>редактирование</ST.TitleDepartment>
        </ST.HeadDepartment>
        <ST.Button>
          <BaseButton
            type={'submit'}
            text={ButtonLabels.save}
            typeButton={ButtonTypes.secondary}
            onClick={handleSubmit}
          />
        </ST.Button>
      </ST.EditDepartmentHead>
      <ST.EditDepartmentBody>
        <ST.DepartmentHead>
          <ST.InputWrapper>
            <ST.Label>Вид бенефита</ST.Label>
            <ST.Input
              placeholder={Placeholders.benefitType}
              id={benefitType}
              name={benefitType}
              value={values?.type}
              onChange={(e) => {
                setFieldValue(benefitType, e.target.value)
              }}
            />
          </ST.InputWrapper>
          <ST.SelectWrapper>
            <ST.Label>
              Тип<ST.Star>*</ST.Star>
            </ST.Label>
            <BaseSelect
              isSmallSelect
              placeHolder={Placeholders.benefitOperationalType}
              listItems={listBenefitOperationTypes}
              name={operationTypeData}
              value={values?.operationTypeData?.item || ''}
              typeSelect={operationTypeData}
              onChange={(newValue) =>
                setFieldValue(operationTypeData, newValue)
              }
            />
          </ST.SelectWrapper>
        </ST.DepartmentHead>
      </ST.EditDepartmentBody>
    </Body>
  )
}

export default EditBenefitApplication
