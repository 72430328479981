import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import * as ST from './styled'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { listBenefitOperationTypes } from 'constants/benefitOperationTypesDescription'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { UserTypes } from 'types/model/user'
import { UserStatuses } from 'constants/userStatuses'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'
import { useGetAllUsersQuery } from 'store/api/user'
import { useDebounce } from 'hooks'
import { listBenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'

interface Props {
  userList: UserTypes.Model[]
  setValueFilter: (obj: BenefitApplicationTypes.Filter) => void
  valuesFilter: BenefitApplicationTypes.Filter
  clearInput: boolean
}

type Option = IItem<number>

const UserAutoCompleteHelpers = {
  getOptionLabel: (option: Option) => option?.item ?? '',
  getOptionKey: (option: Option) => option?.value,
  isOptionEqualToValue: (option: Option, value: Option | null) =>
    option?.value === value?.value,
}

const SelectsBlock: FC<Props> = ({
  setValueFilter,
  valuesFilter,
  clearInput,
}) => {
  const [activeSelect, setActiveSelect] = useState<string | null>(null)
  const [selectedUser, setSelectedUser] = useState<IItem<number> | null>(null)
  const [searchUserValue, setSearchUserValue] = useState<string>('')
  const [isUsersSelectOpened, setIsUsersSelectOpened] = useState<boolean>(false)
  const debouncedSearchValue = useDebounce(searchUserValue)

  const { data: employeesData, isFetching } = useGetAllUsersQuery(
    {
      parameters: { status: [UserStatuses.active] },
      doNotMerge: true,
      searchValue: debouncedSearchValue,
    },
    { skip: !isUsersSelectOpened }
  )

  const passValue = useCallback(
    (type: string, currentValue: string): void => {
      const newObj: BenefitApplicationTypes.Filter = {
        status: type === 'status' ? currentValue : valuesFilter.status,
        operationType:
          type === 'operationType' ? currentValue : valuesFilter.operationType,
        targetUserId:
          type === 'targetUserId' ? currentValue : valuesFilter.targetUserId,
      }
      setValueFilter(newObj)
    },
    [
      setValueFilter,
      valuesFilter.operationType,
      valuesFilter.status,
      valuesFilter.targetUserId,
    ]
  )

  const handleInputChange = useCallback((value: string) => {
    setSearchUserValue(value)
  }, [])

  const handleSelectUser = useCallback(
    (value: Option | null) => {
      passValue('targetUserId', value ? String(value.value) : '')
      setSelectedUser(value)
    },
    [passValue]
  )

  const userOptions = useMemo<Option[]>(
    () =>
      employeesData?.data.map((user) => ({
        item: `${user.surname} ${user.name}`,
        value: user.id,
      })) ?? [],
    [employeesData?.data]
  )

  useEffect(() => {
    clearInput &&
      setSelectedUser({
        item: ``,
        value: 0,
      })
  }, [clearInput])

  const isTabletDevice = useMediaQuery(`(max-width: ${BreakPoints.TABLETS})`)

  const clearInputProps = clearInput ? { value: '' } : {}

  return (
    <ST.SelectsBlock>
      <BaseSelect
        isSmallSelect
        placeHolder={'Статус'}
        listItems={listBenefitApplicationStatuses}
        typeSelect={'status'}
        passValue={passValue}
        setActive={setActiveSelect}
        activeSelect={clearInput ? '' : activeSelect}
        {...clearInputProps}
      />
      <BaseSelect
        isSmallSelect
        placeHolder={'Тип бенефита'}
        listItems={listBenefitOperationTypes}
        typeSelect={'operationType'}
        passValue={passValue}
        setActive={setActiveSelect}
        activeSelect={clearInput ? '' : activeSelect}
        {...clearInputProps}
      />
      {/*TODO:Experimental здесь используется кастомный компонент Autocomplete*/}
      <AutocompleteInput
        {...UserAutoCompleteHelpers}
        placeholder="Сотрудник"
        options={userOptions}
        value={selectedUser}
        onInputChange={handleInputChange}
        isOptionsLoading={isFetching}
        onChange={handleSelectUser}
        onFirstOpen={() => {
          setIsUsersSelectOpened(true)
        }}
        containerStyle={isTabletDevice ? {} : { maxWidth: '240px' }}
        disableDefaultFiltering
        keyboardControl
        filterSelectedOptions
        fullWidth
      />
    </ST.SelectsBlock>
  )
}

export default SelectsBlock
