// TODO: MOVE ALL TO CONSTANT ENTITIES
export enum HiringEducationFormEnum {
  INTRAMURAL = 1,
  EVENING = 2,
  EXTRAMURAL = 3,
  MIXED = 4,
  EXTERNAL = 5,
  DISTANCE = 6,
}

export enum HiringLanguagesLevelEnum {
  beginner = 1,
  elementary = 2,
  intermediate = 3,
  upperIntermediate = 4,
  advanced = 5,
  proficiency = 6,
}

export enum HiringLanguagesEnum {
  english = 1,
  arab = 2,
  spanish = 3,
  italian = 4,
  chinese = 5,
  german = 6,
  turkish = 7,
  french = 8,
  japanese = 9,
  russia = 10,
}

export enum BusinessTripType {
  NEVER = 1,
  SOMETIMES = 2,
  READY = 3,
}

export enum EducationLvlEnum {
  INCOMPLETE_SECONDARY = 1,
  SECONDARY = 2,
  INCOMPLETE_VOCATIONAL = 3,
  INCOMPLETE_HIGHER = 4,
  SECONDARY_VOCATIONAL = 5,
  HIGHER_EDUCATION = 6,
  BACHELOR_S = 7,
  SPECIALTY = 8,
  MASTER_S = 9,
  POSTGRADUATE = 10,
  DOCTOR = 11,
  CANDIDATE = 12,
}

export enum HiringTypeEnum {
  HUNTING = 1,
  RECRUITING = 2,
  RECOMMENDATION = 3,
  PARTNER = 4,
}

export enum ReadyToWorkType {
  NOT_LOOKING_FOR = 1,
  LOOKING_FOR = 2,
  CONSIDERS = 3,
}

export enum RemovalType {
  IMPOSSIBLE = 1,
  POSSIBLE = 2,
  DESIRABLE = 3,
}

export enum TimeToWorkType {
  DOESNT_MATTER = 1,
  LESS_HOUR = 2,
  OTHER = 3,
}

export enum HiringMonthEnum {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}
