import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { logout } from 'api/auth'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'constants/paths'
import { useDispatch } from 'react-redux'
import { AuthActionCreators } from 'store/actions/auth'

interface IExitDialog {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const ExitDialog = ({ show, onClose }: IExitDialog) => {
  const handleClose = (): void => {
    onClose(!show)
  }
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleLogout = () => {
    logout().then(() => {
      navigate(PATHS.auth)
      dispatch(AuthActionCreators.logout())
    })
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>
            Вы уверены, что хотите выйти из системы?
          </ST.ModalTitle>
          <ST.ButtonsWrapper>
            <ST.ExitButton onClick={handleLogout}>Выйти</ST.ExitButton>
            <ST.CancelButton
              onClick={() => {
                handleClose()
              }}
            >
              Отмена
            </ST.CancelButton>
          </ST.ButtonsWrapper>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default ExitDialog
