import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { GlobalStyle } from 'GlobalStyle'
import { Provider } from 'react-redux'
import store from './store/store'

import * as Sentry from '@sentry/react'
import { SentryDSN } from 'constants/sentryDSN'
import { Endpoint } from 'constants/endpoint'
import { SentrySettings } from 'constants/sentrySettings'

Sentry.init({
  dsn: String(process.env.REACT_APP_SENTRY_DSN || SentryDSN),
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // TODO: deprecated?
      tracePropagationTargets: ['localhost', `${Endpoint}/api`],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: Number(
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ||
      SentrySettings.TRACES_SAMPLE_RATE
  ),
  replaysSessionSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
      SentrySettings.REPLAYS_SESSION_SAMPLE_RATE
  ),
  replaysOnErrorSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ||
      SentrySettings.REPLAYS_ON_ERROR_SAMPLE_RATE
  ),
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
