import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const SelectsBlock = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  > * {
    flex: 1;
  }

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    width: 100%;
  }
  @media (max-width: 1439px) {
    margin-right: 0;
  }
  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    flex-direction: column;
    width: 100%;
  }
`
export const SearchInput = styled.input`
  background: #eeeeee;
  border: none;

  &::placeholder {
    color: ${COLORS.gray70Input};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`
