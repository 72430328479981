import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import * as ST from './styled'
import DeleteSection from 'components/dialogs/DeleteSection'
import { handlerError, setToastError } from 'utils/handlerError'
import { GradeTypes } from 'types/model/grade'
import { PATHS } from 'constants/paths'
import { ModalTitles } from 'constants/modalTitles'
import { useDeleteGradeMutation } from 'store/api/grade'
import { useModalWindowState } from 'hooks/useModalWindowState'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { CommonStyles } from 'styles/CommonStyles'
import { BreakPoints } from 'constants/breakPoints'
import formatSalary from 'utils/vacancy/formatSalary'

interface Props {
  gradeInfo: GradeTypes.Model
}

const GradeBlock: FC<Props> = ({ gradeInfo }) => {
  const navigate = useNavigate()
  const { modalOpen, toggleModal } = useModalWindowState()

  const goToEditPosition = (id: number) => {
    navigate(`${PATHS.grades}/${id}/edit`)
  }

  const [deleteGrade] = useDeleteGradeMutation()

  const removeItem = () => {
    deleteGrade(gradeInfo.id).catch((err) => {
      setToastError('Не удалось удалить грейд')
      handlerError(err)
    })
  }

  return (
    <>
      <ST.GradeBlock>
        <ST.DataContainer>
          <ST.Title
            title={gradeInfo.name}
            maxWidth={900}
            mediaMaxWidth={{
              ...CommonStyles.DEFAULT_MEDIA,
              [BreakPoints.TABLETS]: 350,
            }}
          >
            {gradeInfo.name}
          </ST.Title>
          {!!gradeInfo.recruiterPremium && (
            <ST.Title
              maxWidth={900}
              mediaMaxWidth={{
                ...CommonStyles.DEFAULT_MEDIA,
                [BreakPoints.TABLETS]: 350,
              }}
            >
              {formatSalary(gradeInfo.recruiterPremium)} руб.
            </ST.Title>
          )}
        </ST.DataContainer>

        <ST.ButtonsBlock>
          <IconButton
            icon={EditIcon}
            onClick={(e) => {
              e.stopPropagation()
              goToEditPosition(gradeInfo.id)
            }}
          />
          <IconButton
            icon={DeleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              toggleModal()
            }}
          />
        </ST.ButtonsBlock>
      </ST.GradeBlock>
      <DeleteSection
        title={ModalTitles.gradeDeleting}
        show={modalOpen}
        onClose={toggleModal}
        handleConfirm={removeItem}
      />
    </>
  )
}

export default GradeBlock
