import { baseApi } from 'store/api/baseApi'
import { UserApiTypes as T } from './types'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { HttpMethods } from 'store/api/types/HttpMethods'

const DEFAULT_TAG_TYPE = 'User' as const

const getProvidedTagValue = (id: number | string) => [
  { type: DEFAULT_TAG_TYPE, id },
]

const providesTagWithUserId = <T>(
  result: T,
  error: FetchBaseQueryError | undefined,
  id: number
) => getProvidedTagValue(id)

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Queries
    getUserStatuses: builder.query<
      T.Response.GetStatuses,
      T.Request.GetStatuses
    >({
      query: () => ({ url: 'user/status' }),
    }),
    getUserInfo: builder.query<T.Response.GetInfo, T.Request.GetInfo>({
      query: (user) => ({
        url: 'user/info',
        params: { user },
      }),
      providesTags: providesTagWithUserId,
    }),
    getUserBirthday: builder.query<
      T.Response.GetBirthday,
      T.Request.GetBirthday
    >({
      query: (params) => ({ url: 'user/birthday', params }),
    }),
    getUserFullName: builder.query<
      T.Response.GetFullName,
      T.Request.GetFullName
    >({
      query: (userId) => ({ url: `user/${userId}` }),
      providesTags: providesTagWithUserId,
    }),
    getUserGeneral: builder.query<T.Response.GetGeneral, T.Request.GetGeneral>({
      query: (user) => ({
        url: 'user/card/general',
        params: { user },
      }),
      providesTags: providesTagWithUserId,
    }),
    getUserEducation: builder.query<
      T.Response.GetEducation,
      T.Request.GetEducation
    >({
      query: (user) => ({ url: 'user/card/education', params: { user } }),
      providesTags: providesTagWithUserId,
    }),
    getUserEducationLevels: builder.query<
      T.Response.GetEducationLevels,
      T.Request.GetEducationLevels
    >({
      query: () => ({
        url: 'institute/education/levels',
      }),
    }),
    getUserDocuments: builder.query<
      T.Response.GetDocuments,
      T.Request.GetDocuments
    >({
      query: (user) => ({
        url: 'user/card/documents',
        params: { user },
      }),
      providesTags: providesTagWithUserId,
    }),
    getUserWorkplaces: builder.query<
      T.Response.GetWorkplaces,
      T.Request.GetWorkplaces
    >({
      query: (user) => ({ url: 'user/card/workplaces', params: { user } }),
      providesTags: providesTagWithUserId,
    }),
    getUserHardware: builder.query<
      T.Response.GetHardware,
      T.Request.GetHardware
    >({
      query: (userId) => ({ url: 'user/card/hardware', params: { userId } }),
      providesTags: providesTagWithUserId,
    }),
    getAllUsers: builder.query<T.Response.GetAll, T.Request.GetAll>({
      query: (args) => {
        const params = new URLSearchParams()

        if (args) {
          const { parameters, searchValue, pageNumber, pageSize } = args

          if (parameters) {
            for (let [key, value] of Object.entries(parameters)) {
              value &&
                params.append(
                  T.Request.GetAllFilterKeys.includes(
                    key as keyof typeof parameters
                  )
                    ? `filter[${key}][]`
                    : key,
                  value
                )
            }
          }
          if (searchValue) {
            params.append('search', searchValue)
          }
          if (pageNumber) {
            params.append('pageNumber', String(pageNumber))
          }
          if (pageSize) {
            params.append('pageSize', String(pageSize))
          }
        }

        return { url: 'user/order', params }
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newData, args) =>
        args.arg && args.arg.doNotMerge
          ? newData
          : args.arg && args.arg.pageNumber === 1
          ? newData
          : {
              ...newData,
              data: [...currentCache.data, ...newData.data],
            },
      forceRefetch: ({ previousArg, currentArg }) =>
        previousArg && currentArg
          ? JSON.stringify(previousArg) !== JSON.stringify(currentArg)
          : false,
    }),
    getUserConfirmation: builder.query<
      T.Response.GetConfirmation,
      T.Request.GetConfirmation
    >({
      query: (userId) => ({
        url: `user/status/${userId}`,
      }),
    }),
    getUsersByDepartment: builder.query<
      T.Response.GetByDepartment,
      T.Request.GetByDepartment
    >({
      query: ({ departmentId, ...args }) => {
        const params = new URLSearchParams()

        Object.entries(args).forEach(([key, value]) => {
          value && params.append(`p[${key}]`, String(value))
        })

        return { url: `user/department/${departmentId}`, params }
      },
      providesTags: (res, err, { departmentId }) =>
        getProvidedTagValue(departmentId),
    }),
    getUserSubscribe: builder.query<
      T.Response.GetSubScribe,
      T.Request.GetSubScribe
    >({
      query: (userId) => ({ url: `user/subscribe/${userId}` }),
      providesTags: providesTagWithUserId,
    }),
    getUserAcceptQuestionnaire: builder.query<
      T.Response.GetAcceptQuestionnaire,
      T.Request.GetAcceptQuestionnaire
    >({
      query: (userId) => ({ url: `user/accept-questionnaire/${userId}` }),
    }),

    // Mutations | POST
    postUserCreate: builder.mutation<
      T.Response.PostCreate,
      T.Request.PostCreate
    >({
      query: (body) => ({ url: 'user', body, method: HttpMethods.POST }),
    }),
    postUploadAvatar: builder.mutation<
      T.Response.PostUploadAvatar,
      T.Request.PostUploadAvatar
    >({
      query: ({ userId, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `user/avatar/${userId}`,
          body: formData,
          method: HttpMethods.POST,
        }
      },
    }),
    postUserAddWorkplace: builder.mutation<
      T.Response.PostAddWorkplace,
      T.Request.PostAddWorkplace
    >({
      query: ({ userId, data }) => ({
        url: 'workplace',
        params: { user: userId },
        body: data,
        method: HttpMethods.POST,
      }),
    }),
    postUserUploadDocuments: builder.mutation<
      T.Response.PostUploadDocuments,
      T.Request.PostUploadDocuments
    >({
      query: ({ userId, data }) => {
        const formData = new FormData()

        if (data.types.length) {
          let types: string
          let dataTypes = data.types.filter((e) => !!e)
          if (dataTypes.length === 1) {
            // @ts-ignore
            types = dataTypes[0]
          } else {
            types = dataTypes.join(',')
          }
          formData.append('types', types)
        }

        if (data.files?.length) {
          data.files.forEach((element: File | string) => {
            typeof element !== 'string' && formData.append('files[]', element)
          })
        }

        if (data.customNames?.length) {
          let names
          let customNames = data.customNames.filter((e) => !!e)
          if (customNames.length === 1) {
            names = customNames[0]
          } else {
            names = customNames.join(',')
          }
          formData.append('customNames', names)
        }

        if (data.customFiles?.length) {
          data.customFiles.forEach((element: File | string) => {
            typeof element !== 'string' &&
              formData.append('customFiles[]', element)
          })
        }

        return {
          url: 'document/upload-user-files',
          params: { userId },
          body: formData,
          method: HttpMethods.POST,
        }
      },
    }),
    postUserPersonal: builder.mutation<
      T.Response.PostPersonal,
      T.Request.PostPersonal
    >({
      query: ({ user, body }) => ({
        url: 'user/personal',
        params: { user },
        method: HttpMethods.POST,
        body,
      }),
    }),

    // Mutations | PATCH
    patchUserDismiss: builder.mutation<
      T.Response.PatchDismiss,
      T.Request.PatchDismiss
    >({
      query: ({ userId, body }) => ({
        url: `user/dismiss/${userId}`,
        body,
        method: HttpMethods.PATCH,
      }),
    }),
    patchUserDocuments: builder.mutation<
      T.Response.PatchDocuments,
      T.Request.PatchDocuments
    >({
      query: ({ userId, body }) => ({
        url: 'user/documents',
        params: { user: userId },
        body,
        method: HttpMethods.PATCH,
      }),
    }),
    patchUserGeneral: builder.mutation<
      T.Response.PatchGeneral,
      T.Request.PatchGeneral
    >({
      query: ({ userId, body }) => ({
        url: 'user/card/general',
        params: { user: userId },
        body,
        method: HttpMethods.PATCH,
      }),
    }),
  }),
})

export const {
  useGetUserInfoQuery,
  useGetAllUsersQuery,
  useGetUserHardwareQuery,
  useLazyGetUserHardwareQuery,
  useGetUserFullNameQuery,
} = userApi
