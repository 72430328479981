import { ISocialNetwork, SocialNetworks } from 'constants/socialNetworks'

export const parseSocialNetworkUrl = (
  url: string,
  type: ISocialNetwork
): string => {
  let prefix = 'https://',
    postfix = '',
    res: string
  switch (type.id) {
    case SocialNetworks.INSTAGRAM:
      prefix += 'instagram.com/'
      res = url?.split(prefix)[1]
      break
    case SocialNetworks.SKYPE:
      prefix = 'skype:'
      postfix = '?chat'
      res = url?.split(prefix)[1]?.split(postfix)[0]
      break
    case SocialNetworks.DISCORD:
      prefix = prefix + 'discordapp.com/users/'
      res = url?.split(prefix)[1]
      break
    case SocialNetworks.MAIL:
      prefix = 'mailto:'
      res = url?.split(prefix)[1]
      break
    default:
      res = url?.split(prefix)[1]
  }

  return res
}
