import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'
import { CommonStyles } from 'styles/CommonStyles'

export interface TDProps {
  disabled?: boolean
}

export const StaticHiringTable = styled.table`
  text-align: left;
`

export const StaticHiringThead = styled.thead``

export const StaticHiringTR = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  margin: 20px 0;
  border-radius: 15px;
`

export const StaticHiringTRTitle = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StaticHiringTH = styled.th`
  padding: 0 20px;
`

export const StaticHiringTBody = styled.tbody``

export const StaticHiringTDText = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
  line-height: 22.4px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const StaticHiringTD = styled.td<TDProps & CommonStyles.MaxWidthProps>`
  padding: 20px 40px;
  text-align: center;

  &:first-child {
    width: 30%;
    max-width: 19%;
    text-align: left;
  }
  &:nth-child(2) {
    width: 15%;
    max-width: 9%;
  }
  &:nth-child(3) {
    width: 15%;
    max-width: 9%;
  }
  &:nth-child(4) {
    width: 15%;
    max-width: 15%;
  }
  &:last-child {
    width: 5%;
    > svg {
      :hover {
        cursor: pointer;
      }
    }
  }

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

export const StepsNotebookContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`

export const StepCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  gap: 20px;
`

export const StepInfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px 20px;
  width: 100%;
`

export const StepCardTitle = styled.h6`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const StepCardValue = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
`

export const StepCheckbox = styled.input`
  width: fit-content;
`

export const Checkbox = styled.input`
  margin: 0;
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  :checked {
    border: none;
    background: ${COLORS.gray70} url(${checkboxIcon}) no-repeat center;
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`
