import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { CommonStyles } from 'styles/CommonStyles'

interface IGridProps {
  columns?: number
}

export const StaticHiringThead = styled.thead``

export const StaticHiringTR = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 15px 0px rgba(51, 51, 51, 0.06);
`

export const StaticHiringTH = styled.th`
  padding: 20px 40px;
`

export const StaticHiringTBody = styled.tbody``

export const StaticHiringTDText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  line-height: 22.4px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const StaticHiringTD = styled.td`
  word-break: break-word;
  padding: 20px 40px;
  &:first-child {
    width: 25%;
  }
  &:nth-child(2) {
    width: 27%;
  }
  &:nth-child(3) {
    width: 43%;
  }
  &:last-child {
    width: 5%;
  }
`

export const StepsNotebookContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`

export const StepCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px 0px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  gap: 20px;
`

export const StepInfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px 20px;
  width: 100%;
`

export const StepCardTitle = styled.h6`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const StepCardValue = styled.p`
  word-break: break-word;
`

export const StepCheckbox = styled.input`
  width: fit-content;
`

export const InfoBlock = styled.div`
  display: flex;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
`
export const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TopBlockIcons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const HeaderBlock = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin-bottom: 12px;
`

export const SubHeaderBlock = styled(HeaderBlock)`
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
`

export const GridInfoBlock = styled.div`
  display: flex;
  width: 100%;
`

export const TitleAttribute = styled.p`
  width: 29%;
  color: ${COLORS.black};
  font-family: Golos;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`
export const ValueAttribute = styled.p<CommonStyles.MaxWidthProps>`
  ${CommonStyles.WrappedText};
  max-width: 600px;
  color: ${COLORS.black70};
  font-family: Golos, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LinkAttribute = styled.a`
  ${CommonStyles.WrappedText};
  color: ${COLORS.blue};
  text-decoration: underline;
  cursor: pointer;
`

export const GridBlock = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-auto-flow: dense;
`

const BlockInfo = styled.div`
  padding: 24px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
`

export const Button = styled.button`
  width: 220px;
  height: 40px;
  background: ${COLORS.yellow};
  font-family: 'Golos-Text_VF';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  border-radius: 50px;
  cursor: pointer;

  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const SecondaryButton = styled(Button)`
  background: ${COLORS.white};
  border: 1px solid ${COLORS.yellow};
  font-size: 14px;
  font-weight: 400;

  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`
