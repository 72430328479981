import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'

export const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Input = styled.input`
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 18px;
  min-height: 18px;
  -webkit-appearance: none;
  margin-right: 10px;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue} url(${checkboxIcon}) no-repeat center;
  }
  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const Label = styled.label`
  font-family: Golos, sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: ${COLORS.black};
  cursor: pointer;
  user-select: none;
  display: inline-block;
  max-width: calc(100% - 40px);
  word-wrap: break-word;
  white-space: break-spaces;
`
