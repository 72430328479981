import React, { Dispatch, FC, SetStateAction } from 'react'
import * as ST from './styled'
import { listSettingsSteps } from 'constants/settingsStepsDescription'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { URLSearchParamsInit } from 'react-router-dom'

interface IStepProps {
  step: number
  setStep: (tabIndex: number) => void
  roles?: string[]
  userId?: number
  userStatus?: number
  setIsEditing: Dispatch<SetStateAction<boolean>>
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: {
      replace?: boolean | undefined
      state?: any
    }
  ) => void
}

const Tabs: FC<IStepProps> = ({
  step,
  setStep,
  setIsEditing,
  setSearchParams,
}) => {
  const isTabletMiddleDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_MIDDLE})`
  )

  return isTabletMiddleDevice ? (
    <ST.WrapperSwiper>
      <Swiper
        slidesPerView={2.2686}
        spaceBetween={10}
        allowTouchMove
        simulateTouch
      >
        {listSettingsSteps.map((singleStep) => (
          <SwiperSlide key={+singleStep.value}>
            <ST.SwiperTabCard
              isSelected={step === +singleStep.value}
              onClick={setStep.bind(this, +singleStep.value)}
            >
              <ST.SwiperTabCardTitle>{singleStep.item}</ST.SwiperTabCardTitle>
            </ST.SwiperTabCard>
          </SwiperSlide>
        ))}
      </Swiper>
    </ST.WrapperSwiper>
  ) : (
    <ST.Wrapper>
      {listSettingsSteps.map((singleStep) => (
        <ST.Step
          key={+singleStep.value}
          isSelected={step === +singleStep.value}
          onClick={() => {
            setStep(+singleStep.value)
            setIsEditing(false)
            setSearchParams({ tab: String(singleStep.value) })
          }}
        >
          {singleStep.item}
        </ST.Step>
      ))}
    </ST.Wrapper>
  )
}

export default Tabs
