import { IItem } from 'components/ui/BaseSelect'

export enum VacancyStatuses {
  NEW = 1,
  IN_PROGRESS = 2,
  ON_HOLD = 3,
  CANCELED = 4,
  CLOSED = 5,
}

export enum VacancyStatusesDescription {
  NEW = 'Новая',
  IN_PROGRESS = 'В работе',
  ON_HOLD = 'На паузе',
  CLOSED = 'Закрыта',
  CANCELED = 'Отменена',
}

export const listVacancyStatusesTypes: Array<IItem> = [
  {
    item: VacancyStatusesDescription.NEW,
    value: VacancyStatuses.NEW,
  },
  {
    item: VacancyStatusesDescription.IN_PROGRESS,
    value: VacancyStatuses.IN_PROGRESS,
  },
  {
    item: VacancyStatusesDescription.ON_HOLD,
    value: VacancyStatuses.ON_HOLD,
  },
  {
    item: VacancyStatusesDescription.CLOSED,
    value: VacancyStatuses.CLOSED,
  },
  {
    item: VacancyStatusesDescription.CANCELED,
    value: VacancyStatuses.CANCELED,
  },
]
export const VacancyStatusesLabels = {
  [VacancyStatuses.NEW]: VacancyStatusesDescription.NEW,
  [VacancyStatuses.IN_PROGRESS]: VacancyStatusesDescription.IN_PROGRESS,
  [VacancyStatuses.ON_HOLD]: VacancyStatusesDescription.ON_HOLD,
  [VacancyStatuses.CLOSED]: VacancyStatusesDescription.CLOSED,
  [VacancyStatuses.CANCELED]: VacancyStatusesDescription.CANCELED,
}

export enum ProjectPriority {
  REGULAR = 1,
  HIGH = 2,
}

export enum ProjectPriorityDescription {
  REGULAR = 'Обычный',
  HIGH = 'Высокий',
}

export const listProjectPriority: Array<IItem> = [
  {
    item: ProjectPriorityDescription.REGULAR,
    value: ProjectPriority.REGULAR,
  },
  {
    item: ProjectPriorityDescription.HIGH,
    value: ProjectPriority.HIGH,
  },
]
export const ProjectPriorityLabels = {
  [ProjectPriority.REGULAR]: ProjectPriorityDescription.REGULAR,
  [ProjectPriority.HIGH]: ProjectPriorityDescription.HIGH,
}
