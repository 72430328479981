import React, { FC, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import { HardwareTypes } from 'types/model/hardware'
import EditHardwareType from 'components/dialogs/settings/EditHardwareType'
import { removeHardwareType } from 'api/hardwareTypes'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ModalTitles } from 'constants/modalTitles'
import DeleteHardwareType from 'components/dialogs/DeleteHardwareType'
import { setToastError } from 'utils/handlerError'
import IconButton from 'components/ui/buttons/IconButton'
import { CommonStyles } from 'styles/CommonStyles'
import { BreakPoints } from 'constants/breakPoints'

interface IUserProps {
  types: HardwareTypes.HardwareType[]
  updateHardwareTypes: () => void
}

const StaticHardwareTypesTabContent: FC<IUserProps> = ({
  types,
  updateHardwareTypes,
}) => {
  const [selectedHardwareType, setSelectedHardwareType] =
    useState<Nullable<HardwareTypes.HardwareType>>(null)
  const [showModalEditHardwareTypes, setShowModalEditHardwareTypes] =
    useState<boolean>(false)
  const [showModalDeleteHardwareTypes, setShowModalDeleteHardwareTypes] =
    useState<boolean>(false)

  const handleShowEditHardwareTypes = (): void => {
    setShowModalEditHardwareTypes((prev) => !prev)
  }

  const handleShowDeleteHardwareTypes = (): void => {
    setShowModalDeleteHardwareTypes((prev) => !prev)
  }

  const removeItem = (id: number) => {
    removeHardwareType(id)
      .then(() => {
        updateHardwareTypes()
      })
      .catch(setToastError)
  }

  const handleButtonClick = (
    type: HardwareTypes.HardwareType,
    action: 'edit' | 'delete'
  ) => {
    setSelectedHardwareType(type)

    switch (action) {
      case 'edit':
        return handleShowEditHardwareTypes()
      case 'delete':
        return handleShowDeleteHardwareTypes()
    }
  }

  return (
    <ST.StaticHiringTable>
      <ST.StaticHiringTBody>
        {types.map((type) => (
          <ST.StaticHiringTR key={type.id}>
            <ST.StaticHiringTD>
              <ST.StaticHiringTDText
                maxWidth={600}
                mediaMaxWidth={{
                  [BreakPoints.DESKTOPS2]: 400,
                  [BreakPoints.TABLETS_MIDDLE]: 200,
                }}
              >
                {type.name}
              </ST.StaticHiringTDText>
            </ST.StaticHiringTD>
            <ST.StaticHiringTD padding="0">
              <IconButton
                icon={EditIcon}
                onClick={() => handleButtonClick(type, 'edit')}
              />
            </ST.StaticHiringTD>
            <ST.StaticHiringTD padding="20px 40px 20px 16px">
              <IconButton
                icon={DeleteButton}
                onClick={() => handleButtonClick(type, 'delete')}
              />
            </ST.StaticHiringTD>
          </ST.StaticHiringTR>
        ))}

        {!!selectedHardwareType && showModalDeleteHardwareTypes && (
          <DeleteHardwareType
            hardwareType={selectedHardwareType}
            title={ModalTitles.hardwareTypeDeleting}
            show={showModalDeleteHardwareTypes}
            onClose={handleShowDeleteHardwareTypes}
            showEndModal={updateHardwareTypes}
            handleConfirm={removeItem}
          />
        )}
        {!!selectedHardwareType && showModalEditHardwareTypes && (
          <EditHardwareType
            show={showModalEditHardwareTypes}
            onClose={handleShowEditHardwareTypes}
            showEndModal={updateHardwareTypes}
            hardwareType={selectedHardwareType}
          />
        )}
      </ST.StaticHiringTBody>
    </ST.StaticHiringTable>
  )
}

export default StaticHardwareTypesTabContent
