import { Action } from 'redux'
import { ROLES } from 'constants/roles'

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE',
  ABORT = 'ABORT',
  SET_ABORT = 'SET_ABORT',
}

export type AuthState = {
  accessToken: string
  role: ROLES[]
  isAuthorised: boolean
  abortLoginController?: AbortController | null
}

interface Login extends Action {
  type: AuthActionTypes.LOGIN
  payload: { accessToken: string; role: ROLES[] }
}

interface Logout extends Action {
  type: AuthActionTypes.LOGOUT
}

interface Update extends Action {
  type: AuthActionTypes.UPDATE
  payload?: Partial<AuthState>
}

interface SetAbortLoginController extends Action {
  type: AuthActionTypes.SET_ABORT
  payload?: AbortController | null
}

interface AbortLogin extends Action {
  type: AuthActionTypes.ABORT
}

export type AuthAction =
  | Login
  | Logout
  | Update
  | AbortLogin
  | SetAbortLoginController

export enum UserIdActionTypes {
  SETUSER = 'SETUSER',
  RESETUSER = 'RESETUSER',
}

export type UserIdState = {
  userId: string
}

interface SetUser extends Action {
  type: UserIdActionTypes.SETUSER
  payload: { userId?: string }
}

interface ResetUser extends Action {
  type: UserIdActionTypes.RESETUSER
}

export type UserIdAction = SetUser | ResetUser
