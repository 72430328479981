import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ModalTitles } from 'constants/modalTitles'
import { LetterTemplate } from 'types/model/letterTemplate'

interface Props {
  show: boolean
  onClose: (isShow: boolean) => void
  handleConfirm?: (id: number) => void
  showEndModal?: SetState<boolean>
  selectedTemplate: LetterTemplate | null
}

const DeleteSection: FC<Props> = ({
  show,
  onClose,
  handleConfirm,
  showEndModal,
  selectedTemplate,
}) => {
  const handleClose = (): void => {
    if (showEndModal) {
      showEndModal(true)
    }
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{ModalTitles.deleteLetterTemplate}</ST.ModalTitle>

          <ST.Description>
            Удалить шаблон "{selectedTemplate?.name}"?
          </ST.Description>

          <ST.ButtonsBlock>
            <ST.Button
              onClick={() => {
                handleConfirm &&
                  selectedTemplate?.id &&
                  handleConfirm(selectedTemplate?.id)
                handleClose()
              }}
            >
              Удалить
            </ST.Button>
            <ST.Button onClick={handleClose} typeButton={'secondary'}>
              Отмена
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
