import React, { FC, useCallback, useMemo, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Edit } from 'assets/icons/edit-review.svg'
import { SurveyStatuses, SurveyStatusesLabels } from 'constants/reviewStatuses'
import FinishReview from 'components/dialogs/Reviews/FinishReview'
import CloseReview from 'components/dialogs/Reviews/CloseReview'
import { Link, useParams } from 'react-router-dom'
import { ReviewStatuses, ReviewTypes } from 'types/model/review'
import EditionReview from 'components/dialogs/Reviews/EditionReview'
import { PositionTypes } from 'types/model/position'
import BaseButton from 'components/ui/buttons/BaseButton'
import { ButtonTypes } from 'constants/buttonTypes'
import { UserTypes } from 'types/model/user'
import { ReactComponent as Check } from 'assets/icons/Check.svg'
import { ReactComponent as Cancel } from 'assets/icons/cancel-red.svg'
import { useModalWindowState } from 'hooks/useModalWindowState'
import IconButton from 'components/ui/buttons/IconButton'
import { useAuth } from 'hooks/useAuth'
import checkRole from 'utils/profile/checkRole'
import {
  OtherUserProfileStepsByRoles,
  ProfileSteps,
} from 'constants/profileSteps'

type Props = {
  status: ReviewStatuses
  review: ReviewTypes.Model
  userList?: UserTypes.Model[]
  userInformation?: UserTypes.UserInfo | ReviewTypes.User | null
  userId: number
  updateReviewList?: (userId: number) => void
  positions?: PositionTypes.Grade[]
} & Partial<{
  isCurrentUser: boolean
}>

const TitleLine: FC<Props> = ({
  status,
  review,
  updateReviewList,
  userInformation,
  userList,
  userId,
  isCurrentUser,
}) => {
  const { userId: currentUserId, roles } = useAuth()
  const {
    modalOpen: showModalEditReview,
    setValue: setShowModalEditReview,
    toggleModal: toggleShowModalEditReview,
  } = useModalWindowState()
  const { modalOpen: openCloseModal, setValue: setOpenCloseModal } =
    useModalWindowState()
  const {
    modalOpen: showModalFinishReview,
    toggleModal: handleShowModalFinishReview,
  } = useModalWindowState()
  const [isListOpen, setIsListOpen] = useState<boolean>(false)
  const [isListHovered, setIsListHovered] = useState<boolean>(false)

  const handleClOseModalAddReview = useCallback((): void => {
    setShowModalEditReview(false)
    setOpenCloseModal(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShowModalEditReview = useCallback((): void => {
    if (showModalEditReview) updateReviewList?.(userId)
    toggleShowModalEditReview()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReviewList])

  const { userIdFromUrl } = useParams()
  const userIdNew = !!userIdFromUrl ? +userIdFromUrl : userId

  const handleEdit = useCallback(() => {
    switch (review.status) {
      case ReviewStatuses.wait:
        setShowModalEditReview(true)
        break
      case ReviewStatuses.not_complete:
        setShowModalEditReview(true)
        break
      default:
        handleShowModalFinishReview()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleShowModalFinishReview, review.status])

  const survey360 = useMemo(
    () =>
      review.surveys.find(
        (_survey360) =>
          (_survey360.expert.id === userId && +_survey360.type === 2) ||
          +_survey360.type === 0
      ),
    [review.surveys, userId]
  )

  const completedOtherSurvey360 = useMemo<number>(
    () =>
      review.surveys.filter((survey) => survey.expert.id !== +userId).length,
    [review.surveys, userId]
  )

  const fullFiledCount = useMemo<number>(
    () =>
      review.surveys.filter(
        (survey) =>
          survey.expert.id !== +userId &&
          survey.status === SurveyStatuses.COMPLETED
      ).length,
    [review.surveys, userId]
  )

  const fullFillData = useMemo(
    () =>
      review.surveys
        .filter((sur) => sur.expert.id !== +userId)
        .map((survey) => ({
          [`${survey.expert.name} ${survey.expert.surname}`]:
            SurveyStatusesLabels[survey.status],
        })),
    [review.surveys, userId]
  )

  const is360SurveyCompleted = useMemo<boolean>(
    () =>
      review.surveys?.length
        ? !!review?.surveys.find(
            (survey) =>
              survey.expert.id === userId &&
              survey.status === SurveyStatuses.COMPLETED &&
              survey.type === 2
          )
        : true,
    [review.surveys, userId]
  )

  const isUserSurveyCompleted = useMemo<boolean>(
    () =>
      review.userSurvey
        ? review.userSurvey?.status === SurveyStatuses.COMPLETED
        : true,
    [review.userSurvey]
  )

  const isFinishButtonDisabled = useMemo(
    () => !(is360SurveyCompleted && isUserSurveyCompleted),
    [is360SurveyCompleted, isUserSurveyCompleted]
  )

  const hasEditAccess = useMemo(
    () =>
      checkRole(roles, OtherUserProfileStepsByRoles[ProfileSteps.reviewTab]),
    [roles]
  )

  return (
    <ST.TitleLine>
      {(review.status === ReviewStatuses.not_complete ||
        review.status === ReviewStatuses.wait) &&
        !isCurrentUser && (
          <ST.Polls>
            {review.surveys.length && survey360?.id ? (
              <>
                <Link
                  to={`/reviews/survey360${
                    is360SurveyCompleted || !isCurrentUser ? '/results' : ''
                  }/${review.id}`}
                >
                  <ST.CountPoll
                    onMouseEnter={() => setIsListOpen(true)}
                    onMouseLeave={() =>
                      setTimeout(() => setIsListOpen(false), 200)
                    }
                  >
                    <ST.Counter
                      isFullfiled={
                        fullFiledCount > 0 &&
                        completedOtherSurvey360 > 0 &&
                        fullFiledCount === completedOtherSurvey360
                      }
                    >
                      {fullFiledCount}/{completedOtherSurvey360}
                    </ST.Counter>
                    <ST.Poll>Опрос 360</ST.Poll>
                    <ST.FullFillInfo
                      isListOpen={isListOpen || isListHovered}
                      onMouseEnter={() => setIsListHovered(true)}
                      onMouseLeave={() => setIsListHovered(false)}
                    >
                      {!!fullFillData?.length &&
                        Object.entries(fullFillData).map(([key, value]) => {
                          const [user] = Object.keys(value)
                          return (
                            <ST.FullFillData key={key}>
                              <ST.FullFillUser>{user}</ST.FullFillUser>
                              <ST.FullFillStatus>
                                {value[user as keyof typeof value]}
                              </ST.FullFillStatus>
                            </ST.FullFillData>
                          )
                        })}
                    </ST.FullFillInfo>
                  </ST.CountPoll>
                </Link>
              </>
            ) : null}
            {review.userSurvey && (
              <>
                <Link
                  to={`/reviews/survey${
                    review.userSurvey.status !== SurveyStatuses.COMPLETED &&
                    survey360?.expert?.id === currentUserId!
                      ? ''
                      : '/results'
                  }/${review.userSurvey.id}`}
                >
                  <ST.Poll>
                    {review.userSurvey.status === SurveyStatuses.COMPLETED ? (
                      <Check />
                    ) : (
                      <Cancel />
                    )}
                    Общий опрос сотрудника
                  </ST.Poll>
                </Link>
              </>
            )}
          </ST.Polls>
        )}
      {!isCurrentUser && hasEditAccess && (
        <ST.ButtonsBlock>
          {(review.status === ReviewStatuses.wait ||
            review.status === ReviewStatuses.not_complete) && (
            <BaseButton
              typeButton={ButtonTypes.primary}
              width="170px"
              height="40px"
              text={'Завершить'}
              onClick={handleShowModalFinishReview}
              disabled={isFinishButtonDisabled}
            />
          )}
          {status !== ReviewStatuses.cancel && (
            <IconButton icon={Edit} onClick={handleEdit} />
          )}
        </ST.ButtonsBlock>
      )}
      <FinishReview
        review={review}
        show={showModalFinishReview}
        onClose={handleShowModalFinishReview}
        userId={userIdNew}
        updateReviews={updateReviewList}
        usersInfo={userInformation}
      />

      {review.id && !!userList?.length && (
        <EditionReview
          showEditModal={handleClOseModalAddReview}
          show={showModalEditReview}
          onClose={handleShowModalEditReview}
          userId={userId}
          review={review}
          userList={userList}
          updateReviews={updateReviewList}
        />
      )}

      <CloseReview
        review={review}
        onClose={setOpenCloseModal}
        show={openCloseModal}
        updateReviews={updateReviewList}
        userId={userId}
      />
    </ST.TitleLine>
  )
}

export default TitleLine
