import { IItem } from 'components/ui/BaseSelect'

export enum VacancyPriorityTypes {
  simple = 1,
  high = 2,
}

export enum VacancyPriorityTypesDescription {
  simple = 'Обычный',
  high = 'Высокий',
}

export const VacancyPriorityTypesLabels = {
  [VacancyPriorityTypes.simple]: VacancyPriorityTypesDescription.simple,
  [VacancyPriorityTypes.high]: VacancyPriorityTypesDescription.high,
}

export const listVacancyPriorityTypes: Array<IItem> = [
  {
    item: VacancyPriorityTypesDescription.simple,
    value: VacancyPriorityTypes.simple,
  },
  {
    item: VacancyPriorityTypesDescription.high,
    value: VacancyPriorityTypes.high,
  },
]
