export enum ReviewStatuses {
  not_complete = 0,
  wait = 1,
  close = 2,
  cancel = 3,
}

export enum SurveyStatuses {
  NOT_COMPLETED = 21,
  COMPLETED = 1,
  REJECTED = 0,
}

export enum SurveyStatusesDescription {
  NOT_COMPLETED = 'Не проведен',
  COMPLETED = 'Проведен',
  REJECTED = 'Отменен',
}

export const SurveyStatusesLabels = {
  [SurveyStatuses.NOT_COMPLETED]: SurveyStatusesDescription.NOT_COMPLETED,
  [SurveyStatuses.COMPLETED]: SurveyStatusesDescription.COMPLETED,
  [SurveyStatuses.REJECTED]: SurveyStatusesDescription.REJECTED,
}

export enum ReviewType {
  adapt = 1,
  plan = 2,
  disc = 3,
  tech = 4,
}

export enum ReviewConclusions {
  STRONG_MISMATCH = 1,
  MISMATCH = 2,
  COMPLIANCE = 3,
  TO_INCREASE = 4,
}
