import styled from 'styled-components'

export const StatusBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 175px;
`

export const Label = styled.span`
  margin-left: 8px;
`
