import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { Tags } from 'styles/Tags'

export const PositionsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    align-items: initial;
  }
`

export const PositionsBody = styled.div``

export const PositionsTitle = styled.p`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
`

export const Button = styled.button`
  background: #ffdc24;
  border-radius: 50px;
  padding: 13px 43px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background-color: ${COLORS.yellowHover};
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }
`

export const ListHeader = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0 40px;
  width: calc(100% - 96px);
`

export const ListHeaderText = styled(Tags.p1Bold)`
  &:first-of-type {
    display: block;
    width: calc(60% - 50px);
  }
`

export const PositionsBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
