import { apiInstance, apiInstanceForFile, apiInstanceWithToken } from 'api'
import { HiringStep } from 'types/model/hiring'
import {
  ISetting,
  ISettingsCreateBody,
  ISettingsPutBody,
  ISettingSubsection,
  ServiceModeType,
  SettingsSections,
} from 'types/model/settings'
import { SettingsSteps } from 'constants/settingsSteps'
import {
  IDocumentTemplate,
  IDocumentTemplateRequest,
} from 'types/model/documentTemplateType'

export const getSettings = async (
  pageNumber: number = 1,
  pageSize: number = 50
): Promise<number> => {
  const params = new URLSearchParams()
  params.append('pageSize', String(pageSize))
  params.append('pageNumber', String(pageNumber))

  return apiInstance
    .get(`/api/setting?${params.toString()}`)
    .then((resp) => resp.data)
}

export const createSetting = async (
  data: ISettingsCreateBody
): Promise<ISetting> => {
  return apiInstance.post('/api/setting', data).then((resp) => resp.data)
}

export const putSetting = async (
  id: number,
  data: ISettingsPutBody
): Promise<ISetting> => {
  return apiInstance.put(`/api/setting/${id}`, data).then((resp) => resp.data)
}

export const getSettingSectionByType = async <T>(
  section: SettingsSections | SettingsSteps
): Promise<T> => {
  const params = new URLSearchParams()
  params.append('section', String(section.toString()))
  params.append('pageSize', '100')

  return apiInstance
    .get(`/api/setting/by-section?${params.toString()}`)
    .then((resp) => resp.data)
}

export const getGeneralSettings = async (): Promise<ISettingSubsection[]> => {
  return getSettingSectionByType<ISettingSubsection[]>(
    SettingsSections.GENERAL
  ).then((resp) => resp)
}

export const getContactsSettings = async (): Promise<ISettingSubsection[]> => {
  return getSettingSectionByType<ISettingSubsection[]>(
    SettingsSections.CONTACTS
  ).then((resp) => resp)
}

export const getHiringStepSettings = async (): Promise<HiringStep[]> => {
  return getSettingSectionByType<HiringStep[]>(
    SettingsSections.HIRING_STAGES
  ).then((resp) => resp)
}

export const getIntegrationSettings = async (): Promise<
  ISettingSubsection[]
> => {
  return getSettingSectionByType<ISettingSubsection[]>(
    SettingsSections.INTEGRATIONS
  ).then((resp) => resp)
}

export const getDocumentTemplateSettings = async (): Promise<
  IDocumentTemplate[]
> => {
  return getSettingSectionByType<IDocumentTemplate[]>(
    SettingsSections.DOCUMENT_PATTERNS
  ).then((resp) => resp)
}

export const getHiringStages = async (): Promise<HiringStep[]> => {
  return getSettingSectionByType<HiringStep[]>(
    SettingsSections.HIRING_STAGES
  ).then((resp) => resp)
}

export const changeStageOrder = async (steps: HiringStep[]): Promise<any> => {
  const newObj = {
    stages: steps,
  }
  return apiInstance.put('/api/stage', newObj).then((resp) => resp.data)
}

export const createStage = async (step: HiringStep): Promise<any> => {
  return apiInstance.post('/api/stage', step).then((resp) => resp.data)
}

export const changeStage = async (
  id: number,
  step: HiringStep
): Promise<any> => {
  return apiInstance.put(`/api/stage/${id}`, step).then((resp) => resp.data)
}

export const deleteStage = async (
  id: number,
  transferStageId?: number
): Promise<any> => {
  let url = `/api/stage/${id}`
  if (transferStageId) {
    const params = new URLSearchParams()
    params.append('transferStageId', transferStageId.toString())
    url = `/api/stage/${id}?${params.toString()}`
  }
  return apiInstance.delete(url).then((resp) => resp.data)
}

export const getDocumentTemplates = async (
  token?: string
): Promise<IDocumentTemplate[]> => {
  return apiInstanceWithToken(token)
    .get('/api/documentPatterns')
    .then((resp) => resp.data)
}

export const createDocumentTemplate = async (
  doc: IDocumentTemplateRequest
): Promise<IDocumentTemplate> => {
  return apiInstance
    .post('/api/documentPatterns', doc)
    .then((resp) => resp.data)
}

export const changeDocumentTemplate = async (
  id: number,
  doc: IDocumentTemplateRequest
): Promise<IDocumentTemplate> => {
  return apiInstance
    .patch(`/api/documentPatterns/${id}`, doc)
    .then((resp) => resp.data)
}

export const deleteDocumentTemplate = async (id: number): Promise<any> => {
  return apiInstance
    .delete(`/api/documentPatterns/${id}`)
    .then((resp) => resp.data)
}

export const createOrUpdateSettings = async (
  data: ISetting[]
): Promise<ISetting> => {
  let newData = data.map((setting) => {
    return {
      ...setting,
      imgId: setting.img?.id,
    }
  })
  let body = {
    settings: newData,
  }
  return apiInstance.post('/api/setting/array', body).then((resp) => resp.data)
}

export const getSheetLists = async (): Promise<string[]> => {
  return apiInstance.get(`/api/setting/sheetLists`).then((resp) => resp.data)
}

export const uploadSurveyPattern = async (
  file: File,
  name?: string
): Promise<ISetting> => {
  const data = new FormData()

  data.append('file', file)
  data.append('name', name ?? file.name)

  return apiInstanceForFile
    .post('/api/setting/survey-pattern', data)
    .then((resp) => resp.data)
}

export const deleteSetting = async (id: number) => {
  return apiInstance
    .delete(`/api/setting/delete/${id}`)
    .then((resp) => resp.data)
}
