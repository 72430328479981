import { PATHS } from 'constants/paths'
import { ALL_ROLES, ROLES } from 'constants/roles'

import { MenuTypes as Types } from './types'

export namespace MenuData {
  export const MenuItemsByRoles: Record<Types.MenuPaths, ROLES[]> = {
    [PATHS.home]: ALL_ROLES,
    [PATHS.employees]: ALL_ROLES,
    [PATHS.reviews]: [ROLES.hr, ROLES.recruiter, ROLES.customer, ROLES.admin],
    [PATHS.applications]: [
      ROLES.hr,
      ROLES.recruiter,
      ROLES.sysadmin,
      ROLES.admin,
    ],
    [PATHS.benefitApplications]: [ROLES.hr, ROLES.admin, ROLES.sysadmin],
    [PATHS.vacancies]: [ROLES.recruiter, ROLES.customer, ROLES.admin],
    [PATHS.hardwares]: [ROLES.hr, ROLES.recruiter, ROLES.sysadmin, ROLES.admin],
    [PATHS.activityLog]: [ROLES.admin],
    [PATHS.departments]: [ROLES.admin, ROLES.owner, ROLES.sysadmin],
    [PATHS.positions]: [ROLES.admin, ROLES.owner, ROLES.sysadmin],
    [PATHS.technologyStack]: [ROLES.recruiter, ROLES.customer, ROLES.admin],
    [PATHS.grades]: [ROLES.admin, ROLES.owner, ROLES.sysadmin],
    [PATHS.settings]: [ROLES.admin, ROLES.owner, ROLES.sysadmin],
  }
}
