import styled from 'styled-components'
import { COLORS } from 'constants/colors'

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
`

export const Label = styled.span`
  font-family: 'Golos-Text_VF';
`

export const Tag = styled.div`
  background-color: ${COLORS.lightGrey};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 10px 16px;
`

export const Tags = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`
