import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IIcon } from 'components/Header/Menu/styled'
import ImageEmployee from 'assets/images/employee.jpg'
import { ReactComponent as EditIconPic } from 'assets/icons/edit.svg'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`

export const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-auto-flow: dense;
`

export const EditBlockContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px 15px 20px;
  background: ${COLORS.yellow};
  border-radius: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.yellowHover};
  }
`

export const EditText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

export const EditIcon = styled(EditIconPic)``

const BlockInfo = styled.div`
  padding: 24px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
`

export const Personal = styled(BlockInfo)``
export const Email = styled(BlockInfo)``
export const Department = styled(BlockInfo)``
export const Boss = styled(BlockInfo)``
export const SocialMedia = styled(BlockInfo)`
  margin-top: 20px;
`
export const Stack = styled(BlockInfo)`
  padding-bottom: 14px;
  margin-top: 20px;
`

export const HeaderBlock = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin-bottom: 12px;
`

export const RowInfo = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 9px;
  &:last-child {
    margin-bottom: 0;
  }
`
export const RowDep = styled(RowInfo)`
  margin-bottom: 14px;
`

export const BossRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
`

export const SocialName = styled(RowInfo)`
  margin-bottom: 0;
`

export const RowSocial = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const LinkSocial = styled.a`
  margin-bottom: 12px;
  display: block;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ImageBlock = styled.div<IIcon>`
  background: url(${({ imageSrc }) => (imageSrc ? imageSrc : ImageEmployee)})
    50% 50% no-repeat;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-size: 33px 22px;
  position: relative;
  margin-right: 7px;
`

export const RowStack = styled(RowInfo)`
  margin-bottom: 11px;
  &:last-child {
    margin-bottom: 11px;
  }
`

export const TechBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

export const ContentBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const Knowledge = styled.div`
  padding: 10px 16px;
  background-color: ${COLORS.lightGrey};
  margin-left: 5px;
  margin-bottom: 10px;
  text-align: center;
`

export const ManageBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const BackBlock = styled.div`
  display: flex;
  align-items: center;
`

export const BackButton = styled.div`
  width: 44px;
  height: 40px;
  background: ${COLORS.yellow};
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const EditingHeader = styled.h2`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
`

export const SaveButton = styled.button`
  width: 220px;
  height: 40px;
  background: ${COLORS.yellow};
  font-family: 'Golos-Text_VF';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  border-radius: 50px;
  cursor: pointer;
`
