import { Dispatch } from 'react'
import { AuthAction, AuthActionTypes, AuthState } from 'types/authType'
import { ROLES } from 'constants/roles'

export namespace AuthActionCreators {
  type ActionFn = (dispatch: Dispatch<AuthAction>) => void

  export const login = (token: string, role: ROLES[]): ActionFn => {
    return (dispatch: Dispatch<AuthAction>) => {
      dispatch({
        type: AuthActionTypes.LOGIN,
        payload: { accessToken: token, role: role },
      })
    }
  }

  export const logout = (): ActionFn => {
    return (dispatch: Dispatch<AuthAction>) => {
      dispatch({ type: AuthActionTypes.LOGOUT })
    }
  }

  export const update = (payload?: Partial<AuthState>): ActionFn => {
    return (dispatch) => {
      dispatch({ type: AuthActionTypes.UPDATE, payload })
    }
  }

  export const setAbortLoginController = (
    payload?: AbortController | null
  ): ActionFn => {
    return (dispatch) => {
      dispatch({ type: AuthActionTypes.SET_ABORT, payload })
    }
  }

  export const abortLogin = (): ActionFn => {
    return (dispatch) => {
      dispatch({ type: AuthActionTypes.ABORT })
    }
  }
}
