import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const Body = styled.div`
  background: ${COLORS.white};
  color: ${COLORS.black};
  height: 100%;
  min-height: calc(100vh - 80px);
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;

  @media (max-width: ${BreakPoints.DESKTOPS}) {
    width: auto;
    padding: 40px 20px;
  }

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    padding: 40px;
    width: auto;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
  }
`

export const Content = styled.div`
  max-width: 1180px;
  width: 100%;
`
