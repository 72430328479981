import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const ApplicationCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 27.5px 40px;
  font-size: 18px;
  line-height: 25px;
`

export const HeaderCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const TargetBlock = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 46px;

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    flex-direction: column;
    gap: 20px;
  }
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  button {
    height: 40px;
    width: 200px;

    @media (max-width: ${BreakPoints.NOTEBOOK}) {
      height: 48px;
      width: 100%;
    }
  }
`
