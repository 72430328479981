import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { Tags } from 'styles/Tags'

type CounterProps = {
  error?: boolean
}

type TextareaContainerProps = {
  hasCounter?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  transition: 0.3s;
`

export const RequiredIndicator = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
  font-family: Golos, sans-serif;
`

export const LabelContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const Label = styled.label`
  font-family: Golos, sans-serif;
  display: flex;
  align-items: start;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.black70};
  cursor: pointer;
`

export const TextareaContainer = styled.div<TextareaContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-bottom: ${({ hasCounter }) => (hasCounter ? '24px' : 0)};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  border-radius: 10px;
`

export const Textarea = styled.textarea<{ isValid?: boolean }>`
  font-family: Golos, sans-serif;
  border-radius: 10px;
  border: none;
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }

  ${({ isValid }) =>
    isValid === false &&
    `
    animation: shake 0.3s;
    border: 1px solid ${COLORS.errorRed};
  `} @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
    }

    50% {
      transform: translateX(5px);
    }
  }
`

export const Error = styled.span`
  position: absolute;
  bottom: 2px;
  left: 20px;
  text-overflow: ellipsis;
  font-family: Golos, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const Counter = styled(Tags.p3)<CounterProps>`
  position: absolute;
  color: ${({ error }) => (error ? COLORS.errorRed : COLORS.gray70)};
  right: 20px;
  bottom: 2px;
`
