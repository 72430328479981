import { SettingsTabsProps } from 'components/settings/Tabs/types'
import { FC, useEffect, useState } from 'react'
import { useModalWindowState } from 'hooks/useModalWindowState'
import { SettingsHiringTypesStyles as ST } from './styled'
import Loader from 'components/ui/Loader'
import EmptyResult from 'components/views/feedback/EmptyResult'
import { BaseContainer } from 'components/ui/layout/BaseContainer'
import BaseButton from 'components/ui/buttons/BaseButton'
import { SettingsStepsDescription } from 'constants/settingsStepsDescription'
import HiringTypeBlock from 'components/settings/Tabs/HiringTypes/HiringTypeBlock'
import { getHiringTypes } from 'api/hiringType'
import { HiringType } from 'types/model/hiringType'
import HiringTypesModal from 'components/settings/Tabs/HiringTypes/Modal'

type ModalType = 'edit' | 'create'

const HiringTypesTab: FC<SettingsTabsProps.Props> = () => {
  const { toggleModal, modalOpen } = useModalWindowState()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingError, setIsLoadingError] = useState<boolean>(false)
  const [data, setData] = useState<HiringType[]>([])
  const [modalType, setModalType] = useState<ModalType>('create')
  const [editInitialValues, setEditInitialValues] = useState<HiringType | null>(
    null
  )

  const updateHiringTypes = (loading?: boolean) => {
    if (loading) setIsLoading(true)
    getHiringTypes()
      .then((res) => {
        setData(res ?? [])
      })
      .catch(() => {
        setIsLoadingError(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    updateHiringTypes()
  }, [])

  return isLoading ? (
    <Loader />
  ) : isLoadingError ? (
    <BaseContainer>
      <EmptyResult title="Ошибка загрузки" description="" />
    </BaseContainer>
  ) : (
    <ST.Container>
      <ST.Header>
        <ST.HeaderTitle>{SettingsStepsDescription.HIRING_TYPES}</ST.HeaderTitle>
        <BaseButton
          style={{ maxWidth: 180 }}
          disabled={isLoading}
          text="Добавить тип"
          onClick={() => {
            setModalType('create')
            toggleModal()
          }}
        />
      </ST.Header>

      <ST.DataContainer>
        {data.map((hiringType) => (
          <HiringTypeBlock
            key={hiringType.id}
            data={hiringType}
            toggleEditModal={() => {
              setModalType('edit')
              setEditInitialValues(hiringType)
              toggleModal()
            }}
          />
        ))}
      </ST.DataContainer>

      <HiringTypesModal
        key={`hiringTypesModal-${modalOpen}-${modalType}`}
        open={modalOpen}
        onClose={() => {
          toggleModal()
          setEditInitialValues(null)
        }}
        onSubmit={() => {
          updateHiringTypes(true)
        }}
        type={modalType}
        data={editInitialValues}
      />
    </ST.Container>
  )
}

export default HiringTypesTab
