import React, { FC, useState } from 'react'
import { IntegrationSteps } from 'constants/settingsSteps'
import MailTab from './EditingTabs/MailTab'
import CalendarTab from './EditingTabs/CalendarTab'
import { ISetting } from 'types/model/settings'
import ReviewTab from './EditingTabs/ReviewTab'
import VKTab from './EditingTabs/VKTab'
import HHTab from './EditingTabs/HHTab'
import { IItem } from 'components/ui/BaseSelect'
import { AvitoTab } from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/AvitoTab'
import TelegramTab from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/TelegramTab'

interface IEditingHiringStepsTabContent {
  handleIsEditing: () => void
  handleSelectEdit: (num: number) => void
  selectedEdit: number
  updateSteps: () => void
  mailData: ISetting[]
  calendarData: ISetting[]
  reviewData: ISetting[]
  vkData: ISetting[]
  hhData: ISetting[]
  habrData: { id: string }
  avitoData: ISetting[]
  telegramData: ISetting[]
  posToSheet: Array<IItem[]>
  pos: IItem[]
  sheets: IItem[]
}

const EditingIntegrationsTabContent: FC<IEditingHiringStepsTabContent> = ({
  handleIsEditing,
  updateSteps,
  handleSelectEdit,
  selectedEdit,
  mailData,
  calendarData,
  reviewData,
  vkData,
  hhData,
  habrData,
  avitoData,
  posToSheet,
  pos,
  sheets,
  telegramData,
}) => {
  const [isEditingTabs, setIsEditingTabs] = useState<boolean>(false)
  const handleIsEditingTabs = () => {
    setIsEditingTabs((prevState) => !prevState)
    handleIsEditing()
  }

  const formContent = () => {
    switch (selectedEdit) {
      case IntegrationSteps.MAIL:
        return (
          <MailTab
            setIsEditing={setIsEditingTabs}
            isEditingTabs={isEditingTabs}
            data={mailData}
            handleIsEditing={handleIsEditingTabs}
          />
        )
      case IntegrationSteps.CALENDAR:
        return (
          <CalendarTab
            setIsEditing={setIsEditingTabs}
            isEditingTabs={isEditingTabs}
            data={calendarData}
            handleIsEditing={handleIsEditingTabs}
          />
        )
      case IntegrationSteps.REVIEWS:
        return (
          <ReviewTab
            setIsEditing={setIsEditingTabs}
            isEditingTabs={isEditingTabs}
            data={reviewData}
            handleIsEditing={handleIsEditingTabs}
            posToSheet={posToSheet}
            positions={pos}
            sheetLists={sheets}
          />
        )
      case IntegrationSteps.VK_BOT:
        return (
          <VKTab
            setIsEditing={setIsEditingTabs}
            isEditingTabs={isEditingTabs}
            data={vkData}
            handleIsEditing={handleIsEditingTabs}
          />
        )
      case IntegrationSteps.API_HH:
        return (
          <HHTab
            setIsEditing={setIsEditingTabs}
            isEditingTabs={isEditingTabs}
            data={hhData}
            handleIsEditing={handleIsEditingTabs}
          />
        )
      case IntegrationSteps.AVITO:
        return (
          <AvitoTab data={avitoData} handleIsEditing={handleIsEditingTabs} />
        )
      case IntegrationSteps.TELEGRAM:
        return (
          <TelegramTab
            setIsEditing={setIsEditingTabs}
            isEditingTabs={isEditingTabs}
            data={telegramData}
            handleIsEditing={handleIsEditingTabs}
          />
        )
      case IntegrationSteps.API_HABR:
        return <></>
      default:
        return null
    }
  }

  return <>{formContent()}</>
}

export default EditingIntegrationsTabContent
