import styled from 'styled-components'
import { COLORS } from 'constants/colors'

export namespace PasswordInput {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    transition: 0.3s;
  `

  export const RequiredIndicator = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: ${COLORS.errorRed};
    font-family: Golos, sans-serif;
  `

  export const LabelContainer = styled.div`
    display: flex;
    gap: 10px;
  `

  export const Label = styled.label`
    display: flex;
    align-items: start;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: ${COLORS.black70};
    cursor: pointer;
    font-family: Golos, sans-serif;
  `

  export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: relative;
  `

  export const Input = styled.input<{ isValid?: boolean }>`
    font-family: Golos, sans-serif;
    border-radius: 10px;
    border: none;
    background: ${COLORS.inputBG} 99% 50% no-repeat;
    padding: 10px 20px;
    height: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #000000;
    padding-right: 80px;

    ::placeholder {
      opacity: 0.5;
    }

    ${({ isValid }) =>
      isValid === false &&
      `
    animation: shake 0.3s;
    border: 1px solid ${COLORS.errorRed};
  `}

    @keyframes shake {
      0%,
      100% {
        transform: translateX(0);
      }
      25%,
      75% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }
    }
  `

  export const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  `

  export const Error = styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: ${COLORS.errorRed};
    font-family: Golos, sans-serif;
  `
}
