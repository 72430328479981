enum SubDomains {
  BOXHR = '.boxhr.ru',
  PROFSOFT = '.profsoft.online',
}

export const saveHost = () => {
  const host = window.location.hostname

  if (host.includes(SubDomains.BOXHR) || host.includes(SubDomains.PROFSOFT)) {
    localStorage.setItem('host', host)
  }
}
