import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { HardwareTypes } from 'types/model/hardware'

interface Props {
  show: boolean
  title: string
  onClose: (isShow: boolean) => void
  handleConfirm?: (id: number) => void
  showEndModal?: SetState<boolean>
  hardwareType: HardwareTypes.HardwareType
}

const DeleteSection: FC<Props> = ({
  show,
  title,
  onClose,
  handleConfirm,
  showEndModal,
  hardwareType,
}) => {
  const handleClose = (): void => {
    if (showEndModal) {
      showEndModal(true)
    }
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{title}</ST.ModalTitle>

          <ST.Description>Удалить тип "{hardwareType.name}"?</ST.Description>

          <ST.ButtonsBlock>
            <ST.Button
              onClick={() => {
                handleConfirm && handleConfirm(hardwareType.id)
                handleClose()
              }}
            >
              Удалить
            </ST.Button>
            <ST.Button onClick={handleClose} typeButton={'secondary'}>
              Отмена
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
