import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import Left from 'assets/icons/Calendar/chevron-left.svg'
import Right from 'assets/icons/Calendar/chevron-right.svg'
import { BreakPoints } from 'constants/breakPoints'

interface WrapperProps {
  disabled?: boolean
}

export const WideInput = styled.input`
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  outline: none;
  padding: 0 0 0 20px;
  border: 0;
  border-radius: 10px;
  background: ${COLORS.inputBG};
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    height: 48px;
    padding-left: 16px;
  }
`

export const Input = styled(WideInput)`
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`
export const ButtonBack = styled.button`
  background: url(${Left}) center no-repeat;
  margin-right: 10px;
`
export const ButtonForward = styled.button`
  background: url(${Right}) center no-repeat;
  margin-left: 10px;
`

export const HeaderText = styled.div`
  color: ${COLORS.black};
  font-size: 14px;
  line-height: 20px;
`

export const Wrapper = styled.div<WrapperProps>`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`
