import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import { BreakPoints } from 'constants/breakPoints'
import { InputSizes } from 'constants/inputSizes'
import { CommonStyles } from 'styles/CommonStyles'

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 580px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`

export const Button = styled.button`
  width: 100%;
  background-color: ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  &:hover {
    background-color: ${COLORS.yellowHover};
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: auto;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.p`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const HeaderLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.black70};
  word-break: break-word;
`

export const LabelName = styled(HeaderLabel)``
export const LabelValue = styled(HeaderLabel)`
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const InputsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  margin-top: 8px;
  margin-bottom: 22px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin: 40px 0;
  }
`

export const DivWrapper = styled.div``

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  ${(props) =>
    props.sizeInput === InputSizes.big
      ? 'max-width: 500px'
      : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }
  :disabled {
    color: ${COLORS.black70};
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    height: 48px;
    padding: 14px 16px 12px;
  }
`

export const InputTextArea = styled.textarea<IInput>`
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.sizeInput === 'big' ? 'max-width: 500px' : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 140px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 14px 16px 12px;
  }
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const RowInfo = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`

export const ContainerLink = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Links = styled.a<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText}
  color: ${COLORS.blue};
  font-weight: 400;
  font-size: 14px;
  word-break: break-word;
  text-decoration: none;
  :hover {
    color: ${COLORS.blue70};
  }
`

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`
