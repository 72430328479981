import styled from 'styled-components'
import { BaseContainerTypes as T } from './types'
import { BreakPoints } from 'constants/breakPoints'

export namespace BaseContainerStyles {
  export const Container = styled.div<T.Props>`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;
    width: 100%;
    height: fit-content;
    border-radius: ${({ rectangular }) => (rectangular ? '0' : '15px')};
    box-shadow: ${({ disableShadow }) =>
      disableShadow ? 'none' : '0px 4px 15px 0px rgba(51, 51, 51, 0.06)'};
  `
}
