import { IItem } from 'components/ui/BaseSelect'

export enum ReviewOperationTypes {
  adaptive = 1,
  plan = 2,
  disciplinary = 3,
  technical = 4,
}

export enum ReviewAnswers {
  definitely_no = 1,
  maybe_no = 2,
  no_info = 3,
  maybe_yes = 4,
  definitely_yes = 5,
}

export enum ReviewAnswersDescription {
  definitely_no = 'Определённо нет',
  maybe_no = 'Скорее нет, чем да',
  no_info = 'Не имею информации',
  maybe_yes = 'Скорее да, чем нет ',
  definitely_yes = 'Определённо да ',
}

export const ReviewAnswersLabels = {
  [ReviewAnswers.definitely_no]: ReviewAnswersDescription.definitely_no,
  [ReviewAnswers.maybe_no]: ReviewAnswersDescription.maybe_no,
  [ReviewAnswers.no_info]: ReviewAnswersDescription.no_info,
  [ReviewAnswers.maybe_yes]: ReviewAnswersDescription.maybe_yes,
  [ReviewAnswers.definitely_yes]: ReviewAnswersDescription.definitely_yes,
}

export const listReviewAnswers: Array<IItem> = [
  {
    item: ReviewAnswersDescription.definitely_no,
    value: ReviewAnswers.definitely_no,
  },
  {
    item: ReviewAnswersDescription.maybe_no,
    value: ReviewAnswers.maybe_no,
  },
  {
    item: ReviewAnswersDescription.no_info,
    value: ReviewAnswers.no_info,
  },
  {
    item: ReviewAnswersDescription.maybe_yes,
    value: ReviewAnswers.maybe_yes,
  },
  {
    item: ReviewAnswersDescription.definitely_yes,
    value: ReviewAnswers.definitely_yes,
  },
]
