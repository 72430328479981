import { IItem } from 'components/ui/BaseSelect'
import { ReviewConclusions, ReviewStatuses, ReviewType } from './reviewStatuses'

export enum ReviewStatusesDescription {
  not_complete = 'Нарушен срок',
  wait = 'Ожидает проведения',
  close = 'Завершено',
  cancel = 'Отменено',
}

export const ReviewStatusesLabels = {
  [ReviewStatuses.not_complete]: ReviewStatusesDescription.not_complete,
  [ReviewStatuses.wait]: ReviewStatusesDescription.wait,
  [ReviewStatuses.close]: ReviewStatusesDescription.close,
  [ReviewStatuses.cancel]: ReviewStatusesDescription.cancel,
}

export const listReviewStatuses: Array<IItem> = [
  {
    item: ReviewStatusesDescription.not_complete,
    value: ReviewStatuses.not_complete,
  },
  {
    item: ReviewStatusesDescription.wait,
    value: ReviewStatuses.wait,
  },
  {
    item: ReviewStatusesDescription.close,
    value: ReviewStatuses.close,
  },
  {
    item: ReviewStatusesDescription.cancel,
    value: ReviewStatuses.cancel,
  },
]

export enum ReviewTypesDescription {
  adapt = 'Адаптационное ревью',
  plan = 'Плановое ревью',
  disc = 'Дисциплинарное ревью',
  tech = 'Техническое ревью',
}

export const ReviewTypesLabels = {
  [ReviewType.adapt]: ReviewTypesDescription.adapt,
  [ReviewType.plan]: ReviewTypesDescription.plan,
  [ReviewType.disc]: ReviewTypesDescription.disc,
  [ReviewType.tech]: ReviewTypesDescription.tech,
}

export const listReviewTypes: Array<IItem> = [
  {
    item: ReviewTypesDescription.adapt,
    value: ReviewType.adapt,
  },
  {
    item: ReviewTypesDescription.plan,
    value: ReviewType.plan,
  },
  {
    item: ReviewTypesDescription.disc,
    value: ReviewType.disc,
  },
  {
    item: ReviewTypesDescription.tech,
    value: ReviewType.tech,
  },
]

export enum ReviewConclusionsDescription {
  STRONG_MISMATCH = 'Сильное несоответствие',
  MISMATCH = 'Несоответствие должности',
  COMPLIANCE = 'Соответствие должности',
  TO_INCREASE = 'Рекомендация к повышению',
}

export const ReviewConclusionsLabels = {
  [ReviewConclusions.STRONG_MISMATCH]:
    ReviewConclusionsDescription.STRONG_MISMATCH,
  [ReviewConclusions.MISMATCH]: ReviewConclusionsDescription.MISMATCH,
  [ReviewConclusions.COMPLIANCE]: ReviewConclusionsDescription.COMPLIANCE,
  [ReviewConclusions.TO_INCREASE]: ReviewConclusionsDescription.TO_INCREASE,
}

export const listReviewConclusions: Array<IItem> = [
  {
    item: ReviewConclusionsDescription.STRONG_MISMATCH,
    value: ReviewConclusions.STRONG_MISMATCH,
  },
  {
    item: ReviewConclusionsDescription.MISMATCH,
    value: ReviewConclusions.MISMATCH,
  },
  {
    item: ReviewConclusionsDescription.COMPLIANCE,
    value: ReviewConclusions.COMPLIANCE,
  },
  {
    item: ReviewConclusionsDescription.TO_INCREASE,
    value: ReviewConclusions.TO_INCREASE,
  },
]
