import Layout from 'components/Layouts/layout'
import Body from 'components/ui/layout/Body'
import EmptyResult from 'components/views/feedback/EmptyResult'
import { BaseContainer } from 'components/ui/layout/BaseContainer'
import { ReactComponent as ServiceIcon } from 'assets/images/serviceIcon.svg'
import { CSSProperties } from 'styled-components'
import { PATHS } from 'constants/paths'

const bodyStyle: CSSProperties = {
  padding: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 100px)',
}

const baseContainerStyle: CSSProperties = { padding: '11px 0 35px' }

const UnexpectedError = () => (
  <Body style={bodyStyle}>
    <BaseContainer style={baseContainerStyle}>
      <EmptyResult
        icon={<ServiceIcon height="190px" style={{ marginLeft: '30px' }} />}
        title="Неизвестная ошибка"
        description="Попробуйте перезагрузить страницу или зайти позже"
        style={{ gap: '22px' }}
        primaryButton={{
          text: 'Перезагрузить страницу',
          action: () => window.location.reload(),
        }}
        secondaryButton={{
          text: 'Вернуться в мой профиль',
          href: PATHS.home,
        }}
      />
    </BaseContainer>
  </Body>
)

export const UnexpectedErrorPage = () => (
  <Layout>
    <UnexpectedError />
  </Layout>
)
