import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

interface CounterProps {
  isFullfiled: boolean
}

interface FullFillInfoProps {
  isListOpen: boolean
}

export const TitleLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${BreakPoints.TABLETS}) {
    flex-direction: column;
    gap: 10px;
  }
`

export const Polls = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
`

export const Poll = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

export const CountPoll = styled(Poll)`
  position: relative;
  cursor: pointer;
`

export const Counter = styled.p<CounterProps>`
  color: ${(props) => (props.isFullfiled ? COLORS.green : COLORS.errorRed)};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 6px;
`

export const FullFillInfo = styled.div<FullFillInfoProps>`
  display: ${(props) => (props.isListOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  width: 300px;
  max-height: 200px;
  overflow-y: auto;
  gap: 5px;
  top: 130%;
  z-index: 999;
  background: ${COLORS.white};
  padding: 10px 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
`

export const FullFillData = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const FullFillUser = styled.div``

export const FullFillStatus = styled.div``

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`

export const Info = styled.div`
  display: flex;
  gap: 14px;
  justify-content: end;
`

export const Date = styled.div`
  display: flex;
  align-items: center;
`

export const DateText = styled.span`
  margin-left: 8px;
  font-size: 14px;
`

export const EditButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 8px 10px;
  border-radius: 50px;
  background: ${COLORS.yellow};
  height: max-content;
  width: max-content;
  > svg {
    cursor: pointer;
  }
`
export const ButtonsBlock = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  align-items: end;
  height: 100%;
  box-sizing: border-box;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
