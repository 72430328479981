import { IItem } from 'components/ui/BaseSelect'
import { HiringLanguagesEnum } from 'api/hiring/hiringEnums'

export enum HiringLanguagesTypes {
  english = 'Английский',
  arab = 'Арабский',
  spanish = 'Испанский',
  italian = 'Итальянский',
  chinese = 'Китайский',
  german = 'Немецкий',
  turkish = 'Турецкий',
  french = 'Французский',
  japanese = 'Японский',
  russian = 'Русский',
}

export const HiringLanguagesTypesLabels = {
  [HiringLanguagesEnum.english]: HiringLanguagesTypes.english,
  [HiringLanguagesEnum.arab]: HiringLanguagesTypes.arab,
  [HiringLanguagesEnum.spanish]: HiringLanguagesTypes.spanish,
  [HiringLanguagesEnum.italian]: HiringLanguagesTypes.italian,
  [HiringLanguagesEnum.chinese]: HiringLanguagesTypes.chinese,
  [HiringLanguagesEnum.german]: HiringLanguagesTypes.german,
  [HiringLanguagesEnum.turkish]: HiringLanguagesTypes.turkish,
  [HiringLanguagesEnum.french]: HiringLanguagesTypes.french,
  [HiringLanguagesEnum.japanese]: HiringLanguagesTypes.japanese,
  [HiringLanguagesEnum.russia]: HiringLanguagesTypes.russian,
}

export const listHiringLanguagesTypes: IItem<
  HiringLanguagesEnum,
  HiringLanguagesTypes
>[] = [
  {
    item: HiringLanguagesTypes.english,
    value: HiringLanguagesEnum.english,
  },
  {
    item: HiringLanguagesTypes.arab,
    value: HiringLanguagesEnum.arab,
  },
  {
    item: HiringLanguagesTypes.spanish,
    value: HiringLanguagesEnum.spanish,
  },
  {
    item: HiringLanguagesTypes.italian,
    value: HiringLanguagesEnum.italian,
  },
  {
    item: HiringLanguagesTypes.chinese,
    value: HiringLanguagesEnum.chinese,
  },
  {
    item: HiringLanguagesTypes.german,
    value: HiringLanguagesEnum.german,
  },
  {
    item: HiringLanguagesTypes.turkish,
    value: HiringLanguagesEnum.turkish,
  },
  {
    item: HiringLanguagesTypes.french,
    value: HiringLanguagesEnum.french,
  },
  {
    item: HiringLanguagesTypes.japanese,
    value: HiringLanguagesEnum.japanese,
  },
  {
    item: HiringLanguagesTypes.russian,
    value: HiringLanguagesEnum.russia,
  },
]
