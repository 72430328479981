import * as ST from 'components/vacanciesStatistic/styled'
import CalendarInput from 'components/ui/inputs/Calendar'
import React, { FC, memo, useMemo, useState } from 'react'
import moment from 'moment'
import { getStatisticByEmployees, getStatisticByVacancy } from 'api/statistic'
import { handlerError } from 'utils/handlerError'
import { subDays, subMonths, subWeeks } from 'date-fns'
import { StatisticFiltersTypes as T } from './types'
import { StatisticFiltersConstants as C } from './constants'
import BaseButton from 'components/ui/buttons/BaseButton'
import { DATE_FORMAT_DTO } from 'constants/Date'
import FilterPeriods = C.FilterPeriods

const TimeButton: FC<T.TimeButtonProps> = ({
  filterPeriod,
  setFilterPeriod,
  title,
}) => (
  <ST.TimeButton onClick={() => setFilterPeriod(filterPeriod)}>
    {title}
  </ST.TimeButton>
)

export const StatisticFilters: FC<T.Props> = memo(
  ({
    displayStatisticByEmployees,
    setStatisticByEmployees,
    setStatisticByVacancies,
    handleLoad,
    setDisableCompareApplied,
  }) => {
    const [dateFrom, setDateFrom] = useState<Date>(new Date(Date.now()))
    const [dateTo, setDateTo] = useState<Date>(new Date(Date.now()))
    const [compareDateFrom, setCompareDateFrom] = useState<Date>(
      new Date(Date.now())
    )
    const [compareDateTo, setCompareDateTo] = useState<Date>(
      new Date(Date.now())
    )
    const [disableCompare, setDisableCompare] = useState<boolean>(false)

    const valuesFilter = useMemo(
      () => ({
        dateFrom: moment(dateFrom).format(DATE_FORMAT_DTO) ?? '',
        dateTo: moment(dateTo).format(DATE_FORMAT_DTO) ?? '',
        compareDateFrom:
          disableCompare || !displayStatisticByEmployees
            ? ''
            : moment(compareDateFrom).format(DATE_FORMAT_DTO),
        compareDateTo:
          disableCompare || !displayStatisticByEmployees
            ? ''
            : moment(compareDateTo).format(DATE_FORMAT_DTO),
      }),
      [
        compareDateFrom,
        compareDateTo,
        dateFrom,
        dateTo,
        disableCompare,
        displayStatisticByEmployees,
      ]
    )

    const handleSubmit = () => {
      handleLoad(true)
      setDisableCompareApplied(disableCompare)

      if (displayStatisticByEmployees) {
        getStatisticByEmployees(valuesFilter)
          .then((resp) => setStatisticByEmployees(resp))
          .catch((err) => handlerError(err))
          .finally(() => {
            handleLoad(false)
          })
      } else {
        getStatisticByVacancy(valuesFilter)
          .then((resp) => setStatisticByVacancies(resp))
          .catch((err) => handlerError(err))
          .finally(() => handleLoad(false))
      }
    }

    const setFilterPeriod = (period: FilterPeriods) => {
      switch (period) {
        case FilterPeriods.TODAY:
          setDateFrom(subDays(new Date(), 1))
          setDateTo(new Date())
          setCompareDateFrom(subDays(new Date(), 3))
          setCompareDateTo(subDays(new Date(), 2))
          break
        case FilterPeriods.YESTERDAY:
          setDateFrom(subDays(new Date(), 2))
          setDateTo(subDays(new Date(), 1))
          setCompareDateFrom(subDays(new Date(), 4))
          setCompareDateTo(subDays(new Date(), 3))
          break
        case FilterPeriods.WEEK:
          setDateFrom(subWeeks(new Date(), 1))
          setDateTo(new Date())
          setCompareDateFrom(subWeeks(new Date(), 2))
          setCompareDateTo(subDays(subWeeks(new Date(), 1), 1))
          break
        case FilterPeriods.MONTH:
          setDateFrom(subMonths(new Date(), 1))
          setDateTo(new Date())
          setCompareDateFrom(subMonths(new Date(), 2))
          setCompareDateTo(subDays(subMonths(new Date(), 1), 1))
          break
      }
    }

    return (
      <ST.FilterBlock>
        <ST.FilterLine>
          <ST.FilterItem>
            <ST.FilterLineText>Данные за</ST.FilterLineText>
            <CalendarInput
              setStartDate={setDateFrom}
              startDate={dateFrom}
              maxDate={dateTo}
            />
            <ST.Space>-</ST.Space>
            <CalendarInput
              setStartDate={setDateTo}
              startDate={dateTo}
              minDate={dateFrom}
            />
          </ST.FilterItem>

          <ST.FilterItem>
            {C.timeButtons.map(({ period, title }) => (
              <TimeButton
                key={period}
                title={title}
                setFilterPeriod={setFilterPeriod}
                filterPeriod={period}
              />
            ))}
          </ST.FilterItem>
        </ST.FilterLine>

        {displayStatisticByEmployees && (
          <ST.FilterLine>
            <ST.FilterLineText>Сравнить с</ST.FilterLineText>
            <ST.FilterItem>
              <CalendarInput
                setStartDate={setCompareDateFrom}
                startDate={compareDateFrom}
                disabled={disableCompare}
                maxDate={compareDateTo}
              />
              <ST.Space>-</ST.Space>
              <CalendarInput
                setStartDate={setCompareDateTo}
                startDate={compareDateTo}
                disabled={disableCompare}
                minDate={compareDateFrom}
              />
              <ST.WithoutCompareCheckbox
                onClick={() => {
                  setDisableCompare((p) => !p)
                }}
              >
                <ST.CompareCheckbox
                  type={'checkbox'}
                  id={'compareCheckbox'}
                  checked={disableCompare}
                />
                <ST.WithoutCompareCheckboxLabel htmlFor={'compareCheckbox'}>
                  Без сравнения
                </ST.WithoutCompareCheckboxLabel>
              </ST.WithoutCompareCheckbox>
            </ST.FilterItem>
          </ST.FilterLine>
        )}

        <ST.ButtonsBlock>
          <BaseButton
            width={'160px'}
            height={'40px'}
            onClick={handleSubmit}
            text="Применить"
          />
        </ST.ButtonsBlock>
      </ST.FilterBlock>
    )
  }
)
