import {
  SystemAction,
  SystemActionTypes,
  SystemMode,
  SystemState,
} from 'types/SystemType'

const initialState: SystemState = {
  mode: SystemMode.BLOCKED,
}

export const SystemReducer = (
  state = initialState,
  action: SystemAction
): SystemState => {
  switch (action.type) {
    case SystemActionTypes.SET_MODE:
      return { ...state, mode: action.payload }
    default:
      return state
  }
}
