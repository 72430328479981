import { IItem } from 'components/ui/BaseSelect'

export enum HiringTypeStatus {
  ACTIVE = 1,
  ARCHIVED = 21,
}

export enum HiringTypeExpenseGroup {
  GENERAL = 1,
  OVER_SALARY = 2,
  FROM_SALARY = 3,
}

export enum HiringTypeExpenseGroupNames {
  GENERAL = 'Общее',
  FROM_SALARY = 'С оклада',
  OVER_SALARY = 'Сверх оклад',
}

export const HiringTypeExpenseGroupOptions: IItem<
  HiringTypeExpenseGroup,
  HiringTypeExpenseGroupNames
>[] = [
  {
    value: HiringTypeExpenseGroup.GENERAL,
    item: HiringTypeExpenseGroupNames.GENERAL,
  },
  {
    value: HiringTypeExpenseGroup.FROM_SALARY,
    item: HiringTypeExpenseGroupNames.FROM_SALARY,
  },
  {
    value: HiringTypeExpenseGroup.OVER_SALARY,
    item: HiringTypeExpenseGroupNames.OVER_SALARY,
  },
]

export enum HiringTypeExpenseType {
  PERCENT = 'percent',
  FIXED = 'fixed',
}

export enum HiringTypeExpenseTypeNames {
  PERCENT = '%',
  FIXED = 'р.',
}

export const HiringTypeExpenseTypeOptions: IItem<
  HiringTypeExpenseType,
  HiringTypeExpenseTypeNames
>[] = [
  {
    value: HiringTypeExpenseType.PERCENT,
    item: HiringTypeExpenseTypeNames.PERCENT,
  },
  {
    value: HiringTypeExpenseType.FIXED,
    item: HiringTypeExpenseTypeNames.FIXED,
  },
]

export type HiringType = {
  id: number
  name: string
  status: HiringTypeStatus
  expenses: HiringTypeExpense[]
}

export type HiringTypeActive = Pick<HiringType, 'id' | 'name'>

export type HiringTypeExpense = Exclude<
  NullableDeep<{
    id: number
    name: string
    type: HiringTypeExpenseType
    value: number
    expenseGroup: HiringTypeExpenseGroup
    number: number
  }>,
  null
>
