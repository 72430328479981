import React, { ElementRef, useEffect, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { handlerError, setToastSuccess } from 'utils/handlerError'
import { ModalTitles } from 'constants/modalTitles'
import { FormikFields } from 'constants/formikFields'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import { ButtonTypes } from 'constants/buttonTypes'
import { ReviewTypes } from 'types/model/review'
import moment from 'moment/moment'
import { requestCancelReview } from 'api/review'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { DATE_FORMAT_DTO } from 'constants/Date'

interface IRequestAccess {
  show: boolean
  onClose: SetState<boolean>
  updateReviews?: (userId: number) => void
  userId: number
  review: ReviewTypes.Model
  onSubmit?: () => void
}

const CloseReview = ({
  show,
  onClose,
  review,
  updateReviews,
  userId,
  onSubmit,
}: IRequestAccess) => {
  const handleClose = (): void => {
    onClose(!show)
  }
  const [reviewType, setReviewType] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  const modalRef = useRef<ElementRef<'div'>>(null)

  useEffect(() => {
    review.status === 1
      ? setReviewType('Адаптационное')
      : review.status === 2
      ? setReviewType('Плановое')
      : review.status === 3
      ? setReviewType('Дисциплинарное')
      : setReviewType('Техническое')
  }, [review?.id])

  const { handleChange, values, handleSubmit, errors, isValid } =
    useFormik<ReviewTypes.Dismiss>({
      initialValues: {
        comment: '',
      },
      onSubmit: () => {
        setBtnDisabled(true)
        setIsLoading(true)
        requestCancelReview(review.id, values.comment)
          .then(() => {
            setToastSuccess('Успешно')
            handleClose()
            updateReviews?.(userId)
            onSubmit?.()
          })
          .catch(() => {
            handlerError(errors)
          })
          .finally(() => {
            setBtnDisabled(false)
            setIsLoading(false)
          })
      },
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent btnDisabled={btnDisabled}>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{ModalTitles.closeReview}</ST.ModalTitle>
          <ST.Label>
            <ST.LabelName>Сотрудник:</ST.LabelName>
            <ST.LabelValue>
              {review.user.surname} {review.user.name}
            </ST.LabelValue>
          </ST.Label>
          <ST.Label>
            <ST.LabelName>Дата и тип ревью:</ST.LabelName>
            <ST.HeaderLabel>
              {moment(review.date).format(DATE_FORMAT_DTO)}, {reviewType}
            </ST.HeaderLabel>
          </ST.Label>
          <ST.InputsBlock>
            <ST.InputWrapper>
              <ST.Label>Причина отмены</ST.Label>
              <ST.InputTextArea
                placeholder={Placeholders.closeReview}
                sizeInput={InputSizes.big}
                id={FormikFields.comment}
                name={FormikFields.comment}
                value={values.comment}
                onChange={handleChange}
              />
            </ST.InputWrapper>
          </ST.InputsBlock>
          <ST.Buttons>
            <ST.Button type={ButtonTypes.submit} onClick={checkForm}>
              Подтвердить
            </ST.Button>
            <ST.ButtonBordered onClick={handleClose}>
              Оставить в графике
            </ST.ButtonBordered>
          </ST.Buttons>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CloseReview
