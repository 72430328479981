import React, { useEffect, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { BenefitTypes } from 'types/model/benefit'
import { useFormik } from 'formik'
import { approveBenefitApplication } from 'api/benefitsApplications'
import { handlerError, setToastSuccess } from 'utils/handlerError'
import { ButtonTypes } from 'constants/buttonTypes'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { listBenefitTypes } from 'constants/benefitTypesDescription'
import { listBenefitOperationTypes } from 'constants/benefitOperationTypesDescription'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import CalendarInput from 'components/ui/inputs/Calendar'
import { UserTypes } from 'types/model/user'
import { DATE_FORMAT_DTO } from 'constants/Date'
import BaseSelect from 'components/ui/BaseSelect'
import BaseButton from 'components/ui/buttons/BaseButton'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import IconButton from 'components/ui/buttons/IconButton'

interface IApproveBenefitApplication {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  benefitApplication: BenefitApplicationTypes.Model
  userData: UserTypes.Model[]
  benefitData: BenefitTypes.Model[]
  dateValue?: Date | null
}

const enum CreateBenefitApplicationStrings {
  sizeInputBig = 'big',
  surname = 'surname',
  name = 'name',
  email = 'email',
  emailPlaceholder = 'example@profsoft.pro',
  salary = 'salary',
  salaryHand = 'salaryHand',
  patronymic = 'patronymic',
  date = 'date',
  datePlaceholder = '01.01.2000',
  dateFormat = '##.##.####',
  department = 'department',
  departmentPlaceholder = 'Выберите отдел',
  post = 'post',
  postPlaceholder = 'Выберите должность',
  grade = 'grade',
  gradePlaceholder = 'Выберите грейд',
  none = 'none',
  file = 'file',
  hardwareType = 'hardwareType',
  cost = 'cost',
  responsibleUser = 'responsibleUser',
  serialNumber = 'serialNumber',
  type = 'type',
}

enum Fields {
  targetUser = 'targetUser',
  commentary = 'commentary',
  value = 'value',
  benefit = 'benefit',
  benefitId = 'benefitId',
  cancellationDate = 'cancellationDate',
}

const ApproveBenefitApplication = ({
  show,
  onClose,
  benefitApplication,
  benefitData,
  dateValue,
}: IApproveBenefitApplication) => {
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true)
  const [cancellationDate, setCancellationDate] = useState<Date>(
    dateValue ?? new Date(Date.now())
  )

  const {
    handleChange,
    values,
    resetForm,
    handleSubmit,
    errors,
    isValid,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      targetUserId: NaN,
      commentary: '',
      value: benefitApplication?.value || 1,
      benefitId: benefitApplication?.benefit?.id,
      benefit: benefitApplication?.benefit,
      type: NaN,
      typeData: listBenefitTypes[0],
      operationTypeData: listBenefitOperationTypes[0],
      operationType: NaN,
    },
    validationSchema: Yup.object().shape({
      commentary: Yup.string(),
      benefitId: Yup.number().required(RequiredFields.base),
      value: Yup.number().required(RequiredFields.base),
    }),
    onSubmit: () => {
      const newObj = {
        commentary: values.commentary,
        value: parseInt(values.value?.toString().replaceAll(' ', '')),
        benefitId: values.benefitId,
        cancellationDate: moment(cancellationDate).format(DATE_FORMAT_DTO),
      }
      approveBenefitApplication(benefitApplication.id, newObj).then(() => {
        resetForm()
        handleClose()
      })
    },
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')

  const handleClose = (): void => {
    onClose(!show)
  }

  useEffect(() => {
    setSubmitBtnDisabled(!isValid)
    if (dateValue) {
      setCancellationDate(dateValue)
    }
  }, [isValid, dateValue])

  const SignupForm = () => (
    <>
      <ST.SelectWrapper>
        <ST.Label>
          <ST.LabelName>{'Сотрудник: '}</ST.LabelName>
          <ST.LabelValue>
            {benefitApplication.targetUser.surname}{' '}
            {benefitApplication.targetUser.name}
          </ST.LabelValue>
        </ST.Label>
        {benefitApplication.benefit.operationType ===
          BenefitOperationTypes.withdrawal && (
          <ST.Label>
            <ST.LabelName>{'Бенефит на счету: '}</ST.LabelName>
            <ST.HeaderLabel>
              {benefitApplication.targetUser.benefitWallet?.balance ?? 0}
            </ST.HeaderLabel>
          </ST.Label>
        )}
        {benefitApplication.commentary && (
          <ST.DivWrapper>
            <ST.LabelName>{'Комментарий автора: '}</ST.LabelName>
            <ST.HeaderLabel>{benefitApplication.commentary}</ST.HeaderLabel>
          </ST.DivWrapper>
        )}
        <ST.InputWrapper>
          <ST.Label>
            Вид бенефита <ST.Star>*</ST.Star>
          </ST.Label>
          <BaseSelect
            placeHolder="Выбери вид"
            required
            listItems={benefitData.map((b) => ({
              item: b.type,
              value: b.id,
            }))}
            value={benefitData.find((b) => b.id === values.benefitId)?.type}
            onChange={(value) => {
              handleChange(value)
              setFieldValue('benefitId', value?.value)
            }}
          />
        </ST.InputWrapper>
      </ST.SelectWrapper>
      <ST.InputsBlock>
        <ST.InputWrapper>
          <ST.Label>
            Номинал<ST.Star>*</ST.Star>
          </ST.Label>
          <NumberFormat
            customInput={ST.Input}
            thousandsGroupStyle="thousand"
            thousandSeparator=" "
            sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
            id={Fields.value}
            name={Fields.value}
            value={values.value}
            onChange={(e: any) => {
              const value = e.target.value.replaceAll(' ', '')
              setFieldValue(Fields.value, value)
            }}
          />
        </ST.InputWrapper>
        {benefitApplication.benefit.operationType ===
          BenefitOperationTypes.refill && (
          <ST.InputWrapper>
            <ST.Label>
              Дата списания (действие до, включительно)<ST.Star>*</ST.Star>
            </ST.Label>
            {cancellationDate && (
              <CalendarInput
                setStartDate={setCancellationDate}
                startDate={cancellationDate}
                minDate={new Date(Date.now())}
              />
            )}
          </ST.InputWrapper>
        )}
        <ST.InputWrapper>
          <ST.Label>Комментарий</ST.Label>
          <ST.InputTextArea
            sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
            id={Fields.commentary}
            name={Fields.commentary}
            value={values.commentary}
            onChange={handleChange}
          />
        </ST.InputWrapper>

        {benefitApplication.links && !!benefitApplication.links.length && (
          <ST.InputWrapper>
            <ST.Label>Прикреплённые ссылки</ST.Label>
            {benefitApplication.links.map((link, index) => (
              <ST.ContainerLink key={index}>
                <ST.Links maxWidth={500} href={link} target={'_blank'}>
                  {link}
                </ST.Links>
                <ST.ContainerIcon>
                  <IconButton
                    style={{ width: '30px', height: '30px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(link).then(() => {
                        setToastSuccess('Ссылка скопирована')
                      })
                    }}
                    icon={CopyIcon}
                    noFill
                  />
                </ST.ContainerIcon>
              </ST.ContainerLink>
            ))}
          </ST.InputWrapper>
        )}
      </ST.InputsBlock>
      <BaseButton
        text="Одобрить заявку"
        width="100%"
        type={ButtonTypes.submit}
        disabled={submitBtnDisabled}
        onClick={checkForm}
      />
    </>
  )

  return (
    <>
      {show ? (
        <ST.ModalOverlay>
          <ST.Modal onClick={(e) => e.stopPropagation()}>
            <ST.ModalContent>
              <ST.Close onClick={handleClose}>
                <Close />
              </ST.Close>
              <ST.ModalTitle>
                Одобрение заявки на{' '}
                {benefitApplication.benefit.operationType ===
                BenefitOperationTypes.refill
                  ? 'начисление'
                  : 'списание'}{' '}
                бенефита
              </ST.ModalTitle>
              {SignupForm()}
            </ST.ModalContent>
          </ST.Modal>
        </ST.ModalOverlay>
      ) : null}
    </>
  )
}

export default ApproveBenefitApplication
