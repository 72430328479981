import { apiRequest, apiRequestForFile } from './request'

export const apiInstance = apiRequest()

export const apiInstanceKeepAlive = apiRequest({ keepAlive: true })

export const apiInstanceWithToken = (token?: string) => apiRequest({ token })

export const apiInstanceForFile = apiRequestForFile()

export const apiInstanceForFileJSON = apiRequestForFile('json')
