import { ROLES } from 'constants/roles'

type Options = {
  every?: boolean
}

const checkRole = (
  userRoles: ROLES[],
  roles: ROLES[],
  options?: Options
): boolean =>
  roles[options?.every ? 'every' : 'some']((role) => userRoles.includes(role))

export default checkRole
