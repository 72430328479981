import React, { FC, useEffect } from 'react'
import 'App.css'
import { Routing } from 'routing'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { ErrorBoundary } from '@sentry/react'
import ErrorBoundaryFallback from 'components/error/ErrorBoundaryFallback'
import { saveHost } from 'utils/saveHost'

const theme = {
  supports: {
    mozAppearance:
      typeof CSS !== 'undefined' && CSS.supports('-moz-appearance: none'),
  },
}

const App: FC = () => {
  useEffect(() => {
    saveHost()
  }, [])

  return (
    <ErrorBoundary fallback={(props) => <ErrorBoundaryFallback {...props} />}>
      <ThemeProvider theme={theme}>
        <Routing />
        <ToastContainer />
      </ThemeProvider>
    </ErrorBoundary>
  )
}
export default App
