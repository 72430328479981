import styled from 'styled-components'

export const StaticHiringTable = styled.table`
  text-align: left;
`

export const StaticHiringThead = styled.thead``

export const StaticHiringTR = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  margin: 20px 0;
  border-radius: 15px;
`

export const StaticHiringTRTitle = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StaticHiringTH = styled.th`
  padding: 20px 40px 0;
`

export const StaticHiringTBody = styled.tbody``

export const StaticHiringTDText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  line-height: 22.4px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const StaticHiringTD = styled.td`
  word-break: break-word;
  padding: 20px 40px;

  &:first-child {
    width: 55%;
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:nth-child(3) {
    width: 20%;
  }

  &:nth-child(4) {
    padding: 20px 8px 20px 40px;
  }

  &:last-child {
    padding: 20px 40px 20px 8px;
  }
`

export const StepsNotebookContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`

export const StepCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  gap: 20px;
`

export const StepInfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px 20px;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: auto;
`

export const StepCardTitle = styled.h6`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const StepCardValue = styled.p`
  word-break: break-word;
`
