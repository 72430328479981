import React, { FC } from 'react'
import * as ST from './styled'
import DashedButton from 'components/ui/buttons/DashedButton'
import { ReactComponent as PlusIcon } from 'assets/icons/blue-plus.svg'

interface Props {
  onClick?: () => void
}

const AddPositionButton: FC<Props> = (props) => (
  <DashedButton {...props}>
    <ST.Container>
      <PlusIcon />
      <ST.Text>Добавить должность</ST.Text>
    </ST.Container>
  </DashedButton>
)

export default AddPositionButton
