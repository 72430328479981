import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as AttachFileIcon } from 'assets/icons/profile/documents/attach-scan.svg'
import { ReactComponent as ReplaceFileIcon } from 'assets/icons/profile/documents/edit-scan.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cancel.svg'

export const AttachFileCtr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  cursor: pointer;
`

export const DownloadLink = styled.label`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
  font-family: Golos, sans-serif;

  :hover {
    cursor: pointer;
  }
`

export const AttachFile = styled(AttachFileIcon)``

export const ReplaceFile = styled(ReplaceFileIcon)``

export const PlusContainer = styled.div<{
  isCursor: boolean
}>`
  > ${AttachFile} {
    cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'auto')};

    rect {
      fill: ${({ isCursor }) => (isCursor ? '' : `${COLORS.blue50}`)};
    }
  }

  > ${ReplaceFile} {
    cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'auto')};

    rect {
      fill: ${({ isCursor }) => (isCursor ? '' : `${COLORS.blue50}`)};
    }
  }
`

export const AttachButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: ${COLORS.blue};
  border-radius: 5px;
  font-family: Golos, sans-serif;

  :hover {
    cursor: pointer;
  }
`

export const FileNameDeleteContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
`

export const FileName = styled.a`
  font-family: Golos, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${COLORS.black};
  margin-bottom: 3px;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ImagePreview = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
`

export const Delete = styled(CrossIcon)`
  cursor: pointer;
`

export const ContainerFiles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 12px;
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`
