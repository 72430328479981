import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError } from 'utils/handlerError'
import { PositionTypes } from 'types/model/position'
import BackButton from 'components/ui/buttons/BackButton'
import BaseButton from 'components/ui/buttons/BaseButton'
import { IItem } from 'components/ui/BaseSelect'
import PositionInput from 'components/settings/Tabs/Department/EditDepartment/PositionInput'
import {
  addPositionGrades,
  editPosition,
  getCurrentPosition,
  removePositionGrade,
} from 'api/position'
import { parseDataToDynamicGrades } from 'utils/parseData'
import { PATHS } from 'constants/paths'
import AddGradeButton from './AddGradeButton'
import { RequiredFields } from 'constants/requiredFields'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import { ButtonLabels } from 'constants/buttonLabels'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import Body from 'components/ui/layout/Body'
import { useGetGradesQuery } from 'store/api/grade'

export interface IDynamicGrade extends IItem {
  new?: boolean
}

const inputName = 'name'

const EditPosition = () => {
  useTitle(PAGES.editPosition)
  const navigate = useNavigate()
  const { positionId } = useParams()
  const [position, setPosition] = useState<PositionTypes.Model | null>(null)
  const [grades, setGrades] = useState<IDynamicGrade[]>([])
  const [newGradeCount, setNewGradeCount] = useState<number>(0)

  const updatePosition = (id: number) => {
    getCurrentPosition(id)
      .then((data) => {
        setPosition(data)
        setGrades(parseDataToDynamicGrades(data.grades))
      })
      .catch((err) => handlerError(err))
  }

  const editGrades = async () => {
    const newGrades = grades
      .map((item) => String(item.value))
      .filter((item) => item && !item.includes('new'))
      .map((item) => +item)

    positionId && (await addPositionGrades(+positionId, newGrades).then())
  }

  const { allGrades } = useGetGradesQuery(undefined, {
    selectFromResult: ({ data, error, isLoading }) => ({
      allGrades: parseDataToDynamicGrades(data || []),
      error,
      isLoading,
    }),
  })

  const { handleChange, values, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: position?.name || '',
    },
    onSubmit: async (data) => {
      await editPosition(+positionId!, {
        name: data.name,
      })
      await editGrades()
      navigate(PATHS.settings_positions)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(RequiredFields.base),
    }),
  })

  useEffect(() => {
    positionId && updatePosition(+positionId)
  }, [positionId])

  const removeGrade = (indexToRemove: number) => {
    const grade = grades[indexToRemove]

    if (position) {
      if (grade.new) {
        setGrades(grades.filter((_, index) => index !== indexToRemove))
      } else {
        removePositionGrade(position.id, +grade.value).then(() => {
          setGrades(grades.filter((_, index) => index !== indexToRemove))
        })
      }
    }
  }

  const createGrade = () => {
    setGrades([
      ...grades,
      {
        value: `new-${newGradeCount}`,
        item: '',
        new: true,
      },
    ])
    setNewGradeCount((prevState) => prevState + 1)
  }

  return (
    <Body>
      <ST.EditPositionHead>
        <ST.HeadPosition>
          <BackButton onClick={() => navigate(PATHS.settings_positions)} />
          <ST.TitlePosition>редактирование</ST.TitlePosition>
        </ST.HeadPosition>
        <ST.Button>
          <BaseButton
            type={'submit'}
            text={ButtonLabels.save}
            typeButton={ButtonTypes.primary}
            onClick={handleSubmit}
          />
        </ST.Button>
      </ST.EditPositionHead>
      <ST.EditPositionBody>
        <ST.PositionHead>
          <ST.InputWrapper>
            <ST.Label>
              Название должности<ST.Star>*</ST.Star>
            </ST.Label>
            <ST.Input
              placeholder={Placeholders.frontendDeveloper}
              sizeInput={InputSizes.small}
              id={inputName}
              name={inputName}
              value={values.name}
              onChange={handleChange}
            />
          </ST.InputWrapper>
        </ST.PositionHead>
        <ST.Label>
          Грейд<ST.Star>*</ST.Star>
        </ST.Label>
        <ST.GradesBlock>
          {grades.map((item, index) => (
            <ST.GradeWrapper key={String(item.value)}>
              <PositionInput
                value={item}
                listItems={allGrades}
                selectedItems={grades}
                isSmallSelect={true}
                onChange={(newValue) => {
                  if (newValue) {
                    const newGrades = grades
                    newGrades[index] = newValue
                    setGrades(newGrades)
                  }
                }}
                onRemove={() => removeGrade(index)}
              />
            </ST.GradeWrapper>
          ))}
        </ST.GradesBlock>
        <AddGradeButton onClick={createGrade} />
      </ST.EditPositionBody>
    </Body>
  )
}

export default EditPosition
