import { createGlobalStyle } from 'styled-components'
import { COLORS } from 'constants/colors'

export const GlobalStyle = createGlobalStyle`
  
  *{
    font-family: Golos, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    margin: 0;
    padding: 0;
    position: relative;
    background: ${COLORS.backgroundBlue};
    scrollbar-gutter: stable;
  }
  ol, ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    outline: none !important;
    color: inherit;
  }

  p, h1, h2, h3, h4, h5, h6, ul, li {
    padding: 0;
    margin: 0;
  }
  img{
    width: auto;
    height: auto;
  }

  button {
    border: none; 
    outline: none;
    cursor: pointer;
    
  }
  textarea:focus, input:focus{
    outline: none;
  }
  input{
    outline: none;
    border: none;
  }
  textarea {
    resize: none;
  }
  .MuiAutocomplete-listbox {
    * {
      font-family: Golos, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: black;
    }
    
    scrollbar-width: thin !important;
    scrollbar-color: transparent ${COLORS.inputBG} !important;

    ::-webkit-scrollbar {
      width: 3px !important;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      border-radius: 20px !important;
    }

    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: gray !important;
      }

      scrollbar-color: gray transparent !important;
    }
  }
`
