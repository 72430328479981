import { FormAction, FormActionTypes, FormState, ISelect } from 'types/formType'

const initialStateForm: FormState = {
  arr: [],
}

export const formReducer = (state = initialStateForm, action: FormAction) => {
  switch (action.type) {
    case FormActionTypes.ADDFORMSELECT: {
      const { payload } = action
      return {
        ...state,
        arr: [
          ...state.arr,
          { type: payload.type, item: payload.item, value: payload.value },
        ],
      }
    }
    case FormActionTypes.REMOVEFORMSELECT: {
      const { payload } = action
      const oldArr = [...state.arr]
      let newArr: Array<ISelect> = []
      oldArr.forEach((item) => {
        if (item.type !== payload.type) newArr.push(item)
      })
      return {
        ...state,
        arr: [...newArr],
      }
    }
    default:
      return state
  }
}
