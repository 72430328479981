import { DepartmentTypes } from 'types/model/department'
import { apiInstance } from 'api'

export const createDepartment = async (
  name: string,
  director: number,
  positions: string[]
): Promise<number> => {
  return apiInstance
    .post('/api/department', { name, director, positions })
    .then((resp) => resp.data.id)
}

export const deleteDepartment = async (departmentId: number) => {
  return apiInstance
    .delete(`/api/department/${departmentId}`)
    .then((resp) => resp.data)
}

interface IDepartmentEdit {
  name?: string
  director?: number
  positions: string[]
}

export const editDepartment = async (
  departmentId: number,
  department: IDepartmentEdit
) => {
  return apiInstance.patch(`/api/department/${departmentId}`, department)
}

export const getDepartments = async (): Promise<DepartmentTypes.Model[]> => {
  return apiInstance.get('/api/department').then((resp) => resp.data)
}

export const getCurrentDepartment = async (
  departmentId: number
): Promise<DepartmentTypes.Model> => {
  return apiInstance
    .get(`/api/department/${departmentId}`)
    .then((resp) => resp.data)
}

export const addDepartmentPosition = async (
  departmentId: number,
  positions: Array<string>
) => {
  let query: string = ''
  positions.forEach((item) => {
    query += `positions%5B%5D=${item}&`
  })

  return apiInstance
    .patch(`/api/department/position/${departmentId}?${query.slice(0, -1)}`)
    .then((resp) => resp.data)
}

export const removeDepartmentPosition = async (
  departmentId: number,
  position: number
): Promise<DepartmentTypes.Model> => {
  return apiInstance
    .delete(`/api/department/position/${departmentId}?position=${position}`)
    .then((resp) => resp.data)
}
