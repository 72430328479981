import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as RejectedIcon } from 'assets/icons/Applications/statuses/rejected.svg'
import { ReactComponent as WaitIcon } from 'assets/icons/Applications/statuses/under-consideration.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import { ReactComponent as ClosedIcon } from 'assets/icons/Applications/statuses/closed.svg'
import { ReviewStatuses } from 'constants/reviewStatuses'
import { ReviewStatusesDescription } from 'constants/reviewStatusesDescription'

interface Props {
  status: ReviewStatuses
}

const StatusBlock: FC<Props> = ({ status }) => {
  const icons = {
    [ReviewStatuses.not_complete]: () => <RejectedIcon />,
    [ReviewStatuses.wait]: () => <WaitIcon />,
    [ReviewStatuses.close]: () => <ApprovedIcon />,
    [ReviewStatuses.cancel]: () => <ClosedIcon />,
  }

  const labels = {
    [ReviewStatuses.not_complete]: ReviewStatusesDescription.not_complete,
    [ReviewStatuses.wait]: ReviewStatusesDescription.wait,
    [ReviewStatuses.close]: ReviewStatusesDescription.close,
    [ReviewStatuses.cancel]: ReviewStatusesDescription.cancel,
  }

  return (
    <ST.StatusBlock>
      {icons[status]()}
      <ST.Label>{labels[status]}</ST.Label>
    </ST.StatusBlock>
  )
}

export default StatusBlock
