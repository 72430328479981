import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'
import { BreakPoints } from 'constants/breakPoints'
import { FIX_CHROME_BLUR } from 'constants/fixChromeBlur'

interface ModalContentProps {
  btnDisabled: boolean
}

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: ${FIX_CHROME_BLUR};
  max-width: 580px;
  width: 100%;
  max-height: calc(100% - 80px);
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div<ModalContentProps>`
  padding: 40px;
  position: relative;
  opacity: ${({ btnDisabled }) => (btnDisabled ? '0.5' : '1')};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: initial;
    box-sizing: border-box;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
  margin-bottom: 10px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Button = styled.button`
  width: 100%;
  background-color: ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
`

export const AddButton = styled.button`
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px 24px 10px 20px;
  border: 1px dashed ${COLORS.blue};
  border-radius: 10px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.blue};
  background: ${COLORS.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
`
export const InputSalaryType = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 6px;
  width: fit-content;
`

export const SalaryType = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const WrapperTasks = styled.div`
  margin-top: 11px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Label = styled.p`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const HeaderLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LabelName = styled(HeaderLabel)``
export const LabelValue = styled(HeaderLabel)`
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const InputsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-bottom: 40px;
  }
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.sizeInput === 'big' ? 'max-width: 500px' : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    height: 48px;
    padding: 14px 16px 12px;
  }
`

export const InputTextArea = styled.textarea<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.sizeInput === 'big' ? 'max-width: 500px' : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 120px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }

  resize: none;
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const RowInfo = styled.div`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const Task = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ReviewInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  margin-top: 18px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: 40px;
  }
`

export const ReviewType = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 25.2px;
`

export const ReviewDate = styled.p`
  display: flex;
  gap: 11px;
  align-items: center;
`

export const InputWrapperWithDelete = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;

  > div > input {
    width: 406px;
  }

  > div > div {
    width: 406px;
  }
`

export const Checkbox = styled.input`
  position: relative;
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue};

    :before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border: 2px solid white;
      border-radius: 50%;
    }
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`

export const CheckboxTasks = styled.input`
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue} url(${checkboxIcon}) no-repeat center;
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const WrapperLoader = styled.div`
  position: absolute;
  padding: 40px;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: gray;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
  }
`
