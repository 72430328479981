import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { NumberBadgeType } from 'components/ui/badges/NumberBadge/index'

export namespace NumberBadge {
  export const Container = styled.div<{ type: NumberBadgeType }>`
    background: ${({ type }) =>
      type === NumberBadgeType.Primary ? COLORS.yellow : COLORS.black};
    transition: all 0.15s ease-in-out;
    display: flex;
    min-width: 14px;
    width: fit-content;
    max-width: 24px;
    height: 14px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  `

  export const Value = styled.p<{ type: NumberBadgeType }>`
    font-family: Golos, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${({ type }) =>
      type === NumberBadgeType.Primary ? COLORS.black : COLORS.white};
    padding: 0;
  `
}
