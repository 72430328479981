import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { IconButtonProps } from 'components/ui/buttons/IconButton/index'

export namespace IconButton {
  type ButtonProps = Pick<IconButtonProps, 'noFill' | 'typeButton'>

  export const Button = styled.button<ButtonProps>`
    background-color: ${({ noFill, typeButton }) =>
      noFill
        ? 'transparent'
        : typeButton === 'primary'
        ? COLORS.yellow
        : 'transparent'};
    border: ${({ noFill }) => (noFill ? 'none' : `1px solid ${COLORS.yellow}`)};
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${COLORS.black};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s linear;

    ${({ noFill }) =>
      !noFill &&
      css`
        &:enabled {
          &:hover {
            background-color: ${COLORS.yellowHover};
            border: 1px solid ${COLORS.yellowHover};
          }
        }
      `};

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    g {
      opacity: 1;
    }
  `
}
