import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as EditIconPic } from 'assets/icons/edit.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/plus-file.svg'
import { ReactComponent as BackIconPic } from 'assets/icons/arrow-left.svg'
import { ReactComponent as PlusFileSign } from 'assets/icons/plus.svg'
import { ReactComponent as Cross } from 'assets/icons/cancel.svg'
import Border from 'assets/icons/dashedBorder.svg'
import BorderHover from 'assets/icons/hover.svg'
import PlusHover from 'assets/icons/employmentPage/PlusHover.svg'
import PlusSign from 'assets/icons/employmentPage/plus.svg'
import { BreakPoints } from 'constants/breakPoints'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`

export const EditBlockContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px 15px 20px;
  background: ${COLORS.yellow};
  border-radius: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.yellowHover};
  }
`

export const EditText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

export const EditIcon = styled(EditIconPic)``

export const BlockInfo = styled.div`
  padding: 30px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
`

export const HeaderBlock = styled.p`
  max-width: 300px;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin-bottom: 12px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const HeaderText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
`

export const Download = styled(DownloadIcon)`
  cursor: pointer;
`

export const InfoContainer = styled.div``

export const RowInfo = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ManageBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: ${BreakPoints.DESKTOPS_SMALL}) {
    flex-direction: column;
    gap: 20px;
  }
`

export const BackBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`

export const BackButton = styled.div`
  width: 44px;
  height: 40px;
  background: ${COLORS.yellow};
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const EditingHeader = styled.h2`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const SaveButton = styled.button`
  width: 220px;
  height: 40px;
  background: ${COLORS.yellow};
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  border-radius: 50px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const BackIcon = styled(BackIconPic)``

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const FileNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Delete = styled(Cross)`
  cursor: pointer;
  margin-right: 2px;
`

export const FileName = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${COLORS.black};
  &:last-child {
    margin-bottom: 0;
  }
`

export const FileNameDeleteContainer = styled.div`
  display: grid;
  grid-template-columns: 7.5fr 0.5fr;
  grid-auto-flow: dense;
  grid-column-gap: 10px;
  margin-bottom: 8px;
`

export const Label = styled.div`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
`

export const Input = styled.input`
  height: 40px;
  outline: none;
  padding: 0 0 0 20px;
  border: 0;
  border-radius: 10px;
  background: ${COLORS.inputBG};
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
  box-sizing: border-box;
  width: 100%;
  max-width: 460px;
`

export const Star = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const PlusFile = styled(PlusFileSign)`
  cursor: pointer;
`

export const Personal = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 460px));
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  grid-auto-flow: dense;
  margin-bottom: 38px;
  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    grid-template-columns: 1fr;
  }
`

export const FullName = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 460px));
  grid-column-gap: 30px;
  margin-bottom: 38px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    grid-template-columns: 1fr;
  }
`

export const Headers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`
export const DepartmentBlock = styled.div`
  display: flex;
  margin-bottom: 30px;
  gap: 30px;
  width: 100%;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
  }
`

export const SocialNetworkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

export const StackWrapper = SocialNetworkWrapper

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  justify-content: flex-end;
`

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  margin-top: 10px;

  @media (max-width: ${BreakPoints.NOTEBOOK}) and (min-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    gap: 5px;
  }
`

export const RadioItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 18px 0 0;
  align-items: center;
`

export const Radiobutton = styled.input`
  position: relative;
  margin: 0 8px 0 0;
  outline: none;
  border: 2px solid ${COLORS.blue50};
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  &:checked {
    &::before {
      content: '';
      box-sizing: border-box;
      border: 2px solid white;
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: ${COLORS.blue};
    }
    border: 2px solid ${COLORS.blue};
  }
`

export const RadioLabel = styled.div`
  font-size: 16px;
  color: ${COLORS.black};
  cursor: pointer;
`

export const Plus = styled.div`
  background-image: url(${PlusSign});
  width: 24px;
  height: 24px;
`

export const Button = styled.button`
  background-image: url(${Border});
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.blue};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  background-color: white;
  gap: 4px;
  width: 240px;
  margin-top: 10px;

  span {
    margin-bottom: -13px;
  }
  &:hover {
    color: ${COLORS.blueHover};
    background-image: url(${BorderHover});
    div${Plus} {
      background-image: url(${PlusHover});
    }
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const UploadBlock = styled.div`
  margin-bottom: 10px;
`

export const DownloadLinkBlock = styled.div`
  display: flex;
  align-items: center;
`

export const DownloadLink = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.black70};
  font-family: 'Golos';
  font-style: normal;
  font-weight: 400;
  cursor: pointer;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const AttachButton = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: ${COLORS.blue};
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
`

export const PlusContainer = styled.div`
  > ${PlusFile} {
    cursor: pointer;
  }
`
export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`
