import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { ReactComponent as Trash } from 'assets/icons/trash.svg'
import ArrowUp from 'assets/icons/employmentPage/arrow_up.svg'
import ArrowDown from 'assets/icons/employmentPage/arrow_down.svg'
import Check from 'assets/icons/employmentPage/check.svg'
import { ISelect } from 'components/employment/main/form/Steps/education/styled'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  max-width: 460px;
`

export const DeleteBlock = styled(Trash)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 10px;
  width: 44px;
  height: 40px;
  background: ${COLORS.yellow};
  border-radius: 10px;
  margin-left: 10px;
  box-sizing: border-box;
`

export const Delete = styled.div`
  cursor: pointer;
`

export const Label = styled.div`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
`

export const Input = styled.input`
  max-width: 460px;
  width: 100%;
  height: 40px;
  outline: none;
  padding: 0 0 0 20px;
  border: 0;
  border-radius: 10px;
  background: ${COLORS.inputBG};
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
`

export const Star = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 30px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
  }
`

export const Error = styled.span`
  color: ${COLORS.errorRed};
`

export const InputWithButton = styled.div`
  display: flex;
  flex-direction: row;
`

export const Select = styled.input<ISelect>`
  border-radius: 10px;
  border: none;
  background: ${(props) =>
    props.isOpen
      ? `${COLORS.white} url(${ArrowUp})`
      : `${COLORS.inputBG} url(${ArrowDown})`} 98% 50% no-repeat;
  box-shadow: ${(props) =>
    props.isOpen ? '0 -1px 8px rgba(0, 0, 0, 0.1)' : 'null'};
  padding: 10px 20px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  width: 100%;
  max-width: 460px;
  color: #000000;
  box-sizing: border-box;

  ::placeholder {
    opacity: 0.5;
  }

  :hover {
    cursor: pointer;
    background-color: ${COLORS.white};
    box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.1);
`

export const SelectBlock = styled.div`
  width: 100%;
  position: relative;
`

export const DropDownList = styled.div<ISelect>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  width: calc(100% - 40px);
  background: ${COLORS.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  padding: 10px 20px 10px;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  z-index: 2;
  top: 30px;
`

export const ListItem = styled.p`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.gray70};
  position: relative;

  :hover {
    cursor: pointer;
    color: #606060;
  }

  &.active {
    color: ${COLORS.black};

    :after {
      content: '';
      background: url(${Check});
      width: 24px;
      height: 24px;
      right: 0;
      position: absolute;
    }
  }
`
