import React, { FC, useState } from 'react'
import * as ST from './styled'
import { BreakPoints } from 'constants/breakPoints'
import IconButton from 'components/ui/buttons/IconButton'
import { LetterTemplate } from 'types/model/letterTemplate'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import CreateLetterTemplate from 'components/dialogs/settings/CreateLetterTemplate'
import { deleteLetterTemplate } from 'api/letterTemplate'
import DeleteLetterTemplate from 'components/dialogs/DeleteLetterTemplate'

type Props = {
  templates: LetterTemplate[]
  updateTemplates: () => void
}

const StaticLetterTemplateTabContent: FC<Props> = ({
  templates,
  updateTemplates,
}) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<LetterTemplate | null>(null)

  const [showModalEditTemplate, setShowModalEditTemplate] =
    useState<boolean>(false)
  const [showModalDeleteTemplate, setShowModalDeleteTemplate] =
    useState<boolean>(false)

  const handleShowEditTemplate = (): void => {
    setShowModalEditTemplate((prev) => !prev)
  }

  const handleShowDeleteTemplate = (): void => {
    setShowModalDeleteTemplate((prev) => !prev)
  }

  const handleButtonClick = (
    template: LetterTemplate,
    action: 'edit' | 'delete'
  ) => {
    setSelectedTemplate(template)

    switch (action) {
      case 'edit':
        return handleShowEditTemplate()
      case 'delete':
        return handleShowDeleteTemplate()
    }
  }

  const deleteTemplate = (id: number) => {
    deleteLetterTemplate(id).then(() => {
      updateTemplates()
    })
  }

  return (
    <ST.StaticHiringTable>
      <ST.StaticHiringTBody>
        {templates.map((template) => (
          <ST.StaticHiringTR key={template.id}>
            <ST.StaticHiringTD>
              <ST.StaticHiringTDText
                maxWidth={600}
                mediaMaxWidth={{
                  [BreakPoints.DESKTOPS2]: 400,
                  [BreakPoints.TABLETS_MIDDLE]: 200,
                }}
              >
                {template.name}
              </ST.StaticHiringTDText>
            </ST.StaticHiringTD>
            <ST.StaticHiringTD padding="0">
              <IconButton
                icon={EditIcon}
                onClick={() => handleButtonClick(template, 'edit')}
              />
            </ST.StaticHiringTD>
            <ST.StaticHiringTD padding="20px 40px 20px 16px">
              <IconButton
                icon={DeleteButton}
                onClick={() => handleButtonClick(template, 'delete')}
              />
            </ST.StaticHiringTD>
          </ST.StaticHiringTR>
        ))}
        {!!selectedTemplate && showModalDeleteTemplate && (
          <DeleteLetterTemplate
            show={showModalDeleteTemplate}
            onClose={handleShowDeleteTemplate}
            handleConfirm={deleteTemplate}
            selectedTemplate={selectedTemplate}
          />
        )}
        {!!selectedTemplate && showModalEditTemplate && (
          <CreateLetterTemplate
            show={showModalEditTemplate}
            onClose={handleShowEditTemplate}
            updateTemplates={updateTemplates}
            selectedTemplate={selectedTemplate}
            isEdit
          />
        )}
      </ST.StaticHiringTBody>
    </ST.StaticHiringTable>
  )
}

export default StaticLetterTemplateTabContent
