import React, { FC, useCallback, useState } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import { RequiredFields } from 'constants/requiredFields'
import { handlerError } from 'utils/handlerError'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import { ReactComponent as Plus } from 'assets/icons/plusAddButton.svg'
import { HiringStep, NotDeletableReasons } from 'types/model/hiring'
import {
  HiringStepsDescription,
  HiringStepsLabels,
  listHiringSteps,
} from 'constants/settingsStepsDescription'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ButtonTypes } from 'constants/buttonTypes'
import DeleteHiringStep from 'components/dialogs/Hiring/DeleteHiringStep'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { changeStageOrder, createStage } from 'api/settings'
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg'
import * as Yup from 'yup'
import { HiringStepsEnum } from 'constants/settingsSteps'
import Loader from 'components/ui/Loader'
import BaseInput from 'components/ui/inputs/BaseInput'
import BaseCheckbox from 'components/ui/checkboxes/BaseCheckbox'
import IconButton from 'components/ui/buttons/IconButton'
import BackButton from 'components/ui/buttons/BackButton'
import { ROLES, RolesNames } from 'constants/roles'
import checkRole from 'utils/profile/checkRole'

interface IEditingHiringStepsTabContent {
  handleIsEditing: () => void
  updateSteps: () => void
  isLoading: boolean
  hiringSteps: HiringStep[]
}

interface IHiringStep extends HiringStep {
  actionData: Exclude<
    NullableDeep<IItem<HiringStepsEnum, HiringStepsDescription>>,
    null
  >
  textID: string
  isNew?: boolean
}

const initialStepsValues: IHiringStep[] = [
  {
    id: 0,
    isActive: false,
    actionData: {
      value: null,
      item: null,
    },
    textID: '0',
    sequence: 0,
    action: null,
    name: '',
    isDeletable: true,
    actionName: '',
  },
]

const RoleSelectOptions: IItem<ROLES, RolesNames>[] = [
  { item: RolesNames.customer, value: ROLES.customer },
  { item: RolesNames.recruiter, value: ROLES.recruiter },
]

const requiredSteps: HiringStepsEnum[] = [
  HiringStepsEnum.INITIAL_STATUS,
  HiringStepsEnum.OFFER,
  HiringStepsEnum.REJECTION,
  HiringStepsEnum.ACCEPTED,
]

const checkMissingActions = (steps: IHiringStep[] | HiringStep[]) => {
  const validatorArray = steps.map((step) =>
    requiredSteps.includes(step.action ?? 0)
  )
  return validatorArray.filter((el) => el).length !== requiredSteps.length
}

const EditingHiringStepsTabContent: FC<IEditingHiringStepsTabContent> = ({
  handleIsEditing,
  updateSteps,
  hiringSteps,
  isLoading,
}) => {
  const isNotebookDevice = useMediaQuery(`(max-width: ${BreakPoints.NOTEBOOK})`)

  const [selectedStep, setSelectedStep] = useState<IHiringStep | null>(null)
  const [showModalDeleteStage, setShowModalDeleteStage] =
    useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [isMissingRequiredFields, setIsMissingRequiredFields] =
    useState<boolean>(checkMissingActions(hiringSteps))

  const handleShowModalDeleteStage = useCallback((): void => {
    setShowModalDeleteStage((prev) => !prev)
  }, [])

  const { handleSubmit, values, errors, setFieldValue, isValid, touched } =
    useFormik<{
      steps: IHiringStep[]
    }>({
      enableReinitialize: true,
      initialValues: {
        steps:
          hiringSteps?.map((step) => ({
            ...step,
            actionData: {
              value: step.action,
              item: step.action ? HiringStepsLabels[step.action] : null,
            },
            isNew: false,
            textID: step.id ? step.id.toString() : String(step.sequence),
          })) ?? initialStepsValues,
      },
      onSubmit: (val) => {
        setButtonDisabled(true)
        const newData = val.steps.map((step) => {
          const data: any = {
            ...step,
            isActive: step.isActive,
            sequence: step.sequence,
            action: step.actionData?.value,
            name: step.name,
            actionName: step.actionName || step.actionData?.item,
            isDeletable: step.isDeletable,
            isNotifyCustomer: step.isNotifyCustomer ?? false,
            isNotifyRecruiter: step.isNotifyRecruiter ?? false,
          }

          if (!!step.id) {
            data.id = step.id
          }

          return data
        })

        const promises = [
          ...newData
            .filter((step) => step.isNew)
            .map(({ isNew, actionData, ...step }) =>
              createStage(
                actionData?.item
                  ? {
                      ...step,
                      actionName: actionData?.item,
                    }
                  : step
              )
            ),
        ]

        if (promises.length) {
          Promise.allSettled<HiringStep>(promises)
            .then((res) => {
              const fulfilled = res.filter(
                (v) => v.status === 'fulfilled'
              ) as PromiseFulfilledResult<HiringStep>[]

              const rejected = res.filter(
                (v) => v.status === 'rejected'
              ) as PromiseRejectedResult[]

              if (fulfilled.length === res.length) {
                changeStageOrder(
                  newData.map((st) => {
                    const createdStep = fulfilled
                      .map((p) => p.value)
                      .find((s) => s.sequence === st.sequence)

                    return createdStep ?? st
                  })
                )
                  .then(() => {
                    handleIsEditing()
                    updateSteps()
                  })
                  .finally(() => {
                    setButtonDisabled(false)
                  })
              } else {
                rejected.forEach((r) => handlerError(r.reason))
              }
            })
            .catch(handlerError)
            .finally(() => {
              setButtonDisabled(false)
            })
        } else {
          changeStageOrder(newData)
            .then(() => {
              handleIsEditing()
              updateSteps()
            })
            .finally(() => {
              setButtonDisabled(false)
            })
        }
      },
      validationSchema: Yup.object().shape({
        steps: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().trim().required(RequiredFields.base).nullable(),
          })
        ),
      }),
    })

  const reorder = (
    list: Iterable<unknown> | ArrayLike<unknown>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const getOpacity = (isDragging: any, draggableStyle: any) => ({
    // change background colour if dragging
    opacity: isDragging ? '0.3' : '1',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getBackground = (isDragging: any) => ({
    // change background colour if dragging
    background: isDragging ? '#FFF8D3' : '#fff',
    // styles we need to apply on draggables
  })

  const onDragEnd = (result: { source: any; destination: any }) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const items = reorder(
      values.steps,
      result.source.index,
      result.destination.index
    )
    setFieldValue('steps', items)
  }

  const addNewStep = () => {
    const max = Math.max(...values.steps.map((step) => step.sequence))
    const seq = isFinite(max) ? max + 1 : 0

    const newStep: IHiringStep = {
      name: '',
      isActive: false,
      actionData: {
        value: null,
        item: null,
      },
      action: null,
      actionName: '',
      sequence: seq,
      isDeletable: true,
      isNotifyCustomer: false,
      textID: String(seq),
      isNew: true,
    }

    setFieldValue('steps', [...values.steps, newStep])
  }

  const filterActions = (
    newAction: HiringStepsEnum,
    currentStep: IHiringStep
  ) => {
    const newSteps = values.steps.map((step) =>
      step.sequence === currentStep.sequence && step.id === currentStep.id
        ? {
            ...step,
            actionData: {
              value: newAction,
              item: HiringStepsLabels[newAction],
            },
            action: newAction,
          }
        : step.action === newAction &&
          step.action !== HiringStepsEnum.RESUME_COMPLETION
        ? {
            ...step,
            action: null,
            actionName: '',
            actionData: { item: HiringStepsDescription.EMPTY, value: null },
          }
        : step
    )
    setIsMissingRequiredFields(checkMissingActions(newSteps))
    setFieldValue('steps', newSteps)
  }

  const checkForm = () => {
    if (isValid) {
      handleSubmit()
    } else {
      handlerError(errors)
    }
  }

  const getAvailableListItems = useCallback(
    (value: IHiringStep['actionData']) => {
      const availableSteps = listHiringSteps
        .filter(
          (step) =>
            !values.steps
              .map((st) => st.actionData?.value)
              .includes(step.value) ||
            step.value === HiringStepsEnum.RESUME_COMPLETION
        )
        .filter((v) => v.value !== value.value)

      return value?.item && value?.value
        ? [
            {
              ...value,
              item: value.item!,
            },
            ...availableSteps,
          ]
        : availableSteps
    },
    [values.steps]
  )

  // TODO рефакторинг
  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />
          <ST.EditingHeader>Редактирование</ST.EditingHeader>
          {isMissingRequiredFields && (
            <ST.ErrorMessage>{RequiredFields.fullFields}</ST.ErrorMessage>
          )}
        </ST.BackBlock>
        <ST.SaveButton
          type={ButtonTypes.submit}
          onClick={checkForm}
          disabled={!isValid || buttonDisabled || isMissingRequiredFields}
        >
          Сохранить
        </ST.SaveButton>
      </ST.ManageBlock>
      {isLoading ? (
        <Loader />
      ) : isNotebookDevice ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ST.StepsNotebookContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {values.steps.map((step, index) => {
                  const error = touched.steps?.[index]?.name
                    ? errors.steps?.[index]
                    : undefined
                  const errorValue =
                    typeof error !== 'string'
                      ? (error?.name as string | undefined)
                      : undefined

                  const notificationsValue: ROLES[] = []

                  if (step.isNotifyCustomer)
                    notificationsValue.push(ROLES.customer)
                  if (step.isNotifyRecruiter)
                    notificationsValue.push(ROLES.recruiter)

                  return (
                    <Draggable
                      key={step.textID}
                      draggableId={step.textID}
                      index={index}
                    >
                      {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                      {(provided) => (
                        <ST.StepCard
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={step.textID}
                          ref={provided.innerRef}
                        >
                          <ST.StepInfoRow>
                            <BaseInput
                              value={step.name}
                              placeholder="Название этапа"
                              error={errorValue}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  `steps[${index}].name`,
                                  e.target.value
                                )
                              }}
                            />
                          </ST.StepInfoRow>
                          <ST.StepInfoRow>
                            <ST.StepCardTitle>Уведомление</ST.StepCardTitle>
                            <BaseSelect
                              placeHolder="Получатель"
                              inputStyle={{ maxWidth: 200 }}
                              dropdownStyle={{ maxWidth: 200 }}
                              listItems={RoleSelectOptions}
                              value={notificationsValue}
                              onChange={(value) => {
                                const valueRoles = value.map((v) => v.value)

                                const newStep = {
                                  ...step,
                                  isNotifyCustomer: checkRole(valueRoles, [
                                    ROLES.customer,
                                  ]),
                                  isNotifyRecruiter: checkRole(valueRoles, [
                                    ROLES.recruiter,
                                  ]),
                                }

                                setFieldValue(`steps[${index}`, newStep)
                              }}
                              multiple
                            />
                          </ST.StepInfoRow>
                          <ST.StepInfoRow>
                            <ST.StepCardTitle>Активность:</ST.StepCardTitle>
                            <BaseCheckbox
                              checked={step.isActive}
                              label=""
                              onChange={(e) => {
                                setFieldValue(
                                  `steps[${index}].isActive`,
                                  e.target.checked
                                )
                              }}
                            />
                          </ST.StepInfoRow>
                          <ST.StepInfoRow>
                            <ST.StepCardTitle>Действие:</ST.StepCardTitle>
                            <BaseSelect
                              isSmallSelect
                              placeHolder={'Выберите действие'}
                              listItems={getAvailableListItems(step.actionData)}
                              name={'actionData'}
                              value={step.actionData?.item ?? ''}
                              typeSelect={'actionData'}
                              passValue={(_, value) => {
                                filterActions(value, step)
                              }}
                              getOptionRequired={(opt) =>
                                opt.value
                                  ? requiredSteps.includes(opt.value)
                                  : false
                              }
                            />
                          </ST.StepInfoRow>
                          <ST.EditingHiringTD>
                            <IconButton
                              icon={DeleteButton}
                              onClick={() => {
                                setSelectedStep(step)
                                setShowModalDeleteStage(
                                  (prevState) => !prevState
                                )
                              }}
                            />
                          </ST.EditingHiringTD>
                        </ST.StepCard>
                      )}
                    </Draggable>
                  )
                })}
              </ST.StepsNotebookContainer>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <>
          <ST.EditingHiringTable>
            <ST.EditingHiringThead>
              <ST.EditingHiringTR>
                <ST.EditingHiringTDWrapper>
                  <ST.EditingHiringTH
                    style={{ padding: '20px 20px 20px 80px' }}
                  >
                    Название этапа
                  </ST.EditingHiringTH>
                  <ST.EditingHiringTH style={{ padding: '0 20px' }}>
                    Уведомление
                  </ST.EditingHiringTH>
                  <ST.EditingHiringTH
                    textAlign="center"
                    style={{ padding: '0 0 0 0' }}
                    width={100}
                  >
                    Активность
                  </ST.EditingHiringTH>
                  <ST.EditingHiringTH
                    width={120}
                    style={{ padding: '0 50px 0 0' }}
                  >
                    Действие
                  </ST.EditingHiringTH>
                  <ST.EditingHiringTH />
                </ST.EditingHiringTDWrapper>
              </ST.EditingHiringTR>
            </ST.EditingHiringThead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <ST.EditingHiringTBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {values.steps?.map((step, index) => {
                      const error = touched.steps?.[index]?.name
                        ? errors.steps?.[index]
                        : undefined
                      const errorValue =
                        typeof error !== 'string'
                          ? (error?.name as string | undefined)
                          : undefined

                      const notificationsValue: ROLES[] = []

                      if (step.isNotifyCustomer)
                        notificationsValue.push(ROLES.customer)
                      if (step.isNotifyRecruiter)
                        notificationsValue.push(ROLES.recruiter)

                      return (
                        <Draggable
                          key={step.textID}
                          draggableId={step.textID}
                          index={index}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                          {(provided, snapshot) => (
                            <ST.EditingHiringTR
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={step.textID}
                              ref={provided.innerRef}
                              style={getOpacity(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ST.EditingTRContent
                                style={getBackground(snapshot.isDragging)}
                              >
                                <ST.EditingHiringTDWrapper>
                                  <ST.EditingHiringTD>
                                    <ST.DragIconBlock>
                                      <DragIcon />
                                      <ST.NameBlock>
                                        <BaseInput
                                          value={step.name}
                                          placeholder="Название этапа"
                                          error={errorValue}
                                          containerStyle={{ maxWidth: 156 }}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `steps[${index}].name`,
                                              e.target.value
                                            )
                                          }}
                                        />
                                      </ST.NameBlock>
                                    </ST.DragIconBlock>
                                  </ST.EditingHiringTD>
                                  <ST.EditingHiringTD textAlign="center">
                                    <BaseSelect
                                      placeHolder="Получатель"
                                      inputStyle={{ maxWidth: 200 }}
                                      dropdownStyle={{ maxWidth: 200 }}
                                      listItems={RoleSelectOptions}
                                      value={notificationsValue}
                                      onChange={(value) => {
                                        const valueRoles = value.map(
                                          (v) => v.value
                                        )

                                        const newStep = {
                                          ...step,
                                          isNotifyCustomer: checkRole(
                                            valueRoles,
                                            [ROLES.customer]
                                          ),
                                          isNotifyRecruiter: checkRole(
                                            valueRoles,
                                            [ROLES.recruiter]
                                          ),
                                        }

                                        setFieldValue(`steps[${index}`, newStep)
                                      }}
                                      multiple
                                    />
                                  </ST.EditingHiringTD>
                                  <ST.EditingHiringTD textAlign="center">
                                    <BaseCheckbox
                                      checked={step.isActive}
                                      label=""
                                      style={{ margin: '0 auto' }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `steps[${index}].isActive`,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  </ST.EditingHiringTD>
                                  <ST.EditingHiringTD>
                                    <BaseSelect
                                      isSmallSelect
                                      placeHolder={'Выберите действие'}
                                      listItems={getAvailableListItems(
                                        step.actionData
                                      )}
                                      name={'actionData'}
                                      value={step.actionData?.item ?? ''}
                                      typeSelect={'actionData'}
                                      inputStyle={{ maxWidth: 200 }}
                                      dropdownStyle={{ maxWidth: 200 }}
                                      style={{ minWidth: 'initial' }}
                                      passValue={(_, value) => {
                                        filterActions(value, step)
                                      }}
                                      getOptionRequired={(opt) =>
                                        opt.value
                                          ? requiredSteps.includes(opt.value)
                                          : false
                                      }
                                    />
                                  </ST.EditingHiringTD>
                                  <ST.EditingHiringTD>
                                    <IconButton
                                      icon={DeleteButton}
                                      onClick={() => {
                                        if (
                                          step.notDeletableReason !==
                                          NotDeletableReasons.BASIC_ACTION
                                        ) {
                                          setSelectedStep(step)
                                          setShowModalDeleteStage(
                                            (prevState) => !prevState
                                          )
                                        }
                                      }}
                                      disabled={
                                        step.notDeletableReason ===
                                        NotDeletableReasons.BASIC_ACTION
                                      }
                                    />
                                  </ST.EditingHiringTD>
                                </ST.EditingHiringTDWrapper>
                                <ST.NameRequired>
                                  Это поле обязательно для заполнения
                                </ST.NameRequired>
                              </ST.EditingTRContent>
                            </ST.EditingHiringTR>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </ST.EditingHiringTBody>
                )}
              </Droppable>
            </DragDropContext>
          </ST.EditingHiringTable>
          <ST.AddButton onClick={addNewStep}>
            <Plus /> Добавить этап
          </ST.AddButton>
        </>
      )}
      {!!selectedStep && hiringSteps.length && showModalDeleteStage && (
        <DeleteHiringStep
          show={showModalDeleteStage}
          onClose={handleShowModalDeleteStage}
          step={selectedStep}
          showEndModal={updateSteps}
          setHiringSteps={(steps) => setFieldValue('steps', steps)}
          hiringSteps={values.steps}
        />
      )}
    </>
  )
}

export default EditingHiringStepsTabContent
