import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import * as ST from './styled'
import StaticGeneralTabContent from 'components/settings/Tabs/General/StaticGeneralTabContent'
import Loader from 'components/ui/Loader'
import { SettingsSteps } from 'constants/settingsSteps'
import { getGeneralSettings } from 'api/settings'
import { ISetting, SettingsKeys, Tunes } from 'types/model/settings'
import { getSettingByKey } from 'utils/settings/getSettingsByKey'
import EmptyResult from 'components/views/feedback/EmptyResult'
import { BaseContainer } from 'components/ui/layout/BaseContainer'

export const DEFAULT_TUNES: Tunes = {
  serviceMode: null,
}

interface Props {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const GeneralTab: FC<Props> = () => {
  const [settings, setSettings] = useState<ISetting[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSettingsLoading, setIsSettingsLoading] = useState<boolean>(false)
  const [isLoadingError, setIsLoadingError] = useState<boolean>(false)
  const [tunes, setTunes] = useState<Tunes>(DEFAULT_TUNES)

  const updateSettings = () => {
    setIsSettingsLoading(true)

    getGeneralSettings()
      .then((resp) => {
        setIsLoadingError(false)
        setSettings(resp[0].settings)
      })
      .catch(() => {
        setIsLoadingError(true)
      })
      .finally(() => {
        setIsLoading(false)
        setIsSettingsLoading(false)
      })
  }

  useEffect(() => {
    void updateSettings()
  }, [])

  useEffect(() => {
    settings &&
      setTunes({
        serviceMode: getSettingByKey(settings, SettingsKeys.SERVICE_MODE),
      })
  }, [settings])

  return isLoading ? (
    <Loader />
  ) : isLoadingError ? (
    <BaseContainer>
      <EmptyResult title="Ошибка загрузки" />
    </BaseContainer>
  ) : (
    <ST.MainContainer>
      <ST.HeaderLine>
        <ST.Header>
          <ST.HeaderTitle>Общие</ST.HeaderTitle>
        </ST.Header>
      </ST.HeaderLine>
      <StaticGeneralTabContent
        {...{ tunes, updateSettings, isLoading: isSettingsLoading }}
      />
      <ST.Footnote>
        * Когда Сервисный режим включен, доступ в систему есть только у
        пользователей с правами администратора
      </ST.Footnote>
    </ST.MainContainer>
  )
}

export default GeneralTab
