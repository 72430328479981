import styled from 'styled-components'
import { Tags } from 'styles/Tags'
import { COLORS } from 'constants/colors'

export namespace PasswordViewer {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `

  export const EyeButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  export const SensitiveText = styled(Tags.p3)<{
    isTextVisible: boolean
  }>`
    color: ${COLORS.black70};

    ${({ isTextVisible }) => !isTextVisible && `transform: translateY(2px);`}
  `
}
