import React, { useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { parseDataToDynamicGrades } from 'utils/parseData'
import { handlerError } from 'utils/handlerError'
import { addPositionGrades, createPosition } from 'api/position'
import PositionInput from 'components/settings/Tabs/Department/EditDepartment/PositionInput'
import { IItem } from 'components/ui/BaseSelect'
import { RequiredFields } from 'constants/requiredFields'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import { useGetGradesQuery } from 'store/api/grade'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
  updatePositions?: () => void
}

interface ICreatePosition {
  positionName: string
}

export interface IDynamicGrade extends IItem {
  new?: boolean
}

const inputName = 'positionName'

const CreatePosition = ({
  show,
  onClose,
  showEndModal,
  updatePositions,
}: Props) => {
  const [grades, setGrades] = useState<IDynamicGrade[]>([
    {
      value: `new-0`,
      item: '',
      new: true,
    },
  ])
  const [newGradeCount, setNewGradeCount] = useState<number>(1)

  const { allGrades } = useGetGradesQuery(undefined, {
    selectFromResult: ({ data, error, isLoading }) => ({
      allGrades: parseDataToDynamicGrades(data || []),
      error,
      isLoading,
    }),
  })

  const addGrades = async (positionId: number) => {
    const newGrades = grades
      .map((item) => String(item.value))
      .filter((item) => item && !item.includes('new'))
      .map((item) => +item)

    positionId && (await addPositionGrades(+positionId, newGrades).then())
  }

  const resetGrades = () => {
    setGrades([
      {
        value: `new-0`,
        item: '',
        new: true,
      },
    ])
  }

  const { handleChange, values, handleSubmit, errors, isValid } =
    useFormik<ICreatePosition>({
      initialValues: {
        positionName: '',
      },
      onSubmit: (data, { resetForm }) => {
        createPosition(values.positionName)
          .then((res) => {
            addGrades(+res.id).then(() => {
              resetForm()
              resetGrades()
            })
            if (updatePositions) {
              updatePositions()
            }
          })
          .catch((err) => handlerError(err))
        handleClose()

        if (showEndModal) {
          showEndModal(true)
        }
      },
      validationSchema: Yup.object().shape({
        positionName: Yup.string().required(RequiredFields.departmentName),
      }),
    })

  const checkForm = (): void => {
    if (isValid && grades[0].item) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const handleClose = (): void => {
    onClose(!show)
  }

  const removeGrade = (indexToRemove: number) => {
    const grade = grades[indexToRemove]

    if (grade.new) {
      setGrades(grades.filter((_, index) => index !== indexToRemove))
    }
  }

  const createGrade = () => {
    setGrades([
      ...grades,
      {
        value: `new-${newGradeCount}`,
        item: '',
        new: true,
      },
    ])
    setNewGradeCount((prevState) => prevState + 1)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>Создание должности</ST.ModalTitle>
          <ST.InputsBlock>
            <ST.InputWrapper>
              <ST.Label>
                Название должности<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Input
                placeholder={''}
                sizeInput={InputSizes.big}
                id={inputName}
                name={inputName}
                value={values.positionName}
                onChange={handleChange}
              />
            </ST.InputWrapper>
            <ST.GradesBlock>
              <ST.Label>
                Грейд<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.GradeWrapper>
                {grades.map((item, index) => (
                  <PositionInput
                    key={String(item.value)}
                    value={item}
                    placeholder={Placeholders.chooseGrade}
                    listItems={allGrades}
                    selectedItems={grades}
                    disabledDelete={index === 0}
                    onChange={(newValue) => {
                      if (newValue) {
                        const newGrades = grades
                        newGrades[index] = newValue
                        setGrades(newGrades)
                      }
                    }}
                    onRemove={() => removeGrade(index)}
                  />
                ))}
              </ST.GradeWrapper>
            </ST.GradesBlock>
            <ST.AddPlaceButton onClick={createGrade}>
              Добавить грейд <ST.Plus />
            </ST.AddPlaceButton>
          </ST.InputsBlock>
          <ST.OutputBlock>
            <ST.ErrorText>
              {errors.positionName || !grades[0].item
                ? RequiredFields.base2
                : ''}
            </ST.ErrorText>
          </ST.OutputBlock>
          <ST.Button type={'submit'} disabled={false} onClick={checkForm}>
            Создать
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreatePosition
