import { apiInstance } from 'api'
import {
  StatisticByEmployeesType,
  StatisticByVacanciesType,
  StatisticFilterType,
} from 'types/statisticType'

export const getStatisticByVacancy = async (
  data: StatisticFilterType
): Promise<StatisticByVacanciesType> => {
  const params = new URLSearchParams()
  for (let [key, value] of Object.entries(data)) {
    value && params.append(key, String(value))
  }
  return apiInstance
    .get(`/api/statistics/byVacancies?${params.toString()}`)
    .then((resp) => resp.data)
}

export const getStatisticByEmployees = async (
  data: StatisticFilterType
): Promise<StatisticByEmployeesType> => {
  const params = new URLSearchParams()
  for (let [key, value] of Object.entries(data)) {
    value && params.append(key, String(value))
  }
  return apiInstance
    .get(`/api/statistics/byEmployees?${params.toString()}`)
    .then((resp) => resp.data)
}
