import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { CommonStyles } from 'styles/CommonStyles'

type InfoCardProps = {
  fullWidth?: boolean
}

export const TargetLine = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-top: 20px;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    flex-direction: column;
  }
`

export const InfoCard = styled.div<InfoCardProps>`
  background: ${COLORS.lightGrey};
  border-radius: 10px;
  padding: 20px;
  flex-grow: 1;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '25%')};

  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    width: initial;
    max-width: initial;
  }
`

export const CardTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const CardLines = styled.div`
  margin-top: 10px;
`

export const CardLine = styled.p`
  color: ${COLORS.black70};
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-top: 8px;
  word-break: break-word;
`

export const CardLineLink = styled.a`
  ${CommonStyles.ClippedText};
  color: ${COLORS.blue};
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-top: 6px;
  word-break: break-word;
  text-decoration: none;
  :hover {
    color: ${COLORS.blue70};
  }
`

export const ContainerLink = styled.div`
  display: flex;
  justify-content: space-between;
`
