import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import ImageEmployee from 'assets/images/employee.jpg'

interface IIcon {
  imageSrc?: string
}

interface IScore extends WidthProps {}

interface WidthProps {
  width?: number
}

export const ApplicationCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 27.5px 40px;
  font-size: 18px;
  line-height: 25px;
`

export const TargetBlock = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 46px;
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  button {
    height: 40px;
    width: 200px;
  }
`

export const StatisticCard = styled.div<WidthProps>`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 30px 24px;
  box-shadow: 0 4px 15px 0 #3333330f;
  border-radius: 15px;
  max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
`
export const StatisticHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StatisticBody = styled.div``

export const Author = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const AuthorName = styled.p`
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  text-decoration-line: underline;
`

export const AuthorPosition = styled(AuthorName)`
  color: ${COLORS.black70};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
`

export const ImageBlock = styled.div``

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Status = styled.div`
  position: relative;
`

export const PhotoContainer = styled.div<IIcon>`
  background: url(${({ imageSrc }) => (!!imageSrc ? imageSrc : ImageEmployee)})
    50% 50% no-repeat;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  margin-right: 12px;
`

export const Result = styled.div`
  display: flex;
  gap: 40px;
  margin-right: 40px;
`

export const ResultTitle = styled.div`
  color: ${COLORS.black70};
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`

export const ResultScore = styled.span`
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

export const StatisticVisibleBody = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
`

export const StatisticHiddenBody = styled(StatisticVisibleBody)``

export const SingleScore = styled.div<IScore>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background: ${COLORS.lightGrey};
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  max-width: 128px;
`

export const SingleHiddenScore = styled.div<IScore>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: ${COLORS.yellow20};
  width: 100%;
  max-width: ${(props) => `${props.width}%`};
`

export const SingleScoreResult = styled.div`
  display: flex;
`

export const SingleMainScore = styled.p`
  color: ${COLORS.black};
  font-family: Golos;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 28.8px */
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

export const SingleScoreCompare = styled.p`
  color: ${COLORS.errorRed};

  font-family: Golos;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

export const SingleScoreNotice = styled.p`
  color: ${COLORS.black70};
  font-family: Golos;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const SingleScoreNoticeSpan = styled(SingleScoreNotice)`
  font-weight: 500;
  display: inline;
`
