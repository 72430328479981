import React, { FC } from 'react'
import * as ST from './styled'
import TitleLine from './TitleLine'
import UserLine from './UserLine'
import { StatisticByVacancyVacancyType } from 'types/statisticType'
import { HiringStep } from 'types/model/hiring'

interface Props {
  vacancy: StatisticByVacancyVacancyType
  stages: HiringStep[]
  tableWidth: number | undefined
}

const StatisticVacancyCard: FC<Props> = ({ vacancy, tableWidth, stages }) => {
  return (
    <ST.StatisticCard width={tableWidth}>
      <ST.StatisticHeader>
        <UserLine
          position={vacancy.position}
          grade={vacancy.grade}
          salaryMax={vacancy.salaryMax}
          id={vacancy.id}
        />
        <TitleLine
          deadlineDate={vacancy.dateDeadline}
          date={vacancy.dateTaking}
          status={vacancy.status}
          priority={vacancy.priority}
          expired={vacancy.daysAfterDeadline > 0}
          daysAfterDeadline={vacancy.daysAfterDeadline.toString()}
        />
      </ST.StatisticHeader>
      <ST.StatisticVisibleBody>
        <ST.SingleScore width={100 / vacancy.byStages.length}>
          <ST.SingleScoreResult>
            <ST.SingleMainScore>
              {vacancy.hired}/{vacancy.requiredEmployees}
            </ST.SingleMainScore>
            <ST.SingleScoreCompare></ST.SingleScoreCompare>
          </ST.SingleScoreResult>
          <ST.SingleScoreNotice>
            <ST.SingleScoreNoticeSpan>
              {vacancy.toRejected}
            </ST.SingleScoreNoticeSpan>{' '}
            {' в отказ'}
          </ST.SingleScoreNotice>
          <ST.SingleScoreNotice>
            <ST.SingleScoreNoticeSpan>
              {vacancy.toReserve}
            </ST.SingleScoreNoticeSpan>{' '}
            {' в резерв'}
          </ST.SingleScoreNotice>
        </ST.SingleScore>
        {vacancy?.byStages
          ?.filter((s, i) => stages[i]?.isActive)
          ?.map((e, index) => (
            <ST.SingleScore
              key={e.stageId}
              width={100 / vacancy.byStages.length}
            >
              <ST.SingleScoreResult>
                <ST.SingleMainScore>{e.count}</ST.SingleMainScore>
                <ST.SingleScoreCompare></ST.SingleScoreCompare>
              </ST.SingleScoreResult>
              <ST.SingleScoreNotice>
                <ST.SingleScoreNoticeSpan>
                  {e.toRejected}
                </ST.SingleScoreNoticeSpan>{' '}
                {' в отказ'}
              </ST.SingleScoreNotice>
              <ST.SingleScoreNotice>
                <ST.SingleScoreNoticeSpan>
                  {e.toReserve}
                </ST.SingleScoreNoticeSpan>{' '}
                {' в резерв'}
              </ST.SingleScoreNotice>
            </ST.SingleScore>
          ))}
      </ST.StatisticVisibleBody>
    </ST.StatisticCard>
  )
}

export default StatisticVacancyCard
