import styled from 'styled-components'
import { COLORS } from 'constants/colors'

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
`

export const Label = styled.span`
  font-family: Golos-Text_VF;
  font-weight: 700;
`

export const Tag = styled.div`
  background: ${COLORS.yellow};
  border-radius: 50px;
  padding: 5px 18px;
  font-size: 16px;
`

export const Tags = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`
