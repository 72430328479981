import { BenefitType } from 'constants/benefitTypes'
import { IItem } from 'components/ui/BaseSelect'

export enum BenefitTypesDescription {
  DEVICES = 'Техника',
  COMPONENTS = 'Комплектующие к технике',
  PERIPHERY = 'Мышки, клавиатуры, наушники, коврики и т.д.',
  OFFICE_STUFF = 'Канцтовары',
  BOOKS = 'Книги',
  BAGS = 'Рюкзаки',
  HOME_STUFF = 'Полотенца и некоторые товары для дома',
  CASUAL_STUFF = 'Товары для быта',
  ELECTRO = 'Электротовары',
  TOURISM = 'Товары для туризма',
  OFFICE_FOOD = 'Питание в офис',
  SPORT_VOUCHER = 'Абонемент в спортзал ',
  VMI = 'ДМС',
  EDUCATION = 'Обучение/ курсы',
  EXPERIENCE = 'Стаж',
}

export const BenefitTypesLabels = {
  [BenefitType.DEVICES]: BenefitTypesDescription.DEVICES,
  [BenefitType.COMPONENTS]: BenefitTypesDescription.COMPONENTS,
  [BenefitType.PERIPHERY]: BenefitTypesDescription.PERIPHERY,
  [BenefitType.OFFICE_STUFF]: BenefitTypesDescription.OFFICE_STUFF,
  [BenefitType.BOOKS]: BenefitTypesDescription.BOOKS,
  [BenefitType.BAGS]: BenefitTypesDescription.BAGS,
  [BenefitType.HOME_STUFF]: BenefitTypesDescription.HOME_STUFF,
  [BenefitType.CASUAL_STUFF]: BenefitTypesDescription.CASUAL_STUFF,
  [BenefitType.ELECTRO]: BenefitTypesDescription.ELECTRO,
  [BenefitType.TOURISM]: BenefitTypesDescription.TOURISM,
  [BenefitType.OFFICE_FOOD]: BenefitTypesDescription.OFFICE_FOOD,
  [BenefitType.SPORT_VOUCHER]: BenefitTypesDescription.SPORT_VOUCHER,
  [BenefitType.VMI]: BenefitTypesDescription.VMI,
  [BenefitType.EDUCATION]: BenefitTypesDescription.EDUCATION,
  [BenefitType.EXPERIENCE]: BenefitTypesDescription.EXPERIENCE,
}

export const listBenefitTypes: Array<IItem<BenefitType>> = [
  { item: BenefitTypesDescription.DEVICES, value: BenefitType.DEVICES },
  {
    item: BenefitTypesDescription.COMPONENTS,
    value: BenefitType.COMPONENTS,
  },
  {
    item: BenefitTypesDescription.PERIPHERY,
    value: BenefitType.PERIPHERY,
  },
  {
    item: BenefitTypesDescription.OFFICE_STUFF,
    value: BenefitType.OFFICE_STUFF,
  },
  {
    item: BenefitTypesDescription.BOOKS,
    value: BenefitType.BOOKS,
  },
  { item: BenefitTypesDescription.BAGS, value: BenefitType.BAGS },
  { item: BenefitTypesDescription.HOME_STUFF, value: BenefitType.HOME_STUFF },
  {
    item: BenefitTypesDescription.CASUAL_STUFF,
    value: BenefitType.CASUAL_STUFF,
  },
  { item: BenefitTypesDescription.ELECTRO, value: BenefitType.ELECTRO },
  { item: BenefitTypesDescription.TOURISM, value: BenefitType.TOURISM },
  {
    item: BenefitTypesDescription.OFFICE_FOOD,
    value: BenefitType.OFFICE_FOOD,
  },
  {
    item: BenefitTypesDescription.SPORT_VOUCHER,
    value: BenefitType.SPORT_VOUCHER,
  },
  { item: BenefitTypesDescription.VMI, value: BenefitType.VMI },
  { item: BenefitTypesDescription.EDUCATION, value: BenefitType.EDUCATION },
  { item: BenefitTypesDescription.EXPERIENCE, value: BenefitType.EXPERIENCE },
]
