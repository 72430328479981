import styled from 'styled-components'
import { COLORS } from 'constants/colors'

export namespace AutocompleteMultipleStyles {
  export const Chip = styled.div`
    display: flex;
    align-items: center;
    background-color: ${COLORS.yellow50};
    width: fit-content;
    height: 24px;
    padding-left: 4px;
    border-radius: 4px;

    & > svg {
      cursor: pointer;
    }
  `
}
