import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import Arrow from 'assets/icons/arrow-up.svg'
import { BreakPoints } from 'constants/breakPoints'

interface IButton {
  typeButton?: 'secondary' | 'order'
  width?: string
  height?: string
}

interface IFilterTitle {
  isOpen: boolean
}

interface IFilterList {
  isOpen: boolean
}

export const DepartmentsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 31px;
`

export const DepartmentsBody = styled.div``

export const DepartmentsTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const DepartmentsBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FilterBlock = styled.div`
  background: ${COLORS.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 30px;
  margin-top: 25px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

export const FilterTitle = styled.div<IFilterTitle>`
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};

  &:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    background: url(${Arrow});
    right: 30px;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`

export const FilterList = styled.div<IFilterList>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1439px) {
    gap: 20px;
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 20px;
`

export const Button = styled.button<IButton>`
  background: ${(props) =>
    props.typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  color: ${COLORS.black};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background: ${COLORS.yellowActiveButton};
    border: 1px solid ${COLORS.yellowActiveButton};
  }
`

export const HardwareTable = styled.table`
  width: 100%;
`
export const HardwareTableHead = styled.thead``
export const HardwareTableHeadElement = styled.th`
  text-align: left;
  padding: 27.5px 40px;
`
export const HardwareTableBody = styled.tbody``

export const ApplicationsBody = styled.div`
  margin-bottom: 20px;
  margin-top: 40px;
`

export const ApplicationsBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const VacancyBody = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 30px;

  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`

export const TabInfo = styled.div`
  width: 100%;
`
