import React, { FC } from 'react'
import Header from 'components/Header'
import MobileNavBar from 'components/MobileNavBar'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'

interface ILayoutProps {
  children: any
  withHeader?: boolean
  title?: string
  titleAttribute?: string
}

const Layout: FC<ILayoutProps> = ({
  children,
  title,
  titleAttribute,
  withHeader = true,
}) => {
  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  return (
    <>
      {withHeader && <Header title={title} titleAttribute={titleAttribute} />}
      {children}
      {isMobileDevice && <MobileNavBar />}
    </>
  )
}

export default Layout
