import React, { FC, useCallback, useEffect, useState } from 'react'
import * as ST from './styled'
import { ButtonTypes } from 'constants/buttonTypes'
import StaticBenefitTypesTabContent from './StaticBenefitTypesTabContent'
import { BenefitTypes } from 'types/model/benefit'
import { getBenefits } from 'api/benefits'
import { handlerError } from 'utils/handlerError'
import CreateBenefit from 'components/dialogs/CreateBenefit'
import { useModalWindowState } from 'hooks/useModalWindowState'

interface IUserProps {
  isEditing: boolean
}

const BenefitTab: FC<IUserProps> = ({ isEditing }) => {
  const { modalOpen, toggleModal } = useModalWindowState()
  const [benefitsList, setBenefitsList] = useState<BenefitTypes.Model[]>([])

  const updateBenefits = useCallback(() => {
    getBenefits()
      .then((data) => setBenefitsList(data.data))
      .catch((err) => handlerError(err))
  }, [])

  useEffect(() => {
    updateBenefits()
  }, [updateBenefits])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>Виды бенефитов</ST.EditingHeader>
          </ST.BackBlock>
          <ST.SaveButton type={ButtonTypes.submit} onClick={toggleModal}>
            Добавить вид
          </ST.SaveButton>
        </ST.ManageBlock>
      )}
      {!!benefitsList.length && (
        <StaticBenefitTypesTabContent
          benefits={benefitsList}
          updateBenefits={updateBenefits}
        />
      )}
      {modalOpen && (
        <CreateBenefit
          onClose={toggleModal}
          showEndModal={updateBenefits}
          show
        />
      )}
    </ST.MainContainer>
  )
}

export default BenefitTab
