import { useCallback, useEffect, useState } from 'react'
import { OverflowY } from 'constants/styles/overflowY'

type ReturnValue = {
  modalOpen: boolean
  toggleModal: () => void
  setValue: SetState<boolean>
}

type UseModalWindowStateHook = (
  initialState?: boolean,
  visibleOverflow?: boolean
) => ReturnValue

export const useModalWindowState: UseModalWindowStateHook = (
  initialState = false,
  visibleOverflow = false
) => {
  const [modalOpen, setValue] = useState<boolean>(initialState)

  const toggleModal = useCallback(() => {
    setValue((prevState) => !prevState)
  }, [])

  useEffect(() => {
    if (document && !visibleOverflow) {
      document.body.style.overflowY = modalOpen
        ? OverflowY.HIDDEN
        : OverflowY.AUTO
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  return { modalOpen, toggleModal, setValue }
}
