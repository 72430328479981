import styled, { css, CSSProperties } from 'styled-components'

export namespace Tags {
  type TypographyProps = Pick<CSSProperties, 'color'>

  export const H1 = styled.h1<TypographyProps>`
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%; /* 50.4px */
    letter-spacing: 2.52px;
    text-transform: uppercase;
  `

  export const H2 = styled.h2<TypographyProps>`
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 30px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
  `

  export const p1 = styled.p<TypographyProps>`
    font-family: Golos, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  `

  export const p1Bold = styled(p1)`
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-weight: 700;
  `

  const p2Styles = css<TypographyProps>`
    font-family: Golos, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    color: ${({ color }) => color};
  `

  export const P2 = styled.p<TypographyProps>`
    ${p2Styles};
  `

  export const P2Line = styled.span`
    ${p2Styles};
    text-decoration: underline;
  `

  export const p2Bold = styled(P2)`
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-weight: 600;
  `

  export const p2Btn = styled(P2)`
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-weight: 500;
  `

  export const p3 = styled.p`
    font-family: Golos, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  `

  export const p3Medium = styled(p3)`
    font-family: 'Golos Text VF', Golos, sans-serif;
    font-weight: 500;
  `

  export const p3Line = styled(p3)`
    text-decoration-line: underline;
  `
}
