import styled from 'styled-components'
import { BreakPoints } from 'constants/breakPoints'

interface IContainer {
  isSmallSelect?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isSmallSelect ? 'auto' : '100%')};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }
`

export const Content = styled.div<IContainer>`
  display: flex;
  width: ${(props) => (props.isSmallSelect ? 'auto' : '100%')};
  gap: 10px;

  > div {
    width: 90%;
  }
`

export const Error = styled.span`
  font-size: 12px;
  margin-left: 22px;
  margin-top: 5px;
  color: red;
`
