export enum BreakPoints {
  DESKTOPS = '1440px',
  DESKTOPS2 = '1080px',
  DESKTOPS_SMALL = '1200px',
  MAC13 = '1050px',
  NOTEBOOK = '980px',
  TABLETS = '800px',
  TABLETS_MIDDLE = '675px',
  TABLETS_PORTRAIT = '520px',
  PHONES = '360px',
}
