import { BreakPoints } from 'constants/breakPoints'
import styled from 'styled-components'

export const TitleLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    width: 100%;
  }
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`

export const Info = styled.div`
  display: flex;
  gap: 120px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.DESKTOPS2}) {
    gap: 40px;
  }
`

export const Date = styled.div`
  display: flex;
  align-items: center;
`

export const DateText = styled.span`
  margin-left: 8px;
`
