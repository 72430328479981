import styled, { CSSProperties } from 'styled-components'
import { CommonStyles } from 'styles/CommonStyles'

type PaddingProps = {
  padding?: CSSProperties['padding']
}

export const StaticHiringTable = styled.table`
  text-align: left;
`
export const StaticHiringThead = styled.thead``
export const StaticHiringTR = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 15px 0px rgba(51, 51, 51, 0.06);
  margin: 20px 0;
  border-radius: 15px;
`
export const StaticHiringTH = styled.th`
  padding: 20px 40px;
`
export const StaticHiringTBody = styled.tbody``

export const StaticHiringTDText = styled.span<CommonStyles.MaxWidthProps>`
  ${CommonStyles.ClippedText};
`

export const StaticHiringTD = styled.td<PaddingProps>`
  padding: ${({ padding }) => padding ?? '20px 40px'};

  &:first-child {
    width: 100%;
  }

  &:last-child {
    width: 5%;
  }
`
