export enum ModalTitles {
  departmentDeleting = 'удаление раздела',
  hardwareDeleting = 'удаление оборудования',
  hardwareTypeDeleting = 'удаление типа оборудования',
  docTemplateDeleting = 'удаление шаблона документа',
  benefitDeleting = 'удаление вида бенефита',
  positionDeleting = 'удаление должности',
  gradeDeleting = 'удаление грейда',
  CMSAccessGiving = 'Выдача доступа в CMS',
  accessGiving = 'Выдача доступа',
  accessProviding = 'Запрос доступа',
  accessWithdrawal = 'изъятие доступа',
  applicationRejection = 'Отклонение заявки на выдачу доступа',
  dismissUser = 'Увольнение сотрудника',
  addReview = 'Назначение ревью',
  editReview = 'Редактирование ревью',
  editFinishedReview = 'Редактирование завершенного ревью',
  finishReview = 'Завершение ревью',
  closeReview = 'Отмена ревью',
  closeApplicationVacancy = 'Отмена заявки на вакансию',
  continueApplicationVacancy = 'возобновление подбора на вакансию',
  closeSelectionVacancy = 'Отмена подбора на вакансию',
  pauseSelectionVacancy = 'Пауза подбора на вакансию',
  finishPauseSelectionVacancy = 'Снятие паузы подбора на вакансию',
  newCommentary = 'Новый комментарий',
  changeStatus = 'Изменение статуса',
  newVacancy = 'Создание новой вакансии',
  newVacancyApplication = 'Создание заявки на вакансию',
  takeVacancyToWork = 'Взятие вакансии в работу',
  editVacancyApplication = 'Изменение вакансии',
  editVacancy = 'Изменение вакансии',
  hiringStatuses = 'Этапы найма',
  copyVacancy = 'Копирование вакансии',
  createDocTemplate = 'Добавление шаблона документа',
  editDocTemplate = 'Редактирование шаблона документа',
  addHardware = 'Добавление оборудования',
  createHardwareType = 'Добавление типа оборудования',
  editHardwareType = 'Редактирование типа оборудования',
  createBenefitType = 'Добавление вида бенефита',
  editBenefitType = 'Редактирование вида бенефита',
  addReserve = 'Добавление в резерв',
  removeReserve = 'Удаление из резерва',
  deleteCandidate = 'Удаление кандидата',
  deleteCommentary = 'Удаление комментария',
  departmentCreate = 'Создание отдела',
  candidateScreening = 'Скрининг кандидата',
  editLetterTemplate = 'Редактирование шаблона письма',
  createLetterTemplate = 'Создание шаблона письма',
  deleteLetterTemplate = 'Удаление шаблона письма',
}
