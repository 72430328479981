import { apiInstance } from 'api'
import { LetterTemplate } from 'types/model/letterTemplate'
import { AxiosResponse } from 'axios'

export const getListLetterTemplate = async (
  reply?: number
): Promise<{ data: LetterTemplate[] }> => {
  const query = new URLSearchParams()

  reply && query.append('replyId', String(reply))

  return apiInstance
    .get(`/api/letter-template?${query}`)
    .then((resp) => resp.data)
}

export const createLetterTemplate = async (
  values: LetterTemplate
): Promise<AxiosResponse<any>> => {
  return apiInstance
    .post(`/api/letter-template`, values)
    .then((resp) => resp.data)
}

export const editLetterTemplate = async (
  id: number,
  values: LetterTemplate
): Promise<AxiosResponse<any>> => {
  return apiInstance
    .put(`/api/letter-template/${id}`, values)
    .then((resp) => resp.data)
}

export const deleteLetterTemplate = async (
  id: number
): Promise<AxiosResponse<any>> => {
  return apiInstance
    .delete(`/api/letter-template/${id}`)
    .then((resp) => resp.data)
}
