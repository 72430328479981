export enum Placeholders {
  languageExample = 'Русский',
  count = 'Количество',
  educationSuccesses = 'Рассказ об успехах',
  educationSpeciality = 'Разработка программно-информационных систем',
  directionTraining = '09.03.04 «Программная инженерия»',
  educationFaculty = 'Факультет компьютерных наук и информационных тех...',
  educationLocation = 'Регион или город',
  educationName = 'НИУ СГУ им. Н.Г. Чернышевского',
  yearExample = '2010',
  year = 'Год',
  month = 'Месяц',
  setCategory = 'Укажите категорию',
  responsibilities = 'Что делал и чего добился',
  companyPosition = 'Занимаемая должность',
  activity = 'Опишите чем занимается компания',
  location = 'Страна, регион или город',
  companyName = 'Полное наименование',
  hardSkills = 'Укажите навык',
  specialization = 'Укажите специализацию',
  messengers = 'Telegram',
  phone = '+7 (000) 000–00–00',
  commentary = 'Ваш комментарий',
  junior = 'Junior',
  uxUi = 'UX/UI',
  technologyName = 'Название технологии',
  hardwareName = 'Название оборудования',
  responsibleUser = 'Ответственный',
  serialNumber = 'Серийный номер',
  hardWareType = 'Тип оборудования',
  cost = 'Стоимость',
  frontendDeveloper = 'Frontend Developer',
  chooseGrade = 'Выберите грейд',
  chooseEmployee = 'Выберите сотрудника',
  choosePosition = 'Выберите должность',
  nameSurname = 'Сергей Галактионов',
  position = 'Должность',
  newposition = 'Новая должность',
  grade = 'Грейд',
  chooseYourGrade = 'Укажите уровень',
  rublesSum = 'Сумма в рублях',
  developmentDepartment = 'Отдел разработки',
  chooseValue = 'Выберите значение',
  url = 'https://example',
  urlExample = 'website.com',
  corpEmail = 'example@profsoft.pro',
  authEmail = 'mail@mail.ru',
  benefitType = 'Вид бенефита',
  selectEmployee = 'Выберите сотрудника',
  benefitOperationalType = 'Выберите тип',
  vacancy = 'Выберите вакансию',
  reviewType = 'Выберите тип',
  typeFromList = 'Выберите тип из списка',
  typeFormList = 'Выберите форму из списка',
  taskForReview = 'Опишите задачу',
  date = '01.01.2000',
  sum = 'Сумма',
  closeReview = 'Опишите почему ревью не состоится',
  address = 'Адрес или ссылка',
  chosePriority = 'Выберите приоритет',
  source = 'Имя рекомендателя или др. источник',
  urlResume = 'hh.ru/ivanov',
  name = 'Иван',
  docName = 'Название документа',
  surname = 'Иванов',
  city = 'Населенный пункт',
  country = 'Страна',
  vacancyRequirements = 'Например, опыт работы',
  vacancyComments = 'Например, информация об особенностях проекта, отрасли, составе команды\n',
  courseName = 'Название курса',
  doc = 'Загрузить документ',
  icon = 'Загрузить иконку',
  docReplacement = 'Заменить документ',
  type = '',
  nameFile = 'Назовите файл',
  sheetName = 'Выберите лист',
  stage = 'Этап',
}
