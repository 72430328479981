import styled, { css, keyframes } from 'styled-components'
import { COLORS } from 'constants/colors'

type ReqiuredProps = {
  $required?: boolean
}

type InputProps = {
  clipPlaceholder?: boolean
  isValid?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  transition: 0.3s;
`

export const Label = styled.label<ReqiuredProps>`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.black70};
  cursor: pointer;
  font-family: Golos, sans-serif;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ $required }) =>
    $required &&
    css`
      &::after {
        content: '*';
        margin-left: 2px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: ${COLORS.errorRed};
        font-family: Golos, sans-serif;
      }
    `};
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const shake = keyframes`
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
`

export const Input = styled.input<InputProps>`
  font-family: Golos, sans-serif;
  border-radius: 10px;
  border: none;
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: #000000;

  text-overflow: ${({ clipPlaceholder }) =>
    clipPlaceholder ? 'ellipsis' : 'none'};
  white-space: nowrap;

  &::placeholder {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:focus::placeholder {
    opacity: 0;
  }

  ${({ isValid }) =>
    isValid === false &&
    css`
      animation: ${shake} 0.3s;
      border: 1px solid ${COLORS.errorRed};
    `}
`

export const Error = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.errorRed};
  font-family: Golos, sans-serif;
`
