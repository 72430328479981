import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import { BreakPoints } from 'constants/breakPoints'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 900px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
  margin-bottom: 10px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`

export const TargetUser = styled.p`
  font-family: Golos;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.black};
  margin-bottom: 18px;
`

export const Button = styled.button`
  width: 100%;
  background-color: ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};
  margin-top: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin-top: auto;
  }

  &:disabled {
    background-color: ${COLORS.yellow50};
    color: ${COLORS.black50};
    cursor: default;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopInputWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const CheckboxInputWrapper = styled(InputWrapper)`
  gap: 15px;
  margin-bottom: 15px;
`

export const Label = styled.p`
  display: flex;
  gap: 4px;
  align-items: start;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const HeaderLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LabelName = styled(HeaderLabel)``
export const LabelValue = styled(HeaderLabel)`
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  ${(props) => (props.sizeInput === 'big' ? 'width: 460px' : 'width: 200px')};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const RowInfo = styled.p`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 10px;
  width: 500px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const AccessInfo = styled.div`
  display: flex;
  gap: 50px;
`

export const Service = styled.p`
  color: ${COLORS.black};
  font-family: Golos, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 200px;
`
export const Login = styled(Service)``

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Checkbox = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 8px;
`
export const CheckboxLabel = styled.label``

export const CheckboxInput = styled.input`
  margin: 0;
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;
  :checked {
    border: none;
    background: ${COLORS.blue} url(${checkboxIcon}) no-repeat center;
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`
