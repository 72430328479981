import React, { ElementRef, useEffect, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { HiringStep } from 'types/model/hiring'
import { deleteStage, getSettingSectionByType } from 'api/settings'
import { handlerError } from 'utils/handlerError'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from 'constants/formikFields'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { parseDataToHiringSteps } from 'utils/parseData'
import { SettingsSections } from 'types/model/settings'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'

interface IDeleteHiringStep {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  step: HiringStep
  stepIndex?: number
  setHiringSteps: (steps: HiringStep[]) => void
  hiringSteps: HiringStep[]
}

const DeleteHiringStep = ({
  show,
  onClose,
  step,
  setHiringSteps,
  hiringSteps,
  showEndModal,
}: IDeleteHiringStep) => {
  const modalRef = useRef<ElementRef<'div'>>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filteredSteps, setFilteredSteps] = useState<IItem[]>([])
  const [transferStep, setTransferStep] = useState({
    item: '',
    value: 0,
  })

  useEffect(() => {
    if (hiringSteps.length) {
      setFilteredSteps(
        parseDataToHiringSteps(
          hiringSteps.filter((hiringStep) => hiringStep.id !== step.id)
        )
      )
    }
  }, [hiringSteps, step.id])

  const handleClose = (): void => {
    onClose(!show)
  }

  const handleDelete = () => {
    if (step.id) {
      setIsLoading(true)
      deleteStage(step.id, transferStep?.value)
        .then(() => {
          getSettingSectionByType<HiringStep[]>(
            SettingsSections.HIRING_STAGES
          ).then((resp) => {
            setHiringSteps(resp)
            showEndModal?.(false)
          })
          handleClose()
        })
        .catch(handlerError)
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setHiringSteps(hiringSteps.filter((s) => s.sequence !== step.sequence))
      handleClose()
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()} ref={modalRef}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>Удаление этапа найма</ST.ModalTitle>
          <ST.ModalBlackText>Текущий этап: {step.name}</ST.ModalBlackText>
          {!step.isDeletable ? (
            <ST.ModalText>{step.notDeletableReasonText}</ST.ModalText>
          ) : (
            <ST.ModalText>Удалить этап “{step.name}“ ?</ST.ModalText>
          )}
          {!step.isDeletable && (
            <>
              <ST.Label>
                Этап для переноса кандидатов<ST.Star>*</ST.Star>
              </ST.Label>
              <BaseSelect
                value={transferStep.item || ''}
                isSmallSelect={false}
                placeHolder={Placeholders.stage}
                listItems={filteredSteps}
                name={FormikFields.reviewType}
                typeSelect={FormikFields.reviewType}
                passValue={(_, b) => {
                  setTransferStep({
                    item: filteredSteps.find((e) => e.value === b)?.item ?? '',
                    value: b,
                  })
                }}
              />
            </>
          )}
          <ST.ButtonsWrapper>
            <ST.ExitButton onClick={handleDelete}>Удалить</ST.ExitButton>
            <ST.CancelButton
              onClick={() => {
                handleClose()
              }}
            >
              Отмена
            </ST.CancelButton>
          </ST.ButtonsWrapper>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteHiringStep
