import { AnyObject } from 'yup/es/types'
import { TestFunction } from 'yup'
import { formatPhoneBack } from '../phone'

const PHONE_LENGTH = 9_223_372_036_854_775_807
const YEAR_LENGTH = 4

export const httpsDomainNullable: TestFunction<
  string | null | undefined,
  AnyObject
> = (value?: string | null): boolean => {
  if (!value) return true
  const urlPattern = /^https?:\/\/.+?\..+/i
  return urlPattern.test(value)
}

export const urlArrayNullable = (value?: string[] | null): boolean => {
  if (!value || value.length === 0) return true

  const urlPatternHttps = /^https?:\/\/.+?\..+/i
  const urlPatternHttp = /^http?:\/\/.+?\..+/i

  for (const item of value) {
    if (!item) {
      return false
    }
    if (!(urlPatternHttps.test(item) || urlPatternHttp.test(item))) {
      return false
    }
  }

  return true
}

export const phoneRu: TestFunction<string | undefined, AnyObject> = (
  value?: string
): boolean => {
  if (!value) return false
  const sliced = value.replace(/\D/g, '').slice(1)

  return sliced.length === PHONE_LENGTH && value.startsWith('+7')
}

export const phoneRuNullable: TestFunction<
  string | undefined | null,
  AnyObject
> = (value?: string | null): boolean => {
  return phoneRuNullableWithoutContext(value)
}

export const phoneRuNullableWithoutContext = (
  value?: string | null
): boolean => {
  if (!value) return true

  let valueInt = formatPhoneBack(value)
  if (valueInt === null) {
    return true
  }
  return valueInt <= PHONE_LENGTH && valueInt >= 0
}

export const yearNullable: TestFunction<
  string | undefined | number,
  AnyObject
> = (value?: string | number): boolean => {
  if (!value || value === 'NaN' || value == 0) return true

  let valueString = value + ''

  return valueString.trim().length === YEAR_LENGTH
}

export const year: TestFunction<string | undefined | number, AnyObject> = (
  value?: string | number
): boolean => {
  if (!value || value === 'NaN' || value == 0) return false

  let valueString = value + ''

  return valueString.trim().length === YEAR_LENGTH
}

export const date: TestFunction<string | undefined, AnyObject> = (
  value?: string
): boolean => {
  if (!value || value === 'NaN') return true

  let values = value.split('.')

  let years = values.pop()
  let yearInt = years ? parseInt(years) : null
  let month = values.pop()
  let monthInt = month ? parseInt(month) : null
  let day = values.pop()
  let dayInt = day ? parseInt(day) : null

  if (!yearInt || !monthInt || !dayInt) {
    return false
  }

  let maxDaysInYear = new Date(yearInt, monthInt, 0).getDate()

  return !(
    dayInt > maxDaysInYear ||
    monthInt > 12 ||
    yearInt < 1900 ||
    yearInt > new Date().getFullYear()
  )
}

export const stringNotEmpty = (value: string | null | undefined): boolean => {
  if (!value) return true
  return value.length !== 0
}

export const stringLengthNullable = (
  value: string | null | undefined,
  maxLength: number
): boolean => {
  if (!value) return true
  return value.length <= maxLength
}
