import { IValuesFilter, PropsEmployee } from 'types/employeeType'
import {
  IGeneralInfoEditing,
  UserEducationResponse,
} from 'types/userProfileTypes'
import { DocumentTypes } from 'constants/documentTypes'
import { IStatusesResponse } from 'api/employees'
import { IDismissUserApi } from 'api/userPage'
import { IFormDocumentsTab } from 'types/employment/documents'
import { UserTypes } from 'types/model/user'
import { ExtendedResponseType } from 'types/utils'
import { HardwareTypes } from 'types/model/hardware'

export namespace UserApiTypes {
  export namespace Request {
    // Queries
    export type GetStatuses = void

    export type GetInfo = number

    export type GetBirthday = {
      DateAfter?: string
      DateBefore?: string
    }

    export type GetFullName = number

    export type GetGeneral = number

    export type GetEducation = number

    export type GetEducationLevels = void

    export type GetDocuments = number

    export type GetWorkplaces = number

    export type GetHardware = number

    export const GetAllFilterKeys: (keyof IValuesFilter)[] = [
      'inOffice',
      'status',
      'department',
    ]

    export type GetAll = {
      parameters?: IValuesFilter
      doNotMerge?: boolean
      searchValue?: string
      pageNumber?: number
      pageSize?: number
    } | void

    export type GetConfirmation = number

    export type GetByDepartment = {
      departmentId: number
      page?: number
      amount?: number
    }

    export type GetSubScribe = number

    export type GetAcceptQuestionnaire = number

    // Mutations | POST
    export type PostCreate = PropsEmployee

    export type PostUploadAvatar = {
      userId: number
      file: File
    }

    export type PostAddWorkplace = {
      userId: number
      data: Omit<UserTypes.Workplace, 'id'>
    }

    export type PostUploadDocuments = {
      userId: number
      data: {
        types: DocumentTypes[]
        files?: File[]
        customNames?: string[]
        customFiles?: File[]
      }
    }

    export type PostPersonal = {
      user: number
      body: UserTypes.Personal
    }

    // Mutations | PATCH
    export type PatchDismiss = {
      userId: number
      body: IDismissUserApi
    }

    export type PatchDocuments = {
      userId: number
      body: IFormDocumentsTab
    }

    export type PatchGeneral = {
      userId: number
      body: IGeneralInfoEditing
    }
  }

  export namespace Response {
    // Queries
    export type GetStatuses = IStatusesResponse

    export type GetInfo = UserTypes.UserInfo

    export type GetBirthday = {
      users: {
        id: number
        surname: string
        name: string
        patronymic: Nullable<string>
        dateBorn: Nullable<string>
      }
    }

    export type GetFullName = UserTypes.FullName

    export type GetGeneral = UserTypes.GeneralTabData

    export type GetEducation = UserEducationResponse

    export type GetEducationLevels = UserTypes.Education

    export type GetDocuments = UserTypes.DocumentsTabData

    export type GetWorkplaces = UserTypes.WorkplacesTabData

    export type GetHardware = Omit<HardwareTypes.Model, 'responsibleUser'>[]

    export type GetAll = ExtendedResponseType<UserTypes.Model>

    export type GetConfirmation = void

    export type GetByDepartment = {
      users: {
        id: number
        fullName: string
      }[]
    }

    export type GetSubScribe = {
      result: boolean
    }

    export type GetAcceptQuestionnaire = void

    // Mutations | POST
    export type PostCreate = { id: number }

    export type PostUploadAvatar = void

    export type PostAddWorkplace = {
      id: number
    }

    export type PostUploadDocuments = number

    export type PostPersonal = void

    // Mutations | PATCH
    export type PatchDismiss = number

    export type PatchDocuments = number

    export type PatchGeneral = number
  }
}
