export enum BenefitType {
  DEVICES = 1,
  COMPONENTS = 2,
  PERIPHERY = 3,
  OFFICE_STUFF = 4,
  BOOKS = 5,
  BAGS = 6,
  HOME_STUFF = 7,
  CASUAL_STUFF = 8,
  ELECTRO = 9,
  TOURISM = 10,
  OFFICE_FOOD = 11,
  SPORT_VOUCHER = 12,
  VMI = 13,
  EDUCATION = 14,
  EXPERIENCE = 15,
}
