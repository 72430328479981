import { UserIdActionTypes, UserIdState, UserIdAction } from 'types/authType'

const initialStateUser: UserIdState = {
  userId: '',
}

export const userIdReducer = (
  state = initialStateUser,
  action: UserIdAction
) => {
  switch (action.type) {
    case UserIdActionTypes.SETUSER: {
      const { payload } = action
      localStorage.setItem('userId', JSON.stringify(payload.userId))

      return {
        ...state,
        userId: payload.userId,
      }
    }
    case UserIdActionTypes.RESETUSER: {
      return {
        ...state,
        userId: '',
      }
    }
    default:
      return state
  }
}
