import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as UnderConsiderationIcon } from 'assets/icons/Applications/statuses/under-consideration.svg'
import { ReactComponent as RejectedIcon } from 'assets/icons/Applications/statuses/rejected.svg'
import { ReactComponent as ClosedIcon } from 'assets/icons/Applications/statuses/closed.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import {
  BenefitApplicationStatuses,
  BenefitApplicationStatusesDescription,
} from 'constants/benefitApplicationStatuses'

interface Props {
  status: BenefitApplicationStatuses
}

const StatusBlock: FC<Props> = ({ status }) => {
  const icons = {
    [BenefitApplicationStatuses.underConsideration]: () => (
      <UnderConsiderationIcon />
    ),
    [BenefitApplicationStatuses.rejected]: () => <RejectedIcon />,
    [BenefitApplicationStatuses.approved]: () => <ApprovedIcon />,
    [BenefitApplicationStatuses.cancel]: () => <ClosedIcon />,
  }

  const labels = {
    [BenefitApplicationStatuses.underConsideration]:
      BenefitApplicationStatusesDescription.underConsideration,
    [BenefitApplicationStatuses.rejected]:
      BenefitApplicationStatusesDescription.rejected,
    [BenefitApplicationStatuses.approved]:
      BenefitApplicationStatusesDescription.approved,
    [BenefitApplicationStatuses.cancel]:
      BenefitApplicationStatusesDescription.cancel,
  }

  return (
    <ST.StatusBlock>
      {icons[status]()}
      <ST.Label>{labels[status]}</ST.Label>
    </ST.StatusBlock>
  )
}

export default StatusBlock
