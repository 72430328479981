import styled, { css, keyframes } from 'styled-components'
import { COLORS } from 'constants/colors'
import { PropsLoader } from 'components/ui/Loader/index'

interface LoaderProps {
  border?: number
}

type LoaderWrapperProps = PropsLoader

const Rotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`

export const Loader = styled.div<LoaderProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  border: ${({ border }) => (border ? border : 4)}px solid ${COLORS.black50};
  border-radius: 50%;
  border-left-color: ${COLORS.yellow};
  animation: ${Rotate} 1s infinite;
`

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: relative;
  width: ${({ width }) => (width ? width : 48)}px;
  height: ${({ height }) => (height ? height : 48)}px;
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${typeof marginTop === 'number'
        ? `${marginTop}px`
        : marginTop};
    `}
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
