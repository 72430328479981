import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

interface IButton {
  typeButton?: 'secondary' | 'order'
  width?: string
  height?: string
}

export const ApplicationsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    align-items: initial;
    width: 100%;

    button {
      width: 100%;
    }
  }
`

export const ApplicationsTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const DepartmentsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DepartmentsBody = styled.div``

export const DepartmentsTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;
`

export const DepartmentsBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FilterBlock = styled.div`
  background: ${COLORS.white};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 24px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  gap: 40px;
`

export const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1439px) {
    gap: 20px;
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button {
      width: 100%;
      margin-left: 0;
    }
  }
`

export const Button = styled.button<IButton>`
  background: ${(props) =>
    props.typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  color: ${COLORS.black};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:enabled:hover {
    background: ${COLORS.yellowActiveButton};
    border: 1px solid ${COLORS.yellowActiveButton};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const ApplicationsBody = styled.div``

export const ApplicationsBlock = styled.div`
  margin-top: 30px;
  > div > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
  }
`
