export enum FormikFields {
  educationForm = 'educationForm',
  educationLvl = 'educationLvl',
  nativeLanguage = 'nativeLanguage',
  directionTraining = 'directionTraining',
  companyName = 'companyName',
  personalSiteLink = 'candidate.personalSiteLink',
  additionalEmail = 'candidate.additionalEmail',
  email = 'email',
  candidateEmail = 'candidate.email',
  additionalPhone = 'candidate.additionalPhone',
  phone = 'phone',
  candidatePhone = 'candidate.phone',
  login = 'login',
  project = 'project',
  targetUser = 'targetUser',
  projectType = 'projectType',
  service = 'service',
  serviceName = 'serviceName',
  commentary = 'commentary',
  candidateComment = 'candidate.comment',
  comment = 'comment',
  date = 'dismissDate',
  reason = 'reason',
  dismissReason = 'dismissReason',
  grade = 'grade',
  taskForReview = 'taskForReview',
  salary = 'salary',
  dateReview = 'dateReview',
  reviewType = 'reviewType',
  duties = 'duties',
  requirements = 'requirements',
  experts = 'experts',
  expertsForSurvey = 'expertsForSurvey',
  address = 'address',
  interviewAddress = 'interview.address',
  interviewDate = 'interview.date',
  interviewCommentary = 'interview.commentary',
  interviewType = 'interview.type',
  interviewTypeData = 'interview.typeDate',
  position = 'position',
  dateDeadline = 'dateDeadline',
  count = 'count',
  hiringType = 'hiringType',
  hiring = 'hiring',
  hireType = 'hireType',
  source = 'source',
  resumeLink = 'resumeLink',
  name = 'name',
  patronymic = 'patronymic',
  surname = 'surname',
  birthday = 'birthday',
  city = 'city',
  sex = 'sex',
  isMarried = 'isMarried',
  isParent = 'isParent',
  story = 'story',
  portfolioLink = 'portfolioLink',
  citizenship = 'citizenship',
  workPermit = 'workPermit',
  readinessForWork = 'readinessForWork',
  priorityType = 'priorityType',
  salaryMin = 'salaryMin',
  salaryMax = 'salaryMax',
  employmentType = 'employmentType',
  location = 'location',
  type = 'type',
  title = 'title',
}
