import { CSSProperties } from 'styled-components'
import { Box, SxProps, TextField } from '@mui/material'
import React from 'react'

export const autocompleteSx = (isMobileDevice: boolean): SxProps => ({
  marginTop: '0 !important',
  '& .MuiInputBase-root': {
    padding: '0 !important',
  },
  '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
    display: 'none !important',
  },
  '& .MuiFormLabel-filled': {
    display: 'none !important',
  },
  '& .MuiFormLabel-filled + div > fieldset > legend': {
    display: 'none !important',
  },
  '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      borderColor: 'transparent',
    },
  '& .MuiFormLabel-root': {
    top: '-8px',
    color: '#606060',
    opacity: '0.5',
    fontFamily: 'Golos, sans-serif',
  },
  '& .MuiAutocomplete-input': {
    ...(isMobileDevice
      ? {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
          fontFamily: 'Golos, sans-serif !important',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '140%',
          color: 'black',
        }
      : {
          paddingLeft: '10px !important',
          fontFamily: 'Golos, sans-serif !important',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '140%',
          color: 'black',
        }),
  },
  '& fieldset': {
    border: 'none !important',
  },
})
export const autocompleteStyle = (isMobileDevice: boolean): CSSProperties =>
  isMobileDevice
    ? {
        display: 'flex',
        alignItems: 'center',
        color: '#000000',
        backgroundColor: '#f8f8f8',
        boxSizing: `border-box`,
        borderRadius: '10px',
        height: '48px',
        fontWeight: '400',
        fontSize: '16px',
        width: '100%',
        fontFamily: 'Golos, sans-serif',
      }
    : {
        color: '#000000',
        backgroundColor: '#f8f8f8',
        padding: '0',
        marginBottom: '18px',
        marginTop: '10px',
        borderRadius: '10px',
        height: '20px',
        fontWeight: '400',
        fontSize: '16px',
        width: '100%',
        fontFamily: 'Golos, sans-serif',
      }

export const autocompleteRenderOptions = (props: any, option: any) => {
  return (
    <Box
      component="li"
      style={{
        backgroundColor: '#fefefe',
        border: 'none',
        color: '#898989',
      }}
      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
      {...props}
      key={option.value}
    >
      {option.item}
    </Box>
  )
}

export const autocompleteRenderInput = (params: any) => (
  <TextField
    style={{
      backgroundColor: '#f8f8f8',
      borderWidth: 0,
      '::placeholder': {
        opacity: '0.5',
      },
    }}
    {...params}
    label="Выберите сотрудника"
  />
)
