import { BreakPoints } from 'constants/breakPoints'
import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { Tags } from 'styles/Tags'

export const TitleLine = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 14px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
`

export const Date = styled.div`
  display: flex;
  align-items: center;
`
export const DeadlineDateText = styled.span`
  margin-right: 11px;
  margin-left: 5px;
  font-weight: 500;
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;
`

export const DeadlineSpan = styled.span`
  color: ${COLORS.black70};
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-right: 5px;
  margin-left: 5px;
`

export const DateText = styled.span`
  margin-left: 8px;
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  > svg {
    margin-left: 6px;
  }
`

export const Expired = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ExpiredText = styled(Tags.p3)`
  color: ${COLORS.black70};
`
