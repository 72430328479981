import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ModalTitles } from 'constants/modalTitles'
import { ButtonLabels } from 'constants/buttonLabels'
import { handlerError } from 'utils/handlerError'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from 'constants/formikFields'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import {
  BenefitOperationTypesLabels,
  listBenefitOperationTypes,
} from 'constants/benefitOperationTypesDescription'
import BaseSelect from 'components/ui/BaseSelect'
import { editBenefit } from 'api/benefits'
import { BenefitTypes } from 'types/model/benefit'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'

interface IDeniedAccess {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  benefitType: BenefitTypes.Model
}

const EditBenefitType = ({
  show,
  onClose,
  showEndModal,
  benefitType,
}: IDeniedAccess) => {
  const handleClose = (): void => {
    onClose(!show)
  }
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik({
      initialValues: benefitType
        ? {
            ...benefitType,
            operationTypeData: {
              value: benefitType.operationType,
              item: BenefitOperationTypesLabels[benefitType.operationType],
            },
          }
        : {
            id: 0,
            type: '',
            operationType: 0,
            operationTypeData: {
              value: 0,
              item: '',
            },
          },
      onSubmit: () => {
        setIsLoading(true)
        editBenefit(values.id, values)
          .then(() => {
            showEndModal?.(true)
            handleClose()
          })
          .finally(() => {
            setIsLoading(false)
          })
      },
      validationSchema: Yup.object().shape({
        type: Yup.string().trim().required(RequiredFields.base),
        operationType: Yup.number().required(RequiredFields.base),
      }),
    })

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  useEffect(() => {
    setSubmitBtnDisabled(!isValid)
  }, [isValid])

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{ModalTitles.editBenefitType}</ST.ModalTitle>
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>
                Вид бенефита<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.ErrorText>{errors.type}</ST.ErrorText>
            </ST.LabelWrapper>
            <ST.Input
              placeholder={Placeholders.type}
              id={FormikFields.type}
              name={FormikFields.type}
              value={values.type}
              onChange={handleChange}
              sizeInput={'big'}
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>
                Тип<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.ErrorText>{errors.operationType}</ST.ErrorText>
            </ST.LabelWrapper>
            <BaseSelect
              isSmallSelect={false}
              placeHolder={'Выбери тип'}
              listItems={listBenefitOperationTypes}
              name={'operationTypeData'}
              value={values?.operationTypeData?.item ?? ''}
              typeSelect={'operationTypeData'}
              passValue={(_, value) => {
                setFieldValue('operationTypeData', {
                  value: value,
                  item: BenefitOperationTypesLabels[
                    value as keyof typeof BenefitOperationTypesLabels
                  ],
                })
                setFieldValue('operationType', value)
              }}
              required
            />
          </ST.InputWrapper>
          <ST.Button
            disabled={submitBtnDisabled}
            onClick={checkForm}
            type={'submit'}
            isDisabled={submitBtnDisabled}
          >
            {ButtonLabels.save}
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default EditBenefitType
