import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import checkboxIcon from 'assets/icons/checkboxIcon.svg'
import QuestSign from 'assets/icons/documents/tooltip.svg'
import { ReactComponent as Cross } from 'assets/icons/cancel.svg'
import { ReactComponent as PlusFileSign } from 'assets/icons/documents/plus-file.svg'
import { BreakPoints } from 'constants/breakPoints'

interface InputWrapperProps {
  isBlur?: boolean
}

interface DisabledProps {
  disabledStyle?: boolean
}

interface HeaderProps {
  question?: boolean
}

interface PlusProps {
  isCursor?: boolean
}

export const PlusFile = styled(PlusFileSign)``

export const PlusContainer = styled.div<PlusProps>`
  > ${PlusFile} {
    cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'auto')};

    rect {
      fill: ${({ isCursor }) => (isCursor ? '' : `${COLORS.blue50}`)};
    }
  }
`

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Star = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

interface HeaderProps {
  question?: boolean
}

interface QuestionProps {
  question?: boolean
}

export const UploadHeader = styled.p<QuestionProps>`
  display: inline-block;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: ${({ question }) => (question ? '0' : '8px')};
`

export const Quest = styled.div`
  cursor: pointer;
  width: 26px;
  height: 26px;
  background: url(${QuestSign});
  margin-top: 3px;
  position: relative;

  &:hover {
    > span {
      visibility: visible;
    }
  }
`

export const TooltipText = styled.span`
  white-space: pre-line;
  visibility: hidden;
  width: 221px;
  background-color: white;
  color: ${COLORS.black};
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: absolute;
  z-index: 1;
  bottom: 77%;
  right: 100%;
  font-size: 14px;
  line-height: 140%;
`

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileNameDeleteContainer = styled.div`
  display: grid;
  grid-template-columns: 7.5fr 0.5fr;
  grid-auto-flow: dense;
  grid-column-gap: 10px;
  margin-bottom: 8px;
`

export const Delete = styled(Cross)`
  cursor: pointer;
  margin-bottom: 4px;
`

export const FileName = styled.p`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${COLORS.black};

  &:last-child {
    margin-bottom: 0;
  }
`

export const HeaderContainer = styled.div<HeaderProps>`
  display: flex;
  margin-bottom: 8px;
  gap: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ActivityLogHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
`

export const ListWrapper = styled.div<{ reverse?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: initial;
  }
`

export const ActivityLogWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > svg {
    cursor: pointer;
  }
`

export const ActivityLogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    width: 100%;
  }
`

export const ActivityLogBody = styled.div`
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.white};
  padding: 60px 80px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 20px;

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 20px;
  }
`

export const ActivityLogTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
    line-height: initial;
  }
`

export const Button = styled.button<DisabledProps>`
  padding: 13px 40px;
  background-color: ${COLORS.yellow};
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};

  &:disabled {
    background-color: ${COLORS.yellow50};
    cursor: default;
  }

  ${({ disabledStyle }) =>
    disabledStyle &&
    css`
      background-color: ${COLORS.yellow50};
    `};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }
`

export const ButtonBordered = styled.button`
  padding: 13px 40px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }
`

export const ActivityLogBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
  border-radius: 10px;
  padding: 27.5px 40px;
  font-size: 18px;
  line-height: 25px;
  min-height: 44px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`

export const LineContent = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  @media (max-width: ${BreakPoints.DESKTOPS_SMALL}) {
    flex-direction: column;
  }
`

export const LeftContent = styled.div`
  width: 100%;
  max-width: 240px;

  @media (max-width: ${BreakPoints.DESKTOPS_SMALL}) {
    display: flex;
    gap: 20px;
  }
  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    width: initial;
    max-width: initial;
    flex-direction: column;
  }
`

export const RightContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`

export const UploadIconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  width: 200px;
`

export const UploadPortfolioWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin-top: -20px;
`

export const UploadWrapper = styled.div`
  width: 100%;
  background: ${COLORS.inputBG};
  border-radius: 12px;
  padding: 20px 20px 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  box-sizing: border-box;

  :nth-child(2) {
    margin-top: 20px;
  }

  @media (max-width: ${BreakPoints.DESKTOPS_SMALL}) {
    height: fit-content;
    :nth-child(2) {
      margin-top: auto;
    }
  }
`

export const WrapperPhoto = styled.div``

export const CandidateImg = styled.img`
  width: 164px;
  height: 164px;
  border-radius: 50%;
  object-fit: cover;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div > input {
    width: 100%;
    max-width: 100%;
  }
`

export const WrapperExpCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  margin-bottom: 8px;

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: initial;
  }
`

export const InputSmallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: 100%;
  }
`

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InputExpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;

  > div > input {
    width: 100%;
    max-width: initial;
  }

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: 100%;
  }
`

export const WorkRangeSelector = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`

export const InputSmallWrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  align-items: end;
  justify-content: end;

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: initial;
    align-items: initial;
  }
`

export const Label = styled.p`
  font-weight: normal;
  width: 100%;
  padding-bottom: 6px;
  display: flex;
  align-items: start;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};

  span {
    margin-bottom: -15px;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const ErrorText = styled.div`
  width: 100%;
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
  text-align: end;
`

export const LeftTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
`

export const BigTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 0;
  white-space: nowrap;
`

export const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 0;
  white-space: nowrap;
`

export const Input = styled.input`
  border-radius: 10px;
  border: none;
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }
`

export const InputNumber = styled(Input)`
  -moz-appearance: textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const CandidateInfo = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 30px;

  @media (max-width: ${BreakPoints.TABLETS}) {
    flex-direction: column;
    align-items: initial;
  }
`

export const CandidateTopInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`

export const CandidateInfoItem = styled.div`
  flex: 1 1 calc(33.333% - 20px);
`

export const TitleList = styled.div<InputWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > svg {
    cursor: pointer;
  }
`

export const ExpInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 30px;

  > div > svg {
    margin-bottom: 10px;
  }

  @media (max-width: ${BreakPoints.TABLETS}) {
    flex-direction: column;
  }
`

export const InputSex = styled.div`
  display: flex;
  gap: 10px 28px;
  flex-wrap: wrap;
  margin-top: 6px;
`

export const SocialNetworkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    flex-direction: column;
    align-items: initial;
  }
`

export const SocialNetworkButton = styled.div`
  margin-top: 10px;
`

export const SocialNetworkInputWrapper = styled.div`
  width: 50%;

  > input {
    width: calc(100% - 40px);
  }

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: 100%;
  }
`

export const Sex = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;

  > label {
    font-size: 16px;
    font-weight: 400;
  }
`

export const ReadinessForWorkCheckbox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;

  > label {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
  }
`

export const ReadinessForWorkCheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

export const ReadinessForWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: initial;
  }
`

export const Checkbox = styled.input`
  position: relative;
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue};

    :before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border: 2px solid white;
      border-radius: 50%;
    }
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const CheckboxSquare = styled.input`
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue} url(${checkboxIcon}) no-repeat center;
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const NoEndWorkLabel = styled.label`
  text-wrap: nowrap;
`

export const WrapperButton = styled.div`
  width: 32%;
`

export const Styled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  > label {
    font-size: 16px;
    font-weight: 400;
  }
`

export const InputWrapperWithDelete = styled.div<InputWrapperProps>`
  width: 500px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;

  > div > input {
    width: 100%;
  }

  > input {
    width: 100%;
  }

  > div > div {
    width: 100%;
  }

  > div > svg {
    opacity: ${({ isBlur }) => (isBlur ? '1' : '0.5')};
    cursor: ${({ isBlur }) => isBlur && 'pointer'};
  }
`

export const WrapperDelete = styled.div<InputWrapperProps>`
  width: 50%;
  margin-top: 28px;

  > svg {
    opacity: ${({ isBlur }) => (isBlur ? '1' : '0.5')};
    cursor: ${({ isBlur }) => isBlur && 'pointer'};
  }

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: initial;
  }
`

export const InputWithDeleteWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
`

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  > div > div {
    border-radius: 10px;

    > div > fieldset {
      border: none;
    }
  }
`

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AddButton = styled.button`
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px 24px 10px 20px;
  border: 1px dashed ${COLORS.blue};
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.blue};
  background: ${COLORS.white};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const LanguageLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${BreakPoints.TABLETS}) {
    display: initial;
  }
`

export const MainInfo = styled.div`
  width: 100%;
`

export const Contacts = styled.div`
  width: 100%;
`

export const WorkPlace = styled.div`
  width: 100%;
`

export const Education = styled.div`
  width: 100%;
`

export const AdditionalEducation = styled.div`
  width: 100%;
`

export const DriverLicense = styled.div`
  width: 100%;
`
export const Languages = styled.div`
  width: 100%;
`

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 15px;
  align-items: baseline;
`
