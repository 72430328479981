import { ISocialNetwork, SocialNetworks } from 'constants/socialNetworks'

export const formatSocialNetworkUrl = (url: string, type: ISocialNetwork) => {
  let prefix = 'https://'
  let postfix = ''
  const regexp = new RegExp(type.pattern)

  switch (type.id) {
    case SocialNetworks.SKYPE:
      prefix = 'skype:'
      postfix = '?chat'
      break
    case SocialNetworks.DISCORD:
      prefix += 'discordapp.com/users/'
      break
    case SocialNetworks.MAIL:
      prefix = 'mailto:'
      break
    case SocialNetworks.INSTAGRAM:
      prefix += 'instagram.com/'
      break
  }

  return !regexp.test(url) ? url : prefix + url + postfix
}
