import { VacanciesNotFoundStyles as ST } from './styled'
import { FC } from 'react'
import EmptyResult from 'components/views/feedback/EmptyResult'

const defaultTitle = 'Вакансии не найдены'
const defaultText = 'Попробуйте изменить критерии поиска'

type Props = {
  title?: string
  text?: string
}

export const VacanciesNotFound: FC<Props> = ({
  title = defaultTitle,
  text = defaultText,
}) => (
  <ST.Wrapper>
    <EmptyResult title={title} description={text} />
  </ST.Wrapper>
)
