import React, { forwardRef, ReactNode, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Modal as ST } from './styled'
import { ReactComponent as CloseIcon } from 'assets/icons/cancel.svg'
import BaseButton from 'components/ui/buttons/BaseButton'
import { ButtonTypes } from 'constants/buttonTypes'
import { CSSProperties } from 'styled-components'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  title?: string
  children: ReactNode
  showCloseButton?: boolean
  disableSubmit?: boolean
  disableAnimation?: boolean
  submitButtonText?: string
  cancelButtonText?: string
  gap?: CSSProperties['gap']
  titleAlign?: 'left' | 'center'
  buttonsDirection?: 'row' | 'column'
  buttonsGap?: CSSProperties['gap']
  fullBodyHeight?: boolean
  style?: CSSProperties
  hideCancelButton?: boolean
}

const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      isOpen,
      onClose,
      onConfirm,
      title,
      children,
      gap,
      showCloseButton = true,
      disableAnimation = false,
      disableSubmit = false,
      submitButtonText = 'Подтвердить',
      cancelButtonText = 'Отмена',
      titleAlign = 'left',
      buttonsDirection = 'row',
      buttonsGap,
      style,
      fullBodyHeight,
      hideCancelButton = false,
      ...props
    },
    ref
  ) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const containerClasses = isOpen ? 'visible' : ''
    const overlayClasses = isOpen ? 'visible' : ''

    const modalContent = (
      <ST.Overlay
        className={overlayClasses}
        disableAnimation={disableAnimation}
      >
        <ST.Container
          {...props}
          className={containerClasses}
          style={style}
          ref={ref ?? modalRef}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
          disableAnimation={disableAnimation}
        >
          {title && <ST.Title align={titleAlign}>{title}</ST.Title>}
          {showCloseButton && (
            <ST.CloseIconContainer onClick={onClose}>
              <CloseIcon />
            </ST.CloseIconContainer>
          )}
          <ST.Body gap={gap} fullHeight={fullBodyHeight}>
            {children}
          </ST.Body>
          <ST.Controls direction={buttonsDirection} gap={buttonsGap}>
            <BaseButton
              typeButton={ButtonTypes.primary}
              text={submitButtonText}
              onClick={onConfirm}
              disabled={disableSubmit}
            />
            {!hideCancelButton && (
              <BaseButton
                typeButton={ButtonTypes.secondary}
                text={cancelButtonText}
                onClick={onClose}
              />
            )}
          </ST.Controls>
        </ST.Container>
      </ST.Overlay>
    )

    return ReactDOM.createPortal(modalContent, document.body)
  }
)
export default Modal
