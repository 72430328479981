import React, { ElementRef, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { handlerError } from 'utils/handlerError'
import * as ST from './styled'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { ModalTitles } from 'constants/modalTitles'
import { FormikFields } from 'constants/formikFields'
import { ButtonLabels } from 'constants/buttonLabels'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { LetterTemplate } from 'types/model/letterTemplate'
import TinyMce from 'components/tinyMce'
import { createLetterTemplate, editLetterTemplate } from 'api/letterTemplate'
import { ErrorMessages } from 'constants/errorMessages'
import { stringNotEmpty } from 'utils/validation/tests'
import BaseButton from 'components/ui/buttons/BaseButton'

type Props = {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  selectedTemplate: LetterTemplate | null
  updateTemplates: () => void
  isEdit: boolean
}

const CreateLetterTemplate = ({
  show,
  onClose,
  showEndModal,
  selectedTemplate,
  updateTemplates,
  isEdit,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)
  const handleClose = (): void => {
    onClose(!show)
  }

  const { handleSubmit, handleChange, values, errors, isValid, setFieldValue } =
    useFormik({
      validateOnMount: true,
      initialValues: {
        name: selectedTemplate?.name ?? '',
        title: selectedTemplate?.title ?? '',
        body: selectedTemplate?.body ?? '',
      } as LetterTemplate,
      onSubmit: () => {
        setIsLoading(true)

        if (isEdit) {
          selectedTemplate?.id &&
            editLetterTemplate(selectedTemplate?.id, values)
              .then(() => {
                updateTemplates()
                showEndModal?.(true)
                handleClose()
              })
              .finally(() => {
                setIsLoading(false)
              })
        } else {
          createLetterTemplate(values)
            .then(() => {
              updateTemplates()
              showEndModal?.(true)
              handleClose()
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      },

      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required(RequiredFields.base)
          .test('name-is-empty', ErrorMessages.requiredField, stringNotEmpty),
        title: Yup.string()
          .required(RequiredFields.base)
          .test('title-is-empty', ErrorMessages.requiredField, stringNotEmpty),
        body: Yup.string()
          .required(RequiredFields.base)
          .test('body-is-empty', ErrorMessages.requiredField, stringNotEmpty),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle style={{ textAlign: 'center' }}>
            {isEdit
              ? ModalTitles.editLetterTemplate
              : ModalTitles.createLetterTemplate}
          </ST.ModalTitle>
          <ST.TopInputWrapper>
            <ST.InputWrapper style={{ width: '50%' }}>
              <ST.LabelWrapper>
                <ST.Label>
                  Название шаблона<ST.Star>*</ST.Star>
                </ST.Label>
              </ST.LabelWrapper>
              <ST.Input
                placeholder={'Оффер'}
                id={FormikFields.name}
                name={FormikFields.name}
                value={values.name}
                onChange={handleChange}
                style={{ width: 'auto' }}
              />
            </ST.InputWrapper>
            <ST.InputWrapper style={{ width: '50%' }}>
              <ST.LabelWrapper>
                <ST.Label>
                  Тема письма<ST.Star>*</ST.Star>
                </ST.Label>
              </ST.LabelWrapper>
              <ST.Input
                placeholder={'Предложите тему письма'}
                id={FormikFields.title}
                name={FormikFields.title}
                value={values.title}
                onChange={handleChange}
                style={{ width: 'auto' }}
              />
            </ST.InputWrapper>
          </ST.TopInputWrapper>
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>
                Текст письма<ST.Star>*</ST.Star>
              </ST.Label>
            </ST.LabelWrapper>

            <TinyMce
              initialValue={values.body}
              setValue={(value: string) => {
                setFieldValue('body', value)
              }}
            />
          </ST.InputWrapper>
          <br />
          <ST.InputWrapper>
            <ST.Label>Поля для автозаполнения из отклика</ST.Label>
            <p>{'{ user.name }'} - Имя кандидата</p>
            <p>{'{ recruiter.contact }'} - Контакты рекрутера</p>
            <p>{'{ vacancy.name }'} - Название вакансии</p>
          </ST.InputWrapper>

          <ST.InputWrapper style={{ marginTop: '20px' }}>
            <BaseButton
              text={isEdit ? ButtonLabels.save : ButtonLabels.add}
              disabled={!isValid}
              onClick={checkForm}
            />
          </ST.InputWrapper>

          <ST.InputWrapper style={{ marginTop: '10px' }}>
            <BaseButton
              text="Отменить"
              typeButton="secondary"
              onClick={handleClose}
            />
          </ST.InputWrapper>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateLetterTemplate
