import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'
import { FIX_CHROME_BLUR } from 'constants/fixChromeBlur'

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: ${FIX_CHROME_BLUR};
  max-width: 580px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
    background: ${COLORS.white};
  }
`

export const ModalContent = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
  margin-bottom: 10px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
    line-height: 140%;
  }
`

export const ModalText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: ${COLORS.black70};
  margin-bottom: 30px;
`

export const ModalBlackText = styled(ModalText)`
  color: ${COLORS.black};
  margin-bottom: 10px;
`

export const ExitButton = styled.button`
  max-width: 240px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${COLORS.yellow};
  height: 48px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    max-width: 540px;
  }
`

export const CancelButton = styled.button`
  max-width: 240px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  height: 48px;
  border-radius: 50px;
  border: 1px solid ${COLORS.yellow};
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    max-width: 540px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    justify-content: initial;
    gap: 20px;
    margin-top: auto;
  }
`

export const Label = styled.p`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`
