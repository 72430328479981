import React, { FC, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { UnexpectedErrorPage } from 'pages/unexpectedErrorPage'

type ErrorBoundaryFallbackProps = {
  error: Error
  componentStack: string
  eventId: string
  resetError(): void
}

const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = ({
  resetError,
}) => {
  const location = useLocation()
  const errorLocation = useRef(location.pathname)

  useLayoutEffect(() => {
    if (location.pathname !== errorLocation.current) resetError()
  }, [location.pathname, resetError])

  return <UnexpectedErrorPage />
}

export default ErrorBoundaryFallback
