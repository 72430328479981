enum Length {
  WITHOUT_CODE = 15,
  WITH_CODE = 18,
  RESULT = 10,
}

export const formatPhone = (
  phone: string | number | null | undefined
): string | null => {
  if (!phone || typeof phone === 'undefined') {
    return null
  }

  const phoneString = phone + ''

  const code = phoneString.slice(0, 1)
  const regionCode = phoneString.slice(1, 4)
  const other = [
    phoneString.slice(4, 7),
    phoneString.slice(7, 9),
    phoneString.slice(9),
  ]

  return `+${code} (${regionCode}) ${other.join('-')}`
}

export const formatPhoneBack = (
  phone: number | string | null | undefined
): number | null =>
  phone && !(typeof phone === 'undefined')
    ? typeof phone === 'number'
      ? phone
      : parseInt(
          phone
            .replaceAll('(', '')
            .replaceAll(' ', '')
            .replaceAll(')', '')
            .replaceAll('-', '')
            .replaceAll('+', '')
        )
    : null
