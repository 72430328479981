import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const DepartmentsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
    align-items: initial;
  }
`

export const DepartmentsBody = styled.div``

export const DepartmentsTitle = styled.p`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};
`

export const Button = styled.button`
  background: #ffdc24;
  border-radius: 50px;
  padding: 13px 43px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background-color: ${COLORS.yellowHover};
  }
`

export const DepartmentsBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: ${BreakPoints.TABLETS}) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

export const DepartmentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.black};
  padding: 0 40px;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  color: ${COLORS.black};
  flex: 1;
`

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 104px;
  gap: 16px;
`

export const Boss = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  color: ${COLORS.black};
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 30px;
`

export const Employee = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  color: ${COLORS.black};
  margin-left: -9px;
`
