import { FormikErrors } from 'formik'

export enum SocialNetworks {
  NOT_SET = 0,
  INSTAGRAM = 1,
  TELEGRAM = 2,
  VKONTAKTE = 3,
  FACEBOOK = 4,
  ODNOKLASSNIKI = 5,
  SKYPE = 6,
  DISCORD = 7,
  TWITTER = 8,
  MAIL = 9,
}

export interface ISocialNetwork {
  id: SocialNetworks
  name: SocialNetworksNames
  pattern: SocialNetworksPatterns
  urlPlaceholder: SocialNetworksUrlPlaceholders
}

export enum SocialNetworksNames {
  NOT_SET = 'Выберите соц.сеть',
  INSTAGRAM = 'Instagram',
  TELEGRAM = 'Telegram',
  VKONTAKTE = 'Вконтакте',
  FACEBOOK = 'Facebook',
  ODNOKLASSNIKI = 'Одноклассники',
  SKYPE = 'Skype',
  DISCORD = 'Discord',
  TWITTER = 'Twitter',
  MAIL = 'Mail',
}

export enum SocialNetworksPatterns {
  NOT_SET = '.*',
  INSTAGRAM = '^(?!.*\\/).+$',
  TELEGRAM = '^t.me/@.+',
  VKONTAKTE = '^vk.com/.*',
  FACEBOOK = '^facebook.com/.+',
  ODNOKLASSNIKI = '^ok.ru/.+',
  SKYPE = '^(?!.*\\/).*$',
  DISCORD = '^[a-zA-zа-яА-я0-9]{2,32}#\\d{4}$',
  TWITTER = '^twitter.com/.+',
  MAIL = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
}

export enum SocialNetworksUrlPlaceholders {
  NOT_SET = '',
  INSTAGRAM = 'Никнейм в Instagram',
  TELEGRAM = 't.me/@user',
  VKONTAKTE = 'vk.com/user',
  FACEBOOK = 'facebook.com/user',
  ODNOKLASSNIKI = 'ok.ru/user',
  SKYPE = 'Логин Skype',
  DISCORD = 'user#1234',
  TWITTER = 'twitter.com/user',
  MAIL = 'Электронная почта',
  FACEBOOK2 = 'facebook.com/id123456789',
}

export const socialNetworksStrings: ISocialNetwork[] = [
  {
    id: 0,
    name: SocialNetworksNames.NOT_SET,
    pattern: SocialNetworksPatterns.NOT_SET,
    urlPlaceholder: SocialNetworksUrlPlaceholders.NOT_SET,
  },
  {
    id: 1,
    name: SocialNetworksNames.INSTAGRAM,
    pattern: SocialNetworksPatterns.INSTAGRAM,
    urlPlaceholder: SocialNetworksUrlPlaceholders.INSTAGRAM,
  },
  {
    id: 2,
    name: SocialNetworksNames.TELEGRAM,
    pattern: SocialNetworksPatterns.TELEGRAM,
    urlPlaceholder: SocialNetworksUrlPlaceholders.TELEGRAM,
  },
  {
    id: 3,
    name: SocialNetworksNames.VKONTAKTE,
    pattern: SocialNetworksPatterns.VKONTAKTE,
    urlPlaceholder: SocialNetworksUrlPlaceholders.VKONTAKTE,
  },
  {
    id: 4,
    name: SocialNetworksNames.FACEBOOK,
    pattern: SocialNetworksPatterns.FACEBOOK,
    urlPlaceholder: SocialNetworksUrlPlaceholders.FACEBOOK,
  },
  {
    id: 5,
    name: SocialNetworksNames.ODNOKLASSNIKI,
    pattern: SocialNetworksPatterns.ODNOKLASSNIKI,
    urlPlaceholder: SocialNetworksUrlPlaceholders.ODNOKLASSNIKI,
  },
  {
    id: 6,
    name: SocialNetworksNames.SKYPE,
    pattern: SocialNetworksPatterns.SKYPE,
    urlPlaceholder: SocialNetworksUrlPlaceholders.SKYPE,
  },
  {
    id: 7,
    name: SocialNetworksNames.DISCORD,
    pattern: SocialNetworksPatterns.DISCORD,
    urlPlaceholder: SocialNetworksUrlPlaceholders.DISCORD,
  },
  {
    id: 8,
    name: SocialNetworksNames.TWITTER,
    pattern: SocialNetworksPatterns.TWITTER,
    urlPlaceholder: SocialNetworksUrlPlaceholders.TWITTER,
  },
  {
    id: 9,
    name: SocialNetworksNames.MAIL,
    pattern: SocialNetworksPatterns.MAIL,
    urlPlaceholder: SocialNetworksUrlPlaceholders.MAIL,
  },
]

export interface INewSocialNetwork {
  userId: number
  name: SocialNetworks | ''
  link: string
}

export interface IOldSocialNetwork {
  id: number
  name: SocialNetworks | ''
  link: string
}

export interface ISocialNetworkError {
  index: number
  errors: FormikErrors<INewSocialNetwork> | FormikErrors<IOldSocialNetwork> | {}
}
