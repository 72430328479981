import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import { IItem } from 'components/ui/BaseSelect'

export enum BenefitOperationTypesDescription {
  refill = 'Начисление',
  withdrawal = 'Списание',
}

export const BenefitOperationTypesLabels = {
  [BenefitOperationTypes.refill]: BenefitOperationTypesDescription.refill,
  [BenefitOperationTypes.withdrawal]:
    BenefitOperationTypesDescription.withdrawal,
}

export const listBenefitOperationTypes: Array<IItem<BenefitOperationTypes>> = [
  {
    item: BenefitOperationTypesDescription.refill,
    value: BenefitOperationTypes.refill,
  },
  {
    item: BenefitOperationTypesDescription.withdrawal,
    value: BenefitOperationTypes.withdrawal,
  },
]

export const listBenefitOperationTypesForUser: Array<IItem> = [
  {
    item: BenefitOperationTypesDescription.withdrawal,
    value: BenefitOperationTypes.withdrawal,
  },
]
