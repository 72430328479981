import { useCallback, useMemo, useState } from 'react'

type UseFiltersReturnType<T extends Record<string, any>> = {
  filters: T
  appliedFilters: T
  setFilters: SetState<T>
  submit: (newValue: T) => void
  clear: () => void
  submitDisabled: boolean
  clearDisabled: boolean
}

const compare = <T>(obj1: T, obj2: T): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2)

const useFilters = <T extends Record<string, any>>(
  initialValue: T
): UseFiltersReturnType<T> => {
  const [filters, setFilters] = useState<T>(initialValue)
  const [appliedFilters, setAppliedFilters] = useState<T>(initialValue)

  const emptyFiltersValue = useMemo<T>(
    () =>
      Object.entries(appliedFilters).reduce(
        (prev, [key]) => ({ ...prev, [key]: null }),
        {} as T
      ),
    [appliedFilters]
  )

  const submitDisabled = useMemo<boolean>(
    () => compare(filters, appliedFilters),
    [appliedFilters, filters]
  )

  const clearDisabled = useMemo<boolean>(
    () =>
      compare(appliedFilters, emptyFiltersValue) ||
      !compare(filters, appliedFilters),
    [appliedFilters, emptyFiltersValue, filters]
  )

  const submit = useCallback<UseFiltersReturnType<T>['submit']>((newValue) => {
    setAppliedFilters(newValue)
    setFilters(newValue)
  }, [])

  const clear = useCallback(() => {
    setAppliedFilters(emptyFiltersValue)
    setFilters(emptyFiltersValue)
  }, [emptyFiltersValue])

  return {
    appliedFilters,
    filters,
    submit,
    setFilters,
    clear,
    submitDisabled,
    clearDisabled,
  }
}

export default useFilters
