import { toast } from 'react-toastify'

export const handlerError = (err: string | unknown): void => {
  // eslint-disable-next-line no-console
  console.log(err)
}

export function setToastError(toaster: string) {
  try {
    const error = () =>
      toast.error(toaster, {
        hideProgressBar: true,
      })
    error()
  } catch (e) {}
}

export function setToastSuccess(toaster: string) {
  try {
    const error = () =>
      toast.success(toaster, {
        hideProgressBar: true,
      })
    error()
  } catch (e) {}
}

export function setToastWarning(toaster: string) {
  try {
    const error = () =>
      toast.warning(toaster, {
        hideProgressBar: true,
      })
    error()
  } catch (e) {}
}

export function setToastInfo(toaster: string) {
  try {
    const error = () =>
      toast.info(toaster, {
        hideProgressBar: true,
      })
    error()
  } catch (e) {}
}
