const HttpMethodsValues = ['GET', 'PUT', 'PATCH', 'POST', 'DELETE'] as const

export type HttpMethodsType = (typeof HttpMethodsValues)[number]

export enum HttpMethods {
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  POST = 'POST',
  DELETE = 'DELETE',
}
