import * as ST from './styled'
import React, { FC } from 'react'
import { DepartmentTypes } from 'types/model/department'
import EditButton from 'components/ui/buttons/EditButton'
import { Link, useNavigate } from 'react-router-dom'
import { PATHS } from 'constants/paths'
import getFileData from 'utils/file/getFileData'

interface Props {
  director: DepartmentTypes.Director
  departmentId?: string
}

const DirectorLine: FC<Props> = ({ departmentId, director }) => {
  const navigate = useNavigate()
  return (
    <ST.Container>
      <ST.InfoContainer>
        <ST.Label>Руководитель:</ST.Label>
        <ST.Photo imageSrc={getFileData(director.userImg)}>
          <ST.StatusCircle statusInOffice />
        </ST.Photo>
        <Link to={`/user/${director.id}`}>
          <ST.Name>
            {director.name} {director.surname}
          </ST.Name>
        </Link>
      </ST.InfoContainer>
      <ST.ControlsContainer>
        <EditButton
          onClick={(e) => {
            e.stopPropagation()
            navigate(`${PATHS.departments}/${departmentId}/edit`)
          }}
        />
      </ST.ControlsContainer>
    </ST.Container>
  )
}

export default DirectorLine
