import {
  ChangeEvent,
  ElementRef,
  FC,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import Modal from 'components/ui/overlay/Modal'
import {
  HiringType,
  HiringTypeExpenseGroup,
  HiringTypeExpenseGroupOptions,
  HiringTypeExpenseType,
} from 'types/model/hiringType'
import { Tags } from 'styles/Tags'
import { FormikProps, useFormik } from 'formik'
import { HiringTypePost, patchHiringType, postHiringType } from 'api/hiringType'
import BaseInput from 'components/ui/inputs/BaseInput'
import { HiringTypesModalStyles as ST } from './styled'
import BaseRadio, { RadioGroup } from 'components/ui/radio-buttons/BaseRadio'
import { ReactComponent as PlusIcon } from 'assets/icons/blue-plus.svg'
import DashedButton from 'components/ui/buttons/DashedButton'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { ErrorMessages } from 'constants/errorMessages'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit?: (newValue: HiringType) => void
  type: 'edit' | 'create'
  data: HiringType | null
}

type Form = {
  name: string
  expenses: FormExpense[]
}

type FormGroupProps = {
  values: Form['expenses']
  group: HiringTypeExpenseGroup
  handleChange: FormikProps<Form>['handleChange']
  handleBlur: FormikProps<Form>['handleBlur']
  setFieldValue: FormikProps<Form>['setFieldValue']
  errors: Partial<{
    name: string | undefined
    expenses: Record<keyof FormExpense, string | undefined>[]
  }>
  touched: Partial<{
    name: boolean | undefined
    expenses: Record<keyof FormExpense, boolean | undefined>[]
  }>
  handleRemove: (index: number) => void
  handleAdd: (group: HiringTypeExpenseGroup) => void
}

type FormInputProps = {
  label: string
  field: keyof FormExpense
  value: FormExpense
  type?: 'number' | 'text'
  handleChange?: FormikProps<Form>['handleChange']
  handleBlur: FormikProps<Form>['handleBlur']
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  getKey: (key: keyof FormExpense) => string
  getFieldError: (key: keyof FormExpense) => string | undefined
  hasAddButton?: boolean
}

type FormExpense = ExtractFromArray<HiringTypePost['expenses']>

const getInitialFormExpense = (group: HiringTypeExpenseGroup): FormExpense => ({
  expenseGroup: group,
  name: null,
  type: HiringTypeExpenseType.PERCENT,
  value: null,
  number: null,
})

const initialValues: Form = {
  name: '',
  expenses: [
    getInitialFormExpense(HiringTypeExpenseGroup.GENERAL),
    getInitialFormExpense(HiringTypeExpenseGroup.FROM_SALARY),
    getInitialFormExpense(HiringTypeExpenseGroup.OVER_SALARY),
  ],
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(RequiredFields.base),
  expenses: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable().required(RequiredFields.base),
      type: Yup.string().required(RequiredFields.base),
      value: Yup.number()
        .nullable()
        .positive(ErrorMessages.positiveValue)
        .required(RequiredFields.base),
      number: Yup.number()
        .nullable()
        .positive(ErrorMessages.positiveValue)
        .required(RequiredFields.base),
    })
  ),
})

type FormGroupValue = {
  index: number
  value: FormExpense
}

const FormInput: FC<FormInputProps> = ({
  getKey,
  field,
  label,
  getFieldError,
  value,
  handleChange,
  handleBlur,
  onChange,
  hasAddButton,
  type = 'text',
}) => {
  const fieldKey = getKey(field)

  const stringValue = useMemo<string>(() => {
    const v = value[field]

    return v && typeof v === 'string' ? v : String(v ?? '')
  }, [field, value])

  return (
    <BaseInput
      id={fieldKey}
      type={type}
      value={stringValue}
      onChange={onChange ?? handleChange?.(fieldKey)}
      label={label}
      onBlur={handleBlur}
      placeholder={`Введите ${label.toLowerCase()}`}
      error={getFieldError(field)}
      containerStyle={
        hasAddButton ? { maxWidth: 'calc(50% - 50px)' } : undefined
      }
      required
    />
  )
}

const FormGroup = memo<FormGroupProps>(
  ({
    values,
    group,
    handleAdd,
    handleRemove,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  }) => {
    const expensesData = useMemo<FormGroupValue[]>(
      () =>
        values
          .map((e, i) => ({ index: i, value: e }))
          .filter((v) => v.value.expenseGroup === group),
      [group, values]
    )

    return (
      <ST.GroupContainer>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Tags.p2Bold>
          {group}.{' '}
          {HiringTypeExpenseGroupOptions.find((g) => g.value === group)?.item}
        </Tags.p2Bold>
        <ST.Group>
          {expensesData.map(({ index, value }, i, array) => {
            const getKey = (key: keyof FormExpense) =>
              `expenses[${index}].` + key

            const getFieldError = (
              key: keyof FormExpense
            ): string | undefined =>
              touched.expenses?.[index]?.[key]
                ? errors?.expenses?.[index]?.[key]
                : undefined

            const hasAddButton = i === array.length - 1

            return (
              <ST.GroupItem key={`group-${group}-${index}`}>
                {i !== 0 && <ST.Close onClick={() => handleRemove(index)} />}

                <ST.GroupItemRow>
                  <FormInput
                    getKey={getKey}
                    getFieldError={getFieldError}
                    value={value}
                    field="name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    label="Название"
                  />

                  <RadioGroup label="Тип" required>
                    <BaseRadio
                      id={getKey('type') + '-percent'}
                      value={HiringTypeExpenseType.PERCENT}
                      onChange={handleChange(getKey('type'))}
                      checked={value.type === HiringTypeExpenseType.PERCENT}
                      label="Процент"
                    />
                    <BaseRadio
                      id={getKey('type') + '-fixed'}
                      value={HiringTypeExpenseType.FIXED}
                      checked={value.type === HiringTypeExpenseType.FIXED}
                      onChange={handleChange(getKey('type'))}
                      label="Фикс"
                    />
                  </RadioGroup>
                </ST.GroupItemRow>

                <ST.GroupItemRow>
                  <FormInput
                    type="number"
                    getKey={getKey}
                    getFieldError={getFieldError}
                    value={value}
                    field="value"
                    handleBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        getKey('value'),
                        e.target.value ? Math.max(+e.target.value, 0) : null
                      )
                    }}
                    hasAddButton={hasAddButton}
                    label="Значение"
                  />
                  <FormInput
                    type="number"
                    field="number"
                    getKey={getKey}
                    getFieldError={getFieldError}
                    value={value}
                    handleBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        getKey('number'),
                        e.target.value ? Math.max(+e.target.value, 0) : null
                      )
                    }}
                    hasAddButton={hasAddButton}
                    label="Номер списания"
                  />

                  {hasAddButton && (
                    <DashedButton
                      onClick={() => handleAdd(group)}
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <PlusIcon />
                    </DashedButton>
                  )}
                </ST.GroupItemRow>
              </ST.GroupItem>
            )
          })}
        </ST.Group>
      </ST.GroupContainer>
    )
  }
)

const getInitialValues = (
  data: Form['expenses'],
  group: HiringTypeExpenseGroup
) =>
  data.find((v) => v.expenseGroup === group)
    ? data.filter((v) => v.expenseGroup === group)
    : initialValues.expenses.filter((v) => v.expenseGroup === group)

const HiringTypesModal: FC<Props> = ({
  open,
  onClose,
  type,
  data,
  onSubmit,
}) => {
  const modalRef = useRef<ElementRef<'div'>>(null)
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  const {
    values,
    setFieldValue,
    isValid,
    resetForm,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
  } = useFormik<Form>({
    validateOnMount: true,
    validateOnChange: true,
    initialValues:
      type === 'edit'
        ? {
            name: data?.name ?? '',
            expenses: data?.expenses?.length
              ? [
                  ...getInitialValues(
                    data.expenses,
                    HiringTypeExpenseGroup.GENERAL
                  ),
                  ...getInitialValues(
                    data.expenses,
                    HiringTypeExpenseGroup.OVER_SALARY
                  ),
                  ...getInitialValues(
                    data.expenses,
                    HiringTypeExpenseGroup.FROM_SALARY
                  ),
                ]
              : initialValues.expenses,
          }
        : initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (_values, helpers) => {
      setSubmitDisabled(true)
      const dto = {
        ..._values,
        expenses: _values.expenses.map((e) => ({
          ...e,
          value: +e.value!,
          number: +e.number!,
        })),
      }
      const save =
        type === 'edit' && data?.id
          ? patchHiringType(data.id, dto)
          : postHiringType(dto)

      save
        .then((res) => {
          onClose()
          onSubmit?.(res)
          helpers.resetForm()
        })
        .finally(() => {
          setSubmitDisabled(false)
        })
    },
  })

  const handleAdd = useCallback(
    (group: HiringTypeExpenseGroup) => {
      setFieldValue('expenses', [
        ...values.expenses,
        getInitialFormExpense(group),
      ])
    },
    [setFieldValue, values.expenses]
  )

  const handleRemove = useCallback(
    (index: number) => {
      setFieldValue(
        'expenses',
        values.expenses.filter((e, i) => i !== index)
      )
    },
    [setFieldValue, values.expenses]
  )

  const groupProps = useMemo(
    () => ({
      handleAdd,
      handleRemove,
      handleChange,
      handleBlur,
      setFieldValue,
      touched: touched as FormGroupProps['touched'],
      values: values.expenses,
      errors: errors as FormGroupProps['errors'],
    }),
    [
      errors,
      touched,
      handleAdd,
      handleChange,
      handleBlur,
      handleRemove,
      setFieldValue,
      values.expenses,
    ]
  )

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      onConfirm={handleSubmit}
      ref={modalRef}
      disableSubmit={!isValid || submitDisabled}
      style={{ width: 580, maxHeight: '80%' }}
      title={`${type === 'edit' ? 'Редактирование' : 'Создание'} типа найма`}
      gap={20}
      submitButtonText="Сохранить"
      hideCancelButton
    >
      <BaseInput
        value={values.name}
        onChange={handleChange('name')}
        label="Название"
        error={touched.name ? errors.name : undefined}
        placeholder="Введите название"
        required
      />

      <FormGroup {...groupProps} group={HiringTypeExpenseGroup.GENERAL} />

      <FormGroup {...groupProps} group={HiringTypeExpenseGroup.OVER_SALARY} />

      <FormGroup {...groupProps} group={HiringTypeExpenseGroup.FROM_SALARY} />
    </Modal>
  )
}

export default HiringTypesModal
