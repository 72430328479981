import styled from 'styled-components'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import { ReactComponent as Cross } from 'assets/icons/cancel.svg'
import { ReactComponent as PlusFileSign } from 'assets/icons/documents/plus-file.svg'
import { InputSizes } from 'constants/inputSizes'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const MainContainer = styled.div``

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

export const Modal = styled.div`
  overflow: auto;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 580px;
  width: 100%;
  max-height: 100%;
  background: ${COLORS.white};
  border-radius: 15px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    border-radius: initial;
    height: 100%;
  }
`

export const ModalContent = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
`

export const ModalTitle = styled.p`
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.26px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`

export const Label = styled.p`
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: start;
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 3px;
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const HeaderLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const LabelName = styled(HeaderLabel)``
export const LabelValue = styled(HeaderLabel)`
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Star = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.errorRed};
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  ${(props) =>
    props.sizeInput === InputSizes.big
      ? 'max-width: 500px'
      : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }
  :disabled {
    color: ${COLORS.black70};
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    height: 48px;
    padding: 14px 16px 12px;
  }
`

export const InputTextArea = styled.textarea<IInput>`
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.sizeInput === 'big' ? 'max-width: 500px' : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  height: 140px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  ::placeholder {
    opacity: 0.5;
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    padding: 14px 16px 12px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputSmallWrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  @media (max-width: ${BreakPoints.TABLETS}) {
    width: initial;
    align-items: initial;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-of-type {
    margin-top: 24px;
  }

  .MuiAutocomplete-listbox {
    scrollbar-width: thin !important;
    scrollbar-color: transparent ${COLORS.inputBG} !important;
    ::-webkit-scrollbar {
      width: 3px !important;
    }
    ::-webkit-scrollbar-track {
      background: ${COLORS.inputBG} !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${COLORS.inputBG} !important;
      border-radius: 20px !important;
    }
    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: gray !important;
      }
      scrollbar-color: gray ${COLORS.inputBG} !important;
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 18px 0 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin: 10px 0 0;
  }
`

export const InputsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  flex-direction: column;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    margin: 40px 0 0 0;
  }
`

export const DownloadLinkBlock = styled.div`
  display: flex;
  align-items: center;
`

export const SalaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
`

export const OutputBlock = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`

export const UploadBlock = styled.div``

export const DownloadLink = styled.div`
  font-size: 16px;
  line-height: 140%;
  color: ${COLORS.black};
  :hover {
    cursor: pointer;
  }
`

export const AttachButton = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: ${COLORS.blue};
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
`

export const PlusFile = styled(PlusFileSign)``

export const PlusContainer = styled.div`
  > ${PlusFile} {
    cursor: pointer;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin-top: 32px;
`

export const FileNameDeleteContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
`

export const ErrorText = styled.div`
  color: ${COLORS.errorRed};
  font-size: 14px;
  line-height: 20px;
`

export const Delete = styled(Cross)`
  cursor: pointer;
`

export const FileName = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${COLORS.black};
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const FileError = styled.div`
  color: ${COLORS.errorRed};
`

export const Select = styled.input`
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }
`

export const InputWrapperWithDelete = styled.div`
  width: 500px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;

  > div > input {
    width: 100%;
  }

  > input {
    width: 100%;
  }

  > div > div {
    width: 100%;
  }
`

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AddButton = styled.button`
  width: max-content;
  display: flex;
  gap: 4px;
  margin-top: 10px;
  align-items: center;
  padding: 10px 24px 10px 20px;
  border: 1px dashed ${COLORS.blue};
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: ${COLORS.blue};
  background: ${COLORS.white};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`
