import { baseApi } from 'store/api/baseApi'
import { RtkReviewApiTypes as T } from 'store/api/review/types'

const DEFAULT_TAG_TYPE = 'Review' as const

export const reviewApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Queries | GET
    getReviews: builder.query<T.Response.GetAll, T.Request.GetAll>({
      query: ({ page, ...queryArgs }) => {
        const params = new URLSearchParams()

        Object.entries(queryArgs).forEach(([key, value]) => {
          value !== null &&
            value !== undefined &&
            params.append(key, String(value))
        })

        page && params.append('pageNumber', String(page))

        return { url: 'review', params, method: 'GET' }
      },
      providesTags: (result, error, { page }) => [
        { type: DEFAULT_TAG_TYPE, id: page },
      ],
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newData, { arg }) =>
        arg.page === 1
          ? newData
          : {
              ...newData,
              data: [...currentCache.data, ...newData.data],
            },
      forceRefetch: ({ previousArg, currentArg }) =>
        previousArg && currentArg
          ? JSON.stringify(previousArg) !== JSON.stringify(currentArg)
          : false,
    }),
    getReviewByUser: builder.query<T.Response.GetByUser, T.Request.GetByUser>({
      query: (queryArgs) => {
        const params = new URLSearchParams()

        for (let [key, value] of Object.entries(queryArgs)) {
          value && params.append(key, value.toString())
        }

        return { url: 'review/user', params, method: 'GET' }
      },
      providesTags: (result, error, { userId }) => [
        { type: DEFAULT_TAG_TYPE, id: userId ?? undefined },
      ],
    }),
    getReviewById: builder.query<T.Response.GetById, T.Request.GetById>({
      query: (id) => ({
        url: `review/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: DEFAULT_TAG_TYPE, id }],
    }),
    getNextReviewTasksByUserId: builder.query<
      T.Response.GetNextReviewTasks,
      T.Request.GetNextReviewTasks
    >({
      query: (userId) => ({
        url: `review/next-review-tasks/${userId}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: DEFAULT_TAG_TYPE, id }],
    }),
    getSurveyById: builder.query<
      T.Response.GetSurveyById,
      T.Request.GetSurveyById
    >({
      query: (surveyId) => ({
        url: `review/survey/${surveyId}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: DEFAULT_TAG_TYPE, id }],
    }),
    getSurvey360ResultByReviewId: builder.query<
      T.Response.GetSurvey360Result,
      T.Request.GetSurvey360Result
    >({
      query: (reviewId) => ({
        url: `review/survey-result/${reviewId}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: DEFAULT_TAG_TYPE, id }],
    }),
    getReviewTypes: builder.query<T.Response.GetTypes, void>({
      query: () => ({ url: 'review/type', method: 'GET' }),
      providesTags: [{ type: DEFAULT_TAG_TYPE, id: 'LIST' }],
    }),

    // Mutations | POST
    createReview: builder.mutation<
      T.Response.PostCreateReview,
      T.Request.PostCreateReview
    >({
      query: (body) => ({ url: 'review', body, method: 'POST' }),
      invalidatesTags: [{ type: DEFAULT_TAG_TYPE, id: 'LIST' }],
    }),

    // Mutations | PATCH
    editReview: builder.mutation<
      T.Response.PatchEditReview,
      T.Request.PatchEditReview
    >({
      query: ({ reviewId, ...body }) => ({
        url: 'review',
        body,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { reviewId }) => [
        { type: 'Resume', id: reviewId },
      ],
    }),
    cancelReview: builder.mutation<
      T.Response.PatchCancelReview,
      T.Request.PatchCancelReview
    >({
      query: ({ reviewId, comment }) => {
        const params = new URLSearchParams()

        comment?.length && params.append('comment', comment)

        return { url: `review/cancel/${reviewId}`, params, method: 'PATCH' }
      },
      invalidatesTags: (result, error, { reviewId }) => [
        { type: 'Resume', id: reviewId },
      ],
    }),
    finishReview: builder.mutation<
      T.Response.PatchFinishReview,
      T.Request.PatchFinishReview
    >({
      query: ({ reviewId, ...body }) => ({
        url: `review/skills/${reviewId}`,
        body,
        method: 'PATCH',
      }),
    }),
    writeSurveyAnswers: builder.mutation<
      T.Response.PatchWriteAnswers,
      T.Request.PatchWriteAnswers
    >({
      query: ({ reviewId, ...body }) => ({
        url: `review/survey/complete/${reviewId}`,
        body,
        method: 'PATCH',
      }),
    }),
  }),
})

export const {
  useGetReviewsQuery,
  useGetReviewByUserQuery,
  useGetReviewByIdQuery,
  useGetSurveyByIdQuery,
  useGetNextReviewTasksByUserIdQuery,
  useGetSurvey360ResultByReviewIdQuery,
  useGetReviewTypesQuery,

  useCreateReviewMutation,

  useEditReviewMutation,
  useCancelReviewMutation,
  useFinishReviewMutation,
  useWriteSurveyAnswersMutation,
} = reviewApi
