import { IItem } from 'components/ui/BaseSelect'
import { HiringLanguagesLevelEnum } from 'api/hiring/hiringEnums'

export enum HiringLanguagesLevelTypes {
  beginner = 'А1 — Начальный',
  elementary = 'А2 — Элементарный',
  intermediate = 'В1 — Средний',
  upperIntermediate = 'В2 — Ср.-продвинутый',
  advanced = 'С1 — Продвинутый',
  proficiency = 'С2 — В совершенстве',
}

export const HiringLanguagesLevelTypesLabels = {
  [HiringLanguagesLevelEnum.beginner]: HiringLanguagesLevelTypes.beginner,
  [HiringLanguagesLevelEnum.elementary]: HiringLanguagesLevelTypes.elementary,
  [HiringLanguagesLevelEnum.intermediate]:
    HiringLanguagesLevelTypes.intermediate,
  [HiringLanguagesLevelEnum.upperIntermediate]:
    HiringLanguagesLevelTypes.upperIntermediate,
  [HiringLanguagesLevelEnum.advanced]: HiringLanguagesLevelTypes.advanced,
  [HiringLanguagesLevelEnum.proficiency]: HiringLanguagesLevelTypes.proficiency,
}

export const listHiringLanguagesLevelTypes: Array<
  IItem<HiringLanguagesLevelEnum, HiringLanguagesLevelTypes>
> = [
  {
    item: HiringLanguagesLevelTypes.beginner,
    value: HiringLanguagesLevelEnum.beginner,
  },
  {
    item: HiringLanguagesLevelTypes.elementary,
    value: HiringLanguagesLevelEnum.elementary,
  },
  {
    item: HiringLanguagesLevelTypes.intermediate,
    value: HiringLanguagesLevelEnum.intermediate,
  },
  {
    item: HiringLanguagesLevelTypes.upperIntermediate,
    value: HiringLanguagesLevelEnum.upperIntermediate,
  },
  {
    item: HiringLanguagesLevelTypes.advanced,
    value: HiringLanguagesLevelEnum.advanced,
  },
  {
    item: HiringLanguagesLevelTypes.proficiency,
    value: HiringLanguagesLevelEnum.proficiency,
  },
]
