import { useCallback } from 'react'
import useLocalstorage from 'hooks/useLocalstorage'
import { PATHS } from 'constants/paths'

const key = 'integrationsRedirectUrls'

type UseIntegrationsRedirectHook = (page?: PATHS) => {
  value: PATHS | null
  setValue: SetState<PATHS | null>
  handleClick: () => void
  storageKey: string
}

const useIntegrationsRedirect: UseIntegrationsRedirectHook = (page) => {
  const [storedUrls, setStoredUrls] = useLocalstorage<PATHS | null>(key, null)

  const handleClick = useCallback(() => {
    if (page) setStoredUrls(page)
  }, [page, setStoredUrls])

  return {
    value: storedUrls,
    setValue: setStoredUrls,
    handleClick,
    storageKey: key,
  }
}

export default useIntegrationsRedirect
