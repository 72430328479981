import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as LogoIcon } from 'assets/icons/logoHeader.svg'
import { BreakPoints } from 'constants/breakPoints'

interface IGridProps {
  columns?: number
}

interface IInfoBlockButtonProps {
  actionType: 'cancel' | 'submit'
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const GridBlock = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-auto-flow: dense;
`

export const BlockInfo = styled.div`
  padding: 24px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
`

export const TunesItem = styled(BlockInfo)``

export const InfoBlockButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

export const InfoBlockButton = styled.button<IInfoBlockButtonProps>`
  display: grid;
  place-items: center;
  padding: 0;
  border-radius: 50%;

  width: 22px;
  height: 22px;

  ${({ actionType }) =>
    actionType === 'submit' &&
    css`
      background-color: ${COLORS.yellow};
    `};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

export const RowInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  & > svg {
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
  }
`

export const HeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  @media (max-width: ${BreakPoints.NOTEBOOK}) {
    font-size: 16px;
  }
`

export const RowInfo = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Link = styled.a``

export const ServiceWrapper = styled(BlockInfo)`
  box-shadow: none;
  padding: 0;
`
export const Service = styled(BlockInfo)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ServiceModeWrapper = styled.div`
  cursor: pointer;
`

export const LogoHeaderBlock = styled(HeaderBlock)`
  margin-bottom: 0;
`

export const LogoWrapper = styled(BlockInfo)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LogoWithButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 16px;
`

export const LogoMask = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 2px));
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  background-color: ${COLORS.gray20};
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s linear;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const LogoContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${LogoMask} {
    opacity: 0.5;
  }
`

export const DefaultLogo = styled(LogoIcon)``

export const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`
