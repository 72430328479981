import { Action } from 'redux'

export enum SystemActionTypes {
  SET_MODE = 'SET_MODE',
}

export enum SystemMode {
  ACTIVE = 'active',
  SERVICE = 'service',
  BLOCKED = 'blocked',
}

export type SystemState = {
  mode: SystemMode
}

interface SetMode extends Action {
  type: SystemActionTypes.SET_MODE
  payload: SystemMode
}

export type SystemAction = SetMode
