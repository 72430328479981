import * as ST from './styled'
import React, { FC } from 'react'
import EditButton from 'components/ui/buttons/EditButton'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'constants/paths'

interface Props {
  positionId: string
  positionName: string
}

const PositionLine: FC<Props> = ({ positionId, positionName }) => {
  const navigate = useNavigate()
  const goToEditPosition = () => {
    navigate(`${PATHS.positions}/${positionId}/edit`)
  }
  return (
    <>
      <ST.Container>
        <ST.InfoContainer>
          <ST.Label>Должность:</ST.Label>
          <ST.Name>{positionName}</ST.Name>
        </ST.InfoContainer>
        <ST.ControlsContainer>
          <EditButton onClick={goToEditPosition} />
        </ST.ControlsContainer>
      </ST.Container>
    </>
  )
}

export default PositionLine
