import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError } from 'utils/handlerError'
import { RequiredFields } from 'constants/requiredFields'
import { useCreateGradeMutation } from 'store/api/grade'
import BaseInput from 'components/ui/inputs/BaseInput'
import BaseButton from 'components/ui/buttons/BaseButton'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
}

interface ICreateGrade {
  gradeName: string
  bonus: string | null
}

const inputName = 'gradeName'

const CreateGrade: FC<Props> = ({ show, onClose, showEndModal }) => {
  const [createGrade] = useCreateGradeMutation()
  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    resetForm,
    handleBlur,
    touched,
    isValid,
  } = useFormik<ICreateGrade>({
    validateOnMount: true,
    initialValues: {
      gradeName: '',
      bonus: null,
    },
    onSubmit: (data, { resetForm: reset }) => {
      createGrade({
        name: data.gradeName,
        recruiterPremium: +data.bonus!.split(' ').join(''),
      })
        .then(() => {
          reset()
          handleClose()
        })
        .catch((err) => handlerError(err))
        .finally(() => {
          showEndModal?.(true)
        })
    },
    validationSchema: Yup.object().shape({
      gradeName: Yup.string().required(RequiredFields.base),
      bonus: Yup.number().nullable().required(RequiredFields.base),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const handleClose = (): void => {
    onClose(!show)
    resetForm()
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>Создание грейда</ST.ModalTitle>
          <ST.InputsBlock>
            <BaseInput
              value={values.gradeName}
              label="Название грейда"
              id={inputName}
              name={inputName}
              placeholder="Введите название"
              onChange={handleChange}
              required
            />
            <BaseInput
              thousandsGroupStyle="thousand"
              thousandSeparator=" "
              value={values.bonus ?? ''}
              type="number"
              label="Премия для отдела рекрутинга, руб"
              placeholder="Введите сумму"
              id="bonus"
              name="bonus"
              onChange={handleChange}
              required
            />
          </ST.InputsBlock>
          <BaseButton onClick={checkForm} text="Создать" disabled={!isValid} />
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateGrade
