import React, { useEffect, useState } from 'react'
import * as ST from './styled'
import PositionBlock from './PositionBlock'
import { getPositions } from 'api/position'
import { handlerError } from 'utils/handlerError'
import { PositionTypes } from 'types/model/position'
import CreatePosition from 'components/dialogs/CreatePosition'
import { useModalWindowState } from 'hooks/useModalWindowState'
import BaseButton from 'components/ui/buttons/BaseButton'
import Loader from 'components/ui/Loader'

const Positions = () => {
  const [positions, setPositions] = useState<PositionTypes.Model[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { modalOpen, toggleModal } = useModalWindowState()

  const updatePositions = () => {
    getPositions()
      .then((data) => setPositions(data))
      .catch((err) => handlerError(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    updatePositions()
  }, [])

  return (
    <>
      <ST.PositionsHead>
        <ST.PositionsTitle>Должности</ST.PositionsTitle>
        <BaseButton
          text="Создать должность"
          onClick={toggleModal}
          disabled={isLoading}
          width={240}
          height={40}
        />
      </ST.PositionsHead>
      <ST.PositionsBody>
        {isLoading ? (
          <Loader marginTop={100} />
        ) : (
          <ST.PositionsBlock>
            {positions.map((item) => (
              <PositionBlock
                key={item.id}
                positionInfo={item}
                updatePositions={updatePositions}
              />
            ))}
          </ST.PositionsBlock>
        )}
      </ST.PositionsBody>
      <CreatePosition
        show={modalOpen}
        onClose={toggleModal}
        updatePositions={updatePositions}
      />
    </>
  )
}

export default Positions
