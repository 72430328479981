import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

export const setupInterceptors = (
  axiosInstance: AxiosInstance,
  token: string,
  errorHandler: (error: AxiosError) => void
): number[] => {
  const reqInterceptor = axiosInstance.interceptors.request.use(
    (cfg: AxiosRequestConfig) => {
      return Promise.resolve({
        ...cfg,
        headers: token ? { ...cfg.headers, apiKey: token } : cfg.headers,
      })
    }
  )

  const resInterceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      errorHandler(error)
      return Promise.reject(error)
    }
  )

  return [reqInterceptor, resInterceptor]
}
