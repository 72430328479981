import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { IDocumentTemplate } from 'types/model/documentTemplateType'

interface Props {
  show: boolean
  title: string
  onClose: (isShow: boolean) => void
  handleConfirm?: (id: number) => void
  doc: IDocumentTemplate
}

const DeleteSection: FC<Props> = ({
  show,
  title,
  onClose,
  handleConfirm,
  doc,
}) => {
  const handleClose = (): void => {
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{title}</ST.ModalTitle>
          <ST.Description>Удалить документ "{doc.name}"?</ST.Description>
          <ST.ButtonsBlock>
            <ST.Button
              onClick={() => {
                handleConfirm && handleConfirm(doc.id)
                handleClose()
              }}
            >
              Удалить
            </ST.Button>
            <ST.Button onClick={handleClose} typeButton={'secondary'}>
              Отмена
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
