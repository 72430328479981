import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as NotificationIcon } from 'assets/icons/notificationIcon.svg'
import { ReactComponent as NotificationHas } from 'assets/icons/notificationHasIcon.svg'
import Notifications from 'components/Header/Notifications'
import { getNotifications, getUserPage, removeNotification } from 'api/userPage'
import { useLocation, useNavigate } from 'react-router-dom'
import { handlerError } from 'utils/handlerError'
import defaultProfileImage from 'constants/defaultProfileImage'
import { UserTypes } from 'types/model/user'
import { ExtendedResponseType } from 'types/utils'
import { useAuth } from 'hooks/useAuth'
import getFileData from 'utils/file/getFileData'

const MobileNavBar: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const { userId } = useAuth()
  const [userImg, setUserImg] = useState(defaultProfileImage)
  const [notifications, setNotifications] =
    useState<ExtendedResponseType<UserTypes.Notification> | null>(null)

  const handleNotifications = () => {
    setShowNotifications((prevState) => !prevState)
  }

  useEffect(() => {
    if (userId) {
      getUserPage(userId)
        .then((resp) => {
          setUserImg(getFileData(resp.userImg))
        })
        .catch((err) => {
          handlerError(err)
        })

      getNotifications(userId).then((resp) => {
        setNotifications(resp)
      })
    }
  }, [userId])

  const loadNextPage = useCallback(() => {
    if (userId) {
      getNotifications(userId, {
        pageNumber: (notifications?.currentPageNumber ?? 0) + 1,
      }).then((res) => {
        setNotifications((prevState) => ({
          ...res,
          data: [...(prevState?.data ?? []), ...res.data],
        }))
      })
    } else {
      setNotifications(null)
    }
  }, [notifications?.currentPageNumber, userId])

  const hasMoreNotifications = useMemo(
    () =>
      notifications
        ? notifications.currentPageNumber < notifications.pageCount
        : false,
    [notifications]
  )

  const removeNotificationById = (id: number) => {
    if (userId) {
      removeNotification(userId, id)
      setNotifications((prevState) => {
        return {
          ...prevState,
          data: prevState?.data.filter(
            (notification) => notification.id !== id
          ),
        } as ExtendedResponseType<UserTypes.Notification>
      })
      if (notifications?.data && notifications?.data.length < 5) {
        loadNextPage()
      }
    }
  }

  const goToPage = (url: string): void => {
    navigate(url)
  }

  return (
    <>
      <ST.Container>
        <ST.Content>
          <ST.NavItem onClick={() => goToPage('/')}>
            <ST.OwnerImg isActive={location.pathname === '/'} src={userImg} />
            <ST.NavItemTitle>Мой профиль</ST.NavItemTitle>
          </ST.NavItem>
          <ST.NavItem onClick={() => goToPage('/employees')}>
            <ST.EmployeesIcon isActive={location.pathname === '/employees'} />
            <ST.NavItemTitle>Сотрудники</ST.NavItemTitle>
          </ST.NavItem>
          <ST.NavItem onClick={handleNotifications}>
            <ST.NotificationButton isActive={showNotifications}>
              <NotificationIcon />
              {!!notifications?.data.length && (
                <ST.HasNotification>
                  <NotificationHas />
                </ST.HasNotification>
              )}
            </ST.NotificationButton>
            <ST.NavItemTitle>Важное</ST.NavItemTitle>
          </ST.NavItem>
        </ST.Content>
      </ST.Container>
      <Notifications
        show={showNotifications}
        onClose={handleNotifications}
        notifications={notifications}
        hasMore={hasMoreNotifications}
        removeNotificationById={removeNotificationById}
        next={loadNextPage}
      />
    </>
  )
}

export default MobileNavBar
