import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { BreakPoints } from 'constants/breakPoints'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`

export const HeaderLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    flex-direction: column;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderTitle = styled.h2`
  font-family: 'Golos-Text_VF', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const Footnote = styled.span`
  margin-top: 10px;
  font-size: 14px;
  color: ${COLORS.black70};
`
