import styled from 'styled-components'
import { EmptyResultTypes as T } from './types'
import { BreakPoints } from 'constants/breakPoints'
import { COLORS } from 'constants/colors'
import { ComponentProps } from 'react'
import BaseButton from 'components/ui/buttons/BaseButton'

export namespace EmptyResult {
  export const Content = styled.div<T.Content>`
    display: flex;
    gap: 24px;

    justify-content: ${({ orientation }) =>
      orientation === 'vertical' ? 'center' : 'space-between'};
    align-items: center;
    border-radius: 10px;
    flex-direction: ${({ orientation }) =>
      orientation === 'horizontal' ? 'row-reverse' : 'column'};

    padding: ${({ orientation }) =>
      orientation === 'horizontal' ? '20px 40px 16px 50px' : '20px 40px'};

    @media (max-width: ${BreakPoints.DESKTOPS2}) {
      gap: 24px;
      flex-direction: column;
      justify-content: center;
      padding: initial;
    }

    @media (max-width: ${BreakPoints.TABLETS}) {
      width: 80%;
    }
  `

  export const NotFoundContainer = styled.div<T.NotFoundContainer>`
    width: 100%;
    display: flex;
    justify-content: ${({ imageAlign }) =>
      imageAlign === 'left'
        ? 'flex-start'
        : imageAlign === 'right'
        ? 'flex-end'
        : 'center'};

    @media (max-width: ${BreakPoints.DESKTOPS2}) {
      justify-content: center;
    }
  `

  export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

  export const Title = styled.h2`
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media (max-width: ${BreakPoints.TABLETS}) {
      font-size: 24px;
    }
  `

  export const Description = styled.p`
    margin-top: 16px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
      font-size: 16px;
    }
  `

  export const ButtonsContainer = styled.div`
    display: flex;
    gap: 16px;

    @media (max-width: ${BreakPoints.TABLETS}) {
      flex-direction: column;
      gap: 8px;
    }
  `

  export const LinkButton = styled.a<{
    typeButton?: ComponentProps<typeof BaseButton>['typeButton']
  }>`
    display: grid;
    place-items: center;
    text-align: center;
    background-color: ${({ typeButton }) =>
      typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
    height: 48px;
    border: 1px solid ${COLORS.yellow};
    border-radius: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${COLORS.black};
    width: 277px;
    transition: 0.1s background-color ease-in-out;

    &:hover {
      background-color: ${COLORS.yellowHover};
      border: 1px solid ${COLORS.yellowHover};
    }

    @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
      width: 100%;
      font-size: 14px;
    }
  `
}
