import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as Contacts } from 'assets/icons/Header/contacts.svg'

interface IIconProps {
  isActive: boolean
}

export const Container = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: ${COLORS.white};
  border-top: 0.5px solid ${COLORS.lightGreyMobile};
  backdrop-filter: blur(10px);
  padding: 0 27px;
  box-sizing: border-box;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const OwnerImg = styled.img<IIconProps>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  ${({ isActive }) =>
    isActive &&
    css`
      border: 2px solid ${COLORS.yellow};
    `}
`

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  width: 75px;
`

export const NavItemTitle = styled.p`
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`

export const NotificationButton = styled.button<IIconProps>`
  width: 28px;
  height: 28px;
  background-color: ${COLORS.white};
  padding: 0;

  svg {
    width: 28px;
    height: 28px;

    ${({ isActive }) =>
      isActive &&
      css`
        * {
          fill: ${COLORS.yellow};
        }
      `}
  }
`

export const HasNotification = styled.div`
  position: absolute;
  top: 4px;

  svg {
    ellipse {
        stroke: ${COLORS.white};
      }
    }
  }
`

export const EmployeesIcon = styled(Contacts)<IIconProps>`
  width: 28px;
  height: 28px;

  ${({ isActive }) =>
    isActive
      ? css`
          * {
            fill: ${COLORS.yellow};
          }
        `
      : css`
          * {
            fill: ${COLORS.black};
          }
        `}
`
