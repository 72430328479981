import React, { FC, useCallback, useEffect, useState } from 'react'
import * as ST from './styled'
import { Checkbox } from './styled'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import { IDocumentTemplate } from 'types/model/documentTemplateType'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import EditDocTemplate from 'components/dialogs/settings/EditDocTemplate'
import {
  deleteDocumentTemplate,
  getDocumentTemplateSettings,
} from 'api/settings'
import { ModalTitles } from 'constants/modalTitles'
import DeleteDocTemplate from 'components/dialogs/DeleteDocTemplate'
import { handlerError } from 'utils/handlerError'
import IconButton from 'components/ui/buttons/IconButton'
import { BreakPoints } from 'constants/breakPoints'
import getFileData from 'utils/file/getFileData'

interface IUserProps {
  docTemplates: IDocumentTemplate[]
}

const StaticDocTemplatesTabContent: FC<IUserProps> = ({ docTemplates }) => {
  const [docs, setDocs] = useState<IDocumentTemplate[]>([])
  const [selectedDoc, setSelectedDoc] = useState<IDocumentTemplate | null>(null)

  const [showModalEditDocTemplate, setShowModalEditDocTemplate] =
    useState<boolean>(false)
  const handleShowEditDocTemplate = useCallback((): void => {
    setShowModalEditDocTemplate((prev) => !prev)
  }, [])

  const [showModalDeleteDocTemplate, setShowModalDeleteDocTemplate] =
    useState<boolean>(false)
  const handleShowDeleteDocTemplate = useCallback((): void => {
    setShowModalDeleteDocTemplate((prev) => !prev)
  }, [])

  const removeItem = async (id: number) => {
    await deleteDocumentTemplate(id)
      .then(() => {
        if (updateDocuments) {
          updateDocuments()
        }
      })
      .catch((err) => handlerError(err))
  }

  const updateDocuments = () => {
    getDocumentTemplateSettings().then((resp) => setDocs(resp))
  }

  useEffect(() => {
    if (docTemplates.length) {
      setDocs(docTemplates)
    }
  }, [docTemplates])

  return (
    <ST.StaticHiringTable>
      <ST.StaticHiringThead>
        <ST.StaticHiringTRTitles>
          <ST.StaticHiringTH style={{ width: '30%' }}>
            Название документа
          </ST.StaticHiringTH>
          <ST.StaticHiringTH style={{ textAlign: 'center', width: '20%' }}>
            Публикация
          </ST.StaticHiringTH>
          <ST.StaticHiringTH style={{ textAlign: 'center', width: '20%' }}>
            Обязательность
          </ST.StaticHiringTH>
        </ST.StaticHiringTRTitles>
      </ST.StaticHiringThead>

      <ST.StaticHiringTBody>
        {docs?.map((doc) => {
          const href =
            typeof doc.files[0] === 'number' ? '' : getFileData(doc.files[0])

          return (
            <ST.StaticHiringTR key={doc.id}>
              {!!doc.files?.length ? (
                <ST.StaticHiringTD>
                  <ST.Link
                    title={doc.name}
                    maxWidth={250}
                    mediaMaxWidth={{
                      [BreakPoints.DESKTOPS]: 175,
                      [BreakPoints.DESKTOPS2]: 150,
                    }}
                    href={href}
                    target={href.includes('.pdf') ? '_blank' : ''}
                  >
                    {doc.name}
                  </ST.Link>
                </ST.StaticHiringTD>
              ) : (
                <ST.StaticHiringTD>{doc.name}</ST.StaticHiringTD>
              )}
              <ST.StaticHiringTD style={{ textAlign: 'center' }}>
                <ST.Checkbox
                  type={'checkbox'}
                  checked={doc.isPublished}
                  disabled
                />
              </ST.StaticHiringTD>
              <ST.StaticHiringTD style={{ textAlign: 'center' }}>
                <Checkbox type={'checkbox'} checked={doc.isRequired} disabled />
              </ST.StaticHiringTD>
              <ST.StaticHiringTD>
                <IconButton
                  icon={EditIcon}
                  onClick={() => {
                    setSelectedDoc(doc)
                    handleShowEditDocTemplate()
                  }}
                />
              </ST.StaticHiringTD>
              <ST.StaticHiringTD>
                <IconButton
                  icon={DeleteButton}
                  onClick={() => {
                    setSelectedDoc(doc)
                    handleShowDeleteDocTemplate()
                  }}
                />
              </ST.StaticHiringTD>
            </ST.StaticHiringTR>
          )
        })}
      </ST.StaticHiringTBody>
      {!!selectedDoc && showModalDeleteDocTemplate && (
        <DeleteDocTemplate
          doc={selectedDoc}
          title={ModalTitles.docTemplateDeleting}
          show={showModalDeleteDocTemplate}
          onClose={handleShowDeleteDocTemplate}
          handleConfirm={removeItem}
        />
      )}
      {showModalEditDocTemplate && !!selectedDoc && (
        <EditDocTemplate
          show={showModalEditDocTemplate}
          onClose={handleShowEditDocTemplate}
          showEndModal={updateDocuments}
          docTemplate={selectedDoc}
        />
      )}
    </ST.StaticHiringTable>
  )
}

export default StaticDocTemplatesTabContent
