import React, { FC } from 'react'
import * as ST from './styled'
import AvatarImage from 'components/ui/image/Avatar'

interface Props {
  photo?: string
  name?: string
  id: number
}

const AuthorLine: FC<Props> = ({ photo, name, id }) => (
  <ST.AuthorLine>
    <ST.Label>Автор заявки</ST.Label>
    <AvatarImage path={photo} size="small" />
    <ST.Name to={`/user/${id}`} maxWidth={400} title={name}>
      {name}
    </ST.Name>
  </ST.AuthorLine>
)

export default AuthorLine
