import React, { useState, FC } from 'react'
import { PasswordViewer as ST } from './styled'
import { ReactComponent as EyeIcon } from 'assets/icons/password-eye.svg'
import { ReactComponent as EyeClosedIcon } from 'assets/icons/password-eye-closed.svg'

export interface Props {
  password?: string
  isInitialVisible?: boolean
  onToggle?: (newValue: boolean) => void
}

const PasswordViewer: FC<Props> = ({
  password,
  isInitialVisible,
  onToggle,
}) => {
  const [isTextVisible, setTextVisibility] = useState<boolean>(
    !!isInitialVisible
  )

  const toggleTextVisibility = () => {
    const newValue = !isTextVisible
    setTextVisibility(newValue)
    onToggle?.(newValue)
  }

  return (
    <ST.Container>
      <ST.EyeButton
        onClick={toggleTextVisibility}
        aria-label="Показать/скрыть пароль"
      >
        {isTextVisible ? <EyeClosedIcon /> : <EyeIcon />}
      </ST.EyeButton>
      <ST.SensitiveText isTextVisible={isTextVisible}>
        {isTextVisible ? password || 'Не указано' : '*****'}
      </ST.SensitiveText>
    </ST.Container>
  )
}

export default PasswordViewer
