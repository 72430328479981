import * as GST from 'components/settings/Tabs/General/styled'
import styled from 'styled-components'

export namespace SettingsHiringTypesStyles {
  export const Container = styled(GST.MainContainer)``

  export const Header = styled(GST.HeaderLine)``

  export const HeaderTitle = styled(GST.HeaderTitle)``

  export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  `
}
