import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IIcon } from 'components/Header/Menu/styled'
import ImageEmployee from 'assets/images/employee.jpg'
import { ReactComponent as EditIconPic } from 'assets/icons/edit.svg'

interface IGridProps {
  columns?: number
}

interface IButton {
  typeButton?: 'secondary' | 'order'
  width?: string
  height?: string
  marginRight?: string
  marginTop?: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const GridBlock = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-auto-flow: dense;
`

export const EditBlockContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px 15px 20px;
  background: ${COLORS.yellow};
  border-radius: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.yellowHover};
  }
`

export const EditText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

export const EditIcon = styled(EditIconPic)``

export const BlockInfo = styled.div`
  padding: 24px;
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 850px;
  }
`

export const Personal = styled(BlockInfo)``
export const Email = styled(BlockInfo)``
export const Department = styled(BlockInfo)``
export const Boss = styled(BlockInfo)``
export const Position = styled(BlockInfo)``
export const Grade = styled(BlockInfo)``
export const SocialMedia = styled(BlockInfo)`
  margin-top: 20px;
`
export const Dismiss = styled(BlockInfo)`
  margin-top: 20px;
`
export const Stack = styled(BlockInfo)`
  padding-bottom: 14px;
  margin-top: 20px;
`

export const HeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: ${COLORS.black};
  font-family: 'Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`

export const HeaderTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin-bottom: 12px;
`

export const Date = styled.div`
  display: flex;
  align-items: center;
`

export const DateText = styled.span`
  margin-left: 8px;
`

export const HeaderDate = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${COLORS.black};
  margin-bottom: 12px;
`

export const RowInfo = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};
  margin-bottom: 9px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const RowDep = styled(RowInfo)`
  margin-bottom: 14px;
`

export const BossRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
`

export const SocialName = styled(RowInfo)`
  margin-bottom: 0;
  color: ${COLORS.black};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
`

export const RowSocial = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const RowSocialStart = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const LinkSocial = styled.a`
  margin-bottom: 12px;
  display: block;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ImageBlock = styled.div<IIcon>`
  background: url(${({ imageSrc }) => imageSrc ?? ImageEmployee}) 50% 50%
    no-repeat;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: 36px 36px;
  position: relative;
`

export const RowStack = styled(RowInfo)`
  margin-bottom: 11px;
  &:last-child {
    margin-bottom: 11px;
  }
`

export const TechBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

export const ContentBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const Knowledge = styled.div`
  padding: 10px 16px;
  background-color: ${COLORS.lightGrey};
  margin-left: 5px;
  margin-bottom: 10px;
  text-align: center;
`

export const BenefitInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  border-radius: 15px;
  cursor: pointer;
`

export const BenefitInfoBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  border-radius: 15px;
  cursor: pointer;
`

export const BenefitButtonBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  border-radius: 15px;
  cursor: pointer;
`

export const DismissList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
`

export const DismissListItem = styled.li`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`

export const DismissListItemTitle = styled.p``
export const DismissListItemReason = styled.p`
  color: ${COLORS.black70};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`
export const DismissListItemDocsTitle = styled(DismissListItemReason)``

export const DismissListItemDocsURL = styled.div``

export const DismissListItemDocs = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`

export const Button = styled.button<IButton>`
  background: ${(props) =>
    props.typeButton === 'secondary' ? COLORS.white : COLORS.yellow};
  border: 1px solid ${COLORS.yellow};
  border-radius: 50px;
  color: ${COLORS.black};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: ${(props) => props.marginRight};
  margin-top: ${(props) => props.marginTop};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background: ${COLORS.yellowActiveButton};
    border: 1px solid ${COLORS.yellowActiveButton};
  }
`
