import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'

type LabelProps = {
  required?: boolean
}

type RadioGroupProps = {
  $orientation?: 'horizontal' | 'vertical'
}

export const Container = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
`

export const Radio = styled.input`
  position: relative;
  border: 2px solid ${COLORS.blue50};
  box-sizing: border-box;
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  height: 18px;
  -webkit-appearance: none;
  cursor: pointer;

  :checked {
    border: none;
    background: ${COLORS.blue};

    :before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border: 2px solid white;
      border-radius: 50%;
    }
  }

  :disabled {
    border: 2px solid ${COLORS.black50};
  }
`

export const Label = styled.label<LabelProps>`
  font-family: 'Golos', sans-serif;
  cursor: pointer;
`

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const RadioGroup = styled.div<RadioGroupProps>`
  display: flex;
  flex-direction: ${({ $orientation }) =>
    $orientation === 'vertical' ? 'column' : 'row'};
  align-items: center;
  gap: 16px;
  min-height: 40px;
`

export const GroupLabel = styled.p<LabelProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: ${COLORS.errorRed};
        margin-left: 4px;
      }
    `};
`
