import styled from 'styled-components'

export namespace VacanciesNotFoundStyles {
  export const Wrapper = styled.div`
    p {
      text-align: center;
      font-style: normal;
    }

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 4px 15px 0 rgba(51, 51, 51, 0.06);
    padding: 0;
    height: 400px;
  `

  export const Container = styled.div`
    display: flex;
    gap: 22px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      margin-bottom: 2px;
    }
  `

  export const Title = styled.p`
    font-size: 30px;
    font-weight: 900;
    line-height: 100%; /* 30px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
  `

  export const Text = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  `
}
