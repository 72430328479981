import styled, { css } from 'styled-components'
import { AutocompleteInputTypes as Types } from './types'
import { COLORS } from 'constants/colors'

export namespace AutocompleteInputStyles {
  export const maxDropdownHeight = 256
  export const listItemHeight = 32

  type RightAdornmentButtonsProps = {
    hasLabel?: boolean
  }

  type ContainerProps = {
    fullWidth?: boolean
    disableClearable?: boolean
  }

  type DropdownListProps = {
    isLoading?: boolean
    hasLabel?: boolean
    hasOverflow?: boolean
    alignToTop?: boolean
  }

  export const Container = styled.div<ContainerProps>`
    position: relative;
    width: ${({ fullWidth }) =>
      fullWidth ? 'max(50px, 100%)' : 'clamp(50px, 100%, 300px)'};

    input {
      padding-right: ${({ disableClearable }) =>
        disableClearable ? '40px' : '75px'};
    }
  `

  export const DropdownList = styled.div<DropdownListProps>`
    display: flex;
    width: 100%;
    min-height: ${listItemHeight}px;
    max-height: ${maxDropdownHeight}px;
    box-sizing: border-box;
    background: ${COLORS.white};
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    border-radius: ${({ hasOverflow }) =>
      hasOverflow ? '10px 0 0 10px' : '10px'};
    padding: ${({ isLoading }) =>
      isLoading ? `${(listItemHeight - 18) / 2}px 0` : 0};
    flex-direction: column;
    position: absolute;
    z-index: 2;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent white;

    ${({ alignToTop, hasLabel }) =>
      alignToTop ? `top: ${hasLabel ? '68px' : '42px'}` : `bottom: 42px`};

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
    }

    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: gray;
      }

      scrollbar-color: gray white;
    }
  `

  export const OptionGroup = styled.optgroup`
    background-color: ${COLORS.gray20};
    font-weight: 500;
    color: ${COLORS.black70};
  `

  export const ListItem = styled.option<Types.ListItemProps>`
    background-color: ${COLORS.white};
    font-size: 16px;
    line-height: 140%;
    color: ${COLORS.gray70};
    position: relative;
    max-width: calc(100% - 24px);
    display: flex;
    align-items: center;
    justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
    padding: 8px 12px;
    transition: all 0.15s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: ${listItemHeight}px;

    &:disabled {
      opacity: 0.5;
      cursor: default;
      user-select: none;
    }

    &:enabled {
      cursor: pointer;
    }

    &:enabled:hover {
      background-color: ${COLORS.gray50};
      color: ${({ active }) => (active ? COLORS.black : COLORS.black70)};
    }

    &:enabled:focus {
      background-color: ${COLORS.gray50};
      color: ${COLORS.black70};
    }

    &:first-of-type {
      border-radius: ${({ hasOverflow, hasGroupBy }) =>
        hasGroupBy ? 0 : hasOverflow ? '10px 0 0 0' : '10px 10px 0 0'};
    }

    &:only-of-type {
      border-radius: ${({ hasGroupBy }) => (hasGroupBy ? 0 : '10px')};
    }

    ${({ active }) =>
      active &&
      css`
        color: ${COLORS.black};
        background-color: ${COLORS.gray20};
      `}
  `

  export const RightAdornmentButtons = styled.div<RightAdornmentButtonsProps>`
    position: absolute;
    top: ${({ hasLabel }) => (hasLabel ? '27px' : 0)};
    right: 0;
    display: flex;
    align-items: center;
  `
}
