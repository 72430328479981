import styled from 'styled-components'
import { COLORS } from 'constants/colors'

type StatusCircleProps = {
  color?: 'green' | 'red'
}

export const AuthorLine = styled.div`
  width: 100%;
`

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

export const Name = styled.p`
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`

export const Department = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.black70};
  margin-top: 4px;
`

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`

export const StatusCircle = styled.div<StatusCircleProps>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${({ color }) => color ?? 'green'};
`

export const StatusName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: ${COLORS.gray70};
`
