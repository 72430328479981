import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import * as ST from './styled'
import { ButtonTypes } from 'constants/buttonTypes'
import { SettingsSteps } from 'constants/settingsSteps'
import { getDocumentTemplateSettings } from 'api/settings'
import StaticDocTemplatesTabContent from './StaticDocTemplatesTabContent'
import { IDocumentTemplate } from 'types/model/documentTemplateType'
import CreateDocTemplate from 'components/dialogs/settings/CreateDocTemplate'

interface IUserProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const DocTemplatesTab: FC<IUserProps> = ({ setIsEditing, isEditing, step }) => {
  const [docTemplates, setDocTemplates] = useState<IDocumentTemplate[]>([])
  const [showModalCreateDocTemplate, setShowModalCreateDocTemplate] =
    useState<boolean>(false)

  const handleShowCreateDocTemplate = useCallback((): void => {
    setShowModalCreateDocTemplate((prev) => !prev)
  }, [])

  const updateDocuments = () => {
    getDocumentTemplateSettings().then((resp) => setDocTemplates(resp))
  }

  useEffect(() => {
    getDocumentTemplateSettings().then((resp) => setDocTemplates(resp))
  }, [])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>Шаблоны документов</ST.EditingHeader>
          </ST.BackBlock>
          <ST.SaveButton
            type={ButtonTypes.submit}
            onClick={handleShowCreateDocTemplate}
          >
            Добавить документ
          </ST.SaveButton>
        </ST.ManageBlock>
      )}
      {!!docTemplates.length && (
        <StaticDocTemplatesTabContent docTemplates={docTemplates} />
      )}
      {showModalCreateDocTemplate && (
        <CreateDocTemplate
          show={showModalCreateDocTemplate}
          onClose={handleShowCreateDocTemplate}
          showEndModal={updateDocuments}
        />
      )}
    </ST.MainContainer>
  )
}

export default DocTemplatesTab
