import React, { FC, PropsWithChildren, useMemo } from 'react'
import * as ST from './styled'
import LinkItem from 'assets/icons/linkBlue.svg'
import { ISettingSubsection, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { ReactComponent as Star } from 'assets/icons/star.svg'
import getFileData from 'utils/file/getFileData'

interface IContacts {
  data: ISettingSubsection[]
}

type InfoBlockProps = { title: string } & (
  | { value: string }
  | ({ settingsKey: SettingsKeys } & IContacts)
  | PropsWithChildren<any>
)

const InfoBlock: FC<InfoBlockProps> = ({ title, data, children, ...props }) => {
  const value = useMemo<string | undefined>(
    () =>
      'value' in props
        ? props.value
        : 'settingsKey' in props
        ? getSettingByKey(data[0].settings, props.settingsKey)?.value
        : undefined,
    [data, props]
  )

  return (
    <ST.BlockInfo>
      <ST.HeaderBlock>{title}</ST.HeaderBlock>
      {children ? children : value ? <ST.RowInfo>{value}</ST.RowInfo> : null}
    </ST.BlockInfo>
  )
}

const StaticContactsTabContent: FC<IContacts> = ({ data }) => {
  return (
    <ST.MainContainer>
      <ST.GridBlock>
        <InfoBlock
          settingsKey={SettingsKeys.ADDRESS}
          title="Адрес"
          data={data}
        />
        <InfoBlock
          settingsKey={SettingsKeys.SITE_URL}
          title="Сайт компании"
          data={data}
        />
      </ST.GridBlock>
      <ST.GridBlock>
        <InfoBlock
          settingsKey={SettingsKeys.PHONE}
          title="Телефон"
          data={data}
        />
        <InfoBlock settingsKey={SettingsKeys.EMAIL} title="Email" data={data} />
      </ST.GridBlock>
      {data.length >= 2 && (
        <ST.GridBlock>
          <InfoBlock title="Социальные сети и мессенджеры">
            {data[1].settings.map((soc) => (
              <ST.LinkSocial
                key={soc.id}
                href={soc.value}
                target="_blank"
                rel="noreferrer"
              >
                <ST.RowSocial>
                  <ST.RowSocialStart>
                    {soc.img && getFileData(soc.img) ? (
                      <ST.ImageBlock imageSrc={getFileData(soc.img)} />
                    ) : (
                      <Star />
                    )}
                    <ST.SocialName>{soc.value}</ST.SocialName>
                  </ST.RowSocialStart>
                </ST.RowSocial>
              </ST.LinkSocial>
            ))}
          </InfoBlock>
        </ST.GridBlock>
      )}
      {data.length >= 3 && (
        <ST.GridBlock>
          <InfoBlock title="Портфолио и другие ссылки">
            {data[2]?.settings.map((portfolio) => (
              <ST.LinkSocial
                key={portfolio.id}
                href={portfolio.value}
                target="_blank"
                rel="noreferrer"
              >
                <ST.RowSocial>
                  <ST.RowSocialStart>
                    {getFileData(portfolio.img) ? (
                      <ST.ImageBlock imageSrc={getFileData(portfolio.img)} />
                    ) : (
                      <Star />
                    )}
                    <ST.SocialName>{portfolio.value}</ST.SocialName>
                  </ST.RowSocialStart>
                </ST.RowSocial>
              </ST.LinkSocial>
            ))}
          </InfoBlock>
        </ST.GridBlock>
      )}
    </ST.MainContainer>
  )
}

export default StaticContactsTabContent
