export enum ButtonLabels {
  save = 'Сохранить',
  confirm = 'Подтвердить',
  apply = 'Применить',
  reject = 'Отклонить',
  reset = 'Сбросить',
  cancel = 'Отмена',
  add = 'Добавить',
  giveAccess = 'Выдать доступ',
  withdrawAccess = 'Изъять доступ',
  withdrawAccessAll = 'Изъять доступы',
  rejectApplication = 'Отклонить заявку',
  approveBenefitApplication = 'Одобрить заявку',
  editBenefitApplication = 'Изменить заявку',
  editVacancy = 'Изменить вакансию',
  rejectBenefitApplication = 'Отклонить',
  cancelBenefitApplication = 'Отменить',
  downloadLog = 'Скачать журнал',
  sendApplication = 'Отправить заявку',
  dismiss = 'Уволить',
  forbiddenError = 'Перейти',
  closeReview = 'Отменить проведение',
  changeGeneralSurvey = 'Изменить ответы на общий опрос',
  changeSurvey = 'Изменить ответы на опрос 360',
  takeGeneralSurvey = 'Пройти общий опрос',
  takeSurvey = 'Пройти опрос 360',
  takeInWork = 'Взять в работу',
  closeVacancy = 'Закрыть',
  serviceModeIsOn = 'Сервисный режим включен',
}
