import styled from 'styled-components'
import { COLORS } from 'constants/colors'

export const DepartmentHead = styled.div`
  display: flex;
  align-items: center;
`

export const DepartmentBody = styled.div`
  margin-top: 30px;
`

export const DepartmentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  background: ${COLORS.absoluteWhite};
  color: ${COLORS.black};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 30px;
  font-size: 18px;
  line-height: 25px;
`

export const DepartmentsTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;
  margin-left: 16px;
`

export const Button = styled.button`
  background: #ffdc24;
  border-radius: 50px;
  padding: 13px 43px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background-color: ${COLORS.yellowHover};
  }
`
