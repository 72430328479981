import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as CalendarIcon } from 'assets/icons/Applications/calendarBlack.svg'
import StatusBlock from './StatusBlock'
import moment from 'moment/moment'
import { DATE_FORMAT_RU } from 'constants/Date'
import { BenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'

interface Props {
  date: string
  status: BenefitApplicationStatuses
}

const TitleLine: FC<Props> = ({ date, status }) => (
  <ST.TitleLine>
    <ST.Info>
      <ST.Date>
        <CalendarIcon />
        <ST.DateText>{moment(date).format(DATE_FORMAT_RU)}</ST.DateText>
      </ST.Date>
      <StatusBlock status={status} />
    </ST.Info>
  </ST.TitleLine>
)

export default TitleLine
