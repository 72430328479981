type FileDto = {
  path?: string
  url?: string
}

const getFileData = <T extends FileDto>(
  target: T | undefined | null
): string => {
  return target?.url || target?.path || ''
}

export default getFileData
